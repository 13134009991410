import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { responsiveReverseFlexDirection } from "../../../../../utilities/commonStyles/commonStyles";

function Singlepicmcq({ question, instruction, qno, qtot, setActiveSubmit }) {
  const [optionChossed, setOptionChoosed] = useState();
  const [optclick, setoptClicks] = useState([]);
  useEffect(() => {
    let newarr = [];
    for (let i = 0; i < qtot; i++) newarr[i] = false;
    setoptClicks(newarr);
  }, [question]);
  //console.log(optclick);
  let cnt = 0;
  const optionclick = (id) => {
    setOptionChoosed(id + 1);
    let ans = {
      qno: qno,
      type: "mcqpic",
      correct: question.options[id].iscorrect,
    };
    localStorage.setItem("answer", JSON.stringify(ans));

    let newarr = [];
    for (let i = 0; i < 4; i++) {
      /// length of arr not less than 4 it will be error
      if (i == id) {
        newarr[i] = true;
      } else {
        newarr[i] = false;
      }
    }
    setoptClicks(newarr);
    setActiveSubmit(true);
  };
  //console.log(question);
  return (
    <Box sx={{ padding: "30px 30px 30px 15px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          ...responsiveReverseFlexDirection,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "100px",
          my: "30px",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "40%" } }}>
          {question &&
            question.options.map((item, id) => {
              cnt++;
              return (
                <Box
                  key={id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                    py: "20px",
                    borderBottom: "1px solid #00495F80",
                    "&:last-child": { borderBottom: "none" },
                  }}
                >
                  <Box
                    onClick={() => optionclick(id)}
                    style={{
                      background: `${optclick[id] ? "#003A69" : "white"}`,
                      color: `${optclick[id] ? "#fff" : "#000"}`,
                    }}
                    sx={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      border: "1px solid #00495F",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>{cnt}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 600,
                        fontSize: { xs: "12px", md: "16px" },
                      }}
                    >
                      {item.option}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "60%" }, padding: "20px" }}>
          <img
            style={{ width: "100%", borderRadius: "2px", height: "30vh" }}
            src={question.url}
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Singlepicmcq;

{
  /* 
  <div className="mcq">
      <div className="mcq-main">
        <div className="mcq-main-question-no">
          Questin {qno} of {qtot}
        </div>
        <div className=" mcq-main-questin">Questin : {question.question}</div>
        <div className="mcq-main-inst">{instruction}</div>
        <div className="mcq-main-cnt">
          <div className="mcq-main-options">
            {question &&
              question.options.map((item, id) => {
                cnt++;
                return (
                  <div className="mcq-main-options-item">
                    <div className="mcq-main-options-item-icon">
                      <div
                        className="mcq-main-options-item-icon-cnt"
                        onClick={(e) => optionclick(e)}
                        style={{
                          backgroundColor: `${
                            optclick[cnt] ? "#004577 " : "white"
                          }`,
                          color: `${optclick[cnt] ? "white " : "black"}`,
                        }}
                      >
                        {cnt}
                      </div>
                    </div>
                    <div className="mcq-main-options-item-txt">
                      {item.option}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="mcq-main-pic">
            <img
              src={question.url}
              className="mcq-main-options-item-drag-image"
              style={{
                height: "inherit",
                width: "30vh",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  */
}
