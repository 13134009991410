import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import img from "./Vector.svg";
import "./mobile.css";

const HrTopNavbar = ({ children, mainStyle, title, str, index }) => {
  return (
    <Box {...mainStyle}>
      <Box
        // data-aos="fade-right"
        // data-aos-duration="1500"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          alignItems: "center",
          // borderBottom:
          //   title == "Culture Index" ? "0px #6846C7" : "1px solid #6846C7",
          // py: str?.py ?? "30px",
          p: str?.py ?? "20px 30px",
          // ml: str?.ml ?? "2.5vw",
          width: str?.w ?? "100%",
        }}
      >
        <Box
          // data-aos="fade-right"
          // data-aos-duration="1500"
          sx={{
            fontSize: "40px",
            fontWeight: 600,
            fontFamily: "Poppins",
            display: "flex",
            alignItems: "center",
            "@media (max-width: 780px)": {
              fontSize: "20px",
            },
          }}
        >
          {index && (
            <Box>
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  "@media (max-width: 780px)": {
                    fontSize: "20px",
                  },
                }}
              >
                {index}.&nbsp;
              </Typography>
            </Box>
          )}

          {!index && (
            <Box
              sx={{
                marginTop: "-3vh",
                "@media (max-width: 780px)": {
                  marginTop: "-1vh",
                },
              }}
            >
              {" "}
              <img src={img} alt="" className="NavbarTopImg" />
            </Box>
          )}
          {index && (
            <Box
              sx={{
                marginTop: "-2vh",
                "@media (max-width: 780px)": {
                  marginTop: "-1vh",
                },
              }}
            >
              {" "}
              <img src={img} alt="" className="NavbarTopImg" />
            </Box>
          )}

          {title}
        </Box>
        <Box>{children}</Box>
      </Box>
      <Divider
        // data-aos="fade-right"
        // data-aos-duration="1000"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          alignItems: "center",
          borderBottom:
            title == "Culture Index" ? "0px #6846C7" : "1px solid #6846C7",
          ml: str?.ml ?? "2.5vw",
          width: str?.w ?? "95%",
        }}
      />
    </Box>
  );
};

export default HrTopNavbar;
