import React from "react";

function GroupEnrolments({ clr }) {
  return (
    <svg
      width="auto"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2361 7.17188C27.5245 8.75813 27.7941 12.5381 25.494 15.5756C24.0564 17.4656 21.307 18.7481 19.2765 18.5794L14.5325 27H12.6457L7.8838 18.5794C5.85324 18.8494 2.97811 17.5163 1.50461 15.5588C-0.795495 12.5213 -0.525952 8.74125 2.78045 7.17188C3.64298 6.76687 4.50552 6.615 5.35009 6.64875C5.33212 7.32375 5.36806 7.99875 5.47588 8.69063C5.94309 11.5425 8.00958 14.6475 10.6152 16.065C10.2737 16.9931 9.73466 17.7356 9.03385 18.1744L12.6457 24.6038V15.0525C10.2019 14.4281 7.72207 11.4075 7.25486 8.4375C6.53608 3.96562 9.05182 0 13.5442 0C18.0366 0 20.5523 3.96562 19.8335 8.4375C19.3663 11.4075 16.8865 14.4281 14.4427 15.0525V24.7894L18.1624 18.3094C17.3717 17.9213 16.7787 17.1112 16.3834 16.1156C19.0429 14.715 21.1453 11.5763 21.6125 8.69063C21.7204 7.99875 21.7563 7.30687 21.7383 6.63187C22.5649 6.615 23.3915 6.76687 24.2361 7.17188Z"
        fill={clr}
      />
    </svg>
  );
}

export default GroupEnrolments;
