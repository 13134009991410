import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { useNavigate } from "react-router";
import { getCookie } from "../../../../Cookies";
import "./Print.css";

const PoliciesPrint = () => {
  const [PoliciesArr, setPoliciesArr] = useState([]);

  const [policiesState, setPolociesState] = useState({});
  const companyId = localStorage.getItem("companyId");

  const [refflag, setrefflag] = useState(true);

  const navigate = useNavigate();

  const functionCallOnSelect = (data) => {
    const arr = [];
    for (let i = 0; i < PoliciesArr.length; i++) {
      if (i == data.index) {
        let obj = {};
        obj.heading = PoliciesArr[i].heading;
        obj.description = PoliciesArr[i].description;
        obj.isAgree = data.ans;
        obj.type = PoliciesArr[i]?.type;
        console.log(obj);
        arr.push(obj);
      } else {
        arr.push(PoliciesArr[i]);
      }
    }
    let configrecrut = {
      method: "patch",

      url: `${IP}/policies?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(arr),
    };

    axios(configrecrut)
      .then((res) => {
        fetchdata();
      })
      .catch((ee) => {
        //   console.log("t");
      });

    //console.log(PoliciesArr);
    //setPolociesState((prev) => ({ ...prev, [name]: data.ans }));
  };

  useEffect(() => {
    setrefflag(!refflag);
    // setgenderbyRegionStates(ex);
  }, [PoliciesArr]);

  const fetchdata = () => {
    let configrecrut = {
      method: "get",

      url: `${IP}/policies?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setPoliciesArr([]);
      /// console.log(PoliciesArr);
      // console.log(res.data);
      setPoliciesArr(res.data);
    });
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <Box className="pagebreak">
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Policies"} index={4}></HrTopNavbar>
      </Box>

      <Stack
        spacing={4}
        className="tablebgColorForHr"
        style={{
          marginTop: "24px",
          backgroundColor: "#F3F1FF",

          width: "97%",
          marginLeft: "auto",
          marginRight: "auto",

          marginBottom: "100px",
        }}
      >
        {PoliciesArr?.map((el, index) => (
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "6fr 1fr",

              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 18fr 2fr",
              }}
            >
              <Box
                sx={{
                  color: "#000",
                  backgroundColor: "#F3F1FF",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                {index + 1}.
              </Box>
              <Box sx={{ whiteSpace: "initial" }}> {el.heading} </Box>
            </Box>

            <Box style={{ display: "flex", gap: "20px" }}>
              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree ? "#E1F566" : "#BCBCBC",
                }}
                onClick={() => {
                  functionCallOnSelect({
                    heading: el.heading,
                    ans: true,
                    index,
                  });
                }}
              >
                Yes
              </Button>

              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree === false ? "#E1F566" : "#BCBCBC",
                }}
                onClick={() => {
                  functionCallOnSelect({
                    heading: el.heading,
                    ans: false,
                    index,
                  });
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default PoliciesPrint;
