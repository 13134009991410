import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import {
  AccordionDownArrowIcons,
  AccordionUpArrowIcons,
  ContentCourseChildAccordionQuizIcons,
  TickCompleted,
} from "../../../../utilities/Icons/Icons";
import ItemsTimeLine from "./ItemsTimeLine";
import QuizTimeLine from "./QuizTimeLine";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
const TopicTimeLine = ({ topicId }) => {
  const [accordion, setAccordion] = useState(null);
  const [topicInfo, setTopicInfo] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { courseId } = useContext(ItemContext);

  const getTopicProgress = httpsCallable(functions, "getTopicProgress");
  const getTopicInfo = httpsCallable(functions, "getTopicInfo");
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userCourseDetails/topic`,
      params: { topicId: topicId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(configrecrut).then((result) => setTopicInfo(result.data));

    let configTopicProg = {
      method: "get",

      url: `${IP}/userProgress/topic`,
      params: { courseId: courseId, topicId: topicId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(configTopicProg).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
    });
  }, []);

  return (
    <Box>
      <Timeline className={"timeline"}>
        <Box>
          <TimelineItem className={"timeline_firstItem"}>
            <TimelineSeparator>
              <TimelineDot className={"timeline_dot_header"}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="7.5" stroke="black" />
                </svg>
              </TimelineDot>
              {accordion && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box
                onClick={() => setAccordion(!accordion)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "2px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Lato",
                      color: "#000",
                      fontWeight: 400,
                    }}
                  >
                    {topicInfo?.topicName}
                  </Typography>
                </Box>
                {completed && (
                  <TickCompleted
                    style={{ marginLeft: "auto", marginRight: "30px" }}
                  />
                )}
                <Box>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: "0px",
                    }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    {accordion ? (
                      <AccordionDownArrowIcons />
                    ) : (
                      <AccordionUpArrowIcons />
                    )}
                  </button>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          {accordion && (
            <Box>
              {topicInfo?.extraInfo.items?.map((itemId, index, arr) => (
                <ItemsTimeLine
                  key={index}
                  itemId={itemId}
                  connect={
                    topicInfo.extraInfo.quizzes || index != arr.length - 1
                  }
                />
              ))}

              {/* quizItem */}
              {topicInfo?.extraInfo.quizzes?.map((quizId, index, arr) => (
                <QuizTimeLine
                  key={index}
                  quizId={quizId}
                  connect={index != arr.length - 1}
                />
              ))}
            </Box>
          )}
        </Box>
      </Timeline>
    </Box>
  );
};

/* export const CustomTimelineSeparator = () => (
    <TimelineSeparator className={'separator_content_padding'}>
        <TimelineDot className={'timeline_dot'} />
        <TimelineConnector />
    </TimelineSeparator>
) */

export default TopicTimeLine;
