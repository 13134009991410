import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import QuizItem from "../Quiz/QuizItem";
import QuizEndPage from "./QuizEndPage";
import QuizStartPage from "./QuizStartPage";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import HrloadingScreen from "../../../LodingHrscreen";
const QuizContainer = ({ quizId }) => {
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const { courseId } = useContext(ItemContext);
  const [finalScore, setFinalScore] = useState(0);
  const [mode, setMode] = useState(0);
  const [timeTaken, setTimeTaken] = useState(null);
  const [quizInfo, setQuizInfo] = useState(null);
  const [questionSet, setQuestionSet] = useState([]);
  const [lodingscreen, setlodingscreen] = useState(true);
  useEffect(() => {
  setQuizInfo(null)
setQuestionSet([])
    setlodingscreen(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/userCourseDetails/quiz`,
      params: { quizId: quizId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((result) => setQuizInfo(result.data));

    let configQuestionset = {
      method: "get",

      url: `${IP}/userCourseDetails/questionSet`,
      params: { questionSetId: quizId, courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configQuestionset).then((res) => {
      const typeMap = {
        MCQ: "MCQ",
        "Choose the correct sequence": "rearange",
        "Picture MCQ": "singlepicMCQ",
        "Choose the correct image": "MCQpic",
        "Fill in the blank": "fillblanks",
        "Match the pair": "matchpair",
        "Match the pairs picture based": "matchpic",
        trueAndFalse: "trueAndFalse",
      };
      let questionData = [];
      for (let questionId of res.data.questionData) {
        // //console.log(question);
        //console.log(questionId);
        if (questionId.questionType === "MCQ") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            options: questionId.data.options,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "trueAndFalse") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            answer: questionId.data.answer,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "Choose the correct sequence") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            answer: questionId.data.answer,
            options: questionId.data.options,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "Picture MCQ") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            url: questionId.data.base64,
            options: questionId.data.options,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "Choose the correct image") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            correctImage: questionId.data.correctImage,
            imageOptions: questionId.data.imageOptions,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "Fill in the blank") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            answer: questionId.data.answer,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (questionId.questionType === "Match the pair") {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            lhs: questionId.data.LHS,
            rhs: questionId.data.RHS,
            pair: questionId.data.pair,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        } else if (
          questionId.questionType === "Match the pairs picture based"
        ) {
          let dataEle = {
            completed: false,
            question: questionId.data.question,
            imageOptions: questionId.data.imageOptions,
            textOptions: questionId.data.textOptions,
            questionInstruction: questionId.data.questionInstruction,
            type: typeMap[questionId.questionType],
          };
          questionData.push(dataEle);
        }
      }
      // setQuestionSet(questionData);
      setQuestionSet(questionData);
      setlodingscreen(false);
      console.log(questionData.length);
      if (questionData.lenth != 0) {
        setMode(1);
      }
    });
  }, [quizId]);
  useEffect(() => {
    setlodingscreen(false);
  }, [mode]);
  return (
    <div>
      <HrloadingScreen open={lodingscreen} />
      {/* {mode == 0 && (
        <QuizStartPage
          setMode={setMode}
          questionSet={questionSet}
          quizInfo={quizInfo}
        />
      )} */}
      {mode == 1 && !lodingscreen && (
        <QuizItem
          quizInfo={quizInfo}
          questionSet={questionSet}
          setMode={setMode}
          setFinalScore={setFinalScore}
        />
      )}
      {mode == 2 && (
        <QuizEndPage
          score={finalScore}
          quizInfo={quizInfo}
          setMode={setMode}
          questionSet={questionSet}
          quizId={quizId}
        />
      )}
    </div>
  );
};

export default QuizContainer;
