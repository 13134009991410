import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import deleteimg from "./delete.svg";
import editimg from "./edit.svg";
import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../Cookies";

const DontSendMailToMails = (props) => {
  const { onClose, open, delfun, itemName } = props;
  const handleClose = () => {
    onClose();
  };

  const [combainarr, setcombainarr] = useState([9, 9, 9]);
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("");

  const [emailList, setEmailList] = useState([]);
  const [index, setIndex] = useState("");

  const [type, setType] = useState("add");
  const [renderState, setrenderState] = useState(false);
  useEffect(() => {
    let configrecrutci = {
      method: "get",

      url: `${IP}/company/dnd`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configrecrutci)
      .then((res) => {
        setEmailList(res.data.email);
      })
      .catch((er) => {
        console.log(er);
      });
  }, [renderState]);
  const editFun = (index, data) => {
    setIndex(index);
    setInputId(data);

    setType("edit");
  };

  const deleteFun = (index) => {
    let temparr = emailList;
    let deletedEmail = temparr.splice(index, 1);
    console.log(temparr);
    setEmailList([]);
    setrenderState(!renderState);
    setEmailList(temparr);

    const data = JSON.stringify({
      email: temparr,
    });
    let configrecrutci = {
      method: "post",

      url: `${IP}/company/dnd`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configrecrutci)
      .then((res) => {
        console.log("deleted");
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const saveFunction = (data) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputId)) {
      // return (true)
    } else {
      alert("You have entered an invalid email address!");
      return false;
    }

    if (type == "edit") {
      let temparr = emailList;
      temparr[index] = inputId;

      const data = JSON.stringify({
        email: temparr,
      });
      let configrecrutci = {
        method: "post",

        url: `${IP}/company/dnd`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };
      axios(configrecrutci)
        .then((res) => {
          // console.log("deleted");
        })
        .catch((er) => {
          console.log(er);
        });
      setEmailList(temparr);
    } else {
      let temparr = emailList;
      temparr.push(inputId);

      const data = JSON.stringify({
        email: temparr,
      });
      let configrecrutci = {
        method: "post",

        url: `${IP}/company/dnd`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };
      axios(configrecrutci)
        .then((res) => {
          console.log("deleted");
        })
        .catch((er) => {
          console.log(er);
        });
      setEmailList(temparr);
    }

    setIndex("");
    setInputId("");

    setType("add");
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="300px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "400px",
          width: "500px",
          padding: "16px",

          background: "#F3F1FF",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <button
          onClick={() => {
            if (loading) return;
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>

      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <input
            value={inputId}
            onChange={(e) => {
              setInputId(e.target.value);
            }}
            type="email"
            required={true}
            style={{
              width: "18vw",
              height: "40px",
              background: "#FFFFFF",
              color: "#000000",
              border: "1px solid #d7d7d7",
              fontFamily: "Poppins",
              fontSize: "16px",
              borderRadius: "16px",
              padding:'12px'
            }}
            placeholder="Email Id "
          />
          <Button
            onClick={saveFunction}
            sx={{
              width: "71px",
              height: "42px",
              borderRadius: "16px",
              background: "#6846C7",
              color: "#FFFFFF",
            }}
          >
            {" "}
            Save
          </Button>

          {/* <Button
            sx={{
              width: "71px",
              height: "42px",
              borderRadius: "16px",
              background: "#6846C7",
              color: "#FFFFFF",
            }}
          >
            {" "}
            Add
          </Button> */}
        </div>

        <Box
          sx={{
            mt: "20px",
            border: "1px solid rgba(0, 0, 0, 0.4);",
          }}
        >
          {" "}
        </Box>
        <Box
          sx={{
            mt: "24px",
            borderRadius: "20px",
            padding: "16px 24px",
            zIndex: "6",
            background: "#FFFFFF",
          }}
        >
          <Box
            sx={{
              zIndex: "1",
              display: "grid",
              gridTemplateColumns: "1fr 4fr 2fr",

              fontFamily: "Poppins",
              fontSize: "16px",
              height: "72px",
              fontWeight: "700",
              alignContent: "center",
              alignItems: "start",
              color: "#000000",
              borderBottom: "1px solid #BFBFBF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              {" "}
              Sr. no.
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              {" "}
              Email
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              {" "}
              Action
            </Box>
          </Box>

          {emailList?.map((el, index) => (
            <Box
              sx={{
                display: "grid",

                gridTemplateColumns: "1fr 4fr 2fr",
                gridRow: "40px",

                background: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "16px",
                height: "60px",
                fontWeight: "700",
                alignContent: "center",
                color: "#666666",
                borderBottom: "1px solid #BFBFBF",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                {" "}
                {index + 1}
              </Box>
              {/* <Box> {el.employeeName}</Box> */}

              <Box
                //   onClick={() => {
                //     if (el.certificateLink != "") {
                //       window.open(el.certificateLink, "_blank");
                //     }
                //   }}
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                {el}
              </Box>

              <Box
                //   onClick={() => {
                //     if (el.certificateLink != "") {
                //       window.open(el.certificateLink, "_blank");
                //     }
                //   }}
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Button
                  onClick={() => {
                    editFun(index, el);
                    // setInputId("dddf");
                  }}
                >
                  {" "}
                  <img src={editimg} alt="" />
                </Button>
                <Button
                  onClick={() => {
                    deleteFun(index);
                    // setInputId("dddf");
                  }}
                >
                  {" "}
                  <img src={deleteimg} alt="" />
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DontSendMailToMails;
