import axios from "axios";
import { IP } from "./baseUrlAndConfig";
// let data = JSON.stringify({
//     refresh_token: refToken,
//   });
const uirole = async (bearer) => {
  try {
    var config = {
      method: "get",

      url: `${IP}/uiPermissions`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearer}`,
      },

      data: "",
    };

    if (bearer) {
      let resDtaa = await axios(config);

      return resDtaa.data;
      console.log(resDtaa.data);
    }
  } catch (er) {
    console.log(er);
  }
};

export { uirole };
