import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { functions } from "../../../../Firebase";
import { ContentCourseChildAccordionQuizIcons } from "../../../../utilities/Icons/Icons";
import circulMoveImg from "../../circulImg.svg";
import empatyImg from "../../onlyCirculImg.svg";
import { ItemContext } from "../Context/ItemContext";
const QuizTimeLine = ({ quizId, connect }) => {
  const [quizInfo, setQuizInfo] = useState(null);
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuizProgress = httpsCallable(functions, "getQuizProgress");

  const { courseId, itemsArray, setProgressIndex, isLinear } =
    useContext(ItemContext);
  const access = !isLinear;
  const [completed, setCompleted] = useState(false);
  const setMainItem = (element) => element.id == quizId;
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userCourseDetails/quiz`,
      params: { quizId: quizId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(configrecrut).then((result) => setQuizInfo(result.data));

    let configrecrutprog = {
      method: "get",

      url: `${IP}/userProgress/quiz`,
      params: { courseId: courseId, quizId: quizId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(configrecrutprog).then((result) => {
      console.log(result);
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
  }, []);
  return (
    <TimelineItem>
      <TimelineSeparator className={"separator_content_padding"}>
        {/* <TimelineDot className={"timeline_dot"} />
        {connect && <TimelineConnector />} */}

        <TimelineConnector style={{ marginBottom: "4px" }} />
        {completed ? (
          <img src={circulMoveImg} style={{ margin: "auto" }} />
        ) : (
          <img src={empatyImg} style={{ margin: "auto" }} />
        )}
        {connect && <TimelineConnector style={{ width: "0px" }} />}
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            pt: "6px",
          }}
        >
          <ContentCourseChildAccordionQuizIcons />
          {console.log(completed)}
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: 400,
              color: "#000000",
              textDecoration: "underline",
              cursor: completed || access ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (completed || access)
                setProgressIndex(itemsArray.findIndex(setMainItem));
            }}
          >
            {quizInfo?.quizName}
          </Typography>
          {/* {completed && (
            <TickCompleted
              style={{ marginLeft: "auto", marginRight: "30px" }}
            />
          )} */}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
export default QuizTimeLine;
