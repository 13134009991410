import React from "react";
import OverallRepresentation from "../../../../Hr/home/component/OverallRepresentation";
import HomePageHr from "../../../../Hr/home/homeHr";
import { useLocation } from "react-router";

const DEIReportAdmin = () => {
  const location = useLocation();
  console.log(location);
  return (
    <div>
      <HomePageHr admin={true} companyId={location.state.companyId} />
    </div>
  );
};

export default DEIReportAdmin;
