import React, { useEffect, useState } from "react";
import Sidebar from "../../../Component/Sidebar";
import { t } from "i18next";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavbarTop from "../../../Component/NavbarTop";

import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CompanyReport = () => {
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <section className="dashboard">
      <NavbarTop page={t("Company Report")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div className="outer-bigger-container">
            {/* Company settings */}

            <Button
              variant="contained"
              style={{
                marginBottom: "20px",
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button> 

            <h1>Company Details</h1>
              <div className="create-new-box" style={{display:'flex',gap:'20px',justifyContent:'flex-end'}}>
                <Link to={{ pathname: "/admin/settings/archieveCompaniesReport" }}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    
                  >
                    Archived Companies
                  </Button>
                </Link>

              </div>
            <div
              className="bigger-container"
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              <div> Company Name</div>
              <div> Company Id </div>

              <div style={{ display: "flex" }}>
                <div>Action</div>
              </div>
            </div>
            <div className="setting_details_table">
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}

              {template?.map((ele) => {
                return (
                  <div className="outer-inner-container">
                    <div className="templatename">{ele.name}</div>
                    <div
                      className="templatename"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {ele.companyId}
                    </div>
                    <div className="button-box">
                      {/* //assign HR */}
                      <div className="button-box-inner mx-3 my-1">
                        <Link
                          to={{
                            pathname: "/admin/settings/companyReportSelect",
                          }}
                          state={ele.companyId}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                            //   onClick={() => {
                            //     navigate("/admin/settings/companyReportView", {
                            //       state: { companyId: ele.companyId },
                            //     });
                            //   }}
                          >
                            Get report
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyReport;
