import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router";
import { getCookie } from "../../../../Cookies";
import { flexCenter } from "../../../../utilities/commonStyles/commonStyles";
import {
  QuizProgressBarIcons,
  TickIcons,
} from "../../../../utilities/Icons/Icons";
import "../../../../User/styles/Quiz/Quiz2.css";
import "./Quize.css";
import Fillblanks from "./QuizTypes/Fillblanks";
import Matchpair from "./QuizTypes/Matchpair";
import Matchpic from "./QuizTypes/Matchpic";
import Mcqpage from "./QuizTypes/Mcqpage";
import Mcqpic from "./QuizTypes/Mcqpic";
import Rearange from "./QuizTypes/Rearange";
import Singlepicmcq from "./QuizTypes/Singlepicmcq";
import TrueAndFalse from "./QuizTypes/TrueAndFalse";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 600,
          textAlign: "center",
          color: "#F03E3E",
        }}
      >
        Too late
      </Typography>
    );
  }
  let rTime = JSON.stringify(parseInt(remainingTime / 60));
  let rSeconds = remainingTime % 60;
  return (
    <Box>
      {remainingTime < 60 ? (
        <Box>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: 600,
              color: "#F03E3E",
              textAlign: "center",
            }}
          >
            Hurry Up
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "16px" },
              textAlign: "center",
              fontWeight: 600,
              color: "#F03E3E",
            }}
          >
            {rTime + ":" + rSeconds}
          </Typography>
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: { xs: "12px", md: "16px" },
            textAlign: "center",
            fontWeight: 600,
            color: "#000",
          }}
        >
          {rTime + ":" + rSeconds}
        </Typography>
      )}
    </Box>
  );
};

function QuizItem({ quizInfo, questionSet, setMode, setFinalScore }) {
  const [completedQuizInd, setCompeletedQuizInd] = useState(0);
  const [question, setQuestion] = useState({});
  const [questionArr, setQuestionArr] = useState();
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(async () => {
    // if (getCookie("UID") == null || getCookie("ROLE") != "user") {
    //   goToHome();
    //   return <></>;
    // }
    setActiveSubmit(false);
  }, [question]);

  useEffect(() => {
    let arr = JSON.parse(localStorage.getItem("QuestionNum"));
    let startTime = JSON.parse(localStorage.getItem("startTime"));
    if (!startTime) {
      let st = Math.floor(new Date().getTime() / 1000);
      localStorage.setItem("startTime", JSON.stringify(st));
      startTime = st;
    }

    let data = {
      questionData: questionSet,
      timeLimit: quizInfo?.extraInfo?.timeLimit,
    };
    arr = data.questionData;
    setQuestionArr(arr);
    let ct = Math.floor(new Date().getTime() / 1000) - startTime;
    setTimer(data.timeLimit * 60 - ct);
    localStorage.setItem("QuestionNum", JSON.stringify(data.questionData));

    //console.log(arr);
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].completed === false) {
        setQuestion(arr[i]);
        setCompeletedQuizInd(i);
        return;
      }
    }
  }, []);

  const submithandler = () => {
    const ans = JSON.parse(localStorage.getItem("answer"));
    let localScore = score;
    if (ans.correct) {
      localScore++;
    }

    const arr = JSON.parse(localStorage.getItem("QuestionNum"));
    arr[completedQuizInd].completed = true;
    setQuestionArr(arr);
    localStorage.setItem("QuestionNum", JSON.stringify(arr));

    setScore(localScore);

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].completed === false) {
        setQuestion(arr[i]);
        setCompeletedQuizInd(i);
        return;
      }
    }
    // runs when all questions are marked completed
    setFinalScore(localScore);
    setMode(2);
  };

  return (
    <Box sx={{ maxWidth: "100%", background: "#F3F1FF" }}>
      <Box
        sx={{
          background: "#F3F1FF",
          padding: { xs: "15px", md: "30px" },
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography sx={{ color: "#000", fontWeight: 600 }}>
            {completedQuizInd}/{questionArr && questionArr.length}
          </Typography>
        </Box> */}

        {/* <Box sx={flexCenter}>
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "500px" },
              // border: "1px solid #00495F",
              borderRadius: "6px",
              mt: "35px",
              overflowX: "auto",
              padding: "20px 10px",
            }}
            // className="scroll-bar"
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              {questionArr &&
                questionArr.map((item, id) => {
                  return (
                    <Box key={id} sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: `${
                            item.completed ? "#003A69" : "#9D9D9D"
                          }`,
                        }}
                        className="circle"
                      >
                        <TickIcons />
                      </Box>
                      {questionArr &&
                        item !== questionArr[questionArr.length - 1] && (
                          <Box>
                            <QuizProgressBarIcons item={item} />
                          </Box>
                        )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box> */}

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "50px",
          }}
        >
          <Box>
            <Typography sx={{ color: "#000", fontWeight: 600 }}>
              Lesson : Lesson Name
            </Typography>
          </Box>
          <Box>
            <CountdownCircleTimer
              strokeWidth={5}
              isPlaying
              duration={timer}
              colors={["#000", "#DE0A0A", "#DE0A0A"]}
              colorsTime={[timer, (timer * 1) / 5, 0]}
              onComplete={() => ({ shouldRepeat: false, delay: 1 })}
              size={70}
              onUpdate={(remainingTime) => {
                if (remainingTime == 0 && timer != 0) {
                  alert("Time Over");
                  setFinalScore(score);
                  setMode(2);
                }
              }}
            >
              {renderTime}
            </CountdownCircleTimer>
          </Box>
        </Box> */}

        <Box
          sx={{
            // border: "1px solid #004577",
            backgroundColor: "#fff",
            borderRadius: "6px",
            mt: "20px",
          }}
        >
          {question.type === "MCQ" && (
            <Mcqpage
              question={question}
              instruction={"mcq"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "trueAndFalse" && (
            <TrueAndFalse
              question={question}
              instruction={"True and False"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "rearange" && (
            <Rearange
              question={question}
              instruction={"Rearrange correct options"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "fillblanks" && (
            <Fillblanks
              question={question}
              instruction={"Fill in blanks"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "singlepicMCQ" && (
            <Singlepicmcq
              question={question}
              instruction={"Anser MCQ based on pic"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "MCQpic" && (
            <Mcqpic
              question={question}
              instruction={"mcq pitcure"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "matchpic" && (
            <Matchpic
              question={question}
              instruction={"Match the pair pic"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
          {question.type === "matchpair" && (
            <Matchpair
              question={question}
              instruction={"Match pair"}
              qno={completedQuizInd + 1}
              qtot={questionArr.length}
              setActiveSubmit={setActiveSubmit}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ mt: "25px" }}>
            {activeSubmit && (
              <Box>
                <Button
                  onClick={submithandler}
                  className="quizItem-button"
                  sx={{
                    color: "#6846C7",
                    border: "1px solid #c3b5e9",
                    borderRadius: "16px",
                  }}
                  // sx={{
                  //   textTransform: "none",
                  //   color: "#fff",
                  //   padding: "5px 30px",
                  //   borderRadius: "5px",
                  //   fontWeight: 600,
                  //   background: (theme) => theme.palette.primary.main,
                  //   "&:hover": {
                  //     background: (theme) => theme.palette.primary.main,
                  //   },
                  // }}
                >
                  Submit
                </Button>
              </Box>
            )}
            {!activeSubmit && (
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "5px 30px",
                    background: "#809DB4",
                    opacity: "50%",
                    borderRadius: "5px",
                    fontWeight: 600,
                    "&:hover": {
                      background: "#809DB4",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default QuizItem;

/*
  notes:
  <div className="quiz-main-top-top-txt-right">
    <p>Score {score}</p>
  </div>

  style={{
                          gridTemplateColumns: `${questionArr &&
                            item === questionArr[questionArr.length - 1]
                            ? "1fr "
                            : "1fr 1fr"
                            }`,
                          width: `${questionArr &&
                            item === questionArr[questionArr.length - 1]
                            ? "3vw "
                            : "10vw"
                            }`,
                        }}

*/
