import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function ORGrapfLegent() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          paddingLeft: "5px",
          //   zIndex: 2000,
          // marginLeft: "7%",
          marginBottom: "10px",
        }}
      >
        {/* male  */}
        <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box
            style={{
              backgroundColor: "#7EC1FF",

              width: "10px",
              height: "10px",
            }}
          ></Box>
          <Typography className="ORDiversityLegendText">Male</Typography>
        </Box>
        {/* female  */}
        <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box
            style={{
              backgroundColor: "#AFFB63",
              color: "red",
              width: "10px",
              height: "10px",
            }}
          ></Box>
          <Typography className="ORDiversityLegendText">Female</Typography>
        </Box>
        {/* non binary  */}
        <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box
            style={{
              backgroundColor: "#FE9CBF",
              color: "red",
              width: "10px",
              height: "10px",
            }}
          ></Box>
          <Typography className="ORDiversityLegendText">Non-binary</Typography>
        </Box>
      </Box>
    </div>
  );
}
