import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Chart from "./Chart";
import { exportMultipleChartsToPdf } from "./GraphUtils";

import { useNavigate } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
// import psy1 from "../../../utilities/pdfImages/psy11.jpg";
import psy1 from "../../../utilities/bystander/1.png";
import psy2 from "../../../utilities/bystander/2.png";
import psy3 from "../../../utilities/bystander/3.png";
import psy4 from "../../../utilities/bystander/4.png";
import psy5 from "../../../utilities/bystander/5.png";
import psy6 from "../../../utilities/bystander/6.jpg";
import psy7 from "../../../utilities/bystander/7.jpg";
import psy8 from "../../../utilities/bystander/8.jpg";
import psy9 from "../../../utilities/bystander/9.jpg";
import psy10 from "../../../utilities/bystander/10.jpg";

import HrloadingScreen from "../../LodingHrscreen";
import { byStandardData } from "./Option";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";

export default function PrintPageForBystandard({ companyName, allApiData }) {
  return (
    <div>
      <div className="custom-chart" style={{}}>
        <img src={psy1} style={{ width: "100%", height: "100%" }}></img>
      </div>
      <div className="custom-chart" style={{ position: "relative" }}>
        <img src={psy2} style={{ width: "100%", height: "100%" }}></img>
        {/* <Typography
              sx={{
                position: "absolute",
                top: "69.5%",
                left: "7%",
                fontSize: "35px",
                fontFamily: "Manrope",
                color: "#6846C7",
                fontWeight: "700px",
              }}
            >{`Psychological Safety at ${companyName}`}</Typography> */}
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={psy3} style={{ width: "100%", height: "100%" }}></img>
      </div>

      <div
        style={{
          position: "relative",
        }}
      >
        {" "}
        <div>
          <div
            style={{
              // width: "100vw",
              // height: "100vh",
              display: "block",
            }}
          >
            {" "}
            <img
              src={psy4}
              style={{
                width: "100%",
                // height: "100vh",
                // display: "block",
              }}
            ></img>{" "}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              // border: "1px solid red",
              height: "300px",
              width: "80%",
              // alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              // padding: "7%",
            }}
          >
            {" "}
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "7%",
                }}
              >
                <Chart chartOptions={allApiData[1]} />
              </Stack>
            </div>
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "40%",
                }}
              >
                <Chart chartOptions={allApiData[2]} />
              </Stack>{" "}
            </div>
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "70%",
                }}
              >
                <Chart chartOptions={allApiData[3]} />
              </Stack>{" "}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              mt: "1px",
              // border: "1px solid red",
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={psy5}
              style={{
                // top: "20%",
                width: "80%",
                // border: "1px solid red",

                height: "40%",
                // display: "block",
              }}
            ></img>{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        {" "}
        <div>
          <div
            style={{
              // width: "100vw",
              // height: "100vh",
              display: "block",
            }}
          >
            {" "}
            <img
              src={psy6}
              style={{
                width: "100%",
                // height: "100vh",
                // display: "block",
              }}
            ></img>{" "}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              // border: "1px solid red",
              height: "400px",
              padding: "7%",
            }}
          >
            {" "}
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "7%",
                }}
              >
                <Chart chartOptions={allApiData[4]} />
              </Stack>
            </div>
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "40%",
                }}
              >
                <Chart chartOptions={allApiData[5]} />
              </Stack>{" "}
            </div>
            <div>
              {" "}
              <Stack
                direction="row"
                sx={{
                  width: "30%",
                  height: "300px",
                  background: "#FFFFFF",
                  borderRadius: "0px 0px 24px 24px",
                  // border:"1px solid green",
                  position: "absolute",

                  // left: "70%",
                }}
              >
                <Chart chartOptions={allApiData[6]} />
              </Stack>{" "}
            </div>
          </div>

          <div
            style={{
              width: "90%",
              mt: "1px",
              // border: "1px solid red",
              height: "10%",
              display: "flex",
              alignItems: "center",
              margin: "auto",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                // height: "50%",
                // display: "block",
              }}
            >
              <img
                src={psy7}
                style={{
                  width: "85%",
                  // height: "50%",
                  // display: "block",
                }}
              ></img>{" "}
            </div>
          </div>
        </div>
      </div>
      <div
      // style={{
      //   position: "relative",
      // }}
      >
        <div
          style={{
            width: "100%",
            padding: "5%",
            whiteSpace: "break-spaces",
          }}
        >
          {" "}
          <Typography
            sx={{
              // position: "absolute",
              top: "69.5%",

              fontSize: "35px",
              fontFamily: "Manrope",
              color: "#6846C7",
              fontWeight: "700px",
            }}
          >{`Psychological Safety at ${companyName}`}</Typography>
          <Typography
            sx={{
              // position: "absolute",
              // top: "30%",

              fontSize: "17px",
              fontFamily: "Manrope",
              color: "black",
              fontWeight: "700px",
              widows: "80%",
              mt: "20px",

              whiteSpace: "normal",
            }}
          >
            {" "}
            in this study, employees have reported what they are most likely to
            do in situations of harassment or discrimination
          </Typography>{" "}
        </div>

        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
            height: "400px",
            padding: "6%",
          }}
        >
          <div style={{ width: "50%" }}>
            {" "}
            <img
              src={psy8}
              alt=""
              style={{
                width: "100%",
              }}
            />
          </div>

          <div style={{ width: "50%" }}>
            {" "}
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",

                // left: "7%",
              }}
            >
              <Chart chartOptions={allApiData[0]} />
            </Stack>{" "}
          </div>
        </div>

        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img
            src={psy9}
            alt=""
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
      <div>
        <img src={psy10} style={{ width: "100%", height: "100%" }}></img>
      </div>
    </div>
  );
}
