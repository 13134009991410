import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "./webinars.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Input, Select } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { Timestamp } from "firebase/firestore";
export const WebinarsUserList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const deleteCompany = httpsCallable(functions, "deleteCompany");
  const location = useLocation();
  // get company and department data
  const fetchdata = () => {
    setLoading(true);

    var config = {
      method: "get",

      params: { id: location.state.id },
      url: `${IP}/webinar/register`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Webinars")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div>
                {" "}
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: "20px" }}>
                  {" "}
                  <input
                    type="text"
                    style={{
                      height: "30px",
                    }}
                  />{" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    filter
                  </Button>
                </div>

                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    // onClick={() => navigate(-1)}
                  >
                    Send Reminder
                  </Button>{" "}
                </div>
              </div>
              <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
                <div className="webinarsUserTabel webinarsTabelTabelHeders">
                  <div>
                    {" "}
                    <input type="checkbox" />
                    S.No
                  </div>
                  <div>User name </div>
                  <div>Email </div>
                  <div>Company mail id </div>
                  <div>Phone Number </div>

                  <div>Role</div>

                  <div>register date</div>
                </div>

                {/* <div className="webinarsTabel webinarsTabelTabelBody"> */}
                {template?.map((ele, index) => {
                  return (
                    <div className="webinarsUserTabel webinarsTabelTabelBody">
                      <div>
                        {" "}
                        <input type="checkbox" /> {index + 1}
                      </div>
                      <div>
                        {ele?.firstname} {ele?.surname}
                      </div>
                      <div>{ele?.companymail}</div>
                      <div>{ele?.email}</div>
                      <div>{ele?.phone}</div>
                      <div>{ele?.role}</div>
                      <div>
                        {Timestamp.fromMillis(ele?.date?._seconds * 1000)
                          .toDate()
                          .toDateString()}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="admin-overdue-bottom-pagination">
                <div className="admin-overdue-bottom-pagination-cnt">
                  <div className="admin-overdue-bottom-pagination-cnt-item">
                    <svg
                      //   onClick={() => clickhandler(currentPage - 1)}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                    {/* {currentPage + 1} of {Math.ceil(arrLength / pageSize)} */}
                    {1} of {Math.ceil(9 / 5)}
                  </div>
                  <div
                    style={{ marginRight: "19vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <svg
                      //   onClick={() => clickhandler(currentPage + 1)}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div
                    style={{ display: "flex", marginRight: "-23vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <label className="admin-row">{t("Show")}</label>
                    <select
                      style={{
                        width: "4vw",
                        margin: "0.5vw",
                        marginBottom: "1.5vw",
                        height: "1.5vw",
                      }}
                      //   value={pageSize}
                      //   onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label className="admin-row"> {t("PagePerItem")}</label>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
