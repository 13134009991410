import { Box, Button } from "@mui/material";
import React from "react";
import img from "./Vector.svg";
const SidebartoolTip = ({ width, name }) => {
  return (
    <Box
      sx={{
        width: width || "111px",
        height: "30px",
        background: "#D9D9D9",
        borderRadius: "4px",
        color: "#6846C7",
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "14px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {" "}
      {name}
    </Box>
  );
};

export default SidebartoolTip;
