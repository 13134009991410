import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import jsPDF from "jspdf";
// import goss from "./goss.png";
// import moraikh from "./moraikh.png";
// import alghat from "./alghat.png";
// import ocasco from "./ocasco.png";

// import { functions } from "../../../../Firebase";
import { CSVLink } from "react-csv";

const GenerateReportForLocal = (props) => {
	const { onClose, open, reportId, data, datat, type } = props;
	const handleClose = () => {
		onClose();
	};
	const [dueDate, setDueDate] = useState(
		new Date().toISOString().split("T")[0]
	);
	const [loading, setLoading] = useState(false);

	let navigater = useNavigate();
	const runGenerateCertificateTraining = () => {
		const name = data[0]["Course name"];

		const pdfName = name;
		const pdf = new jsPDF("p", "pt", "a2");
		console.log(data[0]);
		const columns = Object.keys(data[0]);
		// columns.pop();
		// columns.pop();
		// columns.unshift("Sr.No.");
		console.log(columns);
		var rows = [];
		//   console.log({ rows });
		var rowTwo = [];
		data.forEach((e, index) => {
			// const status = e.status ? "Active" : "InActive";
			var temp = Object.values(e);
			// temp.pop();
			// temp.pop();
			// temp[temp.length - 1] = e?.completionDate
			//   ? new Date(e?.completionDate?._seconds * 1000).toLocaleDateString()
			//   : "";
			// temp.unshift(index + 1);
			rows.push(temp);
		});
		datat.forEach((e, index) => {
			// const status = e.status ? "Active" : "InActive";
			var temp = Object.values(e);
			// temp.pop();
			// temp.pop();
			// temp[temp.length - 1] = e?.completionDate
			//   ? new Date(e?.completionDate?._seconds * 1000).toLocaleDateString()
			//   : "";
			// temp.unshift(index + 1);
			rows.push(temp);
		});
		pdf.text(100, 30, pdfName);
		// var img = new Image();
		// img.src = img;
		// console.log(img.src);
		// pdf.addImage(goss, "png", 50, 50, 300, 100);
		// pdf.addImage(moraikh, "png", 350, 50, 250, 100);
		// pdf.addImage(ocasco, "png", 600, 50, 250, 100);
		// pdf.addImage(alghat, "png", 850, 50, 250, 100);

		// pdf.addImage(

		//   400,
		//   40
		// );
		pdf.autoTable(columns, rows, {
			startY: 50,
			theme: "grid",
			styles: {
				font: "times",
				halign: "center",
				cellPadding: 3.5,
				lineWidth: 0.5,
				lineColor: [0, 0, 0],
				textColor: [0, 0, 0],
			},
			headStyles: {
				textColor: [0, 0, 0],
				fontStyle: "normal",
				lineWidth: 0.5,
				lineColor: [0, 0, 0],
				fillColor: [166, 204, 247],
			},
			rowStyles: {
				lineWidth: 10,
				lineColor: [0, 0, 0],
			},
			tableLineColor: [0, 0, 0],
		});
		// console.log(pdf.output("datauristring"));
		let rses = pdf.save(name + Date.now());

		console.log(pdf.output("bloburl"));
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xs"
			PaperProps={{
				style: {
					borderRadius: "20px",
					padding: "16px",
				}
			}}
			onClose={() => {
				if (loading) return;
				handleClose();
			}}
			open={open}
		>
			<DialogTitle
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					textAlign: "left",
					color: "#11047A",
					padding: "0"
				}}
			>
				<p>Download </p>

				<button
					onClick={() => {
						handleClose();
						if (loading) return;
					}}
					style={{
						width: "40px",
						height: "40px",
						background: "#f4f7fe",
						marginLeft: "auto",
						borderRadius: "5px",
						border: "none",
					}}
				>
					<svg
						width="11"
						height="11"
						viewBox="0 0 11 11"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
							fill="#707EAE"
						/>
					</svg>
				</button>
			</DialogTitle>

			<div style={{ margin: "20px 0" }}>Download report in any format</div>
			<Box className="ReportDownloadPopup">
				<CSVLink filename={"report" + Date.now()} data={[...data, ...datat]}>
					<Button
						fullWidth
						// variant="contained"
						sx={{
							color: "#6846C7",
							border: "1px solid #6846C7",
							padding: "5px 15px",
							width: "100px",
						}}
					>
						CSV
					</Button>
				</CSVLink>

				<Button
					fullWidth
					sx={{
						color: "#6846C7",
						border: "1px solid #6846C7",
						padding: "5px 15px",
						width: "100px",
					}}
					disable={loading}
					onClick={() => {
						const fileName = "excel report";
						const exportType = "xls";
						// let data = [...data, ...datat];
						// console.log(tempdata);
						const test = exportFromJSON({
							data: [...data, ...datat],
							fileName,
							exportType,
						});

						console.log(test);
					}}
				>
					EXCEL
				</Button>

				<Button
					fullWidth
					sx={{
						color: "#6846C7",
						border: "1px solid #6846C7",
						padding: "5px 15px",
						width: "100px",
					}}
					disable={loading}
					onClick={runGenerateCertificateTraining}
				>
					PDF
				</Button>
			</Box>
		</Dialog>
	);
};

export default GenerateReportForLocal;
