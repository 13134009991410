import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
export const AdvancementScaleList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const location = useLocation();
  const companyId = location.state.companyId;

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company
  const deletetemplate = (id) => {
    //console.log(id);
    deleteCompany({ companyId: id }).then((res) => {
      //console.log(res);
      alert("Company Deleted");
      setdeltemp(!deltemp);
    });
  };
  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/maturityScale/maturity?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          console.log(res.data);
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const fetchDepartmentdata = () => {
    setLoading1(true);
    var config = {
      method: "get",

      url: `${IP}/department?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        if (res.data.length > 0) {
          setDepartment(res.data);
        }
        setLoading1(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading1(false);
      });
  };

  //================make default settings===========================//
  const makeCompanyDefault = httpsCallable(functions, "makeCompanyDefault");
  const makeDepartmentDefault = httpsCallable(
    functions,
    "makeDepartmentDefault"
  );

  //================create Department===========================//

  const createDepartment = httpsCallable(functions, "createDepartment");
  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  useEffect(() => {
    fetchDepartmentdata();
  }, [deledep]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Advancement Scale")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <h1>Advancement Scale Details</h1>
              <div className="create-new-box">
                <Link
                  to={{ pathname: "/admin/settings/AdvancementScaleCreate" }}
                >
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div>

              <div
                className="inputHeding"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr 1fr",
                  width: "60vw",
                  margin: "auto",
                  height: "50px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <div>S.No.</div>
                <div> Advancement Scale</div>
                <div>
                  <div>Action</div>
                </div>
              </div>

              {template?.map((ele, index) => {
                return (
                  <div
                    className="borderStyleFortabel"
                    style={{
                      display: "grid",
                      width: "60vw",
                      margin: "auto",
                      gridTemplateColumns: "1fr 4fr 1fr",

                      height: "50px",
                    }}
                  >
                    <div>{index}</div>
                    <div>{ele.name}</div>
                    <div className="button-box">
                      {/* {!ele.isDefault && (
                        <div className="button-box-inner mx-3 my-1">
                          <Button
                            variant="contained"
                            component="span"
                            color="warning"
                            onClick={() => {
                              deletetemplate(ele.companyId);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      )} */}
                      <div className="button-box-inner mx-3 my-1">
                        <Link
                          to={{
                            pathname: "/admin/settings/AdvancementScaleCreate",
                            state: { fromDashboard: "it is first" },
                          }}
                          state={ele}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Edit
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
