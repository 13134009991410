import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import CreateQuestion from "./CreateQuestion";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import { ValidateAuth } from "../../../AuthToken";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import DeleteLesson from "./lessonDeletePopUp";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";

import axios from "axios";
const CreateQuiz = ({ editQuizArr, topicId, courseId, lessonId }) => {
  const initialList = [];
  const [list, setList] = useState(initialList);
  const createQuiz = httpsCallable(functions, "createQuiz");
  const addQuizExtraInfo = httpsCallable(functions, "addQuizExtraInfo");
  const addCourseQuizOrder = httpsCallable(functions, "addCourseQuizOrder");
  const addLessonQuizOrder = httpsCallable(functions, "addLessonQuizOrder");
  const addTopicQuizOrder = httpsCallable(functions, "addTopicQuizOrder");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteQuiz = httpsCallable(functions, "deleteQuiz");
  // deleteQuiz

  const [deleteQuizId, setDeleteQuizId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);

  const [quizDetails, setQuizDetails] = useState({
    quizName: "",
    passScore: "",
    retry: "0",
    answerAll: "yes",
    timeLimit: "",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
  });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    quizName: "",
    passScore: "",
    retry: "0",
    answerAll: "yes",
    timeLimit: "",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
  });
  useEffect(() => {
    ValidateAuth();

    //console.log("quiz Arr ", editQuizArr);
    if (!editQuizArr) return;
    (async () => {
      for (let i = 0; i < editQuizArr.length; i++) {
        let dataex = JSON.stringify({});
        var configEx = {
          method: "get",

          params: { quizId: editQuizArr[i] },
          url: `${IP}/userMicroCourseDetails/quiz`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        let quizInfo = await axios(configEx);
        // let quizInfo = await getQuizInfo({ quizId: editQuizArr[i] });

        let dataSet = JSON.stringify({});
        var configSet = {
          method: "get",

          params: { questionSetId: editQuizArr[i] },
          url: `${IP}/userMicroCourseDetails/questionSet`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataSet,
        };
        let questionSetInfo = await axios(configSet);
        //  let questionSetInfo = await getQuestionSetInfo({ questionSetId: editQuizArr[i] });
        /**
         * id: quizId,
          name: quizDetails.quizName,
          passScore: quizDetails.passScore,
          retry: quizDetails.retry,
          timeLimit: quizDetails.timeLimit,
          success: quizDetails.successMessage,
          retake: quizDetails.retakeMessage,
          answerAll: quizDetails.answerAll,
          random: quizDetails.randomizeQuestions,
          skip: quizDetails.skipQuestion,
          randomAnswer: quizDetails.randomAnswers, */
        setList((prev) =>
          prev.concat({
            id: quizInfo.data.quizId,
            name: quizInfo.data.quizName,
            passScore: quizInfo.data.extraInfo.passScore,
            retry: quizInfo.data.extraInfo.retry,
            timeLimit: quizInfo.data.extraInfo.timeLimit,
            success: quizInfo.data.extraInfo.successMessage,
            retake: quizInfo.data.extraInfo.retakeMessage,
            answerAll: quizInfo.data.extraInfo.answerAll,
            random: quizInfo.data.extraInfo.randomizeQuestions,
            skip: quizInfo.data.extraInfo.skipQuestion,
            randomAnswer: quizInfo.data.extraInfo.randomAnswers,
            questionData: questionSetInfo.data.questionData,
          })
        );
      }
    })();
  }, [editQuizArr]);

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    let keys = Object.keys(quizDetails);
    let res = "allIsWell";
    for (let key of keys) {
      if (quizDetails[key] === "") {
        setCheckErrorInForm((prev) => ({ ...prev, [key]: "error" }));
        res = key;
        break;
      }
    }
    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (topicId != null) {
      let dataex = JSON.stringify({
        topicId: topicId,
        quizzes: orderList,
        edit: true,
      });
      let configEx = {
        method: "post",

        url: `${IP}/microCourseBuilder/addTopicQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configEx).then((res) => {});
    } else if (lessonId != null) {
      let dataex = JSON.stringify({
        lessonId: lessonId,
        quizzes: orderList,
        edit: true,
      });
      let configEx = {
        method: "post",

        url: `${IP}/microCourseBuilder/addLessonQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configEx).then((res) => {});
    } else if (courseId != null) {
      let dataex = JSON.stringify({
        courseId: courseId,
        quizzes: orderList,
        edit: true,
      });
      let configEx = {
        method: "post",

        url: `${IP}/microCourseBuilder/addCourseQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configEx).then((res) => {});
    }
  }
  const addQuiz = async () => {
    let check = checkForm();

    let nsn = quizDetails.quizName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    if (check != "allIsWell") {
      //console.log(check);
      setCheckFailed(true);
      return;
    }
    setCheckErrorInForm({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
    });
    setCheckFailed(false);

    const newList = list.concat({
      id: quizId,
      name: quizDetails.quizName,
      passScore: quizDetails.passScore,
      retry: quizDetails.retry,
      timeLimit: quizDetails.timeLimit,
      success: quizDetails.successMessage,
      retake: quizDetails.retakeMessage,
      answerAll: quizDetails.answerAll,
      random: quizDetails.randomizeQuestions,
      skip: quizDetails.skipQuestion,
      randomAnswer: quizDetails.randomAnswers,
    });

    let dataex = JSON.stringify({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,
      topicAssociated: topicId,
      lessonAssociated: lessonId,
      courseAssociated: courseId,
    });
    let configEx = {
      method: "post",

      url: `${IP}/microCourseBuilder/quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {});

    // createQuiz({
    //   quizId: quizId,
    //   created: new Date().toString(),
    //   quizName: quizDetails.quizName,
    //   topicAssociated: topicId,
    //   lessonAssociated: lessonId,
    //   courseAssociated: courseId,
    // });

    let data = JSON.stringify({
      quizId: quizId,
      allQuestionsRequired: quizDetails.answerAll,
      messageForRetake: quizDetails.retakeMessage,
      messageForSuccess: quizDetails.successMessage,
      noOfRetries: quizDetails.retry,
      passScore: quizDetails.passScore,
      randomizeAnswers: quizDetails.randomAnswers,
      randomizeQuestions: quizDetails.randomizeQuestions,
      skipQuestion: quizDetails.skipQuestion,
      timeLimit: quizDetails.timeLimit,
    });
    let config = {
      method: "patch",

      url: `${IP}/microCourseBuilder/quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config).then((res) => {});

    setList(newList);
    saveOrder(newList);
    alert("Added");

    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
    });

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "You Successfully completed the Quiz",
      retakeMessage: "Better luck next time",
    });
    setQuizId("");
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    quizFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,
      passScore: quiz.passScore,
      retry: quiz.retry,
      answerAll: quiz.answerAll,
      timeLimit: quiz.timeLimit,
      randomizeQuestions: quiz.random,
      skipQuestion: quiz.skip,
      randomAnswers: quiz.randomAnswer,
      successMessage: quiz.success,
      retakeMessage: quiz.retake,
    };
    setQuizDetails(params);
    setQuizId(quiz.id);
  }

  function updateQuiz() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      //console.log("update ", check);
      return;
    }
    setCheckFailed(false);

    let dataex = JSON.stringify({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,
      topicAssociated: topicId,
      lessonAssociated: lessonId,
      courseAssociated: courseId,
    });
    let configEx = {
      method: "post",

      url: `${IP}/microCourseBuilder/quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {});

    // createQuiz({
    //   quizId: quizId,
    //   created: new Date().toString(),
    //   quizName: quizDetails.quizName,
    //   topicAssociated: topicId,
    //   lessonAssociated: lessonId,
    //   courseAssociated: courseId,
    // });

    let data = JSON.stringify({
      quizId: quizId,
      allQuestionsRequired: quizDetails.answerAll,
      messageForRetake: quizDetails.retakeMessage,
      messageForSuccess: quizDetails.successMessage,
      noOfRetries: quizDetails.retry,
      passScore: quizDetails.passScore,
      randomizeAnswers: quizDetails.randomAnswers,
      randomizeQuestions: quizDetails.randomizeQuestions,
      skipQuestion: quizDetails.skipQuestion,
      timeLimit: quizDetails.timeLimit,
    });
    let config = {
      method: "patch",

      url: `${IP}/microCourseBuilder/quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config).then((res) => {});
    // createQuiz({
    //   quizId: quizId,
    //   created: new Date().toString(),
    //   quizName: quizDetails.quizName,
    //   topicAssociated: topicId,
    //   lessonAssociated: lessonId,
    //   courseAssociated: courseId,
    // });
    // addQuizExtraInfo({
    //   quizId: quizId,
    //   allQuestionsRequired: quizDetails.answerAll,
    //   messageForRetake: quizDetails.retakeMessage,
    //   messageForSuccess: quizDetails.successMessage,
    //   noOfRetries: quizDetails.retry,
    //   passScore: quizDetails.passScore,
    //   randomizeAnswers: quizDetails.randomAnswers,
    //   randomizeQuestions: quizDetails.randomizeQuestions,
    //   skipQuestion: quizDetails.skipQuestion,
    //   timeLimit: quizDetails.timeLimit,
    // });

    for (let i = 0; i < list.length; i++) {
      if (quizId === list[i].id) {
        list[i] = {
          id: quizId,
          name: quizDetails.quizName,
          passScore: quizDetails.passScore,
          retry: quizDetails.retry,
          timeLimit: quizDetails.timeLimit,
          success: quizDetails.successMessage,
          retake: quizDetails.retakeMessage,
          answerAll: quizDetails.answerAll,
          random: quizDetails.randomizeQuestions,
          skip: quizDetails.skipQuestion,
          randomAnswer: quizDetails.randomAnswers,
        };
      }
    }
    setList(list);
    alert("Updated");
    setEditable(false);
    quizFormRef.current.style.display = "none";
  }

  async function del(quiz) {
    // implement delete api done
    setopenLessonPopUp(true);
    setDeleteQuizId(quiz);
  }
  const delfunction = (quiz) => {
    deleteQuiz({ quizId: quiz.id });
    alert("Deleted " + quiz.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (quiz.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuizId}
        itemName={"Quiz"}
      />
      <section className="createQuiz-container">
        <button
          className="createQuiz-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add Quiz
        </button>
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">Quiz</div>
          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">Quiz Name *</label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "quizName"}
              style={{
                border: `${
                  checkErrorInForm.quizName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, quizName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuizId(value + "_" + time);
                }
              }}
            />{" "}
            {checkErrorInForm.quizName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz Name
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">
              Passing Score in Percentage *
            </label>
            <input
              type="number"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "passScore"}
              min={0}
              max={100}
              style={{
                border: `${
                  checkErrorInForm.passScore == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              name={"passScore"}
              value={quizDetails.passScore}
              onChange={(event) => {
                if (event.target.value < 0) {
                  event.target.value = quizDetails.passScore;
                } else {
                  if (event.target.value > 100) {
                    event.target.value = 0;
                  } else {
                    handleInput(event);
                    setCheckErrorInForm((prev) => ({ ...prev, passScore: "" }));
                  }
                }
              }}
            />
            {checkErrorInForm.passScore == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Passing Score in Percentage
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Time Limit (minutes)*</p>
            <input
              type="number"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "timeLimit"}
              style={{
                border: `${
                  checkErrorInForm.timeLimit == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              name="timeLimit"
              value={quizDetails.timeLimit}
              onChange={(event) => {
                if (event.target.value < 0) {
                  event.target.value = quizDetails.timeLimit;
                } else {
                  setCheckErrorInForm((prev) => ({ ...prev, timeLimit: "" }));
                  handleInput(event);
                }
              }}
            />
            {checkErrorInForm.timeLimit == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Time Limit
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel" style={{ marginTop: "2vw" }}>
              Randomize Questions
            </p>
            <select
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "randomQuestion"}
              value={quizDetails.randomizeQuestions}
              name="randomizeQuestions"
              onChange={handleInput}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel" style={{ marginTop: "2vw" }}>
              Randomize Answers
            </p>
            <select
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "randomAnswer"}
              value={quizDetails.randomAnswers}
              name="randomAnswers"
              onChange={handleInput}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Success Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "successMessage"}
              value={quizDetails.successMessage}
              name="successMessage"
              onChange={handleInput}
            />
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Retake Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "retakeMessage"}
              value={quizDetails.retakeMessage}
              name="retakeMessage"
              onChange={handleInput}
            />
          </div>

          {checkFailed && <p>Enter the Required Fields</p>}
          <div className="createQuiz-row">
            <button
              className="createQuiz-btn-save"
              onClick={!editable ? addQuiz : updateQuiz}
            >
              Save Quiz
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#2DB5B2",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            <p>Associated course - </p>

                            <CreateQuestion
                              questionData={item.questionData}
                              quizId={item.id}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateQuiz;
