import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import Chart from "./Chart";

import { useContext, useEffect, useRef, useState } from "react";
import psy1 from "../../../utilities/pdfImages/psy11.jpg";

import psy2 from "../../../utilities/pdfImages/psy12.jpg";
import psy3 from "../../../utilities/pdfImages/psy13.jpg";
import psy4 from "../../../utilities/pdfImages/psy14.jpg";
import psy5 from "../../../utilities/pdfImages/psy15.jpg";
import psy6 from "../../../utilities/pdfImages/psy16.jpg";
import psy7 from "../../../utilities/pdfImages/psy17.jpg";
import HrloadingScreen from "../../LodingHrscreen";

import "./psy.css";

import axios from "axios";
import { useNavigate } from "react-router";
import { getCookie, getLocalStorage } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import { exportMultipleChartsToPdf } from "./GraphUtils";

import "../sendinviteforpsy.css";
export default function PsyclogicalSafety() {
  const [lodingscreen, setlodingscreen] = useState(false);
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let newobj =
      rolesAndPermission?.Psycological_Study?.componentPermission.includes(
        "Psycological safety"
      );
    // console.log(newobj.HRDI);
    // if (!newobj) {
    // 	goToHome();
    // 	return <></>;
    // }
  }, []);

  const [subtab, setSubtab] = useState(1);
  const [subTitle, setSubTitle] = useState("Gender");

  const [allApiData, setAllApiData] = useState([]);
  const [optiondata, setoptiondata] = useState({});
  const [seriesData, setSeriesData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [totalNumberOfUserFilledForm, setTotalNumberOfUserFilledForm] =
    useState(0);

  let text = [
    {
      title: "Inclusion safety",
      desc1:
        "Where an individual's basic human need to connect and belong is met. In this stage, the employee feels accepted for who they are (their identity).",
      desc2:
        "Bullying, sexist and racist comments, anti-religious personal statements, or humiliating an employee because of their looks, accent, or name are all forms of exclusionary behaviour that challenge the basic stage of psychological safety.",
      desc3:
        "It is important for leaders to communicate the company’s value for respect at the workplace and encourage initiatives that appreciate team diversity.",
    },
    {
      title: "Learner safety",
      desc1:
        "In this stage, the employee feels safe to participate in the integral learning processes at the company. This is seen when an employee asks questions, gives and receives feedback, and is encouraged to learn from mistakes.",
      desc2:
        "Feedback given respectfully can encourage learner safety. However, feedback that is demeaning or insulting is likely to discourage employees from asking questions.",
      desc3:
        "Learner safety builds value for continuous learning and development. It is important for leaders to create more than one channel for employees to ask questions - access to clarify doubts with the leadership, the ability to ask peers for guidance, and access to a mentor or buddy.",
    },
    {
      title: "Contributor safety",
      desc1:
        "In this stage, the employee feels safe to contribute their unique skills and abilities to the team’s/organization's goals.",
      desc2:
        "During high-stakes projects or projects with tight deadlines, completion and delivery are given the highest priority. In these situations, the voice of the leader, the most vocal team member, or the star employee is prioritized. At the same time, there is little room for mistakes. In such situations, due to excitement, anxiety and stress an employee may be penalized or humiliated for a mistake. ",
      desc3:
        "If an employee does not feel like their work or opinions are valued, they are likely to only deliver the bare minimum.",
      desc4:
        "Asking open-ended questions is one way to encourage contributor safety. At the same time, explicitly acknowledging the ideas of junior employees, interns, and other new joiners is important to motivate employees to put forth their solutions.",
    },
    {
      title: "Challenger safety",
      desc1:
        "In this stage, the employee feels safe to speak up about areas that need improvement, and debate or challenge the status quo.",
      desc2:
        "In the absence of Challenger safety, employees are afraid to rock the boat. This means that incidents of harassment, discrimination, and other misdemeanours go unreported. A psychologically safe workplace builds trust and encourages employees to report acts that the HR or Ethics committee can address.",
      desc3:
        "Innovation is a priority at every organization. Employees have experience with ground-level challenges and practical ways to solve them. Challenger safety allows employees to openly point out shortcomings of the product/service and proactively look for solutions to these ground-level problems. This in turn will help organizations keep their products/services relevant.",
    },
  ];

  //Modal Functionality
  const [open, setOpen] = useState(false);
  const [flag, setflag] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const downloadSubmit = async () => {
    setlodingscreen(true);
    await exportMultipleChartsToPdf();
    setlodingscreen(false);
  };

  // useEffect(() => {
  //   getchatData("11");
  //   getCompanyName();
  // }, [flag]);
  // useEffect(async () => {
  //   setAllApiData([]);
  //   let apiRaoutes = {
  //     11: "inclusionSafetyGender",
  //     12: "inclusionSafetyTenure",
  //     13: "inclusionSafetyPosition",
  //     21: "learnerSafetyGender",
  //     22: "learnerSafetyTenure",
  //     23: "learnerSafetyPosition",
  //     31: "ContributorSafetyGender",
  //     32: "contributorSafetyTenure",
  //     33: "contributorSafetyPosition",
  //     41: "challengerSafetyGender",
  //     42: "challengerSafetyTenure",
  //     43: "challengerSafetyPosition",
  //   };

  //   // { ...optiondata, series: seriesData }
  //   let arr = [];

  //   (async () => {
  //     for (let i = 1; i < 5; i++) {
  //       // setlodingscreen(true);

  //       for (let j = 1; j < 4; j++) {
  //         let configrecrut = {
  //           method: "get",

  //           url: `${IP}/inclusionStudy/graph/${apiRaoutes[`${i}${j}`]}`,

  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${getCookie("bearer")}`,
  //           },
  //           data: "dataregionf",
  //         };

  //         let res = await axios(configrecrut);

  //         let obj = res.data?.graph;
  //         // arr[i - 1][j - 1] = { ...obj, series: res.data?.data };

  //   console.log("Nigger",obj);

  //         setAllApiData((prev) =>
  //           prev.concat({ ...obj, series: res.data?.data })
  //         );

  //         if (i == 4 && j == 3) {
  //           // setlodingscreen(false);
  //         }

  //         // console.log(arr[i - 1][j - 1]);
  //       }
  //     }

  //   })();

  //   // setAllApiData(arr);

  //   let config_obj = {
  //     method: "get",

  //     url: `${IP}/forms`,
  //     params: { survey: "0" },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getCookie("bearer")}`,
  //     },
  //     data: "dataregionf",
  //   };

  //   let res = await axios(config_obj).then((res) => {
  //     setTotalNumberOfUserFilledForm(res.data.count);
  //   });
  // }, []);

  // console.log(allApiData);

  const getCompanyName = (NumberToString) => {
    // { ...optiondata, series: seriesData }

    let configrecrut = {
      method: "get",

      url: `${IP}/inclusionStudy/name`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setCompanyName(res.data?.companyName);
        // setlodingscreen(false);
      })
      .catch((er) => {
        // setlodingscreen(false);
      });
  };

  // const getchatData = (NumberToString) => {
  //   setlodingscreen(true);
  //   let apiRaoutes = {
  //     11: "inclusionSafetyGender",
  //     12: "inclusionSafetyTenure",
  //     13: "inclusionSafetyPosition",
  //     21: "learnerSafetyGender",
  //     22: "learnerSafetyTenure",
  //     23: "learnerSafetyPosition",
  //     31: "ContributorSafetyGender",
  //     32: "contributorSafetyTenure",
  //     33: "contributorSafetyPosition",
  //     41: "challengerSafetyGender",
  //     42: "challengerSafetyTenure",
  //     43: "challengerSafetyPosition",
  //   };

  //   // { ...optiondata, series: seriesData }

  //   let configrecrut = {
  //     method: "get",

  //     url: `${IP}/inclusionStudy/graph/${apiRaoutes[NumberToString]}`,

  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getCookie("bearer")}`,
  //     },
  //     data: "dataregionf",
  //   };

  //   axios(configrecrut)
  //     .then((res) => {
  //       console.log(res.data);
  //       // setlodingscreen(false);
  //       setoptiondata(res.data?.graph);
  //       setSeriesData(res.data?.data);
  //     })
  //     .catch((er) => {
  //       setlodingscreen(false);
  //     })
  //     .finally(() => {
  //       setlodingscreen(false);
  //     });
  // };
  const componentRef = useRef();
  const [KeyFactorFilter, setKeyFactorFilter] = useState(0);
  const [DemographincFilter, setDemographincFilter] = useState(0);

  useEffect(() => {
    const fe = async () => {
      setlodingscreen(true);
      await getCompanyName();
      setGraphData();
      let configrecrut = {
        method: "get",

        url: `${IP}/inclusionStudy/graph/newPsycologicalStudy?graphType=${KeyFactorFilter}&filterType=${DemographincFilter}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };

      const res = await axios(configrecrut);
      console.log("", res.data);
      let seriesData1 = convertDataToSeries(res.data);
      setGraphData(seriesData1);
      setlodingscreen(false);
    };
    fe();
  }, [KeyFactorFilter, DemographincFilter]);
  const [GraphData, setGraphData] = useState();

  useEffect(() => {
    const fetch_agreed = async (kf, df) => {
      let configrecrut = {
        method: "get",

        url: `${IP}/inclusionStudy/graph/newPsycologicalStudy?graphType=${kf}&filterType=${df}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };
      setlodingscreen(true);
      const res = await axios(configrecrut);
      return count_agreed(res.data["Agree"]);
    };
    const count_agreed = (ass) => {
      let count = 0;
      Object.keys(ass).map((e) => {
        count += ass[e];
      });
      return count;
    };
    const count_keyfactor = async (kf) => {
      return await fetch_agreed(kf, 0);
    };
    const sum = (arr) => {
      let count = 0;
      arr.map((e) => {
        count += e;
      });
      return count;
    };
    const fetch_all = async () => {
      let dataa = [];
      for (let i = 0; i <= 4; i++) {
        dataa.push(count_keyfactor(i));
      }
      let data = await Promise.all(dataa);

      setDonut(data);
      setlodingscreen(false);
    };
    fetch_all();
  }, []);
  const [Donut, setDonut] = useState([0, 0, 0, 0, 0]);
  function convertDataToSeries(newData) {
    if (newData == undefined) {
      return [];
    }
    // Extract unique categories (outer keys)
    let categories = Object.keys(newData);

    // Extract unique subcategories (inner keys)
    let subcategories = Object.keys(newData[categories[0]]);

    // Initialize an empty array to store series data
    let seriesData = [];
    let colors = ["#73C1FF", "#AFFB63", "#FE9CBF", "#FFBD59", "#BCBCBC"];
    // Iterate over subcategories
    subcategories.forEach(function (subcategory, subIndex) {
      // Initialize an empty array to store data for each subcategory
      let data = [];

      // Iterate over categories (outer keys)
      categories.forEach(function (category) {
        // Push category and its value for the current subcategory
        // console.log("Nigger", colors[i]);
        data.push({
          name: category,
          y: newData[category][subcategory],
        });
      });

      // Push data for the current subcategory into seriesData
      seriesData.push({
        name: subcategory,
        data: data,
        color: colors[subIndex],
      });
    });

    return seriesData;
  }

  // Convert newData into Highcharts series format
  // var seriesData1 = [
  //   {
  //     "name": "Less than 1 year",
  //     "data": [
  //       [
  //         "Agree",
  //         8
  //       ],
  //       [
  //         "Disagree",
  //         15
  //       ],
  //       [
  //         "Unsure",
  //         20
  //       ],

  //     ]
  //   },
  //   {
  //     "name": "1 to 3 years",
  //     "data":  [
  //       [
  //         "Agree",
  //         8
  //       ],
  //       [
  //         "Disagree",
  //         15
  //       ],
  //       [
  //         "Unsure",
  //         20
  //       ],

  //     ]
  //   },
  //   {
  //     "name": "4 to 5 years",
  //     "data":  [
  //       [
  //         "Agree",
  //         10
  //       ],
  //       [
  //         "Disagree",
  //         1
  //       ],
  //       [
  //         "Unsure",
  //         2
  //       ],

  //     ]
  //   },
  //   {
  //     "name": "6+ years",
  //     "data":  [
  //       [
  //         "Agree",
  //         10
  //       ],
  //       [
  //         "Disagree",
  //         20
  //       ],
  //       [
  //         "Unsure",
  //         30
  //       ],

  //     ]
  //   }
  // ]

  // Categories for the x-axis

  return (
    <>
      <Box className="PySafetyPage">
        {/* Button Box */}
        <HrloadingScreen open={lodingscreen} />

        {/* Actual Content */}
        <Stack className="PySafetyStack">
          {/* Text Box */}
          <Box>
            <p className="PySafetyPageTitle">Psychological safety</p>
            <p className="PySafetyPageSubText">
              Assessing differences in the experience and perception of
              employees at {companyName}
            </p>
            <p className="PySafetyPageSTitleText">
              Building on the foundational concept of safety needs within
              Maslow's hierarchy, this study explores the construct of
              psychological safety in the workplace. This includes the freedom
              to express ideas, voice concerns, ask questions, and even admit
              mistakes without fear of retribution or negative social
              consequences.
            </p>
            <p className="PySafetyPageSTitleText">
              Creating a psychologically safe environment is a deliberate
              process that requires conscious effort to establish mutual
              understanding and encourage authentic interaction among team
              members.
            </p>
          </Box>
          {/* Text Box */}
          <Box>
            <p className="PySafetyPageSubTitle">
              Importance of psychological safety in the workplace
            </p>
            <p className="PySafetyPageSTitleText">
              Psychological safety is an essential aspect of organizational
              success that goes beyond individual well-being. When employees are
              micromanaged, subjected to disrespectful behavior, or publicly
              humiliated, the resulting attrition highlights the absence of
              psychological safety in the workplace. This lack of safety
              prevents diverse talent from contributing ideas and voicing
              concerns. Conversely, a psychologically safe workplace empowers
              employees to advocate for themselves, fosters a sense of trust,
              and promotes well-being. Research shows that psychological safety
              has a positive impact on team performance, innovation, creativity,
              resilience, and learning. These factors are essential for building
              high-performing teams and achieving superior organizational
              outcomes.
            </p>
          </Box>
          {/* Text Box */}
          <Box>
            <p className="PySafetyPageSubTitle">
              How does Serein measure psychological safety{" "}
            </p>
            <p className="PySafetyPageSTitleText mb10">
              This study will assess psychological safety within teams using a
              multi-dimensional survey instrument. The survey will capture data
              on five key factors that contribute to a psychologically safe
              environment :
            </p>

            <p className="PySafetyPageSTitleText mb20">
              <span>Communication: </span> Measures team members' comfort level
              in raising issues, offering ideas, and questioning others. It
              includes open communication frequency, fear of judgment, and the
              overall climate of information sharing.
            </p>
            <p className="PySafetyPageSTitleText mb20">
              <span>Acknowledgement: </span> Focuses on how effectively the team
              recognizes and utilizes individual strengths and talents.
            </p>
            <p className="PySafetyPageSTitleText mb20">
              <span>Acceptance of Differences: </span>Assesses inclusivity and
              sense of belonging. It targets questions on individuality,
              comfort, and value as a team member.
            </p>
            <p className="PySafetyPageSTitleText mb20">
              <span>Empathy and Support: </span>Evaluates emotional intelligence
              and mutual support. It explores avoiding undermining colleagues'
              efforts and cultivating supportive relationships.
            </p>
            <p className="PySafetyPageSTitleText mb10">
              <span>Encouraging Risk-Taking: </span> Assesses the team's
              openness to calculated risks and learning from mistakes. It
              explores safety to experiment and innovate, and viewing errors as
              opportunities for growth.
            </p>
            <p className="PySafetyPageSTitleText mb10">
              By analyzing responses across these five key factors, the survey
              will provide a comprehensive picture of the team's psychological
              safety climate. This data will allow us to understand the
              strengths and weaknesses of the team dynamic and identify areas
              for improvement in fostering a more open and trusting work
              environment.
            </p>
            <p className="PySafetyPageSubTitle">Findings </p>
            <p className="PySafetyPageSTitleText mb10">
              This visualization presents a compelling breakdown of the key
              factors contributing to psychological safety at SED Fund. The
              relative size of each section reflects the factor's significance.
              By analyzing the size of each section, we can gain valuable
              insights into the strengths and weaknesses of SED Fund's current
              team dynamic. This visualization allows us to identify areas where
              the company excels in promoting psychological safety and areas
              where there's room for improvement.
            </p>
          </Box>
          <Box sx={{ height: "500px" }}>
            <Chart
              chartOptions={{
                chart: {
                  type: "pie", // Set chart type to 'pie' for donut chart
                },
                title: {
                  text: "",
                },
                plotOptions: {
                  pie: {
                    innerSize: "50%", // Set inner size to create a donut chart
                    dataLabels: {
                      enabled: true,
                      format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    },
                  },
                },
                tooltip: {
                  pointFormat: "",
                },
                series: [
                  {
                    name: "",
                    data: [
                      ["Communication", Donut[0]],
                      ["Acknowledgement", Donut[1]],
                      ["Acceptance of difference", Donut[2]],
                      ["Empathy & support", Donut[3]],
                      ["Encouraging risk taking", Donut[4]],
                    ],

                    colors: [
                      "#31346D",
                      "#6BE4E7",
                      "#3FB8D5",
                      "#2B89B9",
                      "#2E5E97",
                    ],
                  },
                ],
              }}
            ></Chart>
          </Box>
          <p className="PySafetyPageSTitleText mb10">
            The following interactive tool allows you to delve deeper into how
            factors contributing to psychological safety may vary across
            different demographics within your organization. Select a factor
            from the dropdown menu and then choose a demographic. The
            visualization will update to reveal potential differences in how
            employees from those specific groups experience that particular
            factor.
          </p>
          <p className="PySafetyPageSTitleText mb10">
            This exploration can help identify areas where specific groups might
            feel less psychologically safe and guide targeted interventions to
            create a more inclusive and supportive work environment for all.
          </p>
          <Stack sx={{ flex: 1, flexDirection: "row" }}>
            <FormControl sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">Key Factor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={KeyFactorFilter}
                label="Key Factor"
                onChange={(e) => setKeyFactorFilter(e.target.value)}
                sx={{
                  width: "250px",
                  backgroundColor: "#F4F0FE",
                  borderRadius: "30px",
                }}
              >
                <MenuItem value={0}>Communication</MenuItem>
                <MenuItem value={1}>Acknowledgement</MenuItem>
                <MenuItem value={2}>Acceptance</MenuItem>
                <MenuItem value={3}>Empathy</MenuItem>
                <MenuItem value={4}>Risk</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: 3 }}
            >
              <InputLabel id="demo-simple-select-label2">
                Demographic
              </InputLabel>
              <Select
                sx={{
                  width: "150px",
                  backgroundColor: "#F4F0FE",
                  borderRadius: "30px",
                }}
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                value={DemographincFilter}
                label="Demographic"
                onChange={(e) => setDemographincFilter(e.target.value)}
              >
                <MenuItem value={0}>Gender</MenuItem>
                <MenuItem value={1}>Tenure</MenuItem>
                <MenuItem value={2}>Seniority</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Chart
            chartOptions={{
              chart: {
                type: "column",
              },
              title: {
                text: "",
              },
              xAxis: {
                categories: ["Agree", "Disagree", "Unsure"],
              },
              yAxis: {
                title: "",
              },
              series: GraphData,
            }}
          ></Chart>

          {/* Graph Box */}
        </Stack>
        {getLocalStorage("idc") === "AfCy0ReoaR5FN6qIsKbk" && (
          <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
            {" "}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "224px",
                height: "48px",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                border: "1px solid #6846C7",
                cursor: "pointer",
              }}
              // onClick={() => {}}
            >
              {console.log(getLocalStorage("idc"))}
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#6846C7",
                }}
                onClick={() => {
                  const pdfUrl = "/sed_report.pdf";
                  const link = document.createElement("a");
                  link.href = pdfUrl;
                  link.setAttribute("download", "SED_REPORT.pdf");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                {/* <Link
                to="/print"
                target="_blank"
                state={{ companyName, allApiData, componentRef }}
              > */}
                <p
                // href="https://firebasestorage.googleapis.com/v0/b/serein-demo-lms-gcp.appspot.com/o/SED%20Fund%20Psychological%20Safety%20SaaS%20Tool%20Report.pdf?alt=media&token=53b80214-3bb9-4566-9d66-019a2e21f8c5"

                // download="serin.png"
                >
                  Download report
                </p>
                {/* </Link> */}
              </Typography>
              <FileDownloadOutlinedIcon sx={{ color: "#6846C7" }} />
            </Stack>
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            bgcolor: "white",
            border: "2px solid white",
            borderRadius: "10px",
            boxShadow: 24,
            pt: 3,
            px: 3,
            pb: 1.5,
            overflow: "scroll",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textAlign: "",
          }}
        >
          <div className="custom-chart" style={{}}>
            <img src={psy1} style={{ width: "100%", height: "100%" }}></img>
          </div>
          <div className="custom-chart" style={{ position: "relative" }}>
            <img src={psy2} style={{ width: "100%", height: "100%" }}></img>
            <p
              sx={{
                position: "absolute",
                top: "69.5%",
                left: "7%",
                fontSize: "35px",
                fontFamily: "Manrope",
                color: "#6846C7",
                fontWeight: "700px",
              }}
            >{`Psychological Safety at ${companyName}`}</p>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <img src={psy3} style={{ width: "100%", height: "100%" }}></img>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "7%",
              }}
            >
              <Chart chartOptions={allApiData[0]} />

              {/* <Chart chartOptions={psyclogicalSafetyData[0][0]} /> */}
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "53%",
              }}
            >
              <Chart chartOptions={allApiData[1]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "72%",
                left: "30%",
              }}
            >
              <Chart chartOptions={allApiData[2]} />
            </Stack>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <img src={psy4} style={{ width: "100%", height: "100%" }}></img>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "7%",
              }}
            >
              <Chart chartOptions={allApiData[3]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "53%",
              }}
            >
              <Chart chartOptions={allApiData[4]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "72%",
                left: "30%",
              }}
            >
              <Chart chartOptions={allApiData[5]} />
            </Stack>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <img src={psy5} style={{ width: "100%", height: "100%" }}></img>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "7%",
              }}
            >
              <Chart chartOptions={allApiData[6]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "53%",
              }}
            >
              <Chart chartOptions={allApiData[7]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "72%",
                left: "30%",
              }}
            >
              <Chart chartOptions={allApiData[8]} />
            </Stack>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <img src={psy6} style={{ width: "100%", height: "100%" }}></img>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "7%",
              }}
            >
              <Chart chartOptions={allApiData[9]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "48%",
                left: "53%",
              }}
            >
              <Chart chartOptions={allApiData[10]} />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "40%",
                height: "300px",
                background: "#FFFFFF",
                borderRadius: "0px 0px 24px 24px",
                // border:"1px solid green",
                position: "absolute",
                top: "72%",
                left: "30%",
              }}
            >
              <Chart chartOptions={allApiData[11]} />
            </Stack>
          </div>
          <div className="custom-chart" style={{}}>
            <img src={psy7} style={{ width: "100%", height: "100%" }}></img>
          </div>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            sx={{ margin: "20px 60px" }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "224px",
                height: "48px",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                border: "1px solid #6846C7",
                cursor: "pointer",
              }}
              onClick={downloadSubmit}
            >
              <p
                sx={{
                  fontFamily: "Nunito",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#6846C7",
                }}
              >
                Generate PDF
              </p>
              <FileDownloadOutlinedIcon sx={{ color: "#6846C7" }} />
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
