import React, { useContext, useState } from "react";
import CreateLesson from "./CreateLesson";
import "./CreateCourse.css";
import Course from "./Course";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import CreateQuiz from "./CreateQuiz";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Navbar from "../../Component/NavbarTop";
import { t } from "i18next";
import Sidebar from "../../Component/Sidebar";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import DeleteLesson from "./lessonDeletePopUp";
import { DeleteSVg, SvgVector, EditSvg, ExpandMoreSvg } from "./icons";
import { ValidateAuth } from "../../../AuthToken";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateMicroLearning = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const location = useLocation();
  const editCourseId = location.state?.courseId;
  //===========API for Course EDIT=========

  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getLessonInfo = httpsCallable(functions, "getLessonInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const [editQuizArr, setEditQuizArr] = useState([]);
  const [editCourseInfo, setEditCourseInfo] = useState(null);
  const [checkEditFlag, setCheckEditFlag] = useState(false);
  const [deleteLessonId, setDeleteLessonId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  //=======================================
  const createLesson = httpsCallable(functions, "createLesson");
  const addLessonExtraInfo = httpsCallable(functions, "addLessonExtraInfo");
  const addCourseLessonOrder = httpsCallable(functions, "addCourseLessonOrder");
  const deleteLesson = httpsCallable(functions, "deleteLesson");

  // deleteLesson

  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);
  const [schedule, setSchedule] = useState("immediate");
  const [lessonId, setLessonId] = useState("");
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [isCourseIdSet, setIsCourseIdSet] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const initialInfo = {
    lessonName: "",
    lessonDescription: "",
    created: "",
    isSample: "no",
    hasQuiz: "yes",
    daysAfter: "",
    startDate: "",
    quiz: [],
    topic: [],
    base64Image: "",
  };
  const [lessonDetails, setLessonDetails] = useState({ ...initialInfo });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    lessonName: "",
    lessonDescription: "",
  });

  useEffect(() => {
    if (!editCourseId) return;
    setCheckEditFlag(true);

    let data = JSON.stringify({
      courseId: editCourseId,
    });

    var configs = {
      method: "get",
      params: { courseId: editCourseId },
      url: `${IP}/userMicroCourseDetails/course`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configs)
      .then((res) => {
        setCheckEditFlag(false);
        setEditCourseInfo(res.data);
        (async () => {
          let lessonIdArr = res.data.extraInfo.lessons;
          for (let i = 0; i < lessonIdArr.length; i++) {
            var config = {
              method: "get",
              params: { lessonId: lessonIdArr[i] },
              // url: `${IP}/microCourseBuilder/lesson`,

              url: `${IP}/userMicroCourseDetails/lesson`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: "data",
            };
            let lessonInfo = await axios(config);

            setList((prev) =>
              prev.concat({
                id: lessonIdArr[i],
                name: lessonInfo.data.lessonName,
                description: lessonInfo.data.lessonDescription,
                sampleLesson: "no", // default
                hasQuiz: "yes",
                schedule: lessonInfo.data.extraInfo.schedule,
                start: lessonInfo.data.extraInfo.start,
                courseAssociated: editCourseId,
                topicsData: lessonInfo.data.extraInfo.topics,
                itemsData: lessonInfo.data.extraInfo.items,
                quizData: lessonInfo.data.extraInfo.quizzes,
              })
            );
          }
        })();
        (async () => {
          let quizArr = res.data.extraInfo.quizzes;
          setEditQuizArr(quizArr);
        })();
      })
      .catch((er) => {});
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setLessonDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formCheck = () => {
    if (lessonDetails.lessonName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonName: "error" }));
      return "lessonName";
    }
    if (lessonDetails.lessonDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonDescription: "error" }));
      return "lessonDescription";
    }
    return "allIsWell";
    // not checking for files
  };

  const addLesson = async () => {
    let check = formCheck();

    let nsn = lessonDetails.lessonName.replaceAll(" ", "");

    for (let i = 0; i < nsn.length; i++) {
      if (nsn[i] == "/") {
        alert("you can not use any special characters");
        return;
      }
    }
    // let dat=(/[^A-Za-z0-9]/.test(nsn))

    //                if(!dat){

    //                }else{

    //                  alert("you can not use any special characters")
    //                  return
    //                }
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);
    setCheckErrorInForm({
      lessonName: "",
      lessonDescription: "",
    });
    let d = 0;
    if (schedule == "enrolment") {
      d = lessonDetails.daysAfter;
    } else if (schedule == "specific") {
      d = lessonDetails.startDate;
    }
    const newList = list.concat({
      id: lessonId,
      name: lessonDetails.lessonName,
      description: lessonDetails.lessonDescription,

      schedule: schedule,
      start: d,
      courseAssociated: isCourseIdSet,
    });

    let data = JSON.stringify({
      lessonId: lessonId,
      created:
        lessonDetails.created == ""
          ? new Date().toString()
          : lessonDetails.created,
      courseId: isCourseIdSet,
      lessonDescription: lessonDetails.lessonDescription,
      lessonName: lessonDetails.lessonName,
    });

    var configs = {
      method: "post",

      url: `${IP}/microCourseBuilder/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configs).then((res) => {
      // console.log(res.data);
    });

    let dataLes = JSON.stringify({
      lessonId: lessonId,
    });

    var configLes = {
      method: "patch",

      url: `${IP}/microCourseBuilder/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataLes,
    };
    axios(configLes).then((res) => {
      // console.log(res.data);
    });

    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
    alert("Lesson Added");
  };

  const updateLesson = async () => {
    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    let d = 0;
    if (schedule == "enrolment") {
      d = lessonDetails.daysAfter;
    } else if (schedule == "specific") {
      d = lessonDetails.startDate;
    }

    let data = JSON.stringify({
      lessonId: lessonId,
      created:
        lessonDetails.created == ""
          ? new Date().toString()
          : lessonDetails.created,
      courseId: isCourseIdSet,
      lessonDescription: lessonDetails.lessonDescription,
      lessonName: lessonDetails.lessonName,
    });

    var configs = {
      method: "post",
      url: `${IP}/microCourseBuilder/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configs).then((res) => {
      //console.log(res.data);
    });

    let dataLes = JSON.stringify({
      lessonId: lessonId,
      hasQuiz: lessonDetails.hasQuiz == "yes" ? true : false,
      sample: lessonDetails.isSample == "yes" ? true : false,
      schedule: schedule,
      start:
        schedule == "immediate"
          ? 0
          : schedule == "enrolment"
          ? lessonDetails.daysAfter
          : lessonDetails.startDate,
      image: lessonDetails.base64Image,
    });
    var configLes = {
      method: "patch",

      url: `${IP}/microCourseBuilder/lesson`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataLes,
    };
    axios(configLes).then((res) => {
      //   console.log(res.data);
    });

    const newList = Array.from(list);
    for (let i = 0; i < newList.length; i++) {
      if (lessonId === newList[i].id) {
        newList[i] = {
          id: lessonId,
          name: lessonDetails.lessonName,
          description: lessonDetails.lessonDescription,

          courseAssociated: isCourseIdSet,
        };
      }
    }
    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
  };

  const toggle = () => {
    // resetForm
    setLessonDetails({ ...initialInfo });
    setLessonId("");
    setFormOpen((prev) => !prev);
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let dataLes = JSON.stringify({
      courseId: isCourseIdSet,
      lessons: orderList,
      edit: true,
    });
    var configLes = {
      method: "post",

      url: `${IP}/microCourseBuilder/addCourseLessonOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataLes,
    };
    axios(configLes).then((res) => {
      //console.log(res.data);
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggle();
    let lessonInfo = {
      lessonName: lesson.name,
      lessonDescription: lesson.description,
      created: "",
      isSample: lesson.sampleLesson ? "yes" : "no",
      hasQuiz: lesson.hasQuiz ? "yes" : "no",
      daysAfter: lesson.schedule == "enrolment" ? lesson.start : "",
      startDate: lesson.schedule == "specific" ? lesson.start : "",
      base64Image: "",
    };

    setLessonId(lesson.id);
    setSchedule(lesson.schedule);
    setLessonDetails(lessonInfo);

    setEditable(true);
  }
  async function del(lesson) {
    // implement delete API done
    setopenLessonPopUp(true);
    setDeleteLessonId(lesson);
  }

  const delfunction = async (lesson) => {
    deleteLesson({ lessonId: lesson.id });
    alert("Deleted " + lesson.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (lesson.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }], // dropdown with defaults from theme

      // [
      //   {
      //     color: ["red", "blue", "yellow"],
      //   },
      // ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "link",
  ];
  return (
    <>
      <section className="section">
        <Navbar page={t("Micro learning")} />
        <div className="createcourse-main">
          <Sidebar page={"training"} hidepage={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Course
              editCourseInfo={editCourseInfo}
              setIsCourseIdSet={setIsCourseIdSet}
              checkEditFlag={checkEditFlag}
            />
            <DeleteLesson
              open={openLessonPopUp}
              onClose={() => setopenLessonPopUp(false)}
              delfun={(topic) => {
                delfunction(topic);
              }}
              topicId={deleteLessonId}
              itemName={"Lesson"}
            />
            {isCourseIdSet && (
              <div className="createcourse-main-bottom">
                <button
                  className="addLesson-btn"
                  onClick={() => {
                    setEditable(false);
                    toggle();
                  }}
                >
                  Add Lesson
                </button>

                {formOpen && (
                  <div id="lessonForm" className="lessonForm-container">
                    <h3 className="lessonForm-heading">Lesson</h3>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Lesson Name *</p>
                      <input
                        type="text"
                        className="lessonForm-inputBar"
                        name="lessonName"
                        id="lessonName"
                        style={{
                          border: `${
                            checkErrorInForm.lessonName == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        value={lessonDetails.lessonName}
                        onChange={(event) => {
                          handleInput(event);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonName: "",
                          }));
                          if (!editable) {
                            var today = new Date();
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setLessonId(value + "_" + time);
                          }
                        }}
                      />
                      {checkErrorInForm.lessonName == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Lesson Name
                        </span>
                      )}
                    </div>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Short Description *
                      </p>
                      <ReactQuill
                        theme="snow"
                        value={lessonDetails.lessonDescription}
                        modules={modules}
                        formats={formats}
                        onChange={setLessonDetails["lessonDescription"]}
                      />
                      <button
                        onClick={() => {
                          console.log(lessonDetails.lessonDescription);
                        }}
                      >
                        sbumit
                      </button>

                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonDescription"
                        style={{
                          border: `${
                            checkErrorInForm.lessonDescription == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        name="lessonDescription"
                        value={lessonDetails.lessonDescription}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonDescription: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.lessonDescription == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Lesson Description
                        </span>
                      )}
                    </div>

                    {/* <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Sample Lesson</p>
                      <select
                        value={lessonDetails.isSample}
                        name="isSample"
                        onChange={handleInput}
                        id="sampleLesson"
                        className="lessonForm-inputBar"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div> */}
                    {/* <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Quiz</p>
                      <select
                        value={lessonDetails.hasQuiz}
                        name="hasQuiz"
                        onChange={handleInput}
                        id="hasLessonQuiz"
                        className="lessonForm-inputBar"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div> */}
                    {/* <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Lesson Release Schedule</p>
                      <select
                        id="releaseSchedule"
                        className="lessonForm-inputBar"
                        value={schedule}
                        name="schedule"
                        onChange={(e) => setSchedule(e.target.value)}
                      >
                        <option value="immediate">Immediate</option>
                        <option value="enrolment">Enrolment</option>
                        <option value="specific">Specific Date</option>
                      </select>
                    </div>
                    {schedule === "enrolment" && (
                      <div className="lessonForm-row" id="daysAfterBlock">
                        <p className="lessonForm-inputLabel">Days After Enrolment</p>
                        <input
                          type="number"
                          className="lessonForm-inputBar"
                          id="daysAfter"
                          value={lessonDetails.daysAfter}
                          name="daysAfter"
                          onChange={handleInput}
                        />
                      </div>
                    )}
                    {schedule == "specific" && (
                      <div className="lessonForm-row" id="startDateBlock">
                        <p className="lessonForm-inputLabel">Start Date</p>
                        <input
                          type="date"
                          className="lessonForm-inputBar"
                          id="startDate"
                          value={lessonDetails.startDate}
                          name="startDate"
                          onChange={handleInput}
                        />
                      </div>
                    )} */}

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Lesson ID</p>
                      <input
                        disabled
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonId"
                        value={lessonId}
                      />
                    </div>

                    {checkFailed && (
                      <p style={{ color: "red" }}>Fill all the fields</p>
                    )}
                    <button
                      className="lessonForm-save-btn"
                      onClick={() => (editable ? updateLesson() : addLesson())}
                    >
                      Save Lesson
                    </button>
                    <br />
                  </div>
                )}
                <div className="lessonList-container">
                  <div className="lessonForm-heading">Lesson</div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {list.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={item.id}
                                  className="lessonList-draggable"
                                >
                                  <Accordion>
                                    <AccordionSummary
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: "#FA9600",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <div className="lessonList-accordion-heading">
                                          {item.name}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ borderRadius: "2px" }}
                                      isDragDisabled={true}
                                    >
                                      <div className="lessonList-draggable-summary">
                                        <p>{item.id}</p>
                                        <div
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => del(item)}
                                        >
                                          <DeleteSVg size={28} />
                                        </div>
                                        <div onClick={() => edit(item)}>
                                          <EditSvg size={28} />
                                        </div>
                                      </div>

                                      <p>{item.description}</p>
                                      <p>Course Associated - {isCourseIdSet}</p>
                                      <CreateLesson
                                        quizData={item.quizData}
                                        itemsData={item.itemsData}
                                        topicsData={item.topicsData}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <CreateQuiz
                  editQuizArr={editQuizArr}
                  courseId={isCourseIdSet}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      <div style={{ height: "100px", margin: "auto", width: "100px" }}>
        <button
          style={{ backgroundColor: "rgb(36,24,135)" }}
          className="addLesson-btn"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div>
    </>
  );
};

export default CreateMicroLearning;
