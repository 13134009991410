import { AgChartsReact } from "ag-charts-react";
import { useEffect, useState } from "react";
import DI from "./Di";
import { display } from "@mui/system";
import Stacked from "./stacked";
import LineGraph from "./LineGraph";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import dbbvbarline from "./dbbvbarline.png";
import GroupBarImg from "./GroupBar.png";
import Gbbvbar from "./Gbbvbar.png";
import Genderobar from "./Genderobar.png";
import orgline from "./orgline.png";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router";
import StackedForOrg from "./StatedFororg";
import GroupBar from "./groupbar";
import HrloadingScreen from "../../LodingHrscreen";

import "./recruitment.css";

export default function RecruitmentandRetention() {
  const [shortlistedLineGraf, setShortlistedLineGraf] = useState([]);
  const [yearData, setyearData] = useState([]);
  const [shortlistedLineGrafoff, setShortlistedLineGrafoff] = useState([]);
  const [positionInorgGraph, setPositionInorgGraph] = useState([]);
  const [positionInorgLineGraph, setPositionInorgLineGraph] = useState([]);
  const [caountryansState, setCountryandState] = useState({});
  const [stateListForOverall, setStateListForOverall] = useState([]);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [regionAndYear, setRegionAndYear] = useState({
    year: "",

    bussinessvr: "",
  });
  const [bussinessverticalarr, setbussinessverticalarr] = useState([]);
  const [
    RecruitmentbybusinessverticalData,
    setRecruitmentbybusinessverticalData,
  ] = useState([]);

  const [RecruitmentbypositionState, setRecruitmentbypositionState] = useState({
    position: "",
    year: "",
  });
  const [RecruitmentbypositionDate, setRecruitmentbypositionDate] = useState(
    {}
  );
  const [lodingspagestate, setlodingspagestate] = useState(false);
  const [
    Recruitmentbybusinessverticalstate,
    setRecruitmentbybusinessverticalstate,
  ] = useState({
    year: "",
    businessvertical: "",
  });

  const [
    RepresentationbybusinessverticalBarData,
    setRepresentationbybusinessverticalBarDate,
  ] = useState({});

  const [
    RepresentationbybusinessverticalLineData,
    setRepresentationbybusinessverticalLineDate,
  ] = useState({});

  const [
    RepresentationbyPositionBarState,
    setRepresentationbyPositionBarState,
  ] = useState({
    businessVertical: "",
    year: "",
  });

  const [RepresentationbyPositionBarData, setRepresentationbyPositionBarData] =
    useState({});

  const [
    RepresentationbyPositionLineData,
    setRepresentationbyPositionLineData,
  ] = useState({});
  const fetchGenderbypositionintheorggraph = (region, year) => {
    if (!region || !year) {
      return;
    }
    // setlodingspagestate(true);
    // let configrecrut = {
    //   method: "get",

    //   url: `${IP}/graph/getGenderByPositionInOrgGraph`,
    //   params: { year: year, businessVertical: region },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${getCookie("bearer")}`,
    //   },
    //   data: "dataregionf",
    // };

    // axios(configrecrut)
    //   .then((res) => {
    //     setlodingspagestate(false);
    //     setPositionInorgGraph(res.data);
    //   })
    //   .catch((err) => {
    //     setlodingspagestate(false);
    //   });
  };

  const Recruitmentbybusinessverticalget = (businessvertical, year) => {
    if (!businessvertical || !year) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      // graph/recruitmentByBussinessVertical?year=2022&businessVertical=Sales

      url: `${IP}/graph/recruitmentByBussinessVertical`,
      params: { year: year, businessVertical: businessvertical },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRecruitmentbybusinessverticalData(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const Recruitmentbypositionget = (businessvertical, year) => {
    if (!businessvertical || !year) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",
      // graph/recuritmentByPostion?year=2022&position=Junior

      url: `${IP}/graph/recuritmentByPostion`,
      params: { year: year, position: businessvertical },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRecruitmentbypositionDate(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const RepresentationbybusinessverticalBarGet = (year) => {
    if (!year) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/retentionBarGraphRepresentationByBusinessVertical`,
      params: { year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRepresentationbybusinessverticalBarDate(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };
  const RepresentationbybusinessverticalLineGet = (bussinessvertical) => {
    if (!bussinessvertical) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/retentionTimeSeriesGraphRepresentationByBusinessVertical`,
      params: { businessVertical: bussinessvertical },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRepresentationbybusinessverticalLineDate(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const RepresentationbyPositionBarGet = (bussinessvertical, year) => {
    if (!bussinessvertical || !year) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/retentionBarGraphRepresentationByPosition`,
      params: { businessVertical: bussinessvertical, year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRepresentationbyPositionBarData(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const RepresentationbyPositionLineGet = (gender) => {
    if (!gender) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/retentionTimeSeriesGraphRepresentationByPosition`,
      params: { gender: gender },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRepresentationbyPositionLineData(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };
  useEffect(() => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setbussinessverticalarr(res.data);
    });

    let configarr = {
      method: "get",

      url: `${IP}/bussinessVertical/year`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configarr).then((res) => {
      setyearData(res.data);
    });
  }, []);
  const fetchGenderbypositionintheorgLineGraph = (vr) => {
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getCandidateSortListedGraphByGender`,
      params: { businessVertical: vr },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      // console.log(res.data);
      setShortlistedLineGraf(res.data);
    });
  };

  const fetchGenderbypositionintheorgLineGraphOffer = (vr) => {
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/timeSeriesOfOfferLetterGraphByGender`,
      params: { businessVertical: vr },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      // console.log(res.data);
      setShortlistedLineGrafoff(res.data);
    });
  };
  return (
    <Box sx={{}}>
      {/* comment  */}

      <HrloadingScreen open={lodingspagestate} />

      {/* main title 1  */}
      <Box
        sx={{
          fontFamily: "Poppins",

          fontWeight: "500px",

          fontSize: "20px",
          mb: "30px",
        }}
      >
        {" "}
        Gender representation in recruitment
      </Box>

      <Box className="genbybussinessverticalRR">
        {/* graph box 1  */}
        <Box
          className="genbybussinessverticalchiledRR"
          sx={{
            backgroundColor: "#fff",
            // padding: "25px 10px",
            borderRadius: "24px 24px 24px 24px",
          }}
        >
          {/* title  */}
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Recruitment by business vertical
          </Box>

          {/* graph with select  */}
          <Box
            className="RRGraphWithSelect"
            sx={{
              backgroundColor: "#fff",
              padding: "25px 10px",
              borderRadius: "0px 0px 24px 24px",
              overflow: "hidden",
            }}
          >
            {/* input  */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                marginRight: "10px",
              }}
              // className="RRFormSelectContainer"
            >
              <FormControl
                className="ORformSelectInput"
                // sx={{ width: "150px", marginRight: "10px" }}
              >
                <InputLabel id="demo-simple-select-label5">
                  Business vertical
                </InputLabel>
                <Select
                  className="ORCityCountrySelect"
                  label="Business vertical"
                  labelId="demo-simple-select-label5"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  value={Recruitmentbybusinessverticalstate.businessvertical}
                  onChange={(e) => {
                    setRecruitmentbybusinessverticalstate((prev) => ({
                      ...prev,
                      businessvertical: e.target.value,
                    }));

                    Recruitmentbybusinessverticalget(
                      e.target.value,
                      Recruitmentbybusinessverticalstate.year
                    );
                    // fetchdataForStrockedBar(e.target.value);
                  }}
                >
                  {bussinessverticalarr?.map((ele) => (
                    <MenuItem value={ele}> {ele}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                className="ORformSelectInput"
                // sx={{ marginLeft: "50px", width: "120px" }}
              >
                <InputLabel id="demo-simple-select-label6">Year</InputLabel>
                <Select
                  className="ORCityCountrySelect"
                  label="Year"
                  labelId="demo-simple-select-label6"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      // maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  value={Recruitmentbybusinessverticalstate.year}
                  onChange={(e) => {
                    setRecruitmentbybusinessverticalstate((prev) => ({
                      ...prev,
                      year: e.target.value,
                    }));
                    Recruitmentbybusinessverticalget(
                      Recruitmentbybusinessverticalstate.businessvertical,
                      e.target.value
                    );
                  }}
                >
                  {yearData.map((el) => (
                    <MenuItem value={el}> {el}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* graph  */}

            <GroupBar
              data={RecruitmentbybusinessverticalData}
              Gbbvbar={GroupBarImg}
            />
          </Box>
        </Box>

        {/* graph box 2 */}
        <Box
          className="genbybussinessverticalchiledRR"
          sx={{
            backgroundColor: "#fff",
            // padding: "25px 10px",
            borderRadius: "24px 24px 24px 24px",
          }}
        >
          {/* graph title  */}
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Recruitment by position
          </Box>

          {/* graph 2  */}
          <Box
            className="RRGraphWithSelect"
            sx={{
              backgroundColor: "#fff",
              padding: "25px 10px",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                marginRight: "10px",
              }}
            >
              <FormControl className="ORformSelectInput">
                <InputLabel id="demo-simple-select-label5">Position</InputLabel>
                <Select
                  className="ORCityCountrySelect"
                  label="Position"
                  labelId="demo-simple-select-label5"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  value={RecruitmentbypositionState.position}
                  onChange={(e) => {
                    setRecruitmentbypositionState((prev) => ({
                      ...prev,
                      position: e.target.value,
                    }));

                    Recruitmentbypositionget(
                      e.target.value,
                      RecruitmentbypositionState.year
                    );
                    // fetchdataForStrockedBar(e.target.value);
                  }}
                >
                  {[
                    { val: "Junior", name: "Junior" },
                    { val: "MidLevel", name: "Mid-level" },
                    { val: "Senior", name: "Senior" },
                  ]?.map((ele) => (
                    <MenuItem value={ele.val}> {ele.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="SingleSelectFC">
                <InputLabel id="demo-simple-select-label6">Year</InputLabel>
                <Select
                  className="ORSingleSelect"
                  label="Year"
                  labelId="demo-simple-select-label6"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  value={RecruitmentbypositionState.year}
                  onChange={(e) => {
                    setRecruitmentbypositionState((prev) => ({
                      ...prev,
                      year: e.target.value,
                    }));
                    Recruitmentbypositionget(
                      RecruitmentbypositionState.position,
                      e.target.value
                    );
                  }}
                >
                  {yearData.map((el) => (
                    <MenuItem value={el}> {el}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <GroupBar data={RecruitmentbypositionDate} Gbbvbar={GroupBarImg} />
          </Box>
        </Box>
      </Box>

      {/* main title 2  */}

      <Box
        sx={{
          fontFamily: "Poppins",

          fontWeight: "500px",
          mt: "30px",
          fontSize: "20px",
        }}
      >
        {" "}
        Gender exit trends - Business vertical
      </Box>

      <Box
        className="genbybussinessverticalRR"
        sx={{
          mt: "25px",
        }}
      >
        <Box className="genbybussinessverticalchiledRR">
          {/* graph title  */}
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Representation of gender by business vertical
          </Box>

          <Box
            className="RRGraphWithSelect"
            sx={{
              backgroundColor: "#fff",
              padding: "25px 10px",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            {/* // <Stacked data={positionInorgGraph} /> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <FormControl className="SingleSelectFC">
                <InputLabel id="demo-simple-select-label6">Year</InputLabel>
                <Select
                  className="ORSingleSelect"
                  label="Year"
                  labelId="demo-simple-select-label6"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  // value={regionAndYear.year}
                  onChange={(e) => {
                    // setRegionAndYear((prev) => ({
                    //   ...prev,
                    //   year: e.target.value,
                    // }));
                    RepresentationbybusinessverticalBarGet(e.target.value);
                  }}
                >
                  {yearData.map((el) => (
                    <MenuItem value={el}> {el}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Stacked
              data={RepresentationbybusinessverticalBarData}
              Gbbvbar={Gbbvbar}
            />
          </Box>
        </Box>

        <Box className="genbybussinessverticalchiledRR">
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Time series of gender by business vertical
          </Box>

          <Box
            className="RRGraphWithSelect"
            sx={{
              backgroundColor: "#fff",
              padding: "25px 10px",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <FormControl className="SingleSelectFC">
                <InputLabel id="demo-simple-select-label90">
                  Business vertical
                </InputLabel>
                <Select
                  className="ORSingleSelect"
                  label="Business vertical"
                  labelId="demo-simple-select-label50"
                  id="demo-simple-select"
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      maxWidth: "250px",
                    },
                  }}
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  // value={Recruitmentbybusinessverticalstate.businessvertical}
                  onChange={(e) => {
                    // setRecruitmentbybusinessverticalstate((prev) => ({
                    //   ...prev,
                    //   businessvertical: e.target.value,
                    // }));

                    RepresentationbybusinessverticalLineGet(
                      e.target.value
                      // Recruitmentbybusinessverticalstate.year
                    );
                    // fetchdataForStrockedBar(e.target.value);
                  }}
                >
                  {bussinessverticalarr?.map((ele) => (
                    <MenuItem value={ele}> {ele}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "90%",
                height: "100%",
                overflow: "hidden",
                marginTop: "50px",
                // border: "1px solid red",
              }}
            >
              <LineGraph
                data={RepresentationbybusinessverticalLineData}
                dbbvbarline={dbbvbarline}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* main title 2  */}
      <Box
        sx={{
          fontFamily: "Poppins",

          fontWeight: "500px",
          mt: "30px",
          fontSize: "20px",
        }}
      >
        {" "}
        Gender exit trends - Position
      </Box>

      <Box className="genbybussinessverticalRR" sx={{ mt: "25px" }}>
        <Box className="genbybussinessverticalchiledRR">
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Representation of gender by position
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              // padding: "20px",
              height: "100%",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            {/* // <Stacked data={positionInorgGraph} /> */}
            <Box className="RRGraphWithSelect">
              <Box
                // className="RRFormSelectContainer"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginRight: "10px",
                }}
              >
                <FormControl className="ORformSelectInput">
                  <InputLabel id="demo-simple-select-label5">
                    Business vertical
                  </InputLabel>
                  <Select
                    className="ORCityCountrySelect"
                    label="Business vertical"
                    labelId="demo-simple-select-label5"
                    id="demo-simple-select"
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                        maxWidth: "250px",
                      },
                    }}
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                    value={RepresentationbyPositionBarState.businessVertical}
                    onChange={(e) => {
                      setRepresentationbyPositionBarState((prev) => ({
                        ...prev,
                        businessVertical: e.target.value,
                      }));

                      RepresentationbyPositionBarGet(
                        e.target.value,
                        RepresentationbyPositionBarState.year
                      );
                      // fetchdataForStrockedBar(e.target.value);
                    }}
                  >
                    {bussinessverticalarr?.map((ele) => (
                      <MenuItem value={ele}> {ele}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="ORformSelectInput">
                  <InputLabel id="demo-simple-select-label6">Year</InputLabel>
                  <Select
                    className="ORCityCountrySelect"
                    label="Year"
                    labelId="demo-simple-select-label6"
                    id="demo-simple-select"
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                        maxWidth: "250px",
                      },
                    }}
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                    value={RepresentationbyPositionBarState.year}
                    onChange={(e) => {
                      setRepresentationbyPositionBarState((prev) => ({
                        ...prev,
                        year: e.target.value,
                      }));
                      RepresentationbyPositionBarGet(
                        RepresentationbyPositionBarState.businessVertical,
                        e.target.value
                      );
                    }}
                  >
                    {yearData.map((el) => (
                      <MenuItem value={el}> {el}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <StackedForOrg
                data={RepresentationbyPositionBarData}
                Gbbvbar={Genderobar}
              />
            </Box>
          </Box>
        </Box>

        <Box className="genbybussinessverticalchiledRR">
          <Box
            className="grafdiscriptionandsub"
            sx={{
              // textTransform: "capitalize",
              textAlign: "center",
              backgroundColor: "#E5E0F9",
              padding: "40px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            Time series of gender by position
          </Box>
          <Box
            className="RRGraphWithSelect"
            sx={{
              backgroundColor: "#fff",
              padding: "25px 10px",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <FormControl className="SingleSelectFC">
                <InputLabel id="demo-simple-select-label7">Gender</InputLabel>

                <Select
                  className="ORSingleSelect"
                  label="Gender"
                  labelId="demo-simple-select-label7"
                  id="demo-simple-select"
                  sx={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                  name=""
                  onChange={(e) => {
                    RepresentationbyPositionLineGet(e.target.value);
                    //setPositionInorgLineGraph(e.target.value);
                    // fetchGenderbypositionintheorgLineGraph(e.target.value);
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>

                  <MenuItem value="nonBinary">Non-binary</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "90%",
                height: "100%",
                overflow: "hidden",
                marginTop: "50px",
              }}
            >
              <LineGraph
                data={RepresentationbyPositionLineData}
                dbbvbarline={orgline}
              />

              {/* <LineGraph data={positionInorgLineGraph} dbbvbarline={orgline} /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
