import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Input, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";
import "./webpopup.css";

import axios from "axios";
import { getCookie } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";
import { Timestamp } from "firebase/firestore";
const WebinarPopUp = (props) => {
  const { onClose, open, data } = props;
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [eldata, seteldata] = useState({});
  const [checking, setChecking] = useState("");
  const [userdata, setUserData] = useState({
    firstname: "",
    surname: "",
    companymail: "",
    role: "",
    email: "",
    phone: "",
    date: new Date(),
  });

  const checkfun = () => {
    if (userdata.firstname == "") {
      return "enter first name";
    } else if (userdata.surname == "") {
      return "enter surname";
    } else if (userdata.companymail == "") {
      return "enter companymail";
    } else if (userdata.role == "") {
      return "enter role";
    } else if (userdata.email == "") {
      return "enter your email";
    } else if (userdata.phone == "") {
      return "enter your phone Number";
    }
    return "test";
  };
  async function Ragisteruser() {
    const datacheck = checkfun();
    if (datacheck != "test") {
      setChecking(datacheck);
    }
    let datauser = JSON.stringify({ ...userdata, id: data.id });
    let configrecrut = {
      method: "post",

      url: `${IP}/webinar/register`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datauser,
    };

    axios(configrecrut).then((res) => {
      onClose();
      //   setWebdata(res.data);
      //   setCourses(res.data.filter((e) => e.archived != true));
      //   setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
      //   setLoading(false);
    });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }
  useEffect(() => {
    // fetchCourses();
    seteldata(data);
  }, [data]);
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0].split("-")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")
    if (inputId == 2) {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="725px"
      className="LearningWebinarModal"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "100%",
          maxWidth: "750px",
          padding: "5px",
          overflowY: "auto"
        },
        className: "WebinarDialogFormPaper"
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          color: "#11047A",
        }}
      >
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          style={{
            width: "20px",
            height: "20px",
            background: "#f4f7fe",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </Button>
      </DialogTitle>

      <Box
        sx={{
          padding: "0px 24px 40px 24px",
        }}
      >
        {/* image and details  */}
        <Box className="WebinarDialogModal">

          <Box
            sx={{
              color: "#666666",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "400",
              height: "190px"
            }}
          >
            <img src={eldata?.photo} style={{ height: "100%" }} alt="" />
          </Box>

          <Box className="WebinarDialogTimeAndDetails">
            <Box className="WebinarPopupFormHeading"> {eldata?.topic}</Box>
            <Box className="WebinarFormText"> {eldata?.description}</Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "30px",
              }}
            >

              <Box>

                <span className="webinarTimeAndDate">Date: </span>
                <span className="WebinarFormText">

                  {Timestamp.fromMillis(eldata?.date?._seconds * 1000)
                    .toDate()
                    .toDateString()}
                </span>
              </Box>
              <Box>

                <span className="webinarTimeAndDate">Time: </span>
                <span className="WebinarFormText">
                  {eldata?.timefrom} -{eldata?.timeto}
                </span>
              </Box>
            </Box>
          </Box>

        </Box>

        {/* title  */}
        <Box className="WebinarRegistration"> Webinars registration</Box>

        {/* form input grid  */}
        <Box className="WebinarDialogFormInputs">
          <Box>

            <Box className="subhedingForwebinarPopup"> First nam</Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.firstname}
                onChange={(e) => {
                  //   console.log(event.target);
                  setUserData((prev) => ({
                    ...prev,
                    firstname: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box>

            <Box className="subhedingForwebinarPopup"> Surname</Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.surname}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    surname: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box>

            <Box className="subhedingForwebinarPopup">

              Company Email Address
            </Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.companymail}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    companymail: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box>

            <Box className="subhedingForwebinarPopup">

              Role in organisation
            </Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.role}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    role: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box>

            <Box className="subhedingForwebinarPopup"> Email Address</Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.email}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box>

            <Box className="subhedingForwebinarPopup"> Phone Number</Box>
            <Box>

              <input
                className="webinarpopupinput"
                value={userdata.phone}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box> {checking}</Box>

        {/* submit btn  */}
        <Box sx={{ mt: "20px" }}>
          <Button
            onClick={Ragisteruser}
            sx={{
              color: "#FFFFFF",
              border: "1px solid #6846C7",
              background: "#6846C7",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>

    </Dialog>
  );
};

export default WebinarPopUp;
