import React, { createContext, useState } from "react";

export const DrawerContext = createContext();

const DrawerProvider = ({ children }) => {
  const [hidepage, setHidepage] = useState(true);

  const [rolesAndPermission, setRolesAndPermission] = useState({});
  const [course_Completion, setCourse_Completion] = useState("");
  return (
    <DrawerContext.Provider
      value={{
        hidepage,
        setHidepage,
        rolesAndPermission,
        setRolesAndPermission,
        course_Completion,
        setCourse_Completion,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
