import Sidebar from "../../../../Component/Sidebar";
import NavbarTop from "../../../../Component/NavbarTop";
import { t } from "i18next";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { httpsCallable } from "firebase/functions";
// import { functions } from "../../../../Firebase";
import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useNavigate } from "react-router";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { IP } from "../../../../../baseUrlAndConfig";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";
import AdminloadingScreen from "../../loadingadmin";
export const AddCompanyLocations = () => {
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const { clrs } = useSelector((state) => state.createClr);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm({
    values: {
      registeredName: "",
      address: "",
      location: "",
      dateOfIcAppointment: "",
      icMembersList: [],
      spocEmail: "",
      companyId: "",
    },
  });

  //   const { fields, append, remove } = useFieldArray({
  //     control,
  //     name: "icMembersList",
  //   });
  const navigate = useNavigate();
  const location = useLocation();
  const [flag, setFlag] = useState(false);

  const [subscriptionlist, setSubscriptionlist] = useState([]);
  const [details, setDetails] = useState({
    name: "",

    subscriptionName: "",
    subscriptionId: "",
    start: "",
    end: "",
    noticePeriod: "",
    companyId: "",
  });
  console.log(location.state);
  useEffect(() => {}, [flag]);
  useEffect(() => {
    // register={...}
    if (location.state?.location) {
      let data = location.state;
      console.log(data);
      setValue("icMembersList", data.icMembersList);
      setValue("address", data.address);
      setValue("registeredName", data.registeredName);
      setValue(
        "dateOfIcAppointment",
        new Date(data.dateOfIcAppointment?._seconds * 1000)
          .toISOString()
          .split("T")[0]
      );
      // console.log(
      //   new Date(data.dateOfIcAppointment?._seconds * 1000).toISOString()
      // );
      setValue("location", data.location);
      // setValue("", data.location);

      setValue("spocEmail", data.spocEmail);
      setValue("companyId", data.companyId);
    }
    // setValue({ ...obj });
  }, []);

  const getSubscriptionList = () => {
    //console.log("details", details);

    setSubloading(true);

    var config = {
      method: "get",

      url: `${IP}/subscription/all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        //console.log("res", res);
        setSubloading(false);
        setSubscriptionlist(res.data);
      })
      .catch((err) => {
        //console.log(err);
        setSubloading(false);
      });
  };
  const handleChange = (e) => {
    const name = e.target.name;
    setDetails({
      ...details,
    });
  };

  // logo change

  const handleget = () => {
    //console.log("details", details);

    if (location.state) {
      setSubloading(true);

      var config = {
        method: "get",
        params: { id: location.state.companyId },
        url: `${IP}/company/company`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "data",
      };

      axios(config)
        .then((res) => {
          setSubloading(false);

          setDetails({
            ...res.data,

            // companyId: location.state.companyId,
          });
          setFlag(!flag);
        })
        .catch((err) => {
          setSubloading(false);
        });
    }
  };

  const [subloading, setSubloading] = useState(false);
  const onSubmit = () => {
    //console.log("details", details);

    // setSubloading(true);

    // console.log();
    let data = JSON.stringify({
      ...getValues(),
    });
    var config = {
      method: "post",

      url: `${IP}/iccRoutes/createLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setSubloading(false);
        navigate("/admin/settings/company/locations", {
          state: getValues("companyId"),
        });
      })
      .catch((err) => {
        setSubloading(false);
      });
  };

  const fetchdata = () => {
    // setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        // setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          setCompanyList(res.data);
          setFlag(!flag);
          setValue("companyId", location.state.companyId);
          console.log(location.state.companyId);
        }
      })
      .catch((err) => {
        // setLoading(false);
        //console.log(err);
      });
  };
  useEffect(() => {
    getSubscriptionList();
    handleget();
    fetchdata();
  }, []);
  console.log("233", getValues("dateOfIcAppointment"));
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company location Settings")} />
        {/* <Navbar /> */}

        <AdminloadingScreen open={subloading} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">company name *</h3>
                  <select
                    name=""
                    id=""
                    className="course-builder-inputBar"
                    style={{ padding: "5px", fontSize: "18px", height: "50px" }}
                    // value={companyId}
                    defaultValue={getValues("companyId")}
                    {...register("companyId", { required: true })}
                  >
                    <option value="">select company</option>
                    {companyList?.map((el) => (
                      <option value={el.companyId}>{el.name}</option>
                    ))}
                  </select>

                  {errors.companyId && <span>This field is required</span>}
                </div>
                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">
                    Registered company name *
                  </h3>
                  <input
                    defaultValue="test"
                    {...register("registeredName", { required: true })}
                    className="course-builder-inputBar"
                    style={{ padding: "5px", fontSize: "18px" }}
                  />

                  {errors.registeredName && <span>This field is required</span>}
                </div>

                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">Address *</h3>
                  <textarea
                    defaultValue="address"
                    type="text"
                    {...register("address", { required: true })}
                    className="course-builder-inputBar"
                    style={{ padding: "5px", height: "50px", fontSize: "18px" }}
                  />

                  {errors.address && <span>This field is required</span>}
                </div>

                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">Location *</h3>
                  <textarea
                    defaultValue="location"
                    type="text"
                    {...register("location", { required: true })}
                    className="course-builder-inputBar"
                    style={{ padding: "5px", height: "50px", fontSize: "18px" }}
                  />

                  {errors.location && <span>This field is required</span>}
                </div>

                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">
                    Date of IC appointment *
                  </h3>
                  <input
                    defaultValue={getValues("dateOfIcAppointment")}
                    type="date"
                    // formAction="mm/dd/yyyy"
                    {...register("dateOfIcAppointment", { required: true })}
                    // onChange={(e) => console.log(e.target.value)}
                    className="course-builder-inputBar"
                    style={{ padding: "5px", height: "50px", fontSize: "18px" }}
                  />

                  {errors.dateOfIcAppointment && (
                    <span>This field is required</span>
                  )}
                </div>

                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">SPOC Emails *</h3>
                  <textarea
                    defaultValue=""
                    type="text"
                    {...register("spocEmail", { required: true })}
                    className="course-builder-inputBar"
                    style={{ padding: "5px", height: "50px", fontSize: "18px" }}
                  />

                  {errors.spocEmail && <span>This field is required</span>}
                </div>
                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <h3 className="course-builder-inputLable">
                    IC members list *
                  </h3>

                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => {
                      let data = getValues("icMembersList");
                      console.log(data);
                      setValue(`icMembersList[${data.length}]`, {
                        name: "",
                        email: "",
                      });

                      setFlag(!flag);
                    }}
                  >
                    add list
                  </Button>
                </div>
                {getValues("icMembersList").map((el, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        gap: "20px",
                        width: "800px",
                      }}
                    >
                      <div style={{ display: "flex", width: "50%" }}>
                        <h3 className="course-builder-inputLable">name *</h3>
                        <input
                          defaultValue=""
                          type="text"
                          {...register(`icMembersList[${index}].name`, {
                            required: true,
                          })}
                          className="course-builder-inputBar"
                          style={{
                            padding: "5px",
                            height: "50px",
                            fontSize: "18px",
                          }}
                        />

                        {errors?.icMembersList?.[index]?.name && (
                          <span>This field is required</span>
                        )}
                      </div>
                      <div style={{ display: "flex", width: "50%" }}>
                        <h3 className="course-builder-inputLable">email*</h3>
                        <input
                          defaultValue=""
                          type="text"
                          {...register(`icMembersList[${index}].email`, {
                            required: true,
                          })}
                          className="course-builder-inputBar"
                          style={{
                            padding: "5px",
                            height: "50px",
                            fontSize: "18px",
                          }}
                        />

                        {errors?.icMembersList?.[index]?.email && (
                          <span>This field is required</span>
                        )}
                      </div>

                      <Button
                        // disabled={subloading}
                        variant="contained"
                        component="label"
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                        onClick={() => {
                          let data = getValues("icMembersList");
                          data.splice(index, 1);
                          // console.log(data.splice(index,1));
                          setValue(`icMembersList`, data);
                          setFlag(!flag);
                        }}
                      >
                        delete
                      </Button>
                    </div>
                  </>
                ))}

                <input
                  type="submit"
                  value={"Submit"}
                  style={{
                    width: "150px",
                    color: "white",
                    height: "50px",
                    borderRadius: "10px",
                    outline: "none",
                    border: "1px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                />
              </form>

              {/* <div style={{ textAlign: "center" }}>
                <Button
                  disabled={subloading}
                  variant="contained"
                  component="label"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
