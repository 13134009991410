import React from "react";

function Course({ clr }) {
  return (
    <svg
      width="auto"
      height="29"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1509 0.000383722H26.0647C28.5525 0.000383722 30.2843 1.73603 30.2846 4.22859C30.285 8.81276 30.285 13.3969 30.2846 17.9811C30.2846 20.4727 28.5515 22.2084 26.0635 22.2087C18.7772 22.2092 11.4908 22.2092 4.20439 22.2087C1.74235 22.2087 0.000716445 20.4624 0.000463582 17.9957C-0.000154527 13.401 -0.000154527 8.80622 0.000463582 4.21147C0.000463582 1.74817 1.74522 0.00105664 4.20557 0.000550918C7.85405 -0.000123383 11.5025 -0.000178196 15.1509 0.000383722ZM10.1021 5.04812C8.90419 5.04675 7.7328 5.40063 6.73603 6.06502C5.73927 6.7294 4.96191 7.67444 4.50225 8.78063C4.04259 9.88682 3.92128 11.1045 4.15366 12.2796C4.38604 13.4547 4.96167 14.5346 5.80777 15.3826C6.65386 16.2305 7.73241 16.8086 8.90702 17.0436C10.0816 17.2786 11.2996 17.16 12.4068 16.7028C13.514 16.2456 14.4607 15.4703 15.1273 14.475C15.7939 13.4798 16.1504 12.3092 16.1517 11.1113C16.1509 9.50579 15.5137 7.96611 14.3797 6.82961C13.2458 5.69313 11.7075 5.05251 10.1021 5.04812ZM26.232 6.0787H18.1964V8.05946H26.232V6.0787ZM26.2396 10.1148H18.1945V12.0884H26.2404L26.2396 10.1148ZM26.2323 14.1488H18.1909V16.1296H26.2329L26.2323 14.1488ZM8.08936 28.245V26.2588H12.0971V24.2499H18.1659V26.2373H22.1844V28.245H8.08936ZM13.4476 13.3546C13.8928 12.6914 14.1312 11.9109 14.1328 11.1121C14.132 10.0435 13.7087 9.01853 12.9552 8.26077C12.2017 7.503 11.1792 7.07391 10.1106 7.06706C9.31173 7.06406 8.52997 7.2981 7.8642 7.73956C7.19843 8.18103 6.67859 8.81007 6.37046 9.54709C6.06233 10.2841 5.97976 11.096 6.1332 11.8799C6.28665 12.6639 6.66921 13.3847 7.23247 13.9512C7.79573 14.5176 8.51437 14.9043 9.29745 15.0621C10.0805 15.22 10.8928 15.1421 11.6316 14.8381C12.3703 14.5342 13.0023 14.0179 13.4476 13.3546ZM8.60291 8.22399L12.9259 11.1047L8.60291 13.9858V8.22399Z"
        fill={clr}
      />
    </svg>
  );
}

export default Course;
