import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "./subcription.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import AdminloadingScreen from "../loadingadmin";
export const SubscriptionList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company

  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/subscription/all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const deletedata = (id) => {
    setLoading(true);
    let data = JSON.stringify({
      id: id,
    });
    var config = {
      method: "delete",

      url: `${IP}/subscription`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          fetchdata();
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };
  useEffect(() => {
    fetchdata();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Subscription")} />
        {/* <Navbar /> */}

        <AdminloadingScreen open={showLoading} />

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() =>
                      navigate("/admin/settings/CreateSubscription")
                    }
                  >
                    Add Subscription
                  </Button>{" "}
                </div>
              </div>
              <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
                <div className="subscriptionUserTabel subscriptionTabelTabelHeders">
                  <div> S.No</div>
                  <div>Subscription name </div>

                  <div>Action</div>
                </div>

                {/* <div className="webinarsTabel webinarsTabelTabelBody"> */}
                {template?.map((ele, index) => {
                  return (
                    <div className="subscriptionUserTabel subscriptionTabelTabelBody">
                      <div>{index + 1}</div>
                      <div>{ele?.subscriptionName}</div>

                      <div style={{ display: "flex" }}>
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/CreateSubscription",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>

                        <div className="button-box-inner mx-3 my-1">
                          <Button
                            onClick={() => {
                              deletedata(ele?.id);
                            }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
