import SearchIcon from "@mui/icons-material/Search";

import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import SendRemainderPopUp from "../../../Hr/report/confirmationPopUp";
import GenerateReportForLocal from "../../../Hr/report/reportPopUp";
import { IP } from "../../../baseUrlAndConfig";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import Notify from "../Dashboard/Notify";
import "../Dashboard/OverdueEnrollments.css";
import "../StyleAdminComponents/GroupList.css";
import AddCompletionDatePopUp from "./modal/addComplitionDatePopUp";
function GroupEnrollmentView() {
  const { clrs } = useSelector((state) => state.createClr);

  const tempMap = {
    course: "employee",
    microCourse: "microemployee",
    games: "gamesemployee",
  };
  // const overDueInfo = location.state;
  // //console.log(overDueInfo);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const courseOverdueUserNotificationCallable = httpsCallable(
    functions,
    "courseOverdueUserNotificationCallable"
  );
  const location = useLocation();
  const groupEnrollmentView = httpsCallable(functions, "groupEnrollmentView");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const [completionDatePopUpOpen, setCompletionDatePopUpOpen] = useState(false);
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);
  const [showLoading, setLoading] = useState(false);
  const [overDueArray, setOverDueArray] = useState(null);
  const [details, setDetails] = useState(null);
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayNotify, setDisplayNotify] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [newEmployeeArray, setNewEmployeeArray] = useState(null);

  const [selectDate, setSelectDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [selectDefault, setselectDefault] = useState("default");

  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const totalEmployeeRef = useRef(0);
  const [employeeNameOpt, setEmployeeNameOpt] = useState(true);
  const [emailid, setEmailid] = useState("");
  const [userName, setUserName] = useState("");

  ///////Company DAta///////////

  /////////////////////Group ////////
  const [getApiDataForGroupName, setGetApiDataForGroupName] = useState([]);
  const [selectGroupName, setSelectGroupName] = useState("");
  const [getDataForASelectedGroup, setGetDataForASelectedGroup] = useState([]);
  const [groupCourseName, setGroupCourseName] = useState("");
  const [getDataForASelectedCourse, setGetDataForASelectedCourse] = useState(
    []
  );

  // setSelectAllEmployee(!selectAllEmployee);
  const [selectAllEmployee, setSelectAllEmployee] = useState(false);
  const [reportClouse, setReportClouse] = useState(false);
  const [reportArrData, setReportArrData] = useState([]);
  const [reportArrDataTwo, setReportArrDataTwo] = useState([]);
  const [generateReportPopUp, setgenerateReportPopUp] = useState(false);

  const [extraArr, setExtraArr] = useState([]);
  const [nameAndEmail, setNameAndEmail] = useState("");
  const [tempstateForLoading, setTempStateForLoading] = useState("test");
  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  const stateDataForGroupName = useLocation();
  const [groupNameFromState, setGroupNameFromState] = useState(
    stateDataForGroupName.state.groupId
  );

  //console.log("overDueArray", overDueArray);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const fetchGroupDataFromApi = (e) => {
    setLoading(true);
    //console.log(e.target.value)

    setSelectGroupName(e.target.value);
    let tempArr = getApiDataForGroupName.filter(
      (item) => item.groupId == e.target.value
    );
    //console.log(tempArr)
    let filterdata = tempArr[0].courseList.map((e) => ({
      ...e,
      courseName: e.id.split("_")[0],
    }));
    //console.log(filterdata)

    setGetDataForASelectedGroup(filterdata);
    setLoading(false);
    //console.log(getDataForASelectedGroup)
  };

  const callApiForGetCourse = (e, type) => {
    //console.log(e.target.value)

    if (type == "status") {
      if (e.target.value == "all") {
        setDetails(extraArr);
        setArrLength(extraArr.length);

        setCurrentPage(0);
      } else if (e.target.value == "Completed") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "Completed") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
        setCurrentPage(0);
      } else if (e.target.value == "In Progress") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "In Progress") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
        setCurrentPage(0);
      } else if (e.target.value == "Not Completed") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "Not Completed") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
        setCurrentPage(0);
      }
    }
  };

  // useEffect(() => {
  //   setLoading(true);

  //   let dataex = JSON.stringify({
  //     group: groupNameFromState,
  //     select: "group",
  //     courseId: stateDataForGroupName.state?.courseId,
  //     courseName: stateDataForGroupName.state?.courseName,
  //     type: stateDataForGroupName.state?.courseType,
  //   });
  //   let configExInt = {
  //     method: "post",

  //     url: `${IP}/groupEnrollment/userInfo`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getCookie("bearer")}`,
  //     },
  //     data: dataex,
  //   };
  //   axios(configExInt)
  //     .then((res) => {
  //       setLoading(false);
  //       //console.log(res.data)
  //       let filterdata = res.data.map((e) => ({ ...e, checked: false }));
  //       // setExtraArr(filterData);
  //       //console.log(filterdata)
  //       //  setGetDataForASelectedCourse(filterdata)
  //       //  setDetails(filterdata)
  //       setArrLength(filterdata.length);
  //       let finalFilterData = filterdata;
  //       // let finalFilterData = filterdata.map((item) => {
  //       //   let status = "In Progress";
  //       //   if (
  //       //     item.issuedOn !== undefined &&
  //       //     item.issuedOn != "" &&
  //       //     item.expiry != "" &&
  //       //     item.expiry !== undefined &&
  //       //     item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
  //       //   ) {
  //       //     status = "complete";
  //       //   } else if (
  //       //     item.issuedOn == "" &&
  //       //     item.dueDate !== undefined &&
  //       //     item.dueDate != "" &&
  //       //     item.dueDate?._seconds < Timestamp.fromDate(new Date()).seconds
  //       //   ) {
  //       //     status = "overdue";
  //       //   } else if (
  //       //     item.expiry !== undefined &&
  //       //     item.expiry != "" &&
  //       //     item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
  //       //   ) {
  //       //     status = "expired";
  //       //   } else if (item.renewed == true) {
  //       //     status = "renew";
  //       //   } else if (item.finalStatus == "fail") {
  //       //     status = "fail";
  //       //   }
  //       //   return { ...item, status: status };
  //       // });

  //       setGetDataForASelectedCourse(finalFilterData);
  //       setDetails(finalFilterData);
  //       setExtraArr(finalFilterData);

  //       //console.log(finalFilterData)
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // }, []);

  const getShortUsers = () => {
    const config = {
      method: "get",
      params: {
        courseName: location.state.courseName,
      },
      url: `${IP}/hrGroupReport/${tempMap[location.state.courseType]}/${
        location.state.groupId
      }/${location.state.courseId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      setDetails(res.data.data);
      setNextUrl(res.data.next);
      setCurrPage(res.data.c_page);
      setTotalPage(res.data.t_page);
      setTotalEmployee(res.data.t_count);
      totalEmployeeRef.current = res.data.t_count;
    });
  };
  useEffect(() => {
    getShortUsers();
  }, []);

  const selectedDateTohumanDate = (type) => {
    // //console.log(result.data)
    //  setDetails(newEmployeeArray);

    if (type == "clear") {
      setNameAndEmail("");
      setDetails(extraArr);
      setArrLength(extraArr.length);
      setCurrentPage(0);
    }
    // //console.log(newarr)
  };

  // //console.log("courseName",courseName);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const handelActionStateChange = (item) => {
    window.open(item, "_blank");
    // if (type == "complete") {
    //   window.open(item.downloadLink, "_blank", "noopener,noreferrer");
    // } else if (type == "overdue") {
    //   setTempStateForLoading(id);

    //   let dataex = JSON.stringify({
    //     courseName: item.courseName,
    //     courseId: item.courseId,
    //     dueDate: item.dueDate,
    //     userId: item.userId,
    //   });
    //   let configExInt = {
    //     method: "post",

    //     url: `${IP}/userNotification/courseOverdueUserNotificationCallable`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${getCookie("bearer")}`,
    //     },
    //     data: dataex,
    //   };
    //   axios(configExInt).then((res) => {
    //     setTempStateForLoading("test");
    //     alert("reminder done");
    //   });
    // }
  };

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleRadioSubmit = () => {
    //search by name
    console.log(location.state);
    if (employeeNameOpt) {
      setLoading(true);
      const config = {
        method: "get",
        params: {
          courseName: location.state.courseName,
        },
        url: `${IP}/hrGroupReport/${tempMap[location.state.courseType]}/${
          location.state.groupId
        }/${location.state.courseId}?name=${userName}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(config).then((res) => {
        setDetails(res.data.data);

        setNextUrl(res.data.next);
        setPrevUrl(res.data.prev);
        setCurrPage(res.data.c_page);
        setTotalPage(res.data.t_page);
        setLoading(false);
        // setTotalEmployee(res.data.t_count);
      });
    }
    // search by email
    else {
      // if (!emailid.match(validRegex)) return toast("Enter A Valid Email");
      setLoading(true);
      const config = {
        method: "get",
        params: {
          courseName: location.state.courseName,
        },
        url: `${IP}/hrGroupReport/${tempMap[location.state.courseType]}/${
          location.state.groupId
        }/${location.state.courseId}?email=${emailid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(config).then((res) => {
        setDetails(res.data.data);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.prev);
        setCurrPage(res.data.c_page);
        setTotalPage(res.data.t_page);
        setLoading(false);
        // setTotalEmployee(res.data.t_count);
      });
    }
  };

  const hendelChecked = (id, type) => {
    let newTempData = [];
    //console.log(type)
    if (type === "All") {
      setSelectAllEmployee(!selectAllEmployee);
      details.map((e) => {
        //console.log(e.checked)
        e.checked = !selectAllEmployee;
        //console.log(e.checked)
        newTempData.push(e);
      });
    } else if (type == "one") {
      details.map((e, index) => {
        //console.log(id)
        if (index === id) {
          //console.log(e.checked)
          e.checked = e.checked ? false : true;
          //console.log(e.checked)
          newTempData.push(e);
        } else {
          newTempData.push(e);
        }
      });
    }
    //console.log(newTempData)
    setDetails(newTempData);
  };

  const completeCoursePopFunction = () => {
    let tempobj = {};

    let temparr = [];
    details.map((el) => {
      if (el.checked) {
        temparr.push(el.userId);
      }
    });
    if (temparr.length == 0) {
      alert("select user first");
      return;
    }

    setCompletionDatePopUpOpen(true);
  };
  const completecourse = (data) => {
    let tempobj = {};

    let temparr = [];
    details.map((el) => {
      if (el.checked) {
        temparr.push(el.userId);
      }
    });
    if (temparr.length == 0) {
      alert("select user first");
      return;
    }
    console.log("line 448", stateDataForGroupName.state);

    tempobj["usersId"] = temparr;
    tempobj["course"] = {
      type: stateDataForGroupName.state?.courseType,
      id: stateDataForGroupName.state?.courseId,
      groupId: stateDataForGroupName.state?.groupId,
      data: data.lessonData?.filter((el) => el.select),
    };
    tempobj["groupId"] = stateDataForGroupName.state?.groupId;
    console.log(tempobj);
    let dataex = JSON.stringify(tempobj);
    let configExInt = {
      method: "post",

      url: `${IP}/adminUserProfile/companyUserList`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        alert("user passed");
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterData = (name) => {
    setNameAndEmail(name);
    let newArr = extraArr.filter(
      (el) =>
        el?.userName.toLowerCase().includes(name.toLowerCase()) ||
        el?.email.toLowerCase().includes(name.toLowerCase())
    );
    console.log(newArr);
    setDetails(newArr);
  };

  const downloadreport = () => {
    const config = {
      method: "get",
      params: {
        courseName: location.state.courseName,
        download: 1,
      },

      url: `${IP}/hrGroupReport/${tempMap[location.state.courseType]}/${
        location.state.groupId
      }/${location.state.courseId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((response) => {
      const text = response.data; // Assuming response.data is a string containing CSV data

      // Create a Blob with UTF-8 encoding and a BOM
      const utf8EncodedText = new TextEncoder().encode("\uFEFF" + text); // \uFEFF is the BOM for UTF-8

      const blob = new Blob([utf8EncodedText], {
        type: "text/csv; charset=UTF-8",
      });

      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const formattedDate = Date.now();

      const a = document.createElement("a");
      a.href = url;

      // a.download = `menu-template-${
      //   restaurantData?.name ? restaurantData?.name[language] : "name"
      // }-${formattedDate}.csv`; // Specify the file name with a .csv extension
      const date = new Date();
      a.download = `report_${date}.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
    });
  };
  // const downloadreport = () => {
  //   let temparr = [];
  //   let temparrtwo = [];

  //   setgenerateReportPopUp(true);

  //   details.map((el, index) => {
  //     let obj = {};

  //     // <Box> Employee name</Box>
  //     // <Box> Total lessons</Box>
  //     // <Box> Lessons completed</Box>
  //     // <Box> Last step completed</Box>
  //     // <Box> Start date</Box>
  //     // <Box> Completion date</Box>

  //     // <Box> Status</Box>
  //     obj["User Id"] = el.userId;
  //     obj["Employee name"] = el.userName;
  //     obj["Email"] = el.email;
  //     obj["Course Id"] = el.courseId;
  //     obj["Course name"] = el.courseName;
  //     obj["Total lessons"] = el.totalLessonCount;
  //     obj["Lessons completed"] = el.lessonCompleted;
  //     obj["Last step completed"] = el.course_last_step;
  //     obj["Start date"] = el.course_started_on;
  //     obj["Completion date"] = el.course_completed_on;
  //     obj["Invitation accepted"] = el?.invitationAccept;

  //     obj["Status"] = el.status;

  //     if (index < 2500) {
  //       temparr.push(obj);
  //     } else {
  //       temparrtwo.push(obj);
  //     }
  //   });
  //   console.log(temparr);
  //   setReportArrDataTwo(temparrtwo);
  //   setReportArrData(temparr);
  // };

  const sendremainder = () => {
    let temparr = details.filter((el) => el.checked == true);

    if (temparr.length == 0) {
      alert("select user");
      return;
    }
    setReportClouse(true);
  };

  const delfunction = (type) => {
    if (type == "Yes") {
      let temparr = details
        .filter((el) => el.checked == true)
        .map((el) => {
          return {
            name: el.userName,
            userId: el.userId,
            email: el.email,
          };
        });

      // if (temparr.length == 0) {
      //   alert("select user");
      //   return;
      // }
      let tempobj = {};
      tempobj["courseName"] = stateDataForGroupName.state?.courseName;
      tempobj["groupId"] = stateDataForGroupName.state?.groupId;
      tempobj["courseId"] = stateDataForGroupName.state?.courseId;
      tempobj["users"] = temparr;
      const dataregionf = JSON.stringify(tempobj);
      let configrecrutci = {
        method: "post",

        url: `${IP}/mail/userFailed`,
        // groupId,courseId

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataregionf,
      };
      axios(configrecrutci).then((res) => {
        toast("Email sent successfully");
      });
    } else {
      let tempobj = {};
      tempobj["courseName"] = stateDataForGroupName.state?.courseName;
      tempobj["groupId"] = stateDataForGroupName.state?.groupId;
      tempobj["courseId"] = stateDataForGroupName.state?.courseId;
      // tempobj["users"] = temparr;
      const dataregionf = JSON.stringify(tempobj);
      let configrecrutci = {
        method: "post",

        url: `${IP}/mail/userFailed`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataregionf,
      };
      axios(configrecrutci).then((res) => {
        toast("Email sent successfully");
      });
    }
  };
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {displayNotify && <Notify NotifyClick={NotifyClick} />}

      <GenerateReportForLocal
        open={generateReportPopUp}
        onClose={() => setgenerateReportPopUp(false)}
        data={reportArrData}
        datat={reportArrDataTwo}
      />
      <AddCompletionDatePopUp
        open={completionDatePopUpOpen}
        courseDataprop={stateDataForGroupName.state}
        onClose={() => setCompletionDatePopUpOpen(false)}
        delfun={(date) => {
          completecourse(date);
        }}
      />

      <SendRemainderPopUp
        open={reportClouse}
        onClose={() => setReportClouse(false)}
        // setConfirmation={setConfirmation}
        delfun={(topic) => {
          delfunction(topic);
        }}
      />
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("View Group Enrollment")}
            </div>
          </div>

          <div className="admin-overdue-mid" style={{}}>
            {/* <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("Status")}</div>
              <div className="admin-overdue-mid-left-input">
                <select
                  name="groupStatus"
                  id="group"
                  onChange={(e) => {
                    callApiForGetCourse(e, "status");
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select a status
                  </option>

                  <option value="all">All</option>
                  <option value="Completed">Completed</option>

                  <option value="Not Completed">Not Completed</option>
                </select>
              </div>
            </div> */}

            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {t("Name & Email")}
              </div>
              <div className="admin-overdue-mid-left-input">
                {/* <input
                  value={nameAndEmail}
                  onChange={(e) => filterData(e.target.value)}
                /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "40px",
                    border: "1px solid #000",
                    width: "max-content",
                  }}
                >
                  <input
                    type="text"
                    placeholder={
                      employeeNameOpt
                        ? "Search by Employee Name"
                        : "Search by Employee Email"
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleRadioSubmit();
                    }}
                    style={{
                      // width: "20.18rem",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      padding: "10px",
                    }}
                    value={employeeNameOpt ? userName : emailid}
                    onChange={(e) => {
                      if (employeeNameOpt) {
                        setUserName(e.target.value);
                      } else {
                        setEmailid(e.target.value);
                      }
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "#007C84",
                      width: "3.3rem",
                      borderRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <SearchIcon
                      style={{
                        marginLeft: "1.05rem",
                        marginTop: "0.5rem",
                        color: "white",
                      }}
                      onClick={handleRadioSubmit}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", marginTop: "10px", gap: "15px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      // justifyContent: "center",
                      // justifyItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        width: "1.375rem",
                        height: "1.375rem",
                        accentColor: "#007C84",
                      }}
                      value="empName"
                      onChange={() => {
                        setEmployeeNameOpt(true);
                        setEmailid("");
                        setUserName("");
                      }}
                      checked={employeeNameOpt}
                    />
                    <label style={{ fontFamily: "Poppins" }}>
                      Employee Name
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",

                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        width: "1.375rem",
                        height: "1.375rem",
                        accentColor: "#007C84",
                      }}
                      value="empEmail"
                      onChange={() => {
                        setEmployeeNameOpt(false);
                        setEmailid("");
                        setUserName("");
                      }}
                      checked={!employeeNameOpt}
                    />
                    <label style={{ fontFamily: "Poppins" }}>
                      Employee Email
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "8vw",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("clear");
                      }}
                    >
                      <div>
                        <svg
                          width="auto"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div
                        onClick={() => {
                          setUserName("");
                          setEmailid("");
                          getShortUsers();
                        }}
                      >
                        {t("Clear")}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "13vw",
                        color: "#FFFFFF",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        sendremainder();
                        // completecourse();
                      }}
                    >
                      <div>{t("send reminder")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "13vw",
                        color: "#FFFFFF",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        completeCoursePopFunction();
                        // completecourse();
                      }}
                    >
                      <div>{t("Complete course")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "13vw",
                        color: "#FFFFFF",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        downloadreport();
                      }}
                    >
                      <div>{t("Download report")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{
                  gridTemplateColumns:
                    "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                {/*     <div style={{width:"1vw"}} className="admin-overdue-bottom-table-head"></div> */}
                <div
                  className="admin-overdue-bottom-table-head"
                  style={{
                    marginRight: "20px",
                  }}
                >
                  {" "}
                  <input
                    type="checkbox"
                    onClick={() => {
                      hendelChecked("id", "All");
                    }}
                    checked={selectAllEmployee}
                  />{" "}
                  {t("SrNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Email")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("CourseName")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("Total Lesson Count")}
                </div>
                {/* <div className="admin-overdue-bottom-table-head">
                  {t("Taning Provider")}
                </div> */}

                <div className="admin-overdue-bottom-table-head">
                  {t("Lesson Completed")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Start Date")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Completion Date")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("Status")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
                {/* <div className="admin-overdue-bottom-table-head">{t("Action")}</div> */}
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details?.length == 0 && (
                <h1 style={{ marginLeft: "24px" }}>No Record Found</h1>
              )}
              {details?.map((item, id) => {
                // if (id < pageSize * currentPage) return;
                // if (id >= pageSize * (currentPage + 1)) return;

                let issuedOn = item?.course_completed_on;
                let startDate = item?.course_started_on;

                return (
                  <div
                    className="admin-overdue-bottom-table-cnt"
                    key={id}
                    style={{
                      gridTemplateColumns:
                        "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          onClick={() => {
                            hendelChecked(id, "one");
                          }}
                          checked={item.checked}
                        />{" "}
                      </div>
                      <div> {(currPage - 1) * 50 + id + 1} </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.userName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.email}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.courseName}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {item?.totalLessonCount}
                    </div>
                    {/* <div className="admin-overdue-bottom-table-td">
                      {item.trainingProvider}
                    </div> */}

                    <div className="admin-overdue-bottom-table-td">
                      {item?.lessonCompleted}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {startDate}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {issuedOn}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {item.status}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      <select
                        style={{
                          border: "none",
                          backgroundColor: "#F2F2F2",
                          height: "4vh",
                          width: "7vw",
                          padding: "0 1vh",
                          fontWeight: "700",
                          color: "#717579",
                          borderRadius: "6px",
                        }}
                        disabled={item.status != "Completed"}
                        onChange={(e) =>
                          handelActionStateChange(e.target.value)
                        }
                      >
                        <option>action</option>
                        {item.status == "Completed" && (
                          <option value={item.certificateLink}>download</option>
                        )}
                      </select>
                    </div>
                    {/* <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                      onClick={NotifyClick}
                    >
                      {item.Action} */}
                    {/* </div> */}
                  </div>
                );
              })}
            </div>

            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <button disabled={currPage == 1 ? true : false}>
                    <svg
                      // onClick={() => clickhandler(currentPage - 1)}

                      onClick={() => {
                        // setlodingscreen(true);
                        const config = {
                          method: "get",
                          url: `${prevUrl}`,
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${getCookie("bearer")}`,
                          },
                        };
                        axios(config).then((res) => {
                          setDetails(res.data.data);
                          setSelectAllEmployee(false);
                          // setlodingscreen(false);
                          setNextUrl(res.data.next);
                          setPrevUrl(res.data.prev);
                          setCurrPage(res.data.c_page);
                        });
                      }}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </button>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {/* {currentPage + 1} of {Math.ceil(arrLength / pageSize)}*/}
                  {currPage} of {totalPage}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <button disabled={currPage == totalPage ? true : false}>
                    <svg
                      // onClick={() => clickhandler(currentPage + 1)}
                      onClick={() => {
                        // setlodingscreen(true);
                        const config = {
                          method: "get",
                          url: `${nextUrl}`,
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${getCookie("bearer")}`,
                          },
                        };
                        axios(config).then((res) => {
                          //   console.log("line 470", res.data.data);
                          setDetails(res.data.data);
                          setSelectAllEmployee(false);

                          // setlodingscreen(false);
                          setNextUrl(res.data.next);
                          setPrevUrl(res.data.prev);
                          setCurrPage(res.data.c_page);
                        });
                      }}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </button>
                </div>
                {/* <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentView;
