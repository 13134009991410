import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";
import AddNewUser from "./Admin/AdminComponents/AddNewUser";
import AdminSettings from "./Admin/AdminComponents/AdminSetting/AdminSettings";
import CreateCourse from "./Admin/AdminComponents/Builder/CreateCourse";
import Certificates from "./Admin/AdminComponents/Certificates/Certificates";
import CertificateCreate from "./Admin/AdminComponents/CertificateTemplate/CertificateCreate";
import Certificatedetails from "./Admin/AdminComponents/CertificateTemplate/Certificatedetails";
import CourseReport from "./Admin/AdminComponents/CourseReport";
import Dashboard from "./Admin/AdminComponents/Dashboard";
import ExpiringTraining from "./Admin/AdminComponents/Dashboard/ExpiringTraining";
import NewEmployees from "./Admin/AdminComponents/Dashboard/NewEmployees";
import OverdueEnrollments from "./Admin/AdminComponents/Dashboard/OverdueEnrollments";
import UserFailed from "./Admin/AdminComponents/Dashboard/UserFailed";
import UserPassed from "./Admin/AdminComponents/Dashboard/UserPassed";
import GroupEnrollment from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollment";
import GroupEnrollmentEdits from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentEdits";
import GroupEdits from "./Admin/AdminComponents/Groups/GroupEdit";
import Groups from "./Admin/AdminComponents/Groups/Groups";
import IndividualEnrollmentList from "./Admin/AdminComponents/IndividualEnrollment/IndividualEnrollmentList";
import TrainExtAdd from "./Admin/AdminComponents/TrainExtAdd";
import TrainExtrenalTrainEn from "./Admin/AdminComponents/TrainExtrenalTrainEn";
import Trainings from "./Admin/AdminComponents/Trainings";

import TrainInternalOffTrainEn from "./Admin/AdminComponents/TrainInternalOffTrainEn";
import TrainInternalTrainEn from "./Admin/AdminComponents/TrainInternalTrainEn";
import TrainInterOffAdd from "./Admin/AdminComponents/TrainInterOffAdd";
import Users from "./Admin/AdminComponents/Users/Users";
import UserView from "./Admin/AdminComponents/Users/UserView";
import "./App.css";

import { onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import AvailableCertificates from "./Admin/AdminComponents/AdminSetting/AvailableCertificates";
import { CompanyDepartmentSettings } from "./Admin/AdminComponents/AdminSetting/CompanyDepartmentSettings";
import { EmailSettings } from "./Admin/AdminComponents/AdminSetting/EmailSettings";
import { CompanyDepartmentCreate } from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyDepartmentCreate";
import { EditCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/EditCompany";
import { ViewCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/ViewCompany";
import { CreateEmailNotification } from "./Admin/AdminComponents/Emails/CreateEmailNotification";
import EmailSettingPage from "./Admin/AdminComponents/Emails/EmailSettingPage";
import GenerateReportPage from "./Admin/AdminComponents/GenerateReports";
import GroupEnrollmentView from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentView";
import AddNewEnrollment from "./Admin/AdminComponents/IndividualEnrollment/AddNewEnrollment";
import { auth, functions } from "./Firebase";
import PageNotFound from "./PageNotFound";

import SingleCourseContentMainHr from "./Hr/Learning/SingleCourse/SingleCourseContentMain";

import ForgotPassword from "./User/components/Forgot-password/ForgotPassword";

import LoginPage from "./User/components/loginpage/LoginPage";

import {
  deleteCookie,
  getCookie,
  setCookie,
  getLocalStorage,
  getCookieFromServer,
} from "./Cookies";
import { useDispatch } from "react-redux";
import { roles } from "./Admin/Redux/roles/action";
import { uirole } from "./roles";
import HomePageHr from "./Hr/home/homeHr";
import MyLearning from "./Hr/myCourses/myCourse";
import Learning from "./Hr/Learning/Learning";
import Communications from "./Hr/report/GroupReport";
import HrProfilePage from "./Hr/HrProfile/HrProfile";
import CourseContent from "./Hr/Learning/CourseContent";
import DownloadCourseCertificate from "./Hr/Learning/DownloadCourseCertificate";
import ViewCoursesAll from "./Hr/Learning/ViewCoursesAll";
import ViewMicroLearningsAll from "./Hr/Learning/ViewMicroLearningsAll";
import TestSteper from "./Hr/home/testpageforsteper";
import { AdvancementScaleList } from "./Admin/AdminComponents/AdminSetting/AdvancementScale/scalelist";
import { AdvanceMentScaleCreate } from "./Admin/AdminComponents/AdminSetting/AdvancementScale/createScaleList";
import { StrategiesList } from "./Admin/AdminComponents/AdminSetting/Strategies/strategiesList";
import { StrategiesEdit } from "./Admin/AdminComponents/AdminSetting/Strategies/editStrategies";
import { AddEditSection } from "./Admin/AdminComponents/AdminSetting/Strategies/addeditSection";
import { PoliciesAddList } from "./Admin/AdminComponents/AdminSetting/Policies/PoliciesList";
import { InfrastructureAddList } from "./Admin/AdminComponents/AdminSetting/infraStructur/infraListAndAdd";
import { ProcessToolsAddList } from "./Admin/AdminComponents/AdminSetting/prosessAndTools/porocessAndTollsList";
import { WebinarsList } from "./Admin/AdminComponents/AdminSetting/Webinars/WebinarsList";
import { WebinarsUserList } from "./Admin/AdminComponents/AdminSetting/Webinars/webinarsUserList";
import { CreateWebinars } from "./Admin/AdminComponents/AdminSetting/Webinars/createWebinars";
import { SubscriptionList } from "./Admin/AdminComponents/AdminSetting/Subscription/subscriptionList";
import { CreateSubscription } from "./Admin/AdminComponents/AdminSetting/Subscription/createSubscription";
import CreateMicroLearning from "./Admin/AdminComponents/Microlerning/CreateCourse";
import CreateGames from "./Admin/AdminComponents/Game/CreateCourse";
import MicroLearningList from "./Admin/AdminComponents/microLearning";
import GamesList from "./Admin/AdminComponents/GamesList";
import MicroLearningContent from "./Hr/Learning/microlerningContent";
import { EventList } from "./Admin/AdminComponents/AdminSetting/events/eventList";
import { CreateEvent } from "./Admin/AdminComponents/AdminSetting/events/addEvent";
import SingleMicroContentMainHr from "./Hr/Learning/MicroLerningSingleCourse/SingleCourseContentMain";
import MicroContent from "./Hr/Learning/MicroLerningSingleCourse/CoureContentMain/CourseContent";
import EventView from "./Hr/myCourses/eventDetails";
import DEI from "./Hr/myCourses/myCourse";
import GroupReport from "./Hr/report/GroupReport";
import GroupReportView from "./Hr/report/viewReport";
import ViewWebinarAll from "./Hr/Learning/Webinar/viewAllWebinar";
import Strategies from "./Hr/home/DIComponent/strategi";
import ViewGamesAll from "./Hr/Learning/Game/viewAllWebinar";
// import SingleGamesContentMainHr from "./Hr/Learning/Game/GamesSingleCourse/SingleCourseContentMain";
import GamesContent from "./Hr/Learning/Game/gamescontent";
import SingleGamesContentMainHr from "./Hr/Learning/Game/GamesSingleCourse/SingleCourseContentMain";
import Setpassword from "./User/components/setPaassword/SetPassword";
import PsyclogicalList from "./Admin/AdminComponents/AdminSetting/PsyclogicalSafety/PsyclogicalList";
import AddCompanyFormData from "./Admin/AdminComponents/AdminSetting/PsyclogicalSafety/AddCompanyFormData";
import axios from "axios";
import PsyclogicalBystandard from "./Hr/Psychological_safety/psy_Home";
import { DrawerContext } from "./utilities/context/DrawerProvider";
import Maintenance from "./User/components/maintenance/Maintenance";
import MobileTopBar from "./Hr/Sidebar/MobaileTopBar";
import CommonLink from "./User/components/CommonLink/CommonLink";
// import { toast, ToastContainer } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { DepartmentSettings } from "./Admin/AdminComponents/AdminSetting/DepartmentSetting";
import PrintPage from "./Hr/Psychological_safety/PsyclogicalSaftey/PrintPage";
// import AssignHr from "./Admin/AdminComponents/AdminSetting/AssignHR/AssignHr";
import AssignHr from "./Admin/AdminComponents/AdminSetting/AssignHR/AssignHr";
import CompanyReport from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyReport";
import CompanyReportView from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyReportView";
import CompanyReportBeforeView from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyReportBeforeView";
import DEIReportAdmin from "./Admin/AdminComponents/AdminSetting/SettingComponents/DEIReportAdmin";
import ArchievedCompanies from "./Admin/AdminComponents/AdminSetting/SettingComponents/ArchievedCompanies";
import ArchievedCompaniesReport from "./Admin/AdminComponents/AdminSetting/SettingComponents/ArchievedCompaniesReport";
import BackupDownload from "./Admin/AdminComponents/AdminSetting/SettingComponents/BackupDownload";
import { CompanyLocationsList } from "./Admin/AdminComponents/AdminSetting/SettingComponents/companyLocations/CompanyLocationsList";
import { AddCompanyLocations } from "./Admin/AdminComponents/AdminSetting/SettingComponents/companyLocations/AddLocations";
import { ICCMembersCompanyAndLocationList } from "./Admin/AdminComponents/AdminSetting/SettingComponents/ICCMembers/IccListCompanyAndLocation";
import CreateStaticTemplate from "./Admin/AdminComponents/AdminSetting/SettingComponents/ICCMembers/StaticTempalate";
import { ICCTemplateList } from "./Admin/AdminComponents/AdminSetting/SettingComponents/ICCMembers/ListOfTempalate";

// toast-configuration method,
// it is compulsory method.
// toast.configure();
const App = () => {
  const [cookies, setCookies] = useState("");

  const [messagingToken, setMessagingToken] = useState(null);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   // ValidateAuth();
  //   let newobj = getCookie("bearer");
  //   // console.log(newobj.HRDI);
  //   if (!newobj) {
  //     window.location("/");
  //     return <></>;
  //   }
  //   // console.log("in the ler");
  // }, []);
  // useEffect(async () => {
  //   const handleTabClose = (event) => {
  //     event.preventDefault();

  //     // return (event.returnValue = "Are you sure you want to exit?");
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);

  //   const pageAccessedByReload =
  //     (window.performance.navigation &&
  //       window.performance.navigation.type === 1) ||
  //     window.performance
  //       .getEntriesByType("navigation")
  //       .map((nav) => nav.type)
  //       .includes("reload");

  //   if (!pageAccessedByReload) {
  //     if (!getCookie("keepMeLogin")) {
  //       deleteCookie("UID");
  //       deleteCookie("keepMeLogin");
  //     } else {
  //       // let rol = await uirole();
  //       // dispatch(roles(rol));
  //     }
  //     if (pageAccessedByReload) {
  //       // let rol = await uirole();
  //       // dispatch(roles(rol));
  //     }
  //   } else {
  //   }

  //   // };
  // }, []);

  useEffect(async () => {
    axios.defaults.withCredentials = true;
    console.log("UseEffect");
    console.log(rolesAndPermission);
    console.log(getLocalStorage("bearer"));

    if (getLocalStorage("bearer")) {
      console.log("gfdfhg");
      uirole(
        getCookieFromServer("bearer")
          ? getCookieFromServer("bearer")
          : getLocalStorage("bearer")
      )
        .then((res) => {
          setRolesAndPermission(res);
        })
        .catch((err) => {});
    }
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "gdgdg dddfgd gg ds";
  };
  const showNotification = () => {
    NotificationManager.success("Success message", "Title here");
  };

  const ProtectedRoute = ({ user, redirectPath = "/" }) => {
    console.log(user);
    if (!user) {
      //  window.location.assign('/');

      uirole(
        getCookie("bearer") ? getCookie("bearer") : getLocalStorage("bearer")
      )
        .then((res) => {
          console.log("heyi iii i");
          setRolesAndPermission(res);
          // window.location.assign('/');
          // return <Navigate to ='/www.google.com'/>
          // setTimeout(navinateFun, 3000);
          const navinateFun = () => {
            return <Navigate to={redirectPath} />;
          };
        })
        .catch((err) => {
          console.log("line 243", err);
          // window.location.assign('/');

          //  return <Navigate to={redirectPath} replace />;
        });
    }

    return <Outlet />;
  };
  return (
    <div className="App">
      {/* notification  */}
      <ToastContainer />

      <NotificationContainer style={{ zIndex: 100 }} />

      <Router>
        <Routes>
          <Route
            path="/"
            element={<LoginPage messagingToken={messagingToken} />}
          />
          <Route path="/commonLink" element={<CommonLink />} />
          <Route
            path="/forgot-password-page"
            element={<ForgotPassword messagingToken={messagingToken} />}
          />
          <Route path="/mobile" element={<MobileTopBar />} />
          <Route path="/set-password" element={<Setpassword />} />
          {/* HR PORTAL */}
          <Route path="/hr/Learning" element={<Learning />} />
          <Route path="/Maintenance" element={<Maintenance />} />
          <Route
            element={<ProtectedRoute user={getCookieFromServer("rfToken")} />}
          >
            <Route path="/test" element={<TestSteper />} />
            <Route path="/hr" element={<HomePageHr />} />
            <Route path="/hr/Strategies" element={<Strategies />} />
            <Route path="/hr/DEI" element={<DEI />} />

            <Route
              path="/hr/Psychological_safety"
              element={<PsyclogicalBystandard />}
            />

            <Route path="/hr/Learning/courses" element={<ViewCoursesAll />} />
            <Route
              path="/hr/Learning/micro-learnings"
              element={<ViewMicroLearningsAll />}
            />
            <Route
              path="/hr/Learning/view-Webinar"
              element={<ViewWebinarAll />}
            />
            <Route path="/hr/Learning/view-games" element={<ViewGamesAll />} />
            <Route
              path="/hr/Learning/course-content/:courseId"
              element={<CourseContent />}
            />
            <Route
              path="/hr/Learning/course-content/:courseId"
              element={<MicroContent />}
            />
            <Route
              path="/hr/Learning/games-content/:courseId"
              element={<GamesContent />}
            />
            <Route
              path="/hr/Learning/MicroLearning-content/:courseId"
              element={<MicroLearningContent />}
            />
            <Route
              path="/hr/Learning/course-content/course/:courseId"
              element={<SingleCourseContentMainHr />}
            />
            <Route
              path="/hr/Learning/games-content/games/:courseId"
              element={<SingleGamesContentMainHr />}
            />
            <Route
              path="/hr/Learning/MicroLearning-content/course/:courseId"
              element={<SingleMicroContentMainHr />}
            />
            <Route
              path="/hr/Learning/course-content/download-course-certificate"
              element={<DownloadCourseCertificate />}
            />
            <Route path="/hr/GroupReport" element={<GroupReport />} />
            <Route path="/hr/GroupReportView" element={<GroupReportView />} />
            <Route path="/hr/DEI_view" element={<EventView />} />
            <Route path="/hr/HrProfilePage" element={<HrProfilePage />} />
          </Route>
          <Route path="/print/:id" element={<PrintPage />}></Route>
          <Route path="/admin" element={<Dashboard />}></Route>
          <Route path="/admin/users" element={<Users />}></Route> {/**a */}
          <Route path="/admin/addNewUser" element={<AddNewUser />}></Route>{" "}
          <Route path="/admin/settings" element={<AdminSettings />}></Route>{" "}
          <Route path="/admin/settings/assignHr" element={<AssignHr />}></Route>{" "}
          <Route
            path="/admin/settings/available-certificates"
            element={<AvailableCertificates />}
          ></Route>{" "}
          <Route
            path="/admin/settings/AdvancementScaleList"
            element={<AdvancementScaleList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/Department-department"
            element={<DepartmentSettings />}
          ></Route>{" "}
          <Route
            path="/admin/settings/AdvancementScaleCreate"
            element={<AdvanceMentScaleCreate />}
          ></Route>{" "}
          <Route
            path="/admin/settings/StrategiesList"
            element={<StrategiesList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/StrategiesEdit"
            element={<StrategiesEdit />}
          ></Route>{" "}
          <Route
            path="/admin/settings/Strategies_Add_Section"
            element={<AddEditSection />}
          ></Route>{" "}
          <Route
            path="/admin/settings/PoliciesAddList"
            element={<PoliciesAddList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/InfrastructureAddList"
            element={<InfrastructureAddList />}
          ></Route>{" "}
          {/**a */}
          <Route
            path="/admin/settings/Process&ToolsAddList"
            element={<ProcessToolsAddList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/WebinarsList"
            element={<WebinarsList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/WebinarsUserList"
            element={<WebinarsUserList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/EventList"
            element={<EventList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/CreateEvent"
            element={<CreateEvent />}
          ></Route>{" "}
          <Route
            path="/admin/settings/CreateWebinars"
            element={<CreateWebinars />}
          ></Route>{" "}
          <Route
            path="/admin/settings/SubscriptionList"
            element={<SubscriptionList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/CreateSubscription"
            element={<CreateSubscription />}
          ></Route>{" "}
          <Route
            path="/admin/settings/PsyclogicalSafetyList"
            element={<PsyclogicalList />}
          ></Route>{" "}
          <Route
            path="/admin/settings/CreatePsyclogicalForm"
            element={<AddCompanyFormData />}
          ></Route>{" "}
          <Route path="/admin/courseBuilder" element={<CreateCourse />}></Route>
          <Route
            path="/admin/Micro-learnings"
            element={<CreateMicroLearning />}
          ></Route>
          <Route
            path="/admin/Micro-learnings/view"
            element={<MicroLearningList />}
          ></Route>
          <Route path="/admin/Games" element={<CreateGames />}></Route>
          <Route path="/admin/Games/view" element={<GamesList />}></Route>
          <Route path="/admin/groups" element={<Groups />}></Route>
          {/**ai */}
          <Route path="/admin/groupEdits/" element={<GroupEdits />}></Route>
          <Route
            path="/admin/settings/certificate"
            element={<Certificatedetails />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/certificatecreate"
            element={<CertificateCreate />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email"
            element={<EmailSettings />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/emailtemplate"
            element={<EmailSettingPage />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email-create"
            element={<CreateEmailNotification />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/company-department"
            element={<CompanyDepartmentSettings />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/companycreate"
            element={<CompanyDepartmentCreate />}
          ></Route>
          <Route
            path="/admin/settings/archieveCompanies"
            element={<ArchievedCompanies />}
          ></Route>
          <Route
            path="/admin/settings/archieveCompaniesReport"
            element={<ArchievedCompaniesReport />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/edit-company"
            element={<EditCompany />}
          ></Route>
          <Route
            path="/admin/settings/company/locations"
            element={<CompanyLocationsList />}
          ></Route>
          <Route
            path="/admin/settings/company/locations/create"
            element={<AddCompanyLocations />}
          ></Route>
          <Route
            path="/admin/settings/ICC-list"
            element={<ICCMembersCompanyAndLocationList />}
          ></Route>
          <Route
            path="/admin/settings/ICC/Template"
            element={<CreateStaticTemplate />}
          ></Route>
          <Route
            path="/admin/settings/ICC/Templatelist"
            element={<ICCTemplateList />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/view-company"
            element={<ViewCompany />}
          ></Route>
          <Route
            path="/admin/settings/companyReport"
            element={<CompanyReport />}
          ></Route>
          <Route
            path="/admin/settings/backupDownload"
            element={<BackupDownload />}
          ></Route>
          <Route
            path="/admin/settings/companyReportSelect"
            element={<CompanyReportBeforeView />}
          ></Route>
          <Route
            path="/admin/settings/companyReportDEI"
            element={<DEIReportAdmin />}
          ></Route>
          <Route
            path="/admin/settings/companyReportView"
            element={<CompanyReportView />}
          ></Route>
          <Route
            path="/admin/settings/companyReport"
            element={<CompanyReport />}
          ></Route>
          <Route
            path="/admin/groupEnrollment"
            element={<GroupEnrollment />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/groupEnrollment/view"
            element={<GroupEnrollmentView />}
          ></Route>
          <Route
            path="/admin/IndividualEnrollment"
            element={<IndividualEnrollmentList />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/IndividualEnrollment/addNewIndividualEnrollment"
            element={<AddNewEnrollment />}
          ></Route>
          <Route
            path="/admin/groupEnrollmentEdits/"
            element={<GroupEnrollmentEdits />}
          ></Route>
          {/**ai */}
          <Route path="/admin/certificate" element={<Certificates />}></Route>
          {/**a */}
          <Route
            path="/admin/overdueenrollments"
            element={<OverdueEnrollments />}
          />
          {/*ai */}
          <Route path="/admin/userpassed" element={<UserPassed />} />
          {/*ai */}
          <Route path="/admin/userfailed" element={<UserFailed />} />
          {/*ai */}
          <Route path="/admin/newemployees" element={<NewEmployees />} />
          {/*a */}
          <Route
            path="/admin/expiringtraining"
            element={<ExpiringTraining />}
          />
          {/*ai */}
          <Route path="/admin/course-reports" element={<CourseReport />} />
          {/*a */}
          <Route
            path="/admin/course-reports/GenerateReportPage"
            element={<GenerateReportPage />}
          />
          {/*a */}
          <Route path="/admin/userview" element={<UserView />} />
          {/*a */}
          <Route path="/admin/training" element={<Trainings />} />
          <Route
            path="/admin/training/internal"
            element={<TrainInternalTrainEn />}
          />
          <Route
            path="/admin/training/extrenal"
            element={<TrainExtrenalTrainEn />}
          />
          <Route
            path="/admin/training/internalOff"
            element={<TrainInternalOffTrainEn />}
          />
          <Route
            path="/admin/training/extrenal/add"
            element={<TrainExtAdd />}
          />
          <Route
            path="/admin/training/internalOff/add"
            element={<TrainInterOffAdd />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
