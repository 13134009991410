import { Dialog, Typography } from "@mui/material";
import { Box, typography } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import { ItemContext } from "../Context/ItemContext";
import CourseProgressAccordions from "../lessionicons/CourseProgressAccordions";
import FinalQuizzes from "./FinalQuizzes";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MicroContent = ({ lessons, quizzes, setShowTab }) => {
  // some datastructure to store lessons and topics
  // set itemsArray simultaniously
  const { sample, courseId, progressIndex } = useContext(ItemContext);
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const [progress, setProgress] = useState(null);

  const [openModal, setOnpenModal] = useState(false);
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroItemDetails/nodeProgress`,
      params: { courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((res) => {
      setProgress(res.data);
    });

    // getNodeProgress({ courseId: courseId }).then((result) => {
    //   setProgress(result.data);
    // });
  }, [progressIndex]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          fontFamily: typography.fontFamily,
        }}
      >
        <Box
          sx={{
            background: "#E1F566",
            height: "auto",
            "@media (max-width: 780px)": {
              height: "110px",
              background: "#F3F1FF",
              boxShadow: "none",
            },
            padding: "10px 10px 100px 10px",
            position: "relative",
            boxShadow: " 4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
            borderRadius: "20px",
          }}
        >
          {/* {!sample && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "#F0FAB3",
                  padding: "50px 30px",
                  borderRadius: "20px",
                  "@media (max-width: 780px)": {
                    display: "grid",
                    gridTemplateColumns: "95% 5%",
                    padding: "30px",
                    justifyContent: "center",
                    alignContent: "center",
                    gridGap: "10px",
                    height: "100px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "10px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    background: "#D8E1a1",
                    mt: "5px",
                  }}
                >
                  <Box
                    sx={{
                      width: progress
                        ? Math.floor(
                            (progress.completed / progress.total) * 100
                          ).toString() + "%"
                        : "0%",
                      height: "100%",
                      background: "#000",
                      borderRadius: "10px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      color: "#000",
                      mt: "10px",
                    }}
                  >
                    {progress
                      ? Math.floor(
                          (progress.completed / progress.total) * 100
                        ).toString() + "%"
                      : "0%"}{" "}
                    Completed
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000",
                    fontWeight: 400,
                  }}
                >
                 
                </Typography>
              </Box>
            </Box>
          )} */}
          {!sample && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "#F0FAB3",
                  padding: "50px 30px",
                  borderRadius: "20px",
                  "@media (max-width: 780px)": {
                    display: "grid",
                    gridTemplateColumns: "95% 5%",
                    padding: "30px",
                    justifyContent: "center",
                    alignContent: "center",
                    gridGap: "10px",
                    height: "100px",
                  },
                }}
              >
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "10px",
                      borderRadius: "10px",
                      overflow: "hidden",
                      background: "#D8E1a1",

                      mt: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        width: progress
                          ? Math.floor(
                              (progress.completed / progress.total) * 100
                            ).toString() + "%"
                          : "0%",
                        height: "100%",
                        background: "#000",
                        borderRadius: "10px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Typography sx={{ fontWeight: 600, color: "#000" }}>
                    My Progress
                  </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        color: "#000",
                        mt: "10px",
                      }}
                    >
                      {progress
                        ? Math.floor(
                            (progress.completed / progress.total) * 100
                          ).toString() + "%"
                        : "0%"}{" "}
                      Completed
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    "@media (min-width: 780px)": {
                      display: "none",
                    },
                  }}
                >
                  {" "}
                  <Box
                    onClick={() => {
                      setOnpenModal(true);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <ExpandMoreIcon />
                  </Box>{" "}
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#000",
                    fontWeight: 400,
                  }}
                >
                  {/* {Number(progress.completed)}/{totalItems} */}
                </Typography>
              </Box>
            </Box>
          )}
          {/* <Box>
            {lessons && <CourseProgressAccordions lessons={lessons} />}

            {!sample &&
              quizzes &&
              quizzes.map((quizId, index) => (
                <FinalQuizzes key={index} quizId={quizId} />
              ))}
          </Box> */}

          <Box
            sx={{
              "@media (min-width: 780px)": {
                display: "none",
              },
            }}
          >
            {" "}
            <Dialog
              sx={{
                top: "30vh",
                bottom: "-10vh",
                "@media (min-width: 780px)": {
                  display: "none",
                },
                width: "100vw",
                position: "absolute",
              }}
              open={openModal}
              PaperProps={{
                style: {
                  borderRadius: "20px",
                  height: "400px",
                  padding: "16px",
                  margin: "0px",
                  width: "100vw",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "#F0FAB3",
                    padding: "50px 30px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateColumns: "95% 5%",
                    gridGap: "10px",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "10px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        background: "#D8E1a1",
                        mt: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          width: progress
                            ? Math.floor(
                                (progress.completed / progress.total) * 100
                              ).toString() + "%"
                            : "0%",
                          height: "100%",
                          background: "#000",
                          borderRadius: "10px",
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {/* <Typography sx={{ fontWeight: 600, color: "#000" }}>
                    My Progress
                  </Typography> */}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          color: "#000",
                          mt: "10px",
                        }}
                      >
                        {progress
                          ? Math.floor(
                              (progress.completed / progress.total) * 100
                            ).toString() + "%"
                          : "0%"}{" "}
                        Completed
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <button
                      onClick={() => {
                        setOnpenModal(false);
                      }}
                      style={{
                        width: "20px",
                        height: "20px",
                        // background: "#f4f7fe",
                        marginLeft: "auto",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    >
                      <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                          fill="#707EAE"
                        />
                      </svg>
                    </button>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#000",
                      fontWeight: 400,
                    }}
                  >
                    {/* {Number(progress.completed)}/{totalItems} */}
                  </Typography>
                </Box>
              </Box>
              <>
                {" "}
                {lessons && <CourseProgressAccordions lessons={lessons} />}
                {!sample &&
                  quizzes &&
                  quizzes.map((quizId, index) => (
                    <FinalQuizzes key={index} quizId={quizId} />
                  ))}{" "}
              </>
            </Dialog>{" "}
          </Box>
          <Box
            sx={{
              "@media (max-width: 780px)": {
                display: "none",
              },
            }}
          >
            {lessons && <CourseProgressAccordions lessons={lessons} />}

            {!sample &&
              quizzes &&
              quizzes.map((quizId, index) => (
                <FinalQuizzes key={index} quizId={quizId} />
              ))}

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,

                "@media (max-width: 780px)": {
                  display: "none",
                },
              }}
            >
              <Typography
                onClick={() => setShowTab(0)}
                sx={{
                  padding: "15px 20px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Hide
              </Typography>
            </Box>
          </Box>
          {/* <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <Typography
              onClick={() => setShowTab(0)}
              sx={{
                padding: "15px 20px",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#000",
                fontWeight: 600,
              }}
            >
              Hide
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default MicroContent;
