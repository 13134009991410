import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Input } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { Navigate, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import HrloadingScreen from "../../../../Hr/LodingHrscreen";
// import { functions } from "../../../../Firebase";

const EventTopic = (props) => {
  const { onClose, open, delfun, topicId, itemName } = props;
  const handleClose = () => {
    onClose();
  };

  const [lodingScreenPop, setlodingScreenPop] = useState(false);
  useEffect(() => {
    // console.log(itemName);
    if (itemName.eventId) {
      console.log("dvnkdnvkdnv");
      setWebinarsData(itemName);
      //   setWebinarsData((prev) => ({ ...prev, eventId: topicId }));
      setImage({ url: itemName.file });
    }
    if (topicId) {
      setWebinarsData({
        topic: "",
        description: "",
        filetype: "",
        file: "",
        link: "",

        topicId: "",
        eventId: topicId,
      });
    }
  }, [open]);
  const [image, setImage] = useState([]);
  const { clrs } = useSelector((state) => state.createClr);

  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [webinarsData, setWebinarsData] = useState({
    topic: "",
    description: "",
    filetype: "",
    file: "",
    link: "",
    eventId: "",
    topicId: "",
  });
  const [loading, setLoading] = useState(false);
  const [mathDotRendom, setMathDotRendom] = useState(topicId.id);
  const [inputId, setInputId] = useState("");

  let navigater = useNavigate();

  const updatetopic = () => {
    // get company and department data
    setlodingScreenPop(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "patch",

      url: `${IP}/event/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setlodingScreenPop(false);
        if (res.data?.error) {
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const runGenerateCertificateTraining = () => {
    // get company and department data
    setlodingScreenPop(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "post",

      url: `${IP}/event/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    console.log(webinarsData);

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        setlodingScreenPop(false);
        if (res.data?.error) {
        } else {
          handleClose();
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setlodingScreenPop(false);
        //console.log(err);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      //  setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      // setDetails({ ...details, logo: reader.result });
      setWebinarsData({ ...webinarsData, file: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="700px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "100vh",

          padding: "5px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <HrloadingScreen
        open={lodingScreenPop}
        onClose={() => {
          setlodingScreenPop(false);
        }}
      />
      <DialogTitle
        sx={{
          display: "flex",
          height: "10px",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <button
          onClick={() => {
            if (loading) return;
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <div className="inputandlabelboxforstrategies">
            <div className="inputlabelnameForstrategi"> Item Topic</div>
            <div>
              {" "}
              <input
                className="inputbox"
                type="text"
                value={webinarsData.topic}
                onChange={(e) => {
                  setWebinarsData((prev) => ({
                    ...prev,
                    topic: e.target.value,
                  }));
                }}
                placeholder="Item Topic"
                style={{
                  padding: "5px",
                  height: "35px",
                  width: "250px",
                }}
              />
            </div>
          </div>

          <div className="inputandlabelboxforstrategies">
            <div className="inputlabelnameForstrategi"> Description</div>
            <div>
              {" "}
              <TextField
                value={webinarsData.description}
                onChange={(e) => {
                  setWebinarsData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
                InputProps={{
                  sx: {
                    resize: "both",
                    overflow: "auto",
                  },
                }}
                multiline
                className="inputbox"
                sx={{ width: "250px" }}
                type="text"
                placeholder="   Webinar Description"
              />
            </div>
          </div>

          <div className="inputandlabelboxforstrategies">
            <div className="inputlabelnameForstrategi"> File Type</div>
            <div style={{ display: "flex", gap: "20px" }}>
              {" "}
              <div>
                {" "}
                <input
                  type="radio"
                  checked={webinarsData.filetype == "file"}
                  name="eventradio"
                  onChange={(e) => {
                    setWebinarsData((prev) => ({
                      ...prev,
                      filetype: "file",
                    }));
                  }}
                  className="inputbox"
                  placeholder="  From"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "30px",
                  }}
                />
                <label htmlFor=""> File</label>
              </div>
              <div>
                {" "}
                <input
                  value={"videolink"}
                  type="radio"
                  name="eventradio"
                  checked={webinarsData.filetype == "videolink"}
                  onChange={(e) => {
                    setWebinarsData((prev) => ({
                      ...prev,
                      filetype: "videolink",
                    }));
                  }}
                  className="inputbox"
                  placeholder="  From"
                  style={{
                    padding: "5px",
                    height: "25px",
                    width: "50px",
                  }}
                />
                <label htmlFor=""> Video Link</label>
              </div>
            </div>
          </div>
          {webinarsData.filetype == "file" ? (
            <div className="inputandlabelboxforstrategies">
              <div className="inputlabelnameForstrategi"> Section File</div>
              <div>
                {" "}
                <div
                  style={{
                    display: "flex",
                    gap: "50px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      startIcon={<FileUploadIcon />}
                    >
                      Upload File
                      <input
                        name="logo"
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Button>{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    {image.url && (
                      <div style={{ width: "300px", height: "150px" }}>
                        <iframe src={image?.url} frameborder="0"></iframe>
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="inputandlabelboxforstrategies">
              <div className="inputlabelnameForstrategi"> Video Link</div>
              <div>
                {" "}
                <input
                  className="inputbox"
                  type="text"
                  value={webinarsData.link}
                  onChange={(e) => {
                    setWebinarsData((prev) => ({
                      ...prev,
                      link: e.target.value,
                    }));
                  }}
                  placeholder="Video Link"
                  style={{
                    padding: "5px",
                    height: "35px",
                    width: "250px",
                  }}
                />
                {webinarsData.link && (
                  <div style={{ width: "300px", height: "150px" }}>
                    <iframe src={webinarsData.link} frameborder="0"></iframe>
                  </div>
                )}{" "}
              </div>
            </div>
          )}

          <div style={{ display: "flex", gap: "50px" }}>
            {" "}
            <Button
              //   onClick={createWebinar}
              style={{
                backgroundColor: "#007C84",
                marginTop: "24px",
                borderRadius: "5px",
                color: "#FFFFFF",
              }}
              onClick={
                itemName.eventId ? updatetopic : runGenerateCertificateTraining
              }
            >
              {" "}
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default EventTopic;
