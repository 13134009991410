import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFunctions } from "firebase/functions";

const prod = true;
let firebaseConfig;
if (prod) {
  firebaseConfig = {
    apiKey: "AIzaSyA-Fj1JSJ4tv5pXF65yfvKU415G0yM7y6c",

    authDomain: "serein-demo-lms-gcp.firebaseapp.com",

    projectId: "serein-demo-lms-gcp",

    storageBucket: "serein-demo-lms-gcp.appspot.com",

    messagingSenderId: "929871714167",

    appId: "1:929871714167:web:187788b37f60c1aa233372",

    measurementId: "G-P90CCQC65M",
  };
}

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
// const messaging = firebase.messaging();
export const functions = getFunctions(firebaseApp, "asia-south1");
export { auth, db };
