import React from "react";

function Profile({ clr }) {
  return (
    <svg
      width="auto"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3636 0.0666177C12.2496 0.0448584 12.1355 0.023099 12.0218 0.000339591L10.7757 0C10.6945 0.0145091 10.6131 0.0284851 10.5318 0.0424612C10.3567 0.0725373 10.1817 0.102614 10.0077 0.138007C8.50962 0.443001 7.29955 1.2254 6.29534 2.36084C4.65909 4.21082 4.23698 7.1839 5.28075 9.41562C6.39196 11.7918 8.83999 13.5426 11.8161 13.3413C14.0827 13.1882 15.7964 12.1086 17.035 10.2541C17.9674 8.85835 18.2076 7.29796 17.9898 5.64691C17.659 3.13877 15.5603 0.794275 13.1031 0.218415C12.8581 0.161013 12.6108 0.113816 12.3636 0.0666177ZM4.95446 27.7383H11.5624C13.702 27.7414 15.8414 27.7406 17.9806 27.736C18.3228 27.7352 18.6662 27.7177 19.0068 27.6853C21.3801 27.4595 23.0885 25.6295 23.1194 23.2443C23.1297 22.4144 23.0942 21.5845 23.013 20.7585C22.8581 19.1786 22.5098 17.6457 21.7327 16.2372C20.9462 14.8116 19.8052 13.8538 18.1854 13.5124C17.3518 13.3367 16.56 13.3416 15.8519 13.9151C15.6565 14.0733 15.4387 14.2044 15.2214 14.3352C15.1596 14.3724 15.0978 14.4095 15.0367 14.4473C12.7241 15.8729 10.4115 15.8743 8.09892 14.4515C7.94397 14.3562 7.79169 14.2561 7.63946 14.1561C7.3918 13.9934 7.14424 13.8307 6.88539 13.6884C6.63854 13.5527 6.35228 13.4169 6.08087 13.4111C5.20158 13.3893 4.33168 13.596 3.55616 14.011C2.28245 14.6868 1.50785 15.7951 0.99136 17.0836C0.130929 19.2307 -0.135594 21.4813 0.0617873 23.7753C0.172386 25.06 0.79961 26.0988 1.85986 26.8544C2.78571 27.5144 3.83877 27.7383 4.95446 27.7383Z"
        fill={clr}
      />
    </svg>
  );
}

export default Profile;
