import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

import "./pdfmodal.css"

import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Close } from "@material-ui/icons";

// import { ReactComponent as NextPage } from "../icons/pdfNextPage.svg";
// import { ReactComponent as PrevPage } from "../icons/pdfPrevPage.svg";
// import { ReactComponent as PageIcon } from "../icons/pdfPageIcon.svg";
// import { ZoomIn, ZoomOut } from "@material-ui/icons";



export default function PDFloaderModel({ open, pdfData, handleClose }) {
  // const [audioEnable, setAudioEnable] = useState(false);
  // const [completed, setCompleted] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomFactor, setZoomFactor] = useState(1.5);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const changePage = (goToPage) => {
    if (goToPage > numPages || goToPage <= 0) return;
    setPageNumber(goToPage);
  };

  // const zoom = (action) => {
  //   if (zoomFactor <= 0.25 && action == "out") return;
  //   if (zoomFactor >= 1.5 && action == "in") return;
  //   if (action === "in") {
  //     setZoomFactor((prev) => prev + 0.25);
  //     //console.log(zoomFactor);
  //   } else if (action === "out") {
  //     setZoomFactor((prev) => prev - 0.25);
  //     //console.log(zoomFactor);
  //   }
  // };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box disabled className="pdfLoaderModal">
        <div className="courseItem-pdfContainer">
          <div className="courseItem-pdfTopbar">
            <div style={{ marginRight: "auto", display: "flex" }}>
              {/* <PrevPage
                height={"1em"}
                style={{ marginRight: "20px" }}
                onClick={() => changePage(pageNumber - 1)}
              />
              <PageIcon height={"1em"} style={{ marginRight: "10px" }} />
              <p className="courseItem-pdfTopbarText">
                {pageNumber}/{numPages}
              </p>
              <NextPage
                height={"1em"}
                onClick={() => changePage(pageNumber + 1)}
              /> */}
            </div>
            <div className="courseItem-pdfTopbar-zoomInfo">
              {/* <ZoomOut onClick={() => zoom("out")} />
              <ZoomIn
                onClick={() => zoom("in")}
                style={{ marginRight: "10px" }}
              /> */}
            </div>

            <div>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <div
            className="courseItem-mainpdf"
            style={{
              margin: "auto",
            }}
          >
            <Document
              className="courseItem-document"
              file={pdfData}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {/* <Page
                scale={zoomFactor}
                className={"courseitem-pdfPage"}
                // pageNumber={pageNumber}
              /> */}

              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page
                    pageNumber={page}
                    scale={zoomFactor}
                  />
                ))}
            </Document>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
