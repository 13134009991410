import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import OTPInput from "react-otp-input";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from "../../../baseUrlAndConfig";

import loginLogo from "../loginpage/login-logo.png";

const CommonLink = () => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(null);
  const [isOTPCorrect, setIsOTPCorrect] = useState(true);
  const [errorCode, setErrorCode] = useState(0);

  const handleSubmit = () => {
    const urlString = window.location.href;
    let paramString = urlString.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    const paramObj = {};
    for (let pair of queryString.entries()) {
      paramObj[pair[0]] = pair[1];
    }

    const dummyCompanyId = paramObj["coid"];

    var config = {
      method: "get",

      url: `${IP}/commonLink?emailId=${email}&coid=${dummyCompanyId}`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${userCredential.idToken}`,
      },
      //   data: data,
    };
    axios(config)
      .then((res) => {
        console.log(res.status);
        let resObj = res.status;
        if (res.status == 200) {
          setErrorMessage("Please Enter valid Email Id");
        }

        res.status == 201 ? setIsEmailValid(true) : setIsEmailValid(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setErrorCode(409);
          setErrorMessage("Invitation Already Accepted");
        }

        setLoading(false);
        setIsEmailValid(false);

        // console.log(err);
        // if (err.response.data.error === "USER_DISABLED") {
        //   return setNotification("This user archived Please contact to serien");
        // }
        // if (
        //   err.response.data.error == "INVALID_PASSWORD" ||
        //   err.response.data.error == "EMAIL_NOT_FOUND"
        // )
        //   return setNotification("Enter valid Credentials");
      });
  };

  const handleOTP = () => {
    const urlString = window.location.href;
    let paramString = urlString.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    const paramObj = {};
    for (let pair of queryString.entries()) {
      paramObj[pair[0]] = pair[1];
    }
    const dummyCompanyId = paramObj["coid"];

    console.log(email, otp);
    var config = {
      method: "get",

      url: `${IP}/commonLink/otpVerify?coid=${dummyCompanyId}&emailId=${email}&OTP=${otp}`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${userCredential.idToken}`,
      },
      //   data: data,
    };
    axios(config)
      .then((res) => {
        let userId = res.data;
        console.log(userId);
        if (res.status == 401) {
          setIsOTPCorrect(false);
          console.log("87");
        } else if (res.status != 401) {
          setIsOTPCorrect(true);
          navigate(`/set-password?mode=resetPassword&email=${userId}`, {
            replace: "true",
          });
          // window.location = `/set-password?mode=resetPassword&email=${userId}`;

          console.log("92");
        }
        console.log(isOTPCorrect);
        if (isOTPCorrect) {
          navigate(`/set-password?mode=resetPassword&email=${userId}`, {
            replace: "true",
          });
          // window.location = `/set-password?mode=resetPassword&email=${userId}`;
          // window.location = `${IP}/auth/setPassword`;
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsOTPCorrect(false);

        // console.log(err);
        // if (err.response.data.error === "USER_DISABLED") {
        //   return setNotification("This user archived Please contact to serien");
        // }
        // if (
        //   err.response.data.error == "INVALID_PASSWORD" ||
        //   err.response.data.error == "EMAIL_NOT_FOUND"
        // )
        //   return setNotification("Enter valid Credentials");
      });
  };

  const [time, setTime] = useState(60);
  useEffect(() => {
    let intervalId; // Declare intervalId here

    if (time === 0) {
      // Timer has reached 0, you can handle what happens here
      clearInterval(intervalId);
    } else {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval on unmount
    };
  }, [time]);
  return (
    <Box style={{ backgroundColor: "F3F1FF" }}>
      <Box sx={{ position: "absolute", top: "30px", left: "60px" }}>
        <img
          // className={classes.logo}
          src={loginLogo}
          alt=""
          style={{
            background: "none",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "29.75rem",
          // height: "22.9375rem",
          paddingBottom: "2rem",
          backgroundColor: "#EDEAFF",
          borderRadius: "1.25rem",
          border: "2px solid #6846C7",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "12rem",
          gap: "5rem",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              // color: (theme) => theme.palette.primary.main,
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "1.25rem",
              color: "#6846C7",
            }}
          >
            Welcome
          </Typography>
          <Typography
            sx={{
              // color: (theme) => theme.palette.primary.main,
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "0.875rem",
              color: "#6846C7",
              lineHeight: "1.375rem",
              color: "#000",
              opacity: "0.6",
            }}
          >
            Please Enter Your Email Address
          </Typography>
          <Box sx={{ marginBottom: "2rem" }}>
            <input
              type="email"
              placeholder="E-mail"
              disabled={isEmailValid ? true : false}
              style={{
                width: "100%",
                backgroundColor: `${
                  isEmailValid === null ? "#FFF" : "rgba(149, 165, 166,0.3)"
                }`,
                // backgroundColor:  "#FFF",
                fontFamily: "Poppins",
                height: "3rem",
                borderRadius: "0.5rem",
                paddingLeft: "0.5rem",
                border: "none",
                outline: "none",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>

          {isEmailValid === false && (
            <Typography
              sx={{
                // color: (theme) => theme.palette.primary.main,
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "0.875rem",
                color: "#6846C7",
                lineHeight: "1.375rem",
                color: "#D90202",
                textAlign: "center",
              }}
            >
              {errorMessage}
              {/* Please Enter Valid Email id */}
            </Typography>
          )}

          {isEmailValid && (
            <>
              <Typography
                sx={{
                  // color: (theme) => theme.palette.primary.main,
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#6846C7",
                  lineHeight: "1.375rem",
                  color: "#000",
                  opacity: "0.6",
                }}
              >
                Enter your OTP
              </Typography>
              <Container>
                <Box style={{ marginLeft: "1.25rem" }}>
                  <OTPInput
                    value={otp}
                    onChange={setOTP}
                    numInputs={6}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                          outline: "none",
                          paddingLeft: "20px",
                          border: "none",
                          outline: "none",

                          // paddingRight: "20px",
                        }}
                      />
                    )}
                  />
                </Box>
              </Container>

              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  {time != 0 && (
                    <>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "0.875rem",
                          color: "#6846C7",
                          lineHeight: "1.375rem",
                          color: "#000",
                          opacity: "0.6",
                        }}
                      >
                        Resend OTP again in :&nbsp;
                      </span>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "0.875rem",
                          color: "#6846C7",
                          lineHeight: "1.375rem",
                          color: "#6846C7",
                          opacity: "1",
                        }}
                      >
                        {time}s
                      </span>
                    </>
                  )}
                  {/* <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "0.875rem",
                      color: "#6846C7",
                      lineHeight: "1.375rem",
                      color: "#000",
                      opacity: "0.6",
                    }}
                  >
                    {time === 0 ? "" : `Resend OTP again in : `}
                    <span>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "0.875rem",
                          color: "#6846C7",
                          lineHeight: "1.375rem",
                          color: "#6846C7",
                          opacity: "1",
                        }}
                      >
                        {time == 0 ? "" : time}
                      </Typography>
                    </span>
                  </Typography> */}
                </Box>
                {time == 0 && (
                  <Typography
                    sx={{
                      // color: (theme) => theme.palette.primary.main,
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "0.875rem",
                      color: "#6846C7",
                      lineHeight: "1.375rem",
                      color: "#6846C7",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      setTime(60);
                      setIsOTPCorrect(true);
                      setOTP("");
                      handleSubmit();
                    }}
                  >
                    Resend
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Box style={{ marginTop: "" }}>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#6846C7",
                height: "3rem",
                "&:hover": {
                  background: "#6846C7",
                },
              }}
              // color="secondary"
              onClick={() => {
                if (errorCode == 409) {
                  navigate("/", { replace: true });
                  console.log("redirecting");
                  //redirect to login page..
                } else if (!isEmailValid) {
                  handleSubmit();
                } else if (isEmailValid) {
                  handleOTP();
                }
              }}
            >
              {loading && "Loading ..."}
              {errorCode == 409
                ? "Login"
                : isEmailValid === null || isEmailValid === false
                ? "Get OTP"
                : "Submit"}
              {/* {console.log(errorCode)} */}
              {/* {errorCode == 409 && "Login"}
              {isEmailValid === null || isEmailValid === false && "Get OTP"}
              {isEmailValid && "Submit"}
              {errorCode != 409 &&
              (isEmailValid === null || isEmailValid === false)
                ? "Get OTP"
                : "Submit"} */}
            </Button>
          </Box>
          {!isOTPCorrect && (
            <Typography
              sx={{
                // color: (theme) => theme.palette.primary.main,
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "0.875rem",
                color: "#6846C7",
                lineHeight: "1.375rem",
                color: "#D90202",
                textAlign: "center",
              }}
            >
              Invalid OTP
            </Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default CommonLink;
