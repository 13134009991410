import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Input, Select, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";
import "./sendinviteforpsy.css";

import axios from "axios";
import { getCookie } from "../../Cookies";
import { IP } from "../../baseUrlAndConfig";
import { Timestamp } from "firebase/firestore";
import HrloadingScreen from "../LodingHrscreen";
import SendRemainderPopUp from "../report/confirmationPopUp";
const SendInviteForPsyBsy = (props) => {
  const { onClose, open, data } = props;
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [eldata, seteldata] = useState({});
  const [checking, setChecking] = useState("");
  const [link, setlink] = useState("");
  const [userList, setUserList] = useState([]);
  const [extraArrForUser, setExtraArrForUser] = useState([]);
  const [valueCopy, setValueCopy] = useState(false);
  const [lodingscreen, setlodingscreen] = useState(false);
  const [selectAllEmployee, setSelectAllEmployee] = useState(false);
  const [reportClouse, setReportClouse] = useState(false);
  const [sendInviteFlag, setSendInviteFlag] = useState(false);
  const [userdata, setUserData] = useState({
    firstname: "",
    surname: "",
    companymail: "",
    role: "",
    email: "",
    phone: "",
    date: new Date(),
  });

  const checkfun = () => {
    if (userdata.firstname == "") {
      return "enter first name";
    } else if (userdata.surname == "") {
      return "enter surname";
    } else if (userdata.companymail == "") {
      return "enter companymail";
    } else if (userdata.role == "") {
      return "enter role";
    } else if (userdata.email == "") {
      return "enter your email";
    } else if (userdata.phone == "") {
      return "enter your phone Number";
    }
    return "test";
  };
  async function Ragisteruser() {
    const datacheck = checkfun();
    if (datacheck != "test") {
      setChecking(datacheck);
    }
    let datauser = JSON.stringify({ ...userdata, id: data.id });
    let configrecrut = {
      method: "post",

      url: `${IP}/webinar/register`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datauser,
    };

    axios(configrecrut).then((res) => {});
  }

  async function getAllUsers() {
    setlodingscreen(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/inclusionStudy/allUser`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "datauser",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingscreen(false);
        setUserList(res.data.map((el) => ({ ...el, select: false })));
        setExtraArrForUser(res.data.map((el) => ({ ...el, select: false })));
        console.log(res.data);
        //   setWebdata(res.data);
        //   setCourses(res.data.filter((e) => e.archived != true));
        //   setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
        //   setLoading(false);
      })
      .catch((e) => {
        setlodingscreen(false);
      });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }

  async function getFormSendDetails() {
    setlodingscreen(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/inclusionStudy/survey`,
      params: { survey: data },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "datauser",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingscreen(false);

        setSendInviteFlag(res.data?.sendFromLink);
        setlink(res.data?.sharebleLink);
        console.log(res.data);
      })
      .catch((e) => {
        setlodingscreen(false);
      });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }

  async function sendInviteForm() {
    let temparr = userList.filter((el) => el.select == true);

    if (temparr.length == 0) {
      alert("select user");
      return;
    }
    setlodingscreen(true);

    const datain = JSON.stringify({
      survey: data,
      form_link: link,
      users: temparr,
    });
    let configrecrut = {
      method: "post",

      url: `${IP}/inclusionStudy/survey`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datain,
    };

    axios(configrecrut)
      .then((res) => {
        setlodingscreen(false);

        getAllUsers();
        getFormSendDetails();
        alert("sent");
      })
      .catch((e) => {
        setlodingscreen(false);
      });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }
  useEffect(() => {
    // fetchCourses();
    if (open) {
      getAllUsers();
      getFormSendDetails();
    }

    // seteldata(data);
  }, [open]);

  const filterFunction = (name) => {
    let temparr = extraArrForUser.filter(
      (el) => el?.name.includes(name) || el?.email.includes(name)
    );
    setUserList(temparr);
  };

  const hendelChecked = (id, type) => {
    let newTempData = [];
    //console.log(type)
    if (type === "All") {
      console.log("jkvjkdsfkjs");
      setSelectAllEmployee(!selectAllEmployee);
      userList.map((e) => {
        //console.log(e.checked)
        e.select = !selectAllEmployee;
        //console.log(e.checked)
        newTempData.push(e);
      });
    } else if (type == "one") {
      userList.map((e, index) => {
        //console.log(id)
        if (index === id) {
          //console.log(e.checked)
          e.select = e.select ? false : true;
          //console.log(e.checked)
          newTempData.push(e);
        } else {
          newTempData.push(e);
        }
      });
    }
    //console.log(newTempData)
    setUserList(newTempData);
  };

  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  let navigater = useNavigate();

  const sendremainder = () => {
    let temparr = userList.filter((el) => el.select == true);

    if (temparr.length == 0) {
      alert("select user");
      return;
    }

    setReportClouse(true);
  };

  const sendRemainderForUser = () => {
    let temparr = userList.filter((el) => el.select == true);

    setlodingscreen(true);

    const datain = JSON.stringify({
      survey: data,
      form_link: link,
      users: temparr,
    });
    let configrecrut = {
      method: "patch",

      url: `${IP}/inclusionStudy/survey`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datain,
    };

    axios(configrecrut)
      .then((res) => {
        setlodingscreen(false);

        console.log(res.data);
      })
      .catch((e) => {
        setlodingscreen(false);
      });
  };
  const delfunction = (type) => {
    setReportClouse(false);
    if (type == "Yes") {
      if (sendInviteFlag) {
        sendRemainderForUser();
      } else {
        sendInviteForm();
      }
    }
  };
  return (
    <Dialog
      fullWidth={false}
      maxWidth="725px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "725px",
          height: "750px",
          background: "#F3F1FF",
          padding: "5px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <HrloadingScreen open={lodingscreen} />

      <SendRemainderPopUp
        open={reportClouse}
        onClose={() => setReportClouse(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        calledFrom={"form"}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <Box>
          {" "}
          {data == 0
            ? "Psychological safety survey : participant list"
            : "Bystander intervention survey : Participant list"}
        </Box>
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          style={{
            width: "20px",
            height: "20px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </Button>
      </DialogTitle>
      <Box
        sx={{
          padding: "0px 24px 40px 24px",
          "@media (max-width: 780px)": {
            padding: "0px 12px 20px 12px",
          },
        }}
      >
        <Box
          sx={{
            height: "50px",

            borderRadius: "24px",
            mt: "10px",
            paddingBottom: "10px",
          }}
        >
          {link && (
            <Box
              sx={{
                color: "#666666",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",

                display: "flex",
                alignContent: "center",
                alignItems: "center",
                gap: "30px",

                //   border: "1px solid red",
              }}
            >
              <Box>
                Form link :{" "}
                <a href={link} target="_blank">
                  {" "}
                  Form link
                </a>{" "}
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  setValueCopy(true);
                  setTimeout(() => {
                    setValueCopy(false);
                  }, 5000);
                }}
              >
                {" "}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/serein-demo-lms-gcp.appspot.com/o/Black(1).svg?alt=media&token=44b2fd56-31e6-43a6-a93b-708ebb2ce054"
                  alt=""
                />{" "}
              </Box>

              {valueCopy && <Box> value copied</Box>}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "40px",
            justifyContent: "space-between",
            alignContent: "center",
            mt: "25px",
          }}
        >
          <Box>
            {" "}
            <Box
              // className="webinarpopupinput"
              style={{
                background: "#FFF",
                fontWeight: "600",
                height: "40px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
              }}
            >
              {" "}
              <input
                // className="webinarpopupinput"
                style={{
                  // width: "100%",
                  // marginLeft: "16px",
                  background: "none",
                  outline: "none",

                  padding: "12px",
                  border: "none",
                }}
                value={userdata.firstname}
                placeholder="Search for employee"
                onChange={(e) => {
                  //   console.log(event.target);
                  filterFunction(e.target.value);
                  setUserData((prev) => ({
                    ...prev,
                    firstname: e.target.value,
                  }));
                }}
              />{" "}
            </Box>
          </Box>

          <Box
            sx={{
              "@media (max-width: 780px)": {
                display: "none",

                // padding: "10px",
              },
            }}
          >
            {" "}
            <Box>
              {sendInviteFlag ? (
                <Button
                  onClick={() => {
                    if (link) {
                      sendremainder();
                    } else {
                      return;
                    }
                  }}
                  sx={{
                    color: "#FFFFFF",
                    border: "1px solid #6846C7",
                    background: "#6846C7",
                    cursor: link ? "pointer" : "not-allowed",

                    width: "152px",
                    height: "40px",
                    "&:hover": {
                      background: "#6846C7",
                    },
                  }}
                >
                  Send reminder
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (link) {
                      // alert("fdxg");
                      sendremainder();
                    } else {
                      return;
                    }
                  }}
                  sx={{
                    color: "#FFFFFF",
                    border: "1px solid #6846C7",
                    background: "#6846C7",
                    cursor: link ? "pointer" : "not-allowed",

                    width: "152px",
                    height: "40px",
                    "&:hover": {
                      background: "#6846C7",
                    },
                  }}
                >
                  Send Invite
                </Button>
              )}
            </Box>{" "}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "400px",
            mt: "11px",

            borderRadius: "10px",
            overflow: "scroll",
          }}
        >
          {" "}
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "1fr 6fr 4fr",
              "@media (max-width: 780px)": {
                gridTemplateColumns: "1fr 3fr 2fr",
                padding: "0px",
              },
              position: "sticky",
              background: "#A18CD8",
              top: 0,
              zIndex: 2,
              width: "100%",
              padding: "6px",
              minHeight: "50px",
              borderBottom: "1px solid #E7E6E7",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <input
                type="checkbox"
                style={{
                  border: "1px solid var(--black, #000)",
                  height: "15px",
                  width: "15px",
                  marginLeft: "16px",
                }}
                onClick={() => {
                  hendelChecked("tets", "All");
                }}
                checked={selectAllEmployee}
              />{" "}
            </Box>
            <Box
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
              }}
            >
              {" "}
              Name of employee
            </Box>

            <Box
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                color: "#000",
              }}
            >
              {" "}
              Email
            </Box>
          </Box>
          {userList?.map((el, index) => (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 6fr 4fr",
                minHeight: "44px",
                background: "#FFFFFF",
                borderBottom: "1px solid #E7E6E7",
                padding: "6px",
                "@media (max-width: 780px)": {
                  gridTemplateColumns: "1fr 3fr 2fr",
                  padding: "0px",
                  fontSize: "12px",
                },
                // padding: "0px 20px 20px 20px",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                {" "}
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid var(--black, #000)",
                    height: "15px",
                    width: "15px",
                    marginLeft: "16px",
                  }}
                  onClick={() => {
                    hendelChecked(index, "one");
                  }}
                  checked={el.select}
                />{" "}
              </Box>
              <Box
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#666",
                  whiteSpace: "initial",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    "@media (max-width: 780px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {el?.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#666",
                  whiteSpace: "initial",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    "@media (max-width: 780px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {el?.email}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            "@media (min-width: 780px)": {
              display: "none",

              // padding: "10px",
            },
          }}
        >
          {" "}
          <Box>
            {sendInviteFlag ? (
              <Button
                onClick={() => {
                  if (link) {
                    sendremainder();
                  } else {
                    return;
                  }
                }}
                sx={{
                  color: "#FFFFFF",
                  border: "1px solid #6846C7",
                  background: "#6846C7",
                  cursor: link ? "pointer" : "not-allowed",

                  width: "152px",
                  height: "40px",
                  "&:hover": {
                    background: "#6846C7",
                  },
                }}
              >
                Send reminder
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (link) {
                    // alert("fdxg");
                    sendremainder();
                  } else {
                    return;
                  }
                }}
                sx={{
                  color: "#FFFFFF",
                  border: "1px solid #6846C7",
                  background: "#6846C7",
                  cursor: link ? "pointer" : "not-allowed",

                  width: "152px",
                  height: "40px",
                  "&:hover": {
                    background: "#6846C7",
                  },
                }}
              >
                Send Invite
              </Button>
            )}
          </Box>{" "}
        </Box>
      </Box>
    </Dialog>
  );
};

export default SendInviteForPsyBsy;
