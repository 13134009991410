import jsPDF from "jspdf";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { useState } from "react";

export async function exportMultipleChartsToPdf() {

  const doc = new jsPDF("p", "px"); // (1)  

  const elements = document.getElementsByClassName("custom-chart"); // (2)

  await creatPdf({ doc, elements}); // (3-5)

  doc.save(`charts.pdf`); // (6)
}

async function creatPdf({ doc, elements }) {

  let top = 0
  const padding = 0

  let avoidIst=false;

  for (let i = 0; i < elements.length; i++) {
    
    const el = elements.item(i)
    const imgData = await htmlToImage.toPng(el);

    let elHeight = el.offsetHeight
    let elWidth = el.offsetWidth

    const pageWidth = doc.internal.pageSize.getWidth()

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth
      elHeight = elHeight * ratio - padding
      elWidth = elWidth * ratio - padding
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      if(avoidIst){
        doc.addPage()
      }else{
        avoidIst=true;
      }
      top = 0
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight,`image${i}`)
    top += elHeight
  }

}
