import React from "react";
import { useSelector } from "react-redux";

function Notify({ NotifyClick }) {
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <div className="admin-remind-notify">
      <div className="admin-remind-notify-main">
        <div className="admin-remind-notify-main-head">
          Notification Reminder
        </div>
        <div className="admin-remind-notify-main-txt">
          <div>Email ID</div>
          <div>
            <input type="text" />
          </div>
        </div>
        <div className="admin-remind-notify-main-txt">
          <div>Notification Title</div>
          <div>
            <input type="text" />
          </div>
        </div>
        <div className="admin-remind-notify-main-txt">
          <div>Notification Content</div>
          <div>
            <input type="text" style={{ height: "4vw" }} />
          </div>
        </div>
        <div
          className="admin-remind-notify-main-txt"
          style={{ margin: "1vw 0 2vw 1vw" }}
        >
          <div>Notification Type</div>
          <div>
            <input type="text" />
          </div>
        </div>
        <div className="admin-dashboard-main-mid-item-last-filter-notify">
          <div className="admin-dashboard-main-top-last-filter-notify">
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
            >
              <div>Save & Send</div>
            </button>
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              onClick={NotifyClick}
            >
              <div>Cancel</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notify;
