import React from "react";
import OverallRepresentation from "../OverallRepresentation";
import HomePageHr from "../../homeHr";
import OverallRepresentationPrint from "./OverallRepresentationPrint";

import RecruitmentPrint from "./RecruitmentPrint";

import RetentionPrint from "./RetentionPrint";
import PoliciesPrint from "./PoliciesPrint";
import ProcessandToolPrint from "./ProcessandToolPrint";
import InfrastructurePrint from "./InfrastructurePrint";

const DiversityMetricPrint = () => {
  return (
    <div className="scroll" style={{ backgroundColor: "#EEECFF" }}>
      <OverallRepresentationPrint />
      <RecruitmentPrint />
      <RetentionPrint />
      <PoliciesPrint />
      <ProcessandToolPrint />
      <InfrastructurePrint />
    </div>
  );
};

export default DiversityMetricPrint;
