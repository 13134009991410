import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import { functions } from "../../../Firebase";
import "./CertificateDetails.css";
import { setDefaultNamespace } from "i18next";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { getCookie } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";
//import axios from 'axios';

function Certifictedetails() {
  const location = useLocation();
  const { clrs } = useSelector((state) => state.createClr);

  const data = location.state;
  const [templateName, setTemplateName] = useState(data.templateName);

  console.log(data);
  const fetchCertificateDetails = httpsCallable(
    functions,
    "fetchCertificateDetails"
  );
  const editCertificate = httpsCallable(functions, "editCertificate");
  const previewCertificate = httpsCallable(functions, "previewCertificate");

  const [defname, setdefname] = useState("");
  const [defcourse, setdefcourse] = useState("");
  const [defdate, setdefdate] = useState("");
  const [url, seturl] = useState(data.templateUrl);
  const [defemployee, setdefemployee] = useState("");

  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const [base64Image, setBase64Image] = useState("");

  const [username, setusername] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [usercourse, setusercourse] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [date, setdate] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [employee, setEmployee] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [sign, setsign] = useState({
    y: 0,
    x: 0,
    width: 0,
    aspectRatio: true,
  });

  let name, value;
  const getuserdata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "fontsize" || name == "x") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    //console.log(value);
    setusername({ ...username, [name]: value });
  };

  const getcoursedata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "fontsize" || name == "x") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setusercourse({ ...usercourse, [name]: value });
  };

  const getdatedata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setdate({ ...date, [name]: value });
  };
  const getEmployeeData = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setEmployee({ ...employee, [name]: value });
  };

  const getsigndata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "width") {
      value = +e.target.value;
    } else {
      value = e.target.value;
    }
    setsign({ ...sign, [name]: value });
  };

  const handleFileChange = (e) => {
    console.log(e);

    let filenameType = e.target.value.split(".");

    console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "png") {
    } else {
      alert("only png  file accepted");
      e.target.value = null;

      return;
    }

    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      ////console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const previewcertificate = () => {
    //  //console.log(data.templateUrl,username,usercourse,date,sign,defname,defcourse,defdate,base64Image,defemployee,employee)
    // previewCertificate({
    //   tempUrl: data.templateUrl,
    //   tempUser: username,
    //   tempCourse: usercourse,
    //   tempDate: date,
    //   tempSign: sign,
    //   userName: defname,
    //   courseName: defcourse,
    //   completeDate: defdate,
    //   base64Image: base64Image,
    //   enrollmentNo: defemployee,
    //   tempEnroll: employee,
    // }).then((res) => {
    //   //console.log("url :  ", res.data);
    // });

    let datat = JSON.stringify({
      tempUrl: data.templateUrl,
      tempUser: username,
      tempCourse: usercourse,
      tempDate: date,

      userName: defname,
      courseName: defcourse,
      completeDate: defdate,
    });
    var config = {
      method: "patch",

      url: `${IP}/certificate/trainingCertificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datat,
    };
    //console.log(arrdata);

    axios(config)
      .then((res) => {
        //console.log("url :  ", res);
        // const url = res.data;
        // seturl("hfjsdjh");
        // seturl(url);

        const url = res.data;
        seturl("hfjsdjh");
        seturl(url);
      })
      .catch((err) => {
        //console.log(err);
      });
    // previewCertificate()
  };

  const savechanges = () => {
    // editCertificate({
    //   tempuid: data.id,
    //   user: username,
    //   course: usercourse,
    //   date: date,
    //   sign: sign,
    //   tempEnroll: employee,
    // }).then((res) => {
    //   //console.log(res);
    //   alert(res.data.msg);
    // });

    let data = JSON.stringify({
      // tempurl: url,
      tempuid: location.state.id,
      templateName: templateName,
      user: username,
      course: usercourse,
      date: date,
      // base64Image: base64Image,
      // enrollmentNo: defemployee,
      // tempEnroll: employee,
    });
    var config = {
      method: "patch",

      url: `${IP}/certificate/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    //console.log(arrdata);

    axios(config).then((res) => {
      //console.log(res);
      alert(res.data.msg);
    });
  };

  const fetchdata = () => {
    var config = {
      method: "get",

      // params: { tempuid: data.id },
      url: `${IP}/certificate/trainingCertificate?tempuid=${data.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: data,
    };
    //console.log(arrdata);

    axios(config)
      .then((res) => {
        console.log("res", res.data);
        setusername(res.data.data.username);
        setusercourse(res.data.data.course);
        setdate(res.data.data.completeDate);
        // setEmployee(res.data.data.employeeNo);
        // setsign(res.data.data.signImage);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company and Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-details-container">
              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {data.templateName}
                </h1>
              </div>

              <div className="user-data-bigger-container">Enter Users Data</div>

              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      User__Name__ :
                    </spam>
                    <input
                      type="text"
                      name="username"
                      placeholder=""
                      className="user-data-input"
                      value={defname}
                      onChange={(e) => {
                        setdefname(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Date_Of_Completion :
                    </spam>
                    <input
                      type="date"
                      name="completiondate"
                      placeholder=""
                      className="user-data-input"
                      value={defdate}
                      onChange={(e) => {
                        setdefdate(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Course_Name :
                    </spam>
                    <input
                      type="text"
                      name="coursename"
                      placeholder=""
                      className="user-data-input"
                      value={defcourse}
                      onChange={(e) => {
                        setdefcourse(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="" style={{ marginTop: "38px" }}>
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  Settings
                </h1>
              </div>
              <div className="user-data-bigger-container">
                Edit Template Name
              </div>
              <input
                type="text"
                style={{ margin: "10px 20px", width: "200px" }}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />

              <div className="user-data-bigger-container">Username</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.y}
                      onChange={getuserdata}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.x}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={username.color}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={username.font}
                      onChange={getuserdata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.fontsize}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getuserdata}
                    >
                      <option selected={username.bold == false} value={false}>
                        False
                      </option>
                      <option selected={username.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Usercourse</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.y}
                      onChange={getcoursedata}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.x}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.color}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.font}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.fontsize}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getcoursedata}
                    >
                      <option selected={usercourse.bold == false} value={false}>
                        False
                      </option>
                      <option selected={usercourse.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Date</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.y}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.x}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={date.color}
                      onChange={getdatedata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={date.font}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.fontsize}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getdatedata}
                    >
                      <option selected={date.bold == false} value={false}>
                        False
                      </option>
                      <option selected={date.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="preview-container">
                <div className="" style={{ margin: "5px 10px" }}>
                  {/* <a href={data.templateUrl} target="_blank" rel="noopener noreferrer"> */}
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={previewcertificate}
                  >
                    Preview
                  </Button>
                  {/* </a> */}
                </div>
              </div>
              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    marginBottom: "20px",
                  }}
                >
                  preview Certificate
                </h1>
              </div>
              <div className="">
                <embed type="" src={url} width="100%" height="500" />
              </div>

              <div className="preview-container">
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={savechanges}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Certifictedetails;
