const ArrInfura = [
  {
    heading: "Gender neutral restroom facilities",
  },
  {
    heading:
      "Daycare facilities at the workplace/nearby OR Allowance to purchase daycare facilities",
  },
  {
    heading: "Menstrual products in the female restrooms",
  },
  {
    heading: "Menstrual products in the male and gender neutral rest rooms",
  },

  {
    heading: "Lactation room",
  },
  {
    heading:
      "Transportation for women and othe gender minories when they are travelling alone between 9pm to 9am",
  },
  {
    heading: "Special parking facilities for pregnant women",
  },
];

const PoliciesArr = [
  {
    heading: "Anti sexual harassment policy protecting women",
    description:
      "A legally mandated policy that helps prevent sexual harassment against women at the workplace, and also offers them protection and redressal.",
  },
  {
    heading:
      " 2. Anti sexual harassment policy protecting people of all genders",
    description:
      "A gender-neutral policy that helps prevent sexual harassment against employees of all genders at the workplace. It also offers them protection and redressal.    .",
  },
  {
    heading: "  3. Diversity, Equity and Inclusion charter/policy",
    description:
      "This policy outlines the approaches required to promote and foster an equitable, discrimination-free, diverse and harassment-free work environment.    ",
  },

  {
    heading: " 4. Equal employment opportunity policy",
    description:
      "This policy outlines the approaches required to promote and foster an equitable, discrimination-free, diverse and harassment-free work environment.    ",
  },
  {
    heading: "  5. Maternity leave policy",
    description:
      "Leave policy that outlines the duration of paid leave available for first and second-time mothers.      ",
  },

  {
    heading: "  6. Transgender parental policy",
    description:
      "Leave policy that outlines the duration of paid leave available for first and second-time mothers.      ",
  },
  {
    heading: "  7. Paternity leave policy",
    description:
      "Outlines the duration of paid leave available for employees who are first and second-time fathers.",
  },
];
export { ArrInfura, PoliciesArr };
