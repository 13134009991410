import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import {
  responsiveReverseFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import loginImg from "../../../utilities/images/serin.jpg";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../loginpage/login-logo.png";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";

function ForgotPassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [linkSent, setLinkSet] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  ///========visibility password=======//

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
    if (getCookie("ROLE") === "instructor") {
      goToInstructorDashboard();
      return;
    }
    if (getCookie("ROLE") === "user") {
      goToUserDashboard();
      return;
    }
  }, []);

  const handleForgot = () => {
    clearErrors();
    if (email == "") return setNotification("Please enter your email");
    setLoading(true);
    // const auth = getAuth();

    var data = JSON.stringify({
      email: email.trim(),
    });

    var config = {
      method: "post",

      url: `${IP}/auth/forgotPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((userCredential) => {
        // alert("Reset Link Sent");
        setLoading(false);
        setNotification("");
        setLinkSet(true);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err.response.data.error);
        setNotification(err.response.data.error.toString());
        setLinkSet(false);

        // if (err.code == "auth/user-not-found")
        //   return setNotification("Email not found");
        // else if (err.code == "auth/too-many-requests")
        //   return setNotification("Too many requests. Please try again later");
        // switch (err.code) {
        //   case "auth/invalid-username":
        //   case "auth/user-disabled":
        //   case "auth/user-not-found":
        //     setUserNameError(err.message);
        //     break;
        //   case "auth/wrong-password":
        //     setPasswordError(err.message);
        //     break;
        // }
      });
  };

  return (
    <Box
      sx={{
        py: { md: "0", height: "100vh", background: "#F5F5F5" },
      }}
    >
      <Container
        sx={{
          // ...responsiveReverseFlexDirection,

          gap: "50px",

          // display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            // className={classes.logo}
            src={loginLogo}
            alt=""
            style={{
              marginTop: "60px",

              background: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "50px",
          }}
        >
          <Box
            sx={{
              // ...responsiveWidth,

              border: "2px solid #6846C7",
              borderRadius: "20px",
              width: "476px",
              background: "#EDEAFF",
              // height: linkSent ? "470px" : "390px",
            }}
          >
            <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
              <Box>
                <Typography
                  sx={{
                    // color: (theme) => theme.palette.primary.main,
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#6846C7",
                  }}
                >
                  Forgot password
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#5E5E67",
                    mt: "14px",
                  }}
                >
                  Please enter the e-mail address linked with your account.
                </Typography>
              </Box>
              <form action="">
                <Box>
                  <input
                    type="text"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@domain.com"
                    style={{
                      width: "100%",
                      padding: "7px",
                      height: "56px",
                      border: "none",
                      background: "#FFFFFF",

                      marginTop: "30px",
                      borderRadius: "16px",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: "30px" }}>
                  <Button
                    onClick={handleForgot}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                      py: 1,
                      borderRadius: "15px",
                      fontSize: "16px",
                      fontWeight: 500,
                      background: "#6846C7",
                      color: "#fff",
                      "&:hover": {
                        background: "#6846C7",
                      },
                    }}
                  >
                    {loading ? "Sending ..." : "Submit"}
                  </Button>
                  {/* <Typography> {notification ? notification : ""}</Typography> */}
                </Box>
                {linkSent && (
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      mt: "13px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Poppins",

                        fontWeight: "400",
                        fontSize: "14px",
                        //  / // font-size: 14px,
                        // line-height: 22px;
                        lineHeight: "22px",
                        /* or 157% */
                        textAlign: "center",

                        color: "red",

                        opacity: "0.8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      {/* <Typography
                    variant="h3"
                    sx={{ color: "#6846C7", fontWeight: 700 }}
                  >
                    Password reset link sent
                  </Typography> */}

                      <p>
                        A password recovery link has been sent to your
                        registered email id. Please check your spam folder if
                        you do not see the email in your inbox.
                      </p>
                    </Box>
                  </Box>
                )}
                {/* <Typography sx={{ mt: 1, color: "#C40B0B" }}>
                  {" "}
                  {notification ? notification : ""}
                </Typography> */}

                <Typography sx={{ mt: 1, color: "#C40B0B" }}>
                  {" "}
                  {notification !== "pending invitation" ? notification : ""}
                </Typography>
                <Box>
                  {notification == "pending invitation" && (
                    <ul
                      style={{
                        fontFamily: "Poppins",

                        fontWeight: "400",
                        fontSize: "14px",

                        lineHeight: "22px",

                        textAlign: "left",

                        color: "red",

                        opacity: "0.8",
                      }}
                    >
                      <li>Invitation acceptance pending </li>
                      <li>
                        Refer to the invite email sent to you by{" "}
                        <i
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          Nandini from Serein{" "}
                        </i>{" "}
                      </li>
                      <li>
                        Click on{" "}
                        <i
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          Set Password{" "}
                        </i>{" "}
                        in the invite to begin your login process{" "}
                      </li>
                    </ul>
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Link to="/">
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#6846C7",
                        fontWeight: 600,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      Go to login
                    </Typography>
                  </Link>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
