import ResponsiveDrawer from "../Sidebar/HrSideBar";
import VerticalLinearStepper from "./steper";
import OverallRepresentation from "./component/OverallRepresentation";
import Retention from "./component/Retention";
import Infrastructure from "./component/Infrastructure";
import "../style/HrMainBox.css";
import "./steper.css";
import { useContext, useEffect, useState } from "react";
import Policies from "./component/Policies";
import Recruitment from "./component/Recruitment";
import ProcessandTool from "./component/ProcessandTool";
import CultureIndex from "./DIComponent/CultureIndex";
import { ValidateAuth } from "../../AuthToken";
import { getCookie } from "../../Cookies";
import { Box } from "@mui/material";

import { useLocation, useNavigate } from "react-router";
import HrFooter from "../Sidebar/HrFooter";
import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import HrloadingScreenDEI from "../homeLoadingInDEI";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import MobileTopBar from "../Sidebar/MobaileTopBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";

import MobileViewSvg from "./MobileNoView.svg";

//import { ValidateAuth } from "../../../AuthToken";
const HomePageHr = ({ admin, companyId }) => {
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  const [steperStep, setSteperStep] = useState(9);

  const [lodingscreen, setlodingscreen] = useState(false);
  const [overallgender, setOverallgender] = useState([]);
  const [overAllFlag, setOverAllFlag] = useState(true);
  const [retentionFlag, setretentionFlag] = useState(true);
  const [recrutmentFlag, setrecrutmentFlag] = useState(true);
  const [policiesFlag, setpoliciesFlag] = useState(true);
  const [processandtool, setprocessandtool] = useState(true);
  const [infraflag, setinfraflag] = useState(true);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (admin == true) {
      console.log("i am trye", companyId);

      setSteperStep(0);
      // setOverAllFlag(true);
    }
    let newobj =
      rolesAndPermission?.DEI?.componentPermission.includes(
        "Advancement Scale"
      );
    let newobjtwo = rolesAndPermission?.DEI?.componentPermission.includes(
      "OverAll Representation"
    );
    let newobjthree = rolesAndPermission?.DEI?.componentPermission.includes(
      "Recuritment and Retention"
    );
    // console.log(newobj.HRDI);
    if (!newobj && !newobjtwo && !newobjthree) {
      navigate("/hr/Learning");
      return <></>;
    }
  }, []);

  useEffect(() => {
    setlodingscreen(true);
    console.log('line 71');
    console.log(typeof companyId);
    console.log(companyId);
    let config = {
      method: "get",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config)
      .then((res) => {
        setlodingscreen(false);
        // setOverallGenderRepresentationState(res.data);
        if (res.data.length > 0) {
          setSteperStep(6);
        } else {
          setSteperStep(0);
        }
        if (admin == true) {
          console.log("i am trye");
          setSteperStep(0);
          // setOverAllFlag(true);
        }
        //console.log(res.data);
      })
      .catch((er) => {
        setlodingscreen(false);

        // console.log(er);
      });
  }, []);
  useEffect(() => {
    if (steperStep == 0) {
      setOverAllFlag(!overAllFlag);
    }
    if (steperStep == 1) {
      setrecrutmentFlag(!recrutmentFlag);
    }
    if (steperStep == 2) {
      setretentionFlag(!retentionFlag);
    }

    if (steperStep == 3) {
      setpoliciesFlag(!policiesFlag);
    }
    if (steperStep == 4) {
      setprocessandtool(!processandtool);
    }
    if (steperStep == 5) {
      setinfraflag(!infraflag);
    }
  }, [steperStep]);
  //console.log(overallgender);
  return (
    <Box
      className="hrmainbox"
      sx={{ backgroundColor: "#F3F1FF", marginTop: "0px", padding: "0px" }}
    >
      {!admin && <ResponsiveDrawer sidebar={"HomePageHr"} />}
      <HrloadingScreenDEI open={lodingscreen} />

      {steperStep == 6 ? (
        <Box className="hrmainboxone">
          <CultureIndex setSteperStep={setSteperStep} />
          <HrFooter />
        </Box>
      ) : (
        <Box className="HomeHRWithTopBar">
          <MobileTopBar sidebar={"Diversity"} />
          <Box className="hrmainboxHomeHR" sx={{ padding: "0px" }}>
            <Box sx={{ width: "73%" }}>
              {steperStep == 0 && (
                <OverallRepresentation
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={overAllFlag}
                  companyId={companyId}
                />
              )}
              {steperStep == 2 && (
                <Retention
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={retentionFlag}
                  companyId={companyId}
                />
              )}
              {steperStep == 5 && (
                <Infrastructure
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={infraflag}
                  companyId={companyId}
                />
              )}
              {steperStep == 3 && (
                <Policies
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={policiesFlag}
                  companyId={companyId}
                />
              )}
              {steperStep == 1 && (
                <Recruitment
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={recrutmentFlag}
                  companyId={companyId}
                />
              )}
              {steperStep == 4 && (
                <ProcessandTool
                  setOverallgender={setOverallgender}
                  overallgender={overallgender}
                  overAllFlag={processandtool}
                  companyId={companyId}
                />
              )}

              <HrFooter />
            </Box>

            <Box sx={{ width: "23%", mt: "24px" }}>
              <VerticalLinearStepper
                setSteperStep={setSteperStep}
                steperStep={steperStep}
                admin={admin}
                companyId={companyId}
              />
            </Box>
          </Box>
          <div className="hideOnMobile">
            <HrTopNavbar
              title={"Culture Index "}
              mainStyle={{
                style: {
                  width: "100%",
                },
              }}
            />

            <div className="mobileViewContainer">
              <div className="mobileViewArea">
                <img src={MobileViewSvg} alt="" />
              </div>
            </div>
          </div>
        </Box>
      )}
    </Box>
  );
};
export default HomePageHr;
