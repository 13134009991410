import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import screenfull from "screenfull";
import { getCookie } from "../../../../Cookies";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../baseUrlAndConfig";
import { ItemContext } from "../Context/ItemContext";
const HtmlItem = ({
  url,
  setCanNavigate,
  itemId,
  type,
  progressIndex,
  changeProgressIndex,
  typeOfScorm,
}) => {
  const [completed, setCompleted] = useState(false);
  const { courseId, isLinear, certificateInfo } = useContext(ItemContext);
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const setHTMLProgress = httpsCallable(functions, "setHTMLProgress");
  const [count, setCount] = useState(0);
  const typeScorm = typeOfScorm ?? "iSpring";
  console.log("typeOfScorm from course-----------------", typeScorm);

  useEffect(() => {
    if (type == "scorm") {
      const interval = setInterval(() => {
        let userData = getCookie(courseId);
        // getCookie("user_data");
        console.log(userData);
        // settempdata(userData);

        if (userData) {
          let scoreData = Number(userData);

          console.log(scoreData);
          if (scoreData == 100 && !certificateInfo) {
            setCanNavigate(true);
            changeProgressIndex(progressIndex + 1);

            let configrecrut = {
              method: "patch",

              url: `${IP}/userProgress/items`,
              // params: { courseId: courseId, itemId: itemId },
              data: JSON.stringify({
                courseId: courseId,
                itemId: itemId,
                lastVisited: new Date().toString(),
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
            };
            axios(configrecrut).then((res) => {
              console.log(res);
            });
          }
        }
        setCount(count + 1);
      }, 5000);

      return () => {
        clearInterval(interval);

        // setCookie("user_data", null);
      };
    }
  }, [count]);
  useEffect(() => {
    if (type == "scorm") {
      return;
    }

    let configrecrut = {
      method: "get",

      url: `${IP}/userProgress/items`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    //console.log(completed);
    if (type == "scorm") {
      return;
    }
    if (completed) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userCourseContent/html`,
        // params: { courseId: courseId, itemId: itemId },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(configrecrut).then((res) => {
        console.log(res);
      });

      // setHTMLProgress({
      //   courseId: courseId,
      //   itemId: itemId,
      //   lastVisited: new Date().toString(),
      // });
    }
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  const fullscreen = () => {
    // screenfull.request(findDOMNode("body"));

    // document.getElementById("testdomel").style.height = "1000px";
    // ds.style.width = "800px";
    // playerRef.current
    screenfull.request(
      // playerRef
      document.getElementById("testdomel")
    );
  };

  return (
    <div className="htmlItem-container">
      {type == "scorm" ? (
        <iframe
          // src={`https://content.sereininc.live/index.html?courseUrl=${url}&courseId=${courseId}&type=${typeScorm}&isCourse=${true}`}
          src={`https://content.sereininc.com/index_prod_1.html?courseUrl=${url}&courseId=${courseId}&type=${typeScorm}&isCourse=${true}`}
          id="testdomel"
          className="htmlItem-iframe"
          allowfullscreen={true}
          width="100%"
          height="100%"
        ></iframe>
      ) : (
        <iframe
          src={url}
          id="testdomel"
          className="htmlItem-iframe"
          allowfullscreen={true}
        ></iframe>
      )}

      <div className="markCompleted">
        <Button
          sx={{
            width: "120px",
            hight: "40px",
            // border: "1px solid #F3F1FF",
            backgroundColor: "#E1F566",
            color: "#000",
          }}
          onClick={() => {
            fullscreen();
          }}
        >
          {" "}
          Full Screen{" "}
        </Button>
        {type != "scorm" && (
          <Checkbox
            aria-label="Mark Completed"
            onChange={(e) => setCompleted(e.target.checked)}
            checked={completed}
            sx={{
              color: "#007c84",
              "&.Mui-checked": {
                color: "#007C84",
              },
            }}
          />
        )}

        {type != "scorm" && "Mark Completed"}
      </div>
      {/* <CustomAudioPlayer
        url={
          "https://firebasestorage.googleapis.com/v0/b/alghaithdev.appspot.com/o/download?alt=media&token=7ac7a6ad-17a7-4d0b-b85a-a7033c067203"
        }
      /> */}
    </div>
  );
};
export default HtmlItem;
