import { Box, Container, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { functions } from "../../Firebase";
import "./NavbarTop.css";

// import { useTranslation } from "react-i18next";/styles/Navbar/Navbar.css
import { useSelector } from "react-redux";
import "../Translater/Translater";
import NotificationPopup from "./NavbarTopPOPUp";
import NotificationIcon from "./NotificationIcon";
import pp from "./img/profilePicture.svg";
import settings from "./img/settings.svg";

function Navbar({ page }) {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();
  const fetchNotification = httpsCallable(functions, "fetchNotification");
  const [showPopUp, setShowPopUp] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [notificationCount, setNotificationsCount] = useState("");
  //================user details====================//
  const { clrs } = useSelector((state) => state.createClr);
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const [imgshow, setImgshow] = useState(false);
  const [name, setName] = useState("");
  const [empId, setempId] = useState("");
  // useEffect(() => {
  //   fetchUserProfile({})
  //     .then((res) => {
  //       setImgshow(res.data?.photoURL); //setting url of image
  //       setName(res.data?.name); //setting name of user
  //       setempId(res.data?.employeeId); //setting empId of user
  //       // //console.log(res.data);
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  //    // //console.log(auth._delegate.lastNotifiedUid)
  //   fetchNotification({
  //     uid:auth._delegate.lastNotifiedUid,
  //   }).then((res) => {
  //     if (res.error) {
  //       return;
  //     }
  //    // //console.log(res.data)
  //     setNotifications(res.data.sort((a,b)=>b.timestamp._seconds-a.timestamp._seconds));
  //     const temparr=(res.data.filter((ele)=>ele.read===undefined))

  //     setNotificationsCount(temparr.length>0?temparr.length:"")
  //   });
  // }, []);

  return (
    <Box
      style={{ zIndex: 99 }}
      sx={{
        height: "80px",
        py: 1,
        background: "#fff",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ backgroundColor: "red" }}>
            <img
              className="nav-cnt-left-img"
              // style={{ width: "12vw" }}
              src="/sereinLogo.png"
              alt="loading"
            />
          </Box>
          <div className="nav-cnt-center">
            <div
              style={{
                color: `${clrs.NavbarTxtClr ? clrs.NavbarTxtClr : "black"}`,
              }}
            >
              {page}
            </div>
          </div>

          <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box sx={{ width: "40px", height: "40px" }}>
                {/* <img src={pp} alt="loading" /> */}
                <img
                  src={imgshow ? imgshow : pp}
                  alt="loading"
                  style={{ width: "100%", height: "100%", borderRadius: "20%" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: "22px", fontWeight: "600", color: "#00495F" }}
                >
                  {/* {getCookie("NAME")} */}
                  {name}
                </Typography>
                <Typography>
                  {/* {getCookie("EMPID")} */}
                  {empId}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box
                onClick={() => setShowPopUp((prev) => !prev)}
                sx={{ cursor: "pointer" }}
              >
                <NotificationIcon count={notificationCount} />
              </Box>
              {showPopUp && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "0px",
                    top: "100px",
                    right: "0px",
                    bottom: "0px",
                    zIndex: 9999,
                    width: "100%",
                  }}
                >
                  <NotificationPopup
                    onClose={() => setShowPopUp((prev) => !prev)}
                    notifications={notifications?.map((ele) => {
                      return ele;
                    })}
                  />
                </Box>
              )}
            </Box>
            <Box className="nav-cnt-right-settings">
              <Link to="/admin/settings">
                <img src={settings} alt="loading" />{" "}
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Navbar;
