import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import CoursesImg from "../images/courses-img.png";
import MicroLearnings from "../images/micro-learnings.png";
import {
  CertificateDownloadIcon,
  RightArrowIcon,
  RightArrowIconblue,
} from "../icons/Icons";
import { useNavigate } from "react-router";
import { microArr } from "./microLerningdata";
import micro from "./Vector.svg";
import { getCookie } from "../../Cookies";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../AuthToken";
import { useContext, useEffect, useState } from "react";
import HrFooter from "../Sidebar/HrFooter";
import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import "./learning.css";
import MobileTopBar from "../Sidebar/MobaileTopBar";
const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const ViewMicroLearningsAll = () => {
  const navigate = useNavigate();
  const [microArr, setMicroCourse] = useState([]);
  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission, course_Completion } =
    useContext(DrawerContext);
  useEffect(() => {
    // let newobj =
    //   rolesAndPermission?.Learning?.componentPermission.includes(
    //     "Microlearnings"
    //   );
    // // console.log(newobj.HRDI);
    // if (!newobj) {
    //   goToHome();
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      // setlodingscreen(false);
    } else {
      // setlodingscreen(false);

      let newobj =
        rolesAndPermission?.Report?.componentPermission.includes(
          "Microlearnings"
        );
      // console.log(newobj.HRDI);
      if (!newobj) {
        // navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);

  async function fetchMicroCourse() {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroCourse/courseLibrary`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setMicroCourse(res.data);
      // setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
      // setLoading(false);
    });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }

  useEffect(() => {
    fetchMicroCourse();
  }, []);

  const microlearningpagecontent = (courseId, item) => {
    // navigate(`/hr/Learning/MicroLearning-content/${courseId}`)

    navigate(`/hr/Learning/MicroLearning-content/${courseId}`, {
      state: { ...item },
    });
  };
  return (
    <Box className="hrmainbox" sx={{ backgroundColor: "#F3F1FF" }}>
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      <Box sx={{ width: "100%" }}>
        <MobileTopBar />

        <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
              {" "}
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E1F566",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                {course_Completion}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Course completed{" "}
              </Box>
            </Box>
          </Box>
        </HrTopNavbar>
        <Box
          sx={{
            width: "100%",
            padding: "24px",
            minHeight: "100vh",
            "@media (max-width: 780px)": {
              padding: "10px",
            },
          }}
        >
          <Box sx={{ width: "100%", mt: "20px" }}>
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                gap: "10px",
                mb: "20px",
              }}
            >
              <Box>
                {" "}
                <img src={micro} alt="" />
              </Box>
              <Box>Micro-learnings</Box>
            </Box>
            <Box
              // container
              // rowSpacing={10}
              // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

              sx={{
                gridTemplateColumns: "1fr 1fr 1fr",
                display: "grid",
                gridGap: "25px",
                "@media (max-width: 780px)": {
                  gridTemplateColumns: "1fr",
                  gridGap: "20px",
                },
              }}
            >
              {microArr.map((item) => (
                <Grid item xs={4}>
                  <Item
                    sx={{
                      borderRadius: "24px",
                    }}
                  >
                    <Box
                      onClick={() => {
                        microlearningpagecontent(item.courseId, item);
                      }}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "24px",
                        height: "121px",
                        display: "grid",
                        cursor: "pointer",
                        gridTemplateColumns: "2fr 3fr",
                        gridGap: "20px",
                      }}
                    >
                      <Box>
                        <img
                          style={{
                            width: "100%",
                            height: "121px",
                            borderRadius: "20px",
                            objectFit: "cover",
                          }}
                          src={item.courseImageURL}
                          alt=""
                        />
                      </Box>
                      <Box
                        sx={{
                          // padding: "24px 10px 24px 10px",
                          display: "grid",
                          gridTemplateRows: "5fr 1fr",
                          height: "100%",
                          width: "90%",
                          padding: "10px",

                          overflow: "hidden",
                        }}
                      >
                        <Box
                          className="microlerningdiscription"
                          sx={{
                            overflow: "hidden",
                            paddingTop: "12px",
                          }}
                        >
                          {" "}
                          {item.courseName}
                        </Box>
                        <Box
                          onClick={() => {
                            microlearningpagecontent(item.courseId, item);
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "end",

                            // mt: "25px",
                          }}
                        >
                          <RightArrowIconblue />
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>

        <HrFooter />
      </Box>
    </Box>
  );
};
export default ViewMicroLearningsAll;
