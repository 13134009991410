import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import Sidebar from "../../../Component/Sidebar";
import { Button, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";

export default function AddCompanyFormData() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);

  const location = useLocation();
  const [campanyData, setCampanyData] = useState([]);
  const [formData, setFormData] = useState({
    survey: "0",
    companyId: "",
    imbedCode: "",
    sharebleLink: "",
    url: "",
  });

  const getCompanyData = () => {
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config).then((res) => {
      setCampanyData(res.data);
    });
  };

  const generateUrl = () => {
    if (
      formData.companyId == "" ||
      formData.imbedCode == "" ||
      formData.sharebleLink == "" ||
      formData.survey == ""
    ) {
      alert("fill all the filed");
    }
    const data = JSON.stringify(formData);
    var config = {
      method: "post",

      url: `${IP}/inclusionStudy/generateUrl`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config).then((res) => {
      setFormData((prev) => ({ ...prev, url: res.data.url }));
      console.log("alert");
    });
  };

  const postData = () => {
    const data = JSON.stringify(formData);
    var config = {
      method: "post",

      url: `${IP}/inclusionStudy/generateUrl`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config).then((res) => {
      console.log("alert");
    });
  };

  useEffect(() => {
    getCompanyData();
    if (location.state) {
      setFormData(location.state);
    }
  }, []);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Pysclogical Safety")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div> </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="inputlabelnameForstrategi"
                  style={{ color: "#000000" }}
                >
                  Add Comapany
                </div>

                <div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      survey type
                    </div>
                    <div>
                      <Select
                        className="inputbox"
                        placeholder=" survey type"
                        value={formData.survey}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            survey: e.target.value,
                          }));
                        }}
                        sx={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      >
                        <MenuItem> select</MenuItem>
                        <MenuItem value={"0"}> {"Pysclogical Safety"}</MenuItem>

                        <MenuItem value={"1"}> {"Bystander Safety"}</MenuItem>
                      </Select>{" "}
                    </div>
                  </div>{" "}
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Company Name
                    </div>
                    <div>
                      <Select
                        className="inputbox"
                        placeholder="Comapany Name"
                        value={formData.companyId}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            companyId: e.target.value,
                          }));
                        }}
                        sx={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      >
                        <MenuItem> select</MenuItem>
                        {campanyData?.map((el) => (
                          <MenuItem value={el.companyId}> {el.name}</MenuItem>
                        ))}
                      </Select>{" "}
                    </div>
                  </div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi"> Form Code</div>
                    <div>
                      {" "}
                      <input
                        className="inputbox"
                        type="text"
                        value={formData.imbedCode}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            imbedCode: e.target.value,
                          }));
                        }}
                        placeholder="Code"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Sharable Link
                    </div>
                    <div>
                      {" "}
                      <input
                        className="inputbox"
                        type="text"
                        placeholder="Link"
                        value={formData.sharebleLink}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            sharebleLink: e.target.value,
                          }));
                        }}
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      height: "40px",
                      margin: "24px 0px",
                    }}
                  >
                    {" "}
                    <Button
                      onClick={generateUrl}
                      style={{
                        backgroundColor: "#007C84",
                        borderRadius: "5px",
                        color: "#FFFFFF",
                        padding: "8px 20px",
                      }}
                    >
                      Generate Url
                    </Button>
                  </div>
                  <div
                    className="inputandlabelboxforstrategies"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Generated Url
                    </div>
                    <div>
                      {" "}
                      <input
                        className="inputbox"
                        type="text"
                        value={formData.url}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            url: e.target.value,
                          }));
                        }}
                        placeholder="Link"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
