import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// import OverallRepresentationDI from "./OverallRepresentationdi";
// import RecruitmentandRetention from "./RecruitmentandRetention";
import { ValidateAuth } from "../../AuthToken";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import { Button } from "@mui/material";
import PsyclogicalSafety from "./PsyclogicalSaftey/PsyclogicalSafety";
import Bystandard from "./Bystandard/Bystandard";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import SendInviteForPsyBsy from "./SendInvitePopUpForPsyBys";
import HrloadingScreen from "../LodingHrscreen";
import MobileTopBar from "../Sidebar/MobaileTopBar";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={"PsSafetyTabPanel"}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PsyclogicalBystandard({ setSteperStep }) {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [loadingScreen, setLoadingScreen] = React.useState(false);
  const [popupOpne, setPopupOpen] = React.useState(false);
  const [pageState, setPageState] = React.useState("");
  const { rolesAndPermission, setRolesAndPermission } =
    React.useContext(DrawerContext);

  React.useEffect(() => {
    if (!rolesAndPermission) {
      setLoadingScreen(true);
    } else {
      setLoadingScreen(false);
      let newobj =
        rolesAndPermission?.Psycological_Study?.componentPermission.includes(
          "Psycological safety"
        );
      // console.log(newobj.HRDI);
      // if (!newobj) {
      //   navigate(-1);
      //   return <></>;
      // }
    }
  }, []);
  // }, [rolesAndPermission]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      className="hrmainbox"
      style={{
        background: "#F3F1FF",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <HrloadingScreen open={loadingScreen} />
      <ResponsiveDrawer sidebar={"Psycho_Bystand"} />

      <SendInviteForPsyBsy
        onClose={setPopupOpen}
        open={popupOpne}
        data={value}
      />

      <Box
        sx={{
          width: "100%",
          // overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            backgroundColor: "#F3F1FF",
            zIndex: "2",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <MobileTopBar sidebar={"Inclusion"} />
          <HrTopNavbar title={"Inclusion metric"}>
            <Button
              sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
              onClick={() => {
                setPopupOpen(true);
              }}
            >
              {" "}
              Send link
            </Button>
          </HrTopNavbar>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                width: "95%",
                borderColor: "divider",
                textAlign: "left",
                mt: "6px",
              }}
            >
              <Tabs
                variant="scrollable"
                value={value}
                textColor="inherit"
                indicatorColor="primary"
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    color: "#6846C7",
                    marginLeft: "13px",
                    textAlign: "left",
                    width: "5%",
                  },
                  sx: {
                    "@media (max-width: 600px)": {
                      display: "none",
                    },
                  },
                }}
              >
                {rolesAndPermission?.Psycological_Study?.componentPermission.includes(
                  "Psycological safety"
                ) && (
                  <Tab
                    sx={{
                      color: "#6846C7",
                      fontWeight: "600",
                      fontFamily: "Poppins",

                      fontSize: "16px",
                    }}
                    label="Psychological Safety"
                    onClick={(e) => {
                      e.target.scrollIntoView({
                        behaviour: "smooth",
                        inline: "start",
                      });
                    }}
                    style={{
                      scrollMargin: "50px",
                    }}
                  />
                )}

                {rolesAndPermission?.Psycological_Study?.componentPermission.includes(
                  "Bystander Intervation"
                ) && (
                  <Tab
                    sx={{
                      color: "#6846C7",
                      fontWeight: "600",
                      fontFamily: "Poppins",

                      fontSize: "16px",
                    }}
                    style={{
                      scrollMargin: "50px",
                    }}
                    label="Bystander Intervention"
                    onClick={(e) => {
                      e.target.scrollIntoView({
                        behaviour: "smooth",
                        inline: "start",
                      });
                    }}
                  />
                )}
              </Tabs>
            </Box>
          </Box>
        </Box>

        <TabPanel value={value} index={0}>
          <PsyclogicalSafety />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Bystandard />
        </TabPanel>
      </Box>
    </Box>
  );
}
