import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";

export default function StackedForOrg({
  data,
  legendPosition,

  Gbbvbar,
}) {
  useEffect(() => {
    console.log(data?.male);
    setOptions({
      series: [
        // { name: "Company", data: data?.company },

        { name: "Male", data: data?.Junior },
        { name: "Female", data: data?.MidLevel },
        { name: "Non-binary", data: data?.Senior },
      ],

      options: {
        chart: {
          type: "bar",
          stackType: "100%",
          stacked: true,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "70%",
          },
        },
        stroke: {
          width: 0,
          colors: ["#03C988"],
          // borderRedios:'',
        },
        xaxis: {
          categories: data?.categories,
          // },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              let val = value.toString();
              console.log("tool:", val);

              if (val.length >= 5) {
                // console.log(val.slice(0, 5));
                let newval = val.slice(0, 5);
                return Number(newval) + "%";
              } else {
                return Number(val) + "%";
                console.log(val);
              }
            },
          },
        },
        dataLabels: {
          formatter: (value) => {
            let val = value.toString();
            console.log("org", val);
            // return Number(val) + "%";
            if (val.length >= 5) {
              // console.log(val.slice(0, 5));
              let newval = val.slice(0, 5);

              let newvaldata = val.slice(5, 7);
              console.log(newvaldata);
              if (newvaldata == "99") {
                let newfil = Number(newval) + 0.01;
                return Number(newfil).toFixed(2) + "%";
              } else {
                return Number(newval) + "%";
              }
            } else {
              // console.log(val);
              return Number(val).toFixed(2) + "%";
              console.log(val);
            }

            return Number(val).toFixed(2) + "%";
          },
        },
        fill: {
          opacity: 1,

          colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
        },
        legend: {
          position: legendPosition ? legendPosition : "bottom",
          horizontalAlign: "left",
          offsetX: 60,
          offsetY: 10,
          show: legendPosition ? false : true,
        },
      },
    });
  }, [data]);

  const [option, setOptions] = useState({
    series: [
      { name: "Junior", data: data?.male },
      { name: "FeMale", data: data?.female },
      { name: "Non-Binary", data: data?.nonBinary },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#03C988"],
      },
      // title: {
      //   text: "Representation of Gender by business vertical",
      // },
      xaxis: {
        categories: data?.categories,
        // labels: {
        //   formatter: function (val) {
        //     return val + "K";
        //   },
        // },
      },
      //   yaxis: {
      //     title: {
      //       text: "trs",
      //     },
      //   },
      //   tooltip: {
      //     y: {
      //       formatter: function (val) {
      //         return val + "K";
      //       },
      //     },
      //   },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!data?.Junior ? (
        <img
          src={Gbbvbar}
          alt=""
          style={{
            width: "80%",
            objectFit: "cover",
            overflow: "hidden",
            height: "400px",
          }}
        />
      ) : (
        <div className="responsiveGraph">
          <Chart
            options={option.options}
            series={option.series}
            width="100%"
            height={data?.categories.length * 100}
            type="bar"
          />
        </div>
      )}
    </div>
  );
}
