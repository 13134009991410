import { Button, Container, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import {
  responsiveReverseFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import loginImg from "../../../utilities/images/serin.jpg";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../loginpage/login-logo.png";
import { IP } from "../../../baseUrlAndConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";

function Maintenance() {
  const navigate = useNavigate();

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    setUserPassword();
  }, []);

  const setUserPassword = async (e) => {
    // can make is so that if a continue url is given we can redirect to the continue url
    //console.log(value);
    var data = JSON.stringify({
      //   newPassword: newpass,
      //   confermpass: confermpass,
      //   email: uid,
    });

    var config = {
      method: "get",

      url: `${IP}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((userCredential) => {
        navigate("/");
      })
      .catch((error) => {
        // console.log(error);
        //console.log(error.code);
        // setError(error.code);
      });
  };

  return (
    <Box
      sx={{
        py: { md: "0", height: "100vh", background: "#F5F5F5" },
      }}
    >
      <Container
        sx={{
          // ...responsiveReverseFlexDirection,

          gap: "50px",

          // display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            // className={classes.logo}
            src={loginLogo}
            alt=""
            style={{
              marginTop: "60px",

              background: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "50px",
          }}
        >
          <Box
            sx={{
              // ...responsiveWidth,

              border: "2px solid #6846C7",
              borderRadius: "20px",
              width: "476px",
              background: "#EDEAFF",
              height: "450px",
            }}
          >
            Under maintenance
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Maintenance;
