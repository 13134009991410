import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";

import { CertificateDownloadIcon, RightArrowIcon } from "../../icons/Icons";
import { useNavigate } from "react-router";
import "../hovereffect.css";
// import { lerningicons } from "./lerningIcons";
import imro from "../Vector.svg";

import paly from "./paly.svg";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { microArr } from "../microLerningdata";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
// import {
//   displayOnDesktop,
//   displayOnMobile,
// } from "../../../utilities/commonStyles/commonStyles";
// import {
//   CourseVideoIcons,
//   CourseVideoWatchIcons,
//   SearchIcons,
// } from "../../../utilities/Icons/Icons";

import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { grid } from "@mui/system";

import { ValidateAuth } from "../../../AuthToken";
import WebinarPopUp from "./webinarPopUp";

const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const WebinarHome = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);
  const [popdata, setpopdata] = useState();
  const [webdata, setWebdata] = useState([]);
  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // // console.log(newobj.HRDI);
    // if (!newobj?.LEARNING?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);

  async function fetchCourses() {
    let configrecrut = {
      method: "get",

      url: `${IP}/webinar/user`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setWebdata(res.data);
      //   setCourses(res.data.filter((e) => e.archived != true));
      //   setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
      //   setLoading(false);
    });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }

  useEffect(() => {
    // if (
    //   getCookie("UID") == null ||
    //   getCookie("ROLE") !== "USER" ||
    //   getCookie("ROLE") == "ADMIN"
    // ) {
    //   goToHome();
    //   return <></>;
    // }

    fetchCourses();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <WebinarPopUp
          open={openpopUpForAddYears}
          onClose={() => setOpenpopUpForAddYears(false)}
          // delfun={(topic) => {
          //   addYearsFunPopUp(topic);
          // }}
          data={popdata}
        />
        <Box
          // data-aos="fade-up"
          // data-aos-duration="1500"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            // mt: "100px",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Box>
            <img src={imro} alt="" />
          </Box>

          <Box
            className="sectionname"
            sx={{
              ml: "8px",
            }}
          >
            Webinars
          </Box>

          <Box
            sx={{
              fontWeight: "14px",
              fontSize: "14px",
              ml: "6px",
              height: "20px",
              width: "2px",
              opacity: "0.3",
              backgroundColor: "#6846C7",
            }}
          ></Box>
          <Box
            onClick={() => navigate("view-Webinar")}
            className="sectionnameall"
            sx={{
              ml: "6px",
              height: "20px",

              cursor: "pointer",
              color: "#6846C7",
            }}
          >
            View all
          </Box>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            mt: "24px",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Box
            className="sectionname"
            sx={{
              ml: "8px",
            }}
          >
            Upcoming webinars
          </Box>
        </Box> */}

        {webdata?.passedWebinar?.length == 0 && (
          <Box
            sx={{
              height: "200px",
              ml: "8px",
              mt: "10px",
            }}
          >
            {" "}
            You don't have any upcoming webinar
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            mt: "24px",
            "@media (max-width: 780px)": {
              maxWidth: "100vw",
              height: "194px",
              overflowX: "scroll",
            },
          }}
        >
          <Box
            // container
            // rowSpacing={10}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

            sx={{
              display: "grid",
              gridTemplateRows: "1fr 1fr",
              gap: "24px",
              "@media (max-width: 780px)": {
                width: "100vw",
                gridTemplateColumns: "350px 350px",
                height: "100%",
              },
            }}
          >
            {webdata?.passedWebinar?.map((item, index) => {
              if (index > 1) {
                return;
              }
              return (
                <Grid item xs={4}>
                  <Item
                    sx={{
                      borderRadius: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#DFD6F6",
                        borderRadius: "24px",
                        height: "194px",
                        display: "grid",
                        gridTemplateColumns: "2fr 3fr",
                        gridGap: "20px",
                      }}
                    >
                      <Box>
                        <img
                          style={{
                            width: "100%",
                            height: "194px",
                            borderRadius: "20px",
                          }}
                          //   src={item?.courseImageURL}
                          src={item?.photo}
                          alt=""
                        />
                      </Box>
                      <Box
                        sx={{
                          // padding: "24px 10px 24px 10px",
                          display: "grid",
                          padding: "5px",

                          gridTemplateRows: "5fr 1fr",
                          height: "100%",
                          // justifyContent: "space-between",
                          width: "90%",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          className="microlerningdiscription"
                          // sx={{ minHeight: "80px" }}
                          sx={{
                            paddingTop: "12px",
                          }}
                        >
                          {item?.topic}
                          {/* {item?.courseName} */}
                        </Box>
                        <Box
                          //   onClick={() => {
                          //     microlearningpagecontent("test", "start", {
                          //       courseid: item?.courseId,
                          //     });
                          //   }}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: "13px",
                            alignContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              color: "#666666",
                            }}
                          >
                            {" "}
                            60 mins
                          </Box>

                          <Box
                            onClick={() => {
                              setpopdata(item);
                              setOpenpopUpForAddYears(true);
                              window.open(item?.zoomlink, "_blank");
                            }}
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#6846C7",
                            }}
                          >
                            {" "}
                            Upcoming
                            {/* <RightArrowIcon /> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default WebinarHome;
// {

//   <Box
//   sx={{
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//     mt: "24px",
//     position: "relative",
//     zIndex: "2",
//   }}
// >
//   <Box
//     className="sectionname"
//     sx={{
//       ml: "8px",
//     }}
//   >
//     Past webinars
//   </Box>
// </Box>

// {webdata?.passedWebinar?.length == 0 && (
//   <Box
//     sx={{
//       height: "200px",
//       ml: "8px",
//       mt: "10px",
//     }}
//   >
//     {" "}
//     You don't have any passed webinar
//   </Box>
// )}

// <Box sx={{ width: "100%", mt: "24px" }}>
//   <Grid
//     container
//     rowSpacing={10}
//     columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//   >
//     {webdata.passedWebinar?.map((item, index) => {
//       if (index > 2) {
//         return;
//       }
//       return (
//         <Grid item xs={4}>
//           <Item
//             sx={{
//               borderRadius: "24px",
//             }}
//           >
//             <Box
//               sx={{
//                 backgroundColor: "#fff",
//                 borderRadius: "24px",
//                 height: "160px",
//                 display: "flex",
//                 gap: "0px",
//               }}
//             >
//               <Box sx={{ width: "50%" }}>
//                 <img
//                   style={{
//                     width: "154px",
//                     height: "160px",
//                     borderRadius: "20px",
//                   }}
//                   //   src={item?.courseImageURL}
//                   src={item?.photo}
//                   alt=""
//                 />
//               </Box>
//               <Box sx={{ padding: "24px 10px 24px 10px" }}>
//                 <Box
//                   className="microlerningdiscription"
//                   sx={{ minHeight: "80px" }}
//                 >
//                   {item?.topic}
//                   {/* {item?.courseName} */}
//                 </Box>
//                 <Box
//                   //   onClick={() => {
//                   //     microlearningpagecontent("test", "start", {
//                   //       courseid: item?.courseId,
//                   //     });
//                   //   }}
//                   sx={{
//                     display: "flex",

//                     width: "100%",
//                     gap: "19px",
//                     alignContent: "center",
//                   }}
//                 >
//                   <Box> 20 mins</Box>
//                   <Box
//                     onClick={() => {
//                       window.open(item.videolink, "_blank");
//                     }}
//                     sx={{
//                       fontFamily: "Poppins",
//                       fontSize: "14px",
//                       fontWeight: "600",
//                       display: "flex",
//                       color: "#6846C7",
//                       alignItems: "center",
//                       gap: "10px",
//                       alignContent: "center",
//                     }}
//                   >
//                     <Box> Play</Box>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignContent: "center",
//                         alignItems: "center",
//                         width: "16px",
//                         justifyContent: "center",
//                         height: "16px",
//                         borderRadius: "50%",
//                         border: "1px solid #6846C7",
//                       }}
//                     >
//                       {" "}
//                       <img src={paly} alt="" />
//                     </Box>
//                   </Box>
//                 </Box>
//               </Box>
//             </Box>
//           </Item>
//         </Grid>
//       );
//     })}
//   </Grid>
// </Box>
// }
