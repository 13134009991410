import React from "react";

function Groups({ clr }) {
  return (
    <svg
      width="auto"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96786 2.90112C9.44705 5.1793 10.9556 9.05933 13.5057 9.05933C16.0379 9.05933 17.5465 5.1793 17.0257 2.90112C16.6306 1.15689 15.0861 0 13.5057 0C11.9074 0 10.3809 1.15689 9.96786 2.90112ZM2.55087 4.50297C2.1019 6.42518 3.39494 9.71786 5.54999 9.71786C7.70505 9.71786 8.99808 6.42518 8.54911 4.50297C8.2079 3.02571 6.8969 2.029 5.54999 2.029C4.20308 2.029 2.89209 3.02571 2.55087 4.50297ZM18.4624 4.50297C18.0134 6.42518 19.3064 9.71786 21.4435 9.71786C23.5986 9.71786 24.8916 6.42518 24.4426 4.50297C24.1014 3.02571 22.7904 2.029 21.4435 2.029C20.1146 2.029 18.8036 3.02571 18.4624 4.50297ZM17.4028 24.8464L19.6476 17.1931C21.0484 12.7614 18.3726 10.0382 13.5057 10.0382C8.62095 10.0382 5.94509 12.7614 7.32791 17.1931L9.59072 24.8464C10.0038 26.1279 11.7099 27 13.5057 27C15.2657 27 16.9897 26.1279 17.4028 24.8464ZM6.44793 11.2663C5.56795 12.4588 4.72389 14.5234 5.69366 17.6559L7.70505 24.4015C7.09445 24.7574 6.32222 24.9532 5.54999 24.9532C4.0235 24.9532 2.58679 24.2235 2.22762 23.12L0.306025 16.6236C-0.861297 12.8682 1.40151 10.5722 5.54999 10.5722C6.03488 10.5722 6.51977 10.6078 6.96874 10.679C6.78915 10.857 6.60956 11.0705 6.44793 11.2663ZM21.4435 10.5722C25.592 10.5722 27.8728 12.8682 26.6875 16.6236L24.7659 23.12C24.4067 24.2235 22.97 24.9532 21.4435 24.9532C20.6713 24.9532 19.8991 24.7574 19.2885 24.4015L21.2819 17.6915C22.2696 14.5234 21.4256 12.4588 20.5276 11.2663C20.384 11.0705 20.2044 10.857 20.0248 10.679C20.4738 10.6078 20.9407 10.5722 21.4435 10.5722Z"
        fill={clr}
      />
    </svg>
  );
}

export default Groups;
