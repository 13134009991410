import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
import { ValidateAuth } from "../../../../AuthToken";
import { useNavigate } from "react-router";
const Certificates = ({ uid }) => {
  const [certificate, setCertificate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  useEffect(() => {
    // ValidateAuth();
    // if (!getCookie("roles").includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }

    setLoading(true);

    let dataex = JSON.stringify({
      userId: uid,
    });
    let configExInt = {
      method: "get",
      params: { userId: uid },
      url: `${IP}/userCourse/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((result) => {
      setLoading(false);
      if (result.data?.error) {
        return;
      }
      let certificateTemp = result.data?.filter(
        (item) => item.trainingType !== "Previous"
      );
      setCertificate(certificateTemp);
      setArrLength(certificateTemp.length);
    });
  }, []);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const handleStatusChange = (e, id) => {
    //console.log(e.target);
    //console.log(e.target.value);
    //console.log(certificate[id]);
    if (e.target.value == "Download") {
      window.open(certificate[id].certificateDownloadURL, "_blank");
    }
  };

  return (
    <div className="admin-overdue-bottom">
      <div className="admin-overdue-bottom-table">
        <div className="admin-overdue-bottom-table-cnt-user-view-t">
          <div className="admin-overdue-bottom-table-head">{t("Name")}</div>
          <div className="admin-overdue-bottom-table-head">
            {t("TrainingProvider")}
          </div>
          <div className="admin-overdue-bottom-table-head">
            {t("TypeOfTraining")}
          </div>
          <div className="admin-overdue-bottom-table-head">{t("IssuedOn")}</div>
          <div className="admin-overdue-bottom-table-head">{t("ExpiryOn")}</div>
          <div className="admin-overdue-bottom-table-head">{t("Action")}</div>
        </div>
        {certificate?.map((item, id) => {
          if (id < 5 * currentPage) return;
          if (id >= 5 * (currentPage + 1)) return;
          let issuedOn = Timestamp.fromMillis(item.issuedOn?._seconds * 1000)
            .toDate()
            .toDateString();
          let expiry = Timestamp.fromMillis(item.expiry?._seconds * 1000)
            .toDate()
            .toDateString();
          return (
            <div
              className="admin-overdue-bottom-table-cnt-user-view-t"
              key={id}
            >
              <div className="admin-overdue-bottom-table-td">
                {item.certificateName}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item.trainingProvider}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item.trainingType}
              </div>
              <div className="admin-overdue-bottom-table-td">{issuedOn}</div>
              <div className="admin-overdue-bottom-table-td">{expiry}</div>

              <div
                className="admin-overdue-bottom-table-td"
                style={{ color: "#004577" }}
              >
                <div>
                  <select
                    style={{
                      border: "none",
                      backgroundColor: "#F2F2F2",
                      height: "4vh",
                      width: "7vw",
                      padding: "0 1vh",
                      fontWeight: "700",
                      color: "#717579",
                      borderRadius: "6px",
                    }}
                    onChange={(e) => handleStatusChange(e, id)}
                  >
                    <option selected>{t("Action")}</option>
                    <option value="Download">{t("Download")}</option>
                    {/* <option>{t("Remind")}</option>
                    <option>{t("Retake")}</option>
                    <option>{t("GetCertificate")}</option> */}
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {arrLength == 0 && !loading && (
        <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
          No Records found
        </div>
      )}
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(arrLength / 5)}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Certificates;
