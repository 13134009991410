import { Box } from "@mui/material";
import React from "react";
import { flexCenter } from "../commonStyles/commonStyles";
import externalTraining from "../images/svgImages/external-training.svg";
import internalOfflineTraining from "../images/svgImages/internal-offline-training.svg";
import internalTrainingLms from "../images/svgImages/internal-training-lms.svg";

export const InternalTrainingLmsIcons = () => {
  return (
    <Box
      sx={{
        ...flexCenter,
        width: "50px",
        height: "50px",
        borderRadius: "10px",
        backgroundColor: "#007C84",
      }}
    >
      <img src={internalTrainingLms} />
    </Box>
  );
};
export const ExternalTrainingIcons = () => {
  return (
    <Box
      sx={{
        ...flexCenter,
        width: "50px",
        height: "50px",
        borderRadius: "10px",
        backgroundColor: "#007C84",
      }}
    >
      <img src={externalTraining} />
    </Box>
  );
};
export const InternalOfflineTrainingIcons = () => {
  return (
    <Box
      sx={{
        ...flexCenter,
        width: "50px",
        height: "50px",
        borderRadius: "10px",
        backgroundColor: "#007C84",
      }}
    >
      <img src={internalOfflineTraining} />
    </Box>
  );
};
