import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";
//import { SectionList } from "./strategiesdata.js";

import "./strategies.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { ColorPicker, useColor } from "react-color-palette";
import AdminloadingScreen from "../loadingadmin";
export const StrategiesEdit = () => {
  const [loadingopen, setloadingopen] = useState(false);

  const [SectionList, setSectionList] = useState([]);
  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([1, 2, 3, 4, 5, 6]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [color, setColor] = useColor("hex", "#121212");

  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);

  const [errorSms, setErrorSms] = useState("");
  const [strategiesdata, setStrategiesData] = useState({
    name: "",
    color: "",
    bgcolor: "",
  });

  console.log(strategiesdata);

  const location = useLocation();

  useEffect(() => {
    let sta = location.state;
    if (sta) {
      setStrategiesData(sta);
      setloadingopen(true);
      var config = {
        method: "get",
        params: { id: sta.id },

        url: `${IP}/strategies/section`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "data",
      };

      axios(config)
        .then((res) => {
          setLoading(false);
          setloadingopen(false);
          //console.log(res);
          if (res.data?.error) {
          } else {
            setSectionList(res.data);
            // settemplate(res.data);
          }
        })
        .catch((err) => {
          setloadingopen(false);
          setLoading(false);
          //console.log(err);
        });
    }
  }, []);
  // delete company

  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const checkFormData = () => {
    if (
      strategiesdata.bgcolor == "" ||
      strategiesdata.color == "" ||
      strategiesdata.name == ""
    ) {
      return "enter all the required data";
    }
    return true;
  };

  const createstrategies = () => {
    let check = checkFormData();
    if (check != true) {
      setErrorSms(check);
      return;
    }
    setErrorSms("");
    setLoading1(true);
    setloadingopen(true);
    const data = JSON.stringify(strategiesdata);
    var config = {
      method: strategiesdata?.id ? "patch" : "post",

      url: `${IP}/strategies`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        setloadingopen(false);
        navigate(-1);
        setLoading1(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading1(false);
        setloadingopen(false);
      });
  };

  //================make default settings===========================//
  const makeCompanyDefault = httpsCallable(functions, "makeCompanyDefault");
  const makeDepartmentDefault = httpsCallable(
    functions,
    "makeDepartmentDefault"
  );

  //================create Department===========================//

  const navigate = useNavigate();

  const deletesection = (ele) => {
    console.log(ele);
    setloadingopen(true);
    const data = JSON.stringify(ele);
    var config = {
      method: "delete",

      url: `${IP}/strategies/section`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((el) => {
        // console.log("");
        setloadingopen(false);
      })
      .catch((er) => {
        setloadingopen(false);
      });
  };
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Strategies")} />
        {/* <Navbar /> */}
        <AdminloadingScreen open={loadingopen} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              {/* Company settings */}
              <div className="editaddstrategiesheding"> Strategies name</div>

              <div
                style={{
                  background: "#FFFFFF",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Strategies Heading
                    </div>
                    <div>
                      {" "}
                      <input
                        value={strategiesdata.name}
                        onChange={(e) => {
                          setStrategiesData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="text"
                        placeholder="Strategies Heading"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Strategies Colour
                    </div>
                    <div>
                      {" "}
                      <input
                        value={strategiesdata.color}
                        onChange={(e) => {
                          setStrategiesData((prev) => ({
                            ...prev,
                            color: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="text"
                        placeholder="Strategies Colour"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Background Colour
                    </div>
                    <div>
                      {" "}
                      <input
                        value={strategiesdata.bgcolor}
                        onChange={(e) => {
                          setStrategiesData((prev) => ({
                            ...prev,
                            bgcolor: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="text"
                        placeholder=" Background Colour"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "24px",
                    borderRadius: "5px",
                    color: "red",
                  }}
                >
                  {" "}
                  {errorSms}
                </div>
                <div>
                  {" "}
                  <Button
                    onClick={createstrategies}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </div>
                <div style={{ marginTop: "24px", marginLeft: "20vw" }}>
                  {" "}
                  <ColorPicker
                    width={456}
                    height={150}
                    color={color}
                    onChange={setColor}
                    hideHSV
                    dark
                  />{" "}
                </div>
              </div>
              <div
                className="editaddstrategiesheding"
                style={{
                  marginTop: "70px",
                }}
              >
                List of Section
              </div>
              <div
                style={{
                  background: "#FFFFFF",
                  padding: "5px 10px 10px 10px",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="tabellistinstrategies tabellistinstrategiesFont"
                  style={{
                    marginTop: "20px",
                    gridTemplateColumns: "1fr 3fr 3fr 3fr 2fr",
                  }}
                >
                  <div>S.No.</div>
                  <div>Section Topic</div>
                  <div> Section Description</div>
                  <div> File</div>

                  <div>Action</div>
                </div>

                {SectionList?.map((ele, index) => {
                  return (
                    <div
                      className="tabellistinstrategies tabellistinstrategiesFontFortab"
                      style={{
                        gridTemplateColumns: "1fr 3fr 3fr 3fr 2fr",
                        lineBreak: "anywhere",
                        justifyItems: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>{index}</div>
                      <div>{ele.topic}</div>
                      <div>{ele.description}</div>
                      <div>
                        {" "}
                        <iframe
                          src={ele.file}
                          frameborder="0"
                          width={200}
                          height={50}
                        ></iframe>
                        {/* <button
                        onClick={() => {
                          window.open(
                            "https://www.youtube.com/watch?v=3nRJsANxLGg",
                            "_blank"
                          );
                        }}
                      >
                        open new
                      </button> */}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Link
                          to={{
                            pathname: "/admin/settings/Strategies_Add_Section",
                            state: { fromDashboard: "it is first" },
                          }}
                          state={ele}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{
                              marginRight: "10px",
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Edit
                          </Button>
                        </Link>

                        <Link
                          to={{
                            pathname: "/admin/settings/StrategiesEdit",
                            state: { fromDashboard: "it is first" },
                          }}
                          state={ele}
                        >
                          <Button
                            onClick={() => {
                              deletesection(ele);
                            }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Delete
                          </Button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
