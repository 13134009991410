import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "./strategies.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { arr } from "./strategiesdata.js";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
export const StrategiesList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company
  const deletetemplate = (id) => {
    //console.log(id);
    deleteCompany({ companyId: id }).then((res) => {
      //console.log(res);
      alert("Company Deleted");
      setdeltemp(!deltemp);
    });
  };
  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/strategies`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const deletedata = (ele) => {
    setLoading(true);
    const data = JSON.stringify(ele);
    var config = {
      method: "delete",

      url: `${IP}/strategies`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        fetchdata();
        //console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };
  //================make default settings===========================//

  //================create Department===========================//

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Strategies")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <div className="create-new-box">
                <Link
                  to={{ pathname: "/admin/settings/Strategies_Add_Section" }}
                >
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      marginRight: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Section
                  </Button>
                </Link>

                <Link to={{ pathname: "/admin/settings/StrategiesEdit" }}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Strategies
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  background: " #FFFFFF",
                  borderRadius: "4px",
                  padding: "20px",
                }}
              >
                <div className="tabellistinstrategies tabellistinstrategiesFont">
                  <div> S.No</div>
                  <div>Strategies Name</div>
                  <div> Colour</div>
                  <div> Background Colour</div>
                  <div>No.of.section</div>

                  <div>Action</div>
                </div>

                {template?.map((ele, index) => {
                  return (
                    <div
                      className="tabellistinstrategies tabellistinstrategiesFontFortab borderStyleFortabel"
                      style={{ minHeight: "50px" }}
                    >
                      <div>{index}</div>
                      <div>{ele.name}</div>
                      <div style={{ margin: "auto" }}>
                        <div
                          style={{
                            backgroundColor: `${ele.color}`,
                            border: `15px solid ${ele.color}`,
                            width: "50px",
                            height: "90%",
                          }}
                        ></div>
                      </div>
                      <div style={{ margin: "auto" }}>
                        <div
                          style={{
                            backgroundColor: "#8FE9D2",
                            border: `15px solid ${ele.bgcolor}`,

                            width: "50px",
                            height: "90%",
                          }}
                        ></div>
                      </div>
                      <div>{ele.count}</div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Link
                          to={{
                            pathname: "/admin/settings/StrategiesEdit",
                            state: { fromDashboard: "it is first" },
                          }}
                          state={ele}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Edit
                          </Button>
                        </Link>

                        <Button
                          onClick={() => {
                            deletedata(ele);
                          }}
                          variant="contained"
                          component="span"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
