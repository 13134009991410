import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import CoursesImg from "../images/courses-img.png";
import MicroLearnings from "../images/micro-learnings.png";
import { CertificateDownloadIcon, RightArrowIcon } from "../icons/Icons";
import { useNavigate } from "react-router";
import "./hovereffect.css";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import couseicon from "./Vector(1).svg";
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
import { functions } from "../../Firebase";
import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../AuthToken";
import HrFooter from "../Sidebar/HrFooter";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import "./learning.css";
import MobileTopBar from "../Sidebar/MobaileTopBar";
const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const ViewCoursesAll = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const { rolesAndPermission, setRolesAndPermission, course_Completion } =
    useContext(DrawerContext);
  useEffect(() => {
    // ValidateAuth();
    let newobj =
      rolesAndPermission?.Learning?.componentPermission.includes("Courses");
    console.log(newobj);
    // console.log(newobj.HRDI);
    if (!newobj) {
      // goToHome();
      return <></>;
    }
  }, []);
  const courseLibrary = httpsCallable(functions, "courseLibrary");
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);

  const uid = getCookie("UID");

  const [courses, setCourses] = useState([]);
  const [certificates, setCertificates] = useState(null);
  const [searchCoursess, setSearchCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);

  var list = [];

  async function fetchCourses() {
    setLoading(true);

    let configrecrut = {
      method: "get",

      url: `${IP}/userCourse/courseLibrary`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      console.log(res.data.length);
      setCourses(res.data.filter((e) => e.archived != true));
      setSearchCourses(res.data.filter((e) => e.archived != true));
      setLoading(false);
    });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }

  useEffect(() => {
    if (viewAll) {
      setSearchCourses(courses);
    }
  }, [viewAll]);

  useEffect(() => {
    // if (
    //   getCookie("UID") == null ||
    //   getCookie("ROLE") !== "USER" ||
    //   getCookie("ROLE") == "ADMIN"
    // ) {
    //   goToHome();
    //   return <></>;
    // }
    fetchCourses();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const resetItemProgress = httpsCallable(functions, "resetItemProgress");
  //resetItemProgress

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState([]);

  useEffect(() => {
    fetch_certificates({}).then((res) => {
      if (res.data.error) {
        return;
      }
      setCertificates(res.data.filter((ele) => ele.trainingType == "Internal"));
    });
    fetch_user_courses({ select: 1 })
      .then((res) => {
        // //console.log(res.data);
        if (res.data.error) {
          return;
        }
        // setUserCourses([...res.data]);
        // const temp = [];
        // res.data.forEach((course) => {
        //   temp.push(course.courseId);
        // });
        setUserCourses(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  // //console.log(user_courses);

  const searchCourse = (e) => {
    const search = e.target.value;
    const filteredCourses = courses.filter((course) => {
      return course.courseName?.toLowerCase().includes(search.toLowerCase());
    });
    setSearchCourses(filteredCourses.slice(0, 6));
  };

  ////===================endroll here========================//
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  //check single course to direct enroll
  const enrollUser = (courseId, access, status, item) => {
    // userEnrollment({ courseId: courseId, currentDate: new Date() })
    //   .then((res) => {
    //     //console.log(res.data);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    console.log("item-----------", item);
    navigate(`/hr/Learning/course-content/${courseId}`, {
      state: { ...item },
    });
  };

  const renewCourse = (courseId) => {
    // get items array and reset it then goto course
    getItemsArray({ courseId: courseId }).then((res) => {
      //console.log(res.data);
      resetItemProgress({ courseId: courseId, itemArray: res.data });
    });
    navigate(
      `/user/course-library/single-course-overview/single-course-content/${courseId}`
    );
  };

  return (
    <Box className="hrmainbox" sx={{ backgroundColor: "#F3F1FF" }}>
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      <Box sx={{ width: "100%" }}>
        <MobileTopBar />

        <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              {" "}
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E1F566",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                {course_Completion}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Course completed{" "}
              </Box>
            </Box>
          </Box>
        </HrTopNavbar>

        <Box
          sx={{
            width: "100%",
            padding: "24px",

            "@media (max-width: 780px)": {
              padding: "10px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box>
              {" "}
              <img src={couseicon} alt="" />
            </Box>
            <Box>Courses</Box>
          </Box>
          <Box sx={{ width: "100%", mt: "20px", minHeight: "100vh" }}>
            <Box
              // container
              // rowSpacing={10}
              // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                gridTemplateColumns: "1fr 1fr",
                display: "grid",
                gridGap: "30px",
                "@media (max-width: 780px)": {
                  gridTemplateColumns: "1fr",
                  gridGap: "60px",
                },
              }}
            >
              {console.log(searchCoursess)}
              {searchCoursess?.map((item) => {
                let status = (() => {
                  let courseRef = user_courses.find(
                    (ele) => ele.courseId == item.courseId
                  );

                  let certificateRef = certificates?.find(
                    (ele) => ele.courseId == item.courseId
                  );
                  //console.log(certificateRef, certificates);
                  if (certificateRef) {
                    let isValid = Boolean(
                      Math.floor(new Date().getTime() / 1000) <
                        certificateRef.expiry._seconds
                    );
                    //console.log(isValid, certificateRef.certificateName);
                    if (isValid) return "Completed";
                    if (
                      courseRef?.progress?.completed !=
                      courseRef?.progress.total
                    )
                      return "Continue";
                    else return "Renew";
                  } else if (courseRef?.progress.completed == "0")
                    return "Start";
                  else return "Continue";
                })();

                return (
                  <Grid item xs={6}>
                    <Item
                      sx={{
                        borderRadius: "24px",
                        backgroundColor: "#F5F5F5",
                        mt: "40px",
                        "@media (max-width: 780px)": {
                          mt: "20px",
                        },
                      }}
                    >
                      <Box sx={{ width: "100%" }} className="zoom">
                        <img
                          style={{
                            width: "100%",
                            borderRadius: "20px",
                            height: "354px",
                          }}
                          src={item?.courseImageURL}
                          alt=""
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "-10%",
                          left: 0,
                          backgroundColor: "#E1F566",
                          width: "60%",
                          "@media (max-width: 780px)": {
                            width: "100%",
                            bottom: "-10%",
                          },
                          borderRadius: "24px",
                          padding: "24px",
                          display: "grid",

                          minHeight: "192px",
                          maxHeight: "192px",
                          height: "192px",
                          overflow: "hidden",
                          gridTemplateRows: "80% 20%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          className="coraenameinLerningpage"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          {item?.courseName}
                        </Typography>

                        <Box
                          sx={{
                            mt: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => {
                              enrollUser(
                                item.courseId,
                                item.access,
                                status,
                                item
                              );
                              //navigate("course-content")
                            }}
                            className="startcoursetest"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {status} <RightArrowIcon />
                          </Button>
                        </Box>
                      </Box>
                    </Item>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Box>
        <HrFooter />
      </Box>
    </Box>
  );
};
export default ViewCoursesAll;
