import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";
import "./Print.css";

import { IP } from "../../../../baseUrlAndConfig";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";
import { getCookie } from "../../../../Cookies";
import { useNavigate } from "react-router";

const RetentionPrint = () => {
  const [list, setList] = useState([]);
  const companyId = localStorage.getItem("companyId");

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [genderList, setgenderList] = useState([
    { name: "Junior" },
    { name: "MidLevel" },
    { name: "Senior" },
  ]);
  const Item = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
  }));

  const Item3 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
    // padding: "20px 15px",
  }));

  const [
    OverallGenderRepresentationState,
    setOverallGenderRepresentationState,
  ] = useState([]);

  const [overallgender, setOverallgender] = useState([]);

  const [bussinessvertical, setBussinessvertical] = useState([]);
  const [genderbyRegionStates, setgenderbyRegionStates] = useState([]);
  const [genderbyBusinessVerticalstate, setGenderbyBusinessVerticalstate] =
    useState([]);

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    //setgenderbyRegionStates(ex);

    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setBussinessvertical(res.data);
    });

    let configregiony = {
      method: "get",

      url: `${IP}/retention?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregion",
    };

    axios(configregiony)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setList((prev) => prev.concat({ year: key }));
        }
        setGenderbyBusinessVerticalstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(genderbyBusinessVerticalstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/retention?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (genderbyBusinessVerticalstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }

    setOverallgender((prev) => ({
      ...prev,

      retentionbusinessvertical: genderbyBusinessVerticalstate
        ? genderbyBusinessVerticalstate
        : [],
    }));
    // setgenderbyRegionStates(ex);
  }, [genderbyBusinessVerticalstate]);

  useEffect(() => {
    // console.log(overallgender);
    // let ls = overallgender.OverallGenderRepresentationState
    //   ? overallgender.OverallGenderRepresentationState
    //   : [];

    // setList([]);

    // for (let i = 0; i < ls.length; i++) {
    //   setList((prev) => prev.concat({ year: ls[i]?.year }));
    //   // setListbuver((prev) => prev.concat({ year: ls[i]?.year }));
    // }

    let recrut = overallgender.retentionbusinessvertical
      ? overallgender.retentionbusinessvertical
      : [];
    let newobj = {};
    for (let i = 0; i < recrut.length; i++) {
      newobj[recrut[i]?.year] = "af";
    }
    for (let key in newobj) {
      setList((prev) => prev.concat({ year: key }));
    }

    setOverallGenderRepresentationState(
      overallgender.OverallGenderRepresentationState
        ? overallgender.OverallGenderRepresentationState
        : []
    );
    setGenderbyBusinessVerticalstate(
      overallgender.retentionbusinessvertical
        ? overallgender.retentionbusinessvertical
        : []
    );

    // setgenderbyRegionStates(ex);
  }, []);

  return (
    <Box className="pagebreak" sx={{ backgroundColor: "#EEECFF" }}>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",

          paddingTop: "20px",
        }}
      >
        <HrTopNavbar title={"Retention"} index={3}></HrTopNavbar>
      </Box>

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "24px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          1
        </Box>{" "}
        Number of exits
      </Box>

      <Box
        className="tablebgColorForHr"
        sx={{ padding: "0px 20px", mt: "20px" }}
      >
        {list.map((rclist, ind) => {
          let z = 0;
          return (
            <Box className="pagebreak" sx={{ mt: "15px" }}>
              {console.log("line434", rclist)}
              <Box style={{ display: "flex" }}>
                <h1>{rclist.year}</h1>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {genderbyBusinessVerticalstate?.map((elementList, i) => {
                      if (elementList.year == rclist.year) {
                        z += 1;
                        return (
                          <Grid
                            item
                            xs={6}
                            className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                            sx={{ mb: "10px" }}
                          >
                            <Item3>
                              <Box className="genderbyRegionTableContent">
                                <Stack spacing={2}>
                                  <Box>
                                    {" "}
                                    <Box
                                      sx={{ display: "flex", fontSize: "20px" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          color: "#666",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Business Vertical -&nbsp;
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          color: "black",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {elementList.businessVertical}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                    gridGap: "10px",
                                    // padding: "20px",
                                  }}
                                >
                                  {genderList?.map((el) => (
                                    <Box
                                      style={{
                                        marginTop: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Box>
                                        <Box> {el.name} </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Male{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.male}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Female{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.female}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Non-binary{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={
                                              elementList[el.name]?.nonBinary
                                            }
                                          />{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Item3>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RetentionPrint;
