import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "./Vector.svg";
import logo from "../../utilities/images/sereinLogo.svg";
import manu from "./manu.svg";
import closeicon from "./closeicon.svg";
import "./mobile.css";
import { Link, useLocation } from "react-router-dom";
import { CommunicationsWhiteIcon, CultureIndexIcon, DEI, LearningIcon, ProfileIcon, ProfileWhiteIcon, PsychologicalICON } from "../icons/Icons";
import { DrawerContext } from "../../utilities/context/DrawerProvider";


const MobileTopBar = ({ children, title, sidebar }) => {

  const [mobileOpened, setMobileOpened] = useState(false)

  const [hideImmediate, setHideImmediate] = useState(false)

  useEffect(() => {
    setHideImmediate(true)
    setMobileOpened(false)
  }, [])

  const { rolesAndPermission, setRolesAndPermission } = React.useContext(DrawerContext);


  return (
    <div className="mainMobileMenuBar">
      <Box
        className="MobileTopBar"
        sx={{
          justifyContent: "space-between",
          gap: "20px",
          background: "#6846C7",
          alignItems: "center",
          alignContent: "center",
          justifyItems: "center",
          height: "64px",
          padding: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            fontSize: "40px",
            fontWeight: 600,
            fontFamily: "Poppins",
            display: "flex",
          }}
        >
          <img src={logo} alt="" />
        </Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMobileOpened(prev => !prev)
            setHideImmediate(false)
          }}
        >
          {" "}
          {mobileOpened ?
            (
              <img src={closeicon} alt="" />
            )
            : (
              <img src={manu} alt="" />
            )
          } 
        </Box>
      </Box>
      <div className="mobileMenuItems"
        style={{
          display: (hideImmediate && mobileOpened) ? "none" : "flex",
          transform: mobileOpened ? "translateY(0)" : "translateY(-500px)"
        }}
      >
        {rolesAndPermission?.DEI?.viewPermission && <Link to={"/hr"} className={`hrMobileNavMenuItem ${sidebar === "Diversity" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <CultureIndexIcon />
          </Box>
          <Typography>
            Diversity
          </Typography>
        </Link>
        }
        {rolesAndPermission?.Psycological_Study?.viewPermission  && <Link to={"/hr/Psychological_safety"} className={`hrMobileNavMenuItem ${sidebar === "Inclusion" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <PsychologicalICON />
          </Box>
          <Typography>
            Inclusion
          </Typography>
        </Link>}
        {rolesAndPermission?.Learning?.viewPermission  && <Link to={"/hr/Learning"} className={`hrMobileNavMenuItem ${sidebar === "Learning" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <LearningIcon />
          </Box>
          <Typography>
            Learning
          </Typography>
        </Link>}
        {rolesAndPermission?.Report?.viewPermission  && <Link to={"/hr/GroupReport"} className={`hrMobileNavMenuItem ${sidebar === "Progress-Report" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <CommunicationsWhiteIcon />
          </Box>
          <Typography>
            Progress Report
          </Typography>
        </Link>}
        {rolesAndPermission?.Calender?.viewPermission  && <Link to={"/hr/DEI"} className={`hrMobileNavMenuItem ${sidebar === "DEI-Calendar" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <DEI />
          </Box>
          <Typography>
            DEI Calendar
          </Typography>
        </Link>}
        {rolesAndPermission?.Profile?.viewPermission  && <Link to={"/hr/HrProfilePage"} className={`hrMobileNavMenuItem ${sidebar === "Profile" ? "active" : ""}`} >
          <Box className="hrMobileMenuIcon">
            <ProfileIcon />
          </Box>
          <Typography>
            Profile
          </Typography>
        </Link>}
      </div>
    </div>
  );
};

export default MobileTopBar;
