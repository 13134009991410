import { display } from "@mui/system";
import { useEffect, useState } from "react";
import AddYearsPopUp from "./AddyearsPopUp";
import "./table.css";

import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";

import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import DeletePopUpForDI from "./deletePop";

const Item = styled(Paper)(({ theme }) => ({
  background: "#FFF",
  textAlign: "center",
  borderRadius: "20px",
}));

export default function Recruitment({
  overallgender,
  setOverallgender,
  overAllFlag,
  companyId,
}) {
  const [list, setList] = useState([0, 0, 0, 0]);
  const [recievedList, setrecievedList] = useState([]);
  const [shortlistedList, setshortlistedList] = useState([]);
  const [recruitedList, setrecruitedList] = useState([]);
  const [bussinessvertical, setBussinessvertical] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [genderList, setgenderList] = useState([
    { name: "Junior" },
    { name: "MidLevel" },
    { name: "Senior" },
  ]);
  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);

  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // if (!newobj.HRDI?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);

  const [
    OverallGenderRepresentationState,
    setOverallGenderRepresentationState,
  ] = useState([]);

  const [
    numberofCandidatesShortlistedstate,
    setNumberofCandidatesShortlistedstate,
  ] = useState([]);

  const [
    numberofCandidatesRecruitedstate,
    setNumberofCandidatesRecruitedstate,
  ] = useState([]);

  const [
    numberofCandidatesOfferlaterstate,
    setNumberofCandidatesOfferlaterstate,
  ] = useState([]);

  const [genderbyBusinessVerticalstate, setGenderbyBusinessVerticalstate] =
    useState([]);

  const addGenderbyBusinessVertical = (ind) => {
    if (!recievedList[ind]?.year) {
      alert("select year");
      return;
    }

    let dataregionf = JSON.stringify({
      year: recievedList[ind].year,

      date: Date.now(),
      businessVertical: "",

      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let configregiony = {
      method: "post",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    axios(configregiony)
      .then((res) => {})
      .catch((er) => {
        console.log(er);
      });
    setGenderbyBusinessVerticalstate((prev) =>
      prev.concat({
        year: recievedList[ind].year,

        date: Date.now(),
        businessVertical: "",

        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };

  const addNumberofCandidatesShortlisted = (ind) => {
    if (!shortlistedList[ind].year) {
      alert("select year first");
      return;
    }

    let dataregionf = JSON.stringify({
      year: shortlistedList[ind].year,

      date: Date.now(),
      businessVertical: "",

      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let configregiony = {
      method: "post",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    axios(configregiony)
      .then((res) => {})
      .catch((er) => {
        console.log(er);
      });
    setNumberofCandidatesShortlistedstate((prev) =>
      prev.concat({
        year: shortlistedList[ind].year,

        date: Date.now(),
        businessVertical: "",

        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };

  const addNumberofCandidatesRecruited = (ind) => {
    if (!recruitedList[ind].year) {
      alert("select year first");
      return;
    }

    let dataregionf = JSON.stringify({
      year: recruitedList[ind].year,

      date: Date.now(),
      businessVertical: "",

      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let configregiony = {
      method: "post",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    axios(configregiony)
      .then((res) => {})
      .catch((er) => {
        console.log(er);
      });
    setNumberofCandidatesRecruitedstate((prev) =>
      prev.concat({
        year: recruitedList[ind].year,

        date: Date.now(),
        businessVertical: "",

        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };

  const addNumberofOfferlater = (ind) => {
    if (!offerList[ind].year) {
      alert("select year first");
      return;
    }

    let dataregionf = JSON.stringify({
      year: offerList[ind].year,

      date: Date.now(),
      businessVertical: "",

      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let configregiony = {
      method: "post",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    axios(configregiony)
      .then((res) => {})
      .catch((er) => {
        console.log(er);
      });

    setNumberofCandidatesOfferlaterstate((prev) =>
      prev.concat({
        year: offerList[ind].year,

        date: Date.now(),
        businessVertical: "",

        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };
  // useEffect(() => {
  //   setgenderbyRegionStates(ex);
  // }, [ex]);

  const openPopUpForAddYears = () => {
    setOpenpopUpForAddYears(true);
  };
  const addYearsFunPopUp = (Years) => {
    console.log(Years);

    setOverallGenderRepresentationState((prev) =>
      prev.concat({
        year: Years,
        male: "",
        female: "",
        nonBinary: "",
      })
    );
    setOpenpopUpForAddYears(false);
  };
  console.log(OverallGenderRepresentationState);

  useEffect(() => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setBussinessvertical(res.data);
    });

    let configregiony = {
      method: "get",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configregiony)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setrecievedList((prev) => prev.concat({ year: key }));
        }
        setGenderbyBusinessVerticalstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configSort = {
      method: "get",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configSort)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setshortlistedList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesShortlistedstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configoffer = {
      method: "get",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configoffer)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setOfferList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesOfferlaterstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configrecrut = {
      method: "get",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setrecruitedList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesRecruitedstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(genderbyBusinessVerticalstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (genderbyBusinessVerticalstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [genderbyBusinessVerticalstate]);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesShortlistedstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesShortlistedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [numberofCandidatesShortlistedstate]);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesRecruitedstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesRecruitedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [numberofCandidatesRecruitedstate]);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesOfferlaterstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesOfferlaterstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [numberofCandidatesOfferlaterstate]);

  useEffect(() => {
    setOverallgender((prev) => ({
      ...prev,

      recrotmentbusinessvertical: genderbyBusinessVerticalstate
        ? genderbyBusinessVerticalstate
        : [],
      recrotmentnumberofCandidatesShortlistedstate:
        numberofCandidatesShortlistedstate
          ? numberofCandidatesShortlistedstate
          : [],

      recrotmentnumberofCandidatesRecruitedstate:
        numberofCandidatesRecruitedstate
          ? numberofCandidatesRecruitedstate
          : [],
      recnumberofCandidatesOfferlaterstate: numberofCandidatesOfferlaterstate
        ? numberofCandidatesOfferlaterstate
        : [],
    }));
    // setgenderbyRegionStates(ex);
  }, [
    genderbyBusinessVerticalstate,
    numberofCandidatesRecruitedstate,
    numberofCandidatesShortlistedstate,
    OverallGenderRepresentationState,
    numberofCandidatesOfferlaterstate,
  ]);

  useEffect(() => {
    console.log(overallgender);
    let ls = overallgender.OverallGenderRepresentationState
      ? overallgender.OverallGenderRepresentationState
      : [];

    // setList([]);
    // setrecievedList([]);
    // setshortlistedList([]);
    // setrecruitedList([]);
    // setOfferList([]);
    for (let i = 0; i < ls.length; i++) {
      // setrecievedList((prev) => prev.concat({ year: ls[i]?.year }));
      //  setshortlistedList((prev) => prev.concat({ year: ls[i]?.year }));
      // setrecruitedList((prev) => prev.concat({ year: ls[i]?.year }));
      // setOfferList((prev) => prev.concat({ year: ls[i]?.year }));
      // setListbuver((prev) => prev.concat({ year: ls[i]?.year }));
    }

    // let rc = overallgender.recrotmentbusinessvertical
    //   ? overallgender.recrotmentbusinessvertical
    //   : [];
    // let obj = {};
    // for (let i = 0; i < rc.length; i++) {
    //   obj[rc[i]?.year] = "af";
    // }
    // for (let key in obj) {
    //   setrecievedList((prev) => prev.concat({ year: key }));
    // }
    setOverallGenderRepresentationState(
      overallgender.OverallGenderRepresentationState
        ? overallgender.OverallGenderRepresentationState
        : []
    );
    // setGenderbyBusinessVerticalstate(
    //   overallgender.recrotmentbusinessvertical
    //     ? overallgender.recrotmentbusinessvertical
    //     : []
    // );

    // let recrut = overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   ? overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   : [];
    // let newobj = {};
    // for (let i = 0; i < recrut.length; i++) {
    //   newobj[recrut[i]?.year] = "af";
    // }
    // for (let key in newobj) {
    //   setrecruitedList((prev) => prev.concat({ year: key }));
    // }
    // setNumberofCandidatesRecruitedstate(
    //   overallgender.recrotmentnumberofCandidatesRecruitedstate
    //     ? overallgender.recrotmentnumberofCandidatesRecruitedstate
    //     : []
    // );

    // let sortd = overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   ? overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   : [];
    // let sortobj = {};
    // for (let i = 0; i < sortd.length; i++) {
    //   sortobj[sortd[i]?.year] = "af";
    // }
    // for (let key in sortobj) {
    //   setshortlistedList((prev) => prev.concat({ year: key }));
    // }
    // setNumberofCandidatesShortlistedstate(
    //   overallgender.recrotmentnumberofCandidatesShortlistedstate
    //     ? overallgender.recrotmentnumberofCandidatesShortlistedstate
    //     : []
    // );

    // let offerl = overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   ? overallgender.recrotmentnumberofCandidatesRecruitedstate
    //   : [];
    // let offerobj = {};
    // for (let i = 0; i < offerl.length; i++) {
    //   offerobj[offerl[i]?.year] = "af";
    // }
    // for (let key in offerobj) {
    //   setOfferList((prev) => prev.concat({ year: key }));
    // }
    // setNumberofCandidatesOfferlaterstate(
    //   overallgender.recnumberofCandidatesOfferlaterstate
    //     ? overallgender.recnumberofCandidatesOfferlaterstate
    //     : []
    // );
  }, [overAllFlag]);
  //console.log(Object.keys(curvalue)[0])

  // useEffect(() => {}, []);
  const deleteYearData = (input) => {
    let temparr = genderbyBusinessVerticalstate.filter(
      (ele) => ele.date != input.date
    );

    let dataregionf = JSON.stringify(temparr);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (genderbyBusinessVerticalstate?.length > 0) {
      axios(configregiony)
        .then((res) => {
          setGenderbyBusinessVerticalstate(temparr);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  const deleteshortlisted = (inputdata) => {
    let temparr = numberofCandidatesShortlistedstate?.filter(
      (el) => el.date != inputdata.date
    );
    let dataregionf = JSON.stringify(temparr);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesShortlistedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {
          setNumberofCandidatesShortlistedstate(temparr);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  const deleteofferlater = (inputdata) => {
    let tempdata = numberofCandidatesOfferlaterstate?.filter(
      (el) => el.date != inputdata.date
    );
    let dataregionf = JSON.stringify(tempdata);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesOfferlaterstate?.length > 0) {
      axios(configregiony)
        .then((res) => {
          setNumberofCandidatesOfferlaterstate(tempdata);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  const deleteRecruited = (inputdata) => {
    let tempdata = numberofCandidatesRecruitedstate?.filter(
      (ele) => ele.date != inputdata.date
    );

    let dataregionf = JSON.stringify(tempdata);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesRecruitedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {
          setNumberofCandidatesRecruitedstate(tempdata);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };
  const deleteFunctionCallFromUi = (data, type) => {
    // console.log(data);
    let newobj = { ...data, type };
    setDeleteId(newobj);
    setopenLessonPopUp(true);
  };
  const delfunction = (name) => {
    if (name.type == "bussiness") {
      deleteYearData(name);
    } else if (name.type == "shortlisted") {
      deleteshortlisted(name);
    } else if (name.type == "offerlater") {
      deleteofferlater(name);
    } else if (name.type == "Recruited") {
      deleteRecruited(name);
    }
  };

  const checkAlreadyVertical =(paramArray,vertical,year)=>{

    // genderbyBusinessVerticalstate?.map((_,idx)=>{

    // })
    const vertArr = [];
    for(let i=0;i<paramArray?.length;i++){
      if(paramArray[i].year == year){
        vertArr.push(paramArray[i].businessVertical);
      }
    }
    const outputArr = [];
    bussinessvertical?.map((_,idx)=>{
      if(!vertArr.includes(_)){
        outputArr.push(_);
      }
    })
    outputArr.push(vertical)

    return outputArr;


    
  }

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Recruitment"}>
          {/* <Box
          sx={{
            color: "#6846C7",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Manrope",
          }}
        >
          Save & Exit
        </Box> */}
        </HrTopNavbar>
      </Box>

      <AddYearsPopUp
        open={openpopUpForAddYears}
        onClose={() => setOpenpopUpForAddYears(false)}
        delfun={(topic) => {
          addYearsFunPopUp(topic);
        }}
      />

      <DeletePopUpForDI
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteId}
        itemName={"Lesson"}
      />

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "24px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          1
        </Box>{" "}
        Number of Resumes/CVs received
      </Box>

      <Box
        className="tablebgColorForHr"
        sx={{ width: "98%", padding: "0px 20px", mt: "15px", ml: "24px" }}
      >
        {recievedList.map((rclist, ind) => (
          <Box sx={{ mb: "40px" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                {" "}
                <Select
                  name="se"
                  sx={{ height: "55px" }}
                  className="select-year"
                  onChange={(e) => {
                    // ctonsole.log(e.target.value);
                    // le data = recievedList;
                    // data[ind] = { year: e.target.value };

                    setrecievedList((prev) => {
                      return prev.filter((ele, inx) => {
                        if (inx == ind) {
                          ele.year = e.target.value;
                        }
                        return ele;
                      });
                    });
                    // setGenderbyBusinessVerticalyear(e.target.value);
                  }}
                  value={rclist.year}
                >
                  <MenuItem value={""}>Select year</MenuItem>

                  {OverallGenderRepresentationState?.map((e) => (
                    <MenuItem value={e?.year}> {e?.year}</MenuItem>
                  ))}
                </Select>{" "}
              </Box>
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="tableHightandSizeForThreeSections"
              sx={{ mt: "15px" }}
            >
              {genderbyBusinessVerticalstate?.map((elementList) => {
                if (elementList.year == rclist.year) {
                  return (
                    <Grid item xs={6}>
                      <Item>
                        <Box
                          className="genderbyRegionTableContent"
                          sx={{ paddingBottom: "1px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "#BCBCBC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteFunctionCallFromUi(
                                elementList,
                                "bussiness"
                              );
                            }}
                          >
                            {/* <DeleteIcon /> */}

                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                fill="#707EAE"
                              />
                            </svg>
                          </Box>
                          <Box style={{ margin: "20px" }}>
                            <Box>
                              <Box>
                                {" "}
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label80">
                                    Select business vertical
                                  </InputLabel>
                                  <Select
                                    label="Select business vertical"
                                    labelId="demo-simple-select-label80"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      sx: {
                                        maxHeight: "250px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                    sx={{
                                      width: "260px",
                                      height: "55px",
                                      fontSize: "18px",
                                      backgroundColor: "transparent",
                                    }}
                                    value={elementList?.businessVertical}
                                    onChange={(e) => {
                                      setGenderbyBusinessVerticalstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == rclist.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue.businessVertical =
                                                  e.target.value;
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {checkAlreadyVertical(genderbyBusinessVerticalstate,elementList?.businessVertical,rclist.year).map((el)=>{
                                      return(<MenuItem value={el}>{el}</MenuItem>)
                                    })}
                                    {/* {bussinessvertical?.map((el) => (
                                      <MenuItem value={el}>{el}</MenuItem>
                                    ))} */}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gridGap: "20px",
                            padding: "0px 20px 20px 20px",
                          }}
                        >
                          {genderList?.map((el) => (
                            <Box
                              style={{
                                marginTop: "10px",
                                textAlign: "center",
                              }}
                            >
                              <Box>
                                <Box> {el.name} </Box>
                              </Box>
                              <Box className="genderBox">
                                <Box className="genderbyRegionInputBoxLabel">
                                  {" "}
                                  Male{" "}
                                </Box>
                                <Box>
                                  {" "}
                                  <input
                                    min="0"
                                    type="Number"
                                    className="genderbyRegionInputBox"
                                    value={elementList[el.name]?.male}
                                    onChange={(e) => {
                                      setGenderbyBusinessVerticalstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == rclist.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue[el.name].male = Number(
                                                  e.target.value
                                                );
                                              }
                                              // console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  />{" "}
                                </Box>
                              </Box>
                              <Box className="genderBox">
                                <Box className="genderbyRegionInputBoxLabel">
                                  {" "}
                                  Female{" "}
                                </Box>
                                <Box>
                                  {" "}
                                  <input
                                    min="0"
                                    type="Number"
                                    className="genderbyRegionInputBox"
                                    value={elementList[el.name]?.female}
                                    onChange={(e) => {
                                      setGenderbyBusinessVerticalstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == rclist.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue[el.name].female =
                                                  Number(e.target.value);
                                              }
                                              //console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  />{" "}
                                </Box>
                              </Box>
                              <Box className="genderBox">
                                <Box className="genderbyRegionInputBoxLabel">
                                  {" "}
                                  Non-binary{" "}
                                </Box>
                                <Box>
                                  {" "}
                                  <input
                                    min="0"
                                    type="Number"
                                    className="genderbyRegionInputBox"
                                    value={elementList[el.name]?.nonBinary}
                                    onChange={(e) => {
                                      setGenderbyBusinessVerticalstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == rclist.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue[el.name].nonBinary =
                                                  Number(e.target.value);
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  />{" "}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Item>
                    </Grid>
                  );
                }
              })}
            </Grid>
            <Box
              className="addYearsbutton"
              onClick={() => {
                addGenderbyBusinessVertical(ind);
              }}
            >
              {" "}
              Add business vertical
            </Box>
          </Box>
        ))}
        <Box
          className="addYearsbutton"
          onClick={() => {
            setrecievedList((prev) => prev.concat({ year: "" }));
          }}
          sx={{cursor:'pointer'}}
        >
          {" "}
          Add year
        </Box>
      </Box>

      {/* ///////// condidate shortlist */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          2
        </Box>{" "}
        Number of candidates shortlisted
      </Box>

      <Box
        className="tablebgColorForHr"
        sx={{ width: "98%", padding: "0px 20px", mt: "15px", ml: "24px" }}
      >
        {shortlistedList.map((shortlist, ind) => (
          <Box>
            <Box style={{ display: "flex" }}>
              <Box>
                {" "}
                <Select
                  name="se"
                  className="select-year"
                  sx={{ height: "55px" }}
                  onChange={(e) => {
                    // let data = shortlistedList;
                    // data[ind] = { year: e.target.value };
                    // setshortlistedList(data);

                    setshortlistedList((prev) => {
                      return prev.filter((ele, inx) => {
                        if (inx == ind) {
                          ele.year = e.target.value;
                        }
                        return ele;
                      });
                    });
                    //setNumberofCandidatesShortlistedyear(e.target.value);
                  }}
                  value={shortlist.year}
                >
                  <MenuItem value={""}>Select year</MenuItem>

                  {OverallGenderRepresentationState?.map((e) => (
                    <MenuItem value={e?.year}> {e?.year}</MenuItem>
                  ))}
                </Select>{" "}
              </Box>
            </Box>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="tableHightandSizeForThreeSections"
              sx={{ mt: "15px" }}
            >
              {numberofCandidatesShortlistedstate?.map((elementList) => {
                if (elementList.year == shortlist.year) {
                  return (
                    <Grid item xs={6}>
                      <Item>
                        <Box
                          className="genderbyRegionTableContent"
                          sx={{ paddingBottom: "1px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "#BCBCBC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteFunctionCallFromUi(
                                elementList,
                                "shortlisted"
                              );
                            }}
                          >
                            {/* <DeleteIcon /> */}

                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                fill="#707EAE"
                              />
                            </svg>
                          </Box>
                          <Box style={{ margin: "20px" }}>
                            <Box>
                              <Box>
                                {" "}
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label80">
                                    Select business vertical
                                  </InputLabel>
                                  <Select
                                    label="Select business vertical"
                                    labelId="demo-simple-select-label80"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      sx: {
                                        maxHeight: "250px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                    sx={{
                                      width: "260px",
                                      height: "55px",
                                      fontSize: "18px",
                                      backgroundColor: "transparent",
                                    }}
                                    value={elementList?.businessVertical}
                                    onChange={(e) => {
                                      setNumberofCandidatesShortlistedstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year ==
                                                  shortlist.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue.businessVertical =
                                                  e.target.value;
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {checkAlreadyVertical(numberofCandidatesShortlistedstate,elementList?.businessVertical,shortlist.year).map((el)=>{
                                      return(<MenuItem value={el}>{el}</MenuItem>)
                                    })}
                                    {/* {bussinessvertical?.map((el) => (
                                      <MenuItem value={el}>{el}</MenuItem>
                                    ))} */}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              paddingBottom: "20px",

                              textAlign: "center",
                            }}
                          >
                            {genderList?.map((el) => (
                              <Box style={{ margin: "10px" }}>
                                <Box>
                                  <Box> {el.name} </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Male{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      name="tsg"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.male}
                                      onChange={(e) => {
                                        setNumberofCandidatesShortlistedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    shortlist.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].male =
                                                    Number(e.target.value);
                                                }
                                                //console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Female{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.female}
                                      onChange={(e) => {
                                        setNumberofCandidatesShortlistedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    shortlist.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].female =
                                                    Number(e.target.value);
                                                }
                                                // console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Non-binary{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.nonBinary}
                                      onChange={(e) => {
                                        setNumberofCandidatesShortlistedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    shortlist.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].nonBinary =
                                                    Number(e.target.value);
                                                }
                                                // console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                }
              })}
            </Grid>
            <Box
              className="addYearsbutton"
              onClick={() => {
                addNumberofCandidatesShortlisted(ind);
              }}
            >
              {" "}
              Add business vertical
            </Box>
          </Box>
        ))}
        <Box
          className="addYearsbutton"
          onClick={() => {
            setshortlistedList((prev) => prev.concat({ year: "" }));
          }}
          sx={{cursor:'pointer'}}
        >
          {" "}
          Add year
        </Box>
      </Box>

      {/* Number of offer leter */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          3
        </Box>{" "}
        Number of offer letters sent
      </Box>

      <Box
        className="tablebgColorForHr"
        sx={{ width: "98%", padding: "0px 20px", mt: "15px", ml: "24px" }}
      >
        {offerList.map((offList, ind) => (
          <Box>
            <Box style={{ display: "flex" }}>
              <Box>
                {" "}
                <Select
                  name="se"
                  sx={{ height: "55px" }}
                  className="select-year"
                  onChange={(e) => {
                    // let data = offerList;
                    // data[ind] = { year: e.target.value };
                    // setOfferList(data);

                    setOfferList((prev) => {
                      return prev.filter((ele, inx) => {
                        if (inx == ind) {
                          ele.year = e.target.value;
                        }
                        return ele;
                      });
                    });
                    // setNumberofCandidatesRecruitedyear(e.target.value);
                  }}
                  value={offList.year}
                >
                  <MenuItem value={""}>select year</MenuItem>

                  {OverallGenderRepresentationState?.map((e) => (
                    <MenuItem value={e?.year}> {e?.year}</MenuItem>
                  ))}
                </Select>{" "}
              </Box>
            </Box>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="tableHightandSizeForThreeSections"
              sx={{ mt: "30px" }}
            >
              {numberofCandidatesOfferlaterstate?.map((elementList) => {
                if (elementList.year == offList.year) {
                  return (
                    <Grid item xs={6}>
                      <Item>
                        <Box
                          className="genderbyRegionTableContent"
                          sx={{ paddingBottom: "1px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "#BCBCBC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteFunctionCallFromUi(
                                elementList,
                                "offerlater"
                              );
                            }}
                          >
                            {/* <DeleteIcon /> */}

                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                fill="#707EAE"
                              />
                            </svg>
                          </Box>
                          <Box style={{ margin: "20px" }}>
                            <Box>
                              <Box>
                                {" "}
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label82">
                                    Select business vertical
                                  </InputLabel>
                                  <Select
                                    label="Select business vertical"
                                    labelId="demo-simple-select-label82"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      sx: {
                                        maxHeight: "250px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                    sx={{
                                      width: "260px",
                                      height: "55px",
                                      fontSize: "18px",
                                      backgroundColor: "transparent",
                                    }}
                                    value={elementList?.businessVertical}
                                    onChange={(e) => {
                                      setNumberofCandidatesOfferlaterstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == offList.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue.businessVertical =
                                                  e.target.value;
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {checkAlreadyVertical(numberofCandidatesOfferlaterstate,elementList?.businessVertical,offList.year).map((el)=>{
                                      return(<MenuItem value={el}>{el}</MenuItem>)
                                    })}
                                    {/* {bussinessvertical?.map((el) => (
                                      <MenuItem value={el}>{el}</MenuItem>
                                    ))} */}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              textAlign: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            {genderList?.map((el) => (
                              <Box style={{ margin: "10px" }}>
                                <Box>
                                  <Box> {el.name} </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Male{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.male}
                                      onChange={(e) => {
                                        setNumberofCandidatesOfferlaterstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    offList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].male =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Female{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.female}
                                      onChange={(e) => {
                                        setNumberofCandidatesOfferlaterstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    offList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].female =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Non-binary{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.nonBinary}
                                      onChange={(e) => {
                                        setNumberofCandidatesOfferlaterstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    offList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].nonBinary =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                }
              })}
            </Grid>
            <Box
              className="addYearsbutton"
              onClick={() => {
                addNumberofOfferlater(ind);
              }}
            >
              {" "}
              Add business vertical
            </Box>
          </Box>
        ))}
        <Box
          className="addYearsbutton"
          onClick={() => {
            setOfferList((prev) =>
              prev.concat({
                year: "",
              })
            );
          }}
          sx={{cursor:'pointer'}}

        >
          {" "}
          Add year
        </Box>
      </Box>

      {/* ///  Number of Candidates Recruited */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          4
        </Box>{" "}
        Number of offer letters accepted
      </Box>

      <Box
        className="tablebgColorForHr"
        sx={{ width: "98%", padding: "0px 20px", mt: "15px", ml: "24px" }}
      >
        {recruitedList.map((recList, ind) => (
          <Box>
            <Box style={{ display: "flex" }}>
              <Box>
                {" "}
                <Select
                  name="se"
                  sx={{ height: "55px" }}
                  className="select-year"
                  onChange={(e) => {
                    // let data = recruitedList;
                    // data[ind] = { year: e.target.value };
                    // setrecruitedList(data);

                    setrecruitedList((prev) => {
                      return prev.filter((ele, inx) => {
                        if (inx == ind) {
                          ele.year = e.target.value;
                        }
                        return ele;
                      });
                    });

                    // setNumberofCandidatesRecruitedyear(e.target.value);
                  }}
                  value={recList.year}
                >
                  <MenuItem value={""}>select year</MenuItem>

                  {OverallGenderRepresentationState?.map((e) => (
                    <MenuItem value={e?.year}> {e?.year}</MenuItem>
                  ))}
                </Select>{" "}
              </Box>
            </Box>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="tableHightandSizeForThreeSections"
              sx={{ mt: "15px" }}
            >
              {numberofCandidatesRecruitedstate?.map((elementList) => {
                if (elementList.year == recList.year) {
                  return (
                    <Grid item xs={6}>
                      <Item>
                        <Box
                          className="genderbyRegionTableContent"
                          sx={{ paddingBottom: "1px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "right",
                              color: "#BCBCBC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteFunctionCallFromUi(
                                elementList,
                                "Recruited"
                              );
                            }}
                          >
                            {/* <DeleteIcon /> */}

                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                fill="#707EAE"
                              />
                            </svg>
                          </Box>
                          <Box style={{ margin: "20px" }}>
                            <Box>
                              <Box>
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label83">
                                    Select business vertical
                                  </InputLabel>
                                  <Select
                                    label="Select business vertical"
                                    labelId="demo-simple-select-label83"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      sx: {
                                        maxHeight: "250px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                    sx={{
                                      width: "260px",
                                      height: "55px",
                                      fontSize: "18px",
                                      backgroundColor: "transparent",
                                    }}
                                    value={elementList?.businessVertical}
                                    onChange={(e) => {
                                      setNumberofCandidatesRecruitedstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == recList.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue.businessVertical =
                                                  e.target.value;
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {checkAlreadyVertical(numberofCandidatesRecruitedstate,elementList?.businessVertical,recList.year).map((el)=>{
                                      return(<MenuItem value={el}>{el}</MenuItem>)
                                    })}
                                    {/* {bussinessvertical?.map((el) => (
                                      <MenuItem value={el}>{el}</MenuItem>
                                    ))} */}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              textAlign: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            {genderList?.map((el) => (
                              <Box style={{ margin: "10px" }}>
                                <Box>
                                  <Box> {el.name} </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Male{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.male}
                                      onChange={(e) => {
                                        setNumberofCandidatesRecruitedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    recList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].male =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Female{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.female}
                                      onChange={(e) => {
                                        setNumberofCandidatesRecruitedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    recList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].female =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Non-binary{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.nonBinary}
                                      onChange={(e) => {
                                        setNumberofCandidatesRecruitedstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    recList.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].nonBinary =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                }
              })}
            </Grid>
            <Box
              className="addYearsbutton"
              onClick={() => {
                addNumberofCandidatesRecruited(ind);
              }}
            >
              {" "}
              Add bussiness vertical
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        className="addYearsbutton"
        onClick={() => {
          setrecruitedList((prev) =>
            prev.concat({
              year: "",
            })
          );
        }}
        sx={{cursor:'pointer'}}

      >
        {" "}
        Add year
      </Box>
    </Box>
  );
}
