import { Button, Container, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import {
  responsiveReverseFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import loginImg from "../../../utilities/images/serin.jpg";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../loginpage/login-logo.png";
import { IP } from "../../../baseUrlAndConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createBrowserHistory } from "history";

import axios from "axios";
import { supportLogger } from "../../../supportLoggerFunction";

function Setpassword() {
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [linkSent, setLinkSet] = useState(false);
  const [email, setEmail] = useState("");
  const customHistory = createBrowserHistory();
  customHistory.push = (path, state) => {
    // Clear the history stack before pushing the new entry.
    customHistory.length = 1;

    // Push the new entry to the history stack.
    customHistory.push(path, state);
  };
  const navigate = useNavigate();

  const [newpass, setnewpass] = useState("");
  const [confermpass, setconfermass] = useState("");
  //const [clicked, setLoading] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState([
    "This link has been utilised as you have already reset your password.",
    "Please go to the login page to begin.",
    "If you do not remember your password select 'Forgot password' to set a new one from the login page.",
  ]);
  const [errorFlagData, setErrorFlagData] = useState(false);
  const par = useParams();
  ///========visibility password=======//

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    setErrorFlagData(false);
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
    if (getCookie("ROLE") === "instructor") {
      goToInstructorDashboard();
      return;
    }
    if (getCookie("ROLE") === "user") {
      goToUserDashboard();
      return;
    }
  }, []);

  const setUserPassword = async (e) => {
    e.preventDefault();
    setErrorFlagData(false);
    console.log("setUserPassword");

    if (newpass !== confermpass) {
      setLoading(false);

      setPasswordError("Password mismatch");
      return;
    }
    if (newpass.length < 8) {
      setLoading(false);
      setPasswordError("Password must be atleast 8 characters");
      return;
    }

    // console.log(par);
    // console.log(window.location);
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    console.log(params);
    let uid = params.email;
    let mode = params.mode;

    console.log(uid);
    if (!uid) {
      setPasswordError("Invalid Request");
      // setLoading(false);
      return;
    }
    setLoading(true);

    // can make is so that if a continue url is given we can redirect to the continue url
    //console.log(value);
    var data = JSON.stringify({
      newPassword: newpass,
      confermpass: confermpass,
      mode: mode,
      email: uid,
    });

    var config = {
      method: "post",

      url: `${IP}/auth/setPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((userCredential) => {
        setLoading(false);
        setLinkSet(true);
        navigate("/", { replace: true });
      })
      .catch((error, res) => {
        supportLogger(error?.response);
        // supportLogger(error?.code);

        console.log(error);

        // console.log(res);
        // console.log(error.response.data.error);

        // setPasswordError(error?.response?.data?.error);

        if (error?.response?.data?.error == "Invitation already accepted") {
          setErrorFlagData(true);
        }
        if (error.code == "auth/internal-error") {
          setConfirmPasswordError("Internal Error Occured");
        } else if (error.code == "auth/invalid-action-code") {
          setConfirmPasswordError("Password reset link invalid/expired");
        } else {
          setConfirmPasswordError(error.code);
        }
        setLoading(false);
        //console.log(error.code);
        // setError(error.code);
      });
  };
  const handleForgot = () => {
    clearErrors();
    if (newpass == "" || confermpass == "")
      return setNotification("Please enter your password");
    setLoading(true);

    var data = JSON.stringify({
      newpass: newpass,
      confermpass: confermpass,
    });

    var config = {
      method: "post",

      url: `${IP}/auth/resetPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((userCredential) => {
        setLoading(false);
        setLinkSet(true);
        navigate("/");
      })
      .catch((err) => {
        // supportLogger(err?.code);
        supportLogger(err?.response);

        console.log(err);
        setLoading(false);
        // Invitation already accepted

        if (err.code == "auth/user-not-found")
          return setNotification("Email not found");
        else if (err.code == "auth/too-many-requests")
          return setNotification("Too many requests. Please try again later");
        switch (err.code) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  return (
    <Box
      sx={{
        py: { md: "0", height: "100vh", background: "#F5F5F5" },
      }}
    >
      <Container
        sx={{
          // ...responsiveReverseFlexDirection,

          gap: "50px",

          // display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            // className={classes.logo}
            src={loginLogo}
            alt=""
            style={{
              marginTop: "60px",

              background: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "50px",
          }}
        >
          <Box
            sx={{
              // ...responsiveWidth,

              border: "2px solid #6846C7",
              borderRadius: "20px",
              width: "476px",
              background: "#EDEAFF",
              // height: "70vh",
            }}
          >
            {!linkSent ? (
              <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
                <Box>
                  <Typography
                    sx={{
                      // color: (theme) => theme.palette.primary.main,
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#6846C7",
                    }}
                  >
                    Reset password
                  </Typography>
                </Box>
                <form
                  action=""
                  onSubmit={(e) => {
                    setUserPassword(e);
                  }}
                >
                  {/* <Box>
                    <input
                      type="password"
                      required
                      value={newpass}
                      onChange={(e) => setnewpass(e.target.value)}
                      placeholder="New password"
                      style={{
                        width: "100%",
                        padding: "7px",
                        height: "56px",
                        border: "none",
                        background: "#FFFFFF",

                        marginTop: "30px",
                        borderRadius: "16px",
                        outline: "none",
                      }}
                    />
                  </Box> */}

                  <Box>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "15px",
                        outline: "none",
                        display: "flex",
                        marginTop: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type={shownewPassword ? "text" : "password"}
                        required
                        id={shownewPassword ? "showPassword" : "hiddePassword"}
                        value={newpass}
                        onChange={(e) => setnewpass(e.target.value)}
                        placeholder="New password"
                        style={{
                          width: "100%",
                          padding: "7px",
                          height: "30px",
                          border: "none",
                          background: "#FFFFFF",

                          // marginTop: "30px",
                          borderRadius: "16px",
                          outline: "none",
                        }}
                      />

                      <div
                        onClick={() => setShownewPassword(!shownewPassword)}
                        style={{
                          zIndex: "1",
                        }}
                      >
                        {shownewPassword ? (
                          <Tooltip title="Hide Password" placement="top-start">
                            <VisibilityIcon sx={{ color: "#6846C7" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Show Password" placement="top-start">
                            <VisibilityOffIcon sx={{ color: "#6846C7" }} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </Box>

                  <Box>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "15px",
                        outline: "none",
                        display: "flex",
                        marginTop: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        required
                        id={showPassword ? "showPassword" : "hiddePassword"}
                        value={confermpass}
                        onChange={(e) => setconfermass(e.target.value)}
                        placeholder="Confirm password"
                        style={{
                          width: "100%",
                          padding: "7px",
                          height: "30px",
                          border: "none",
                          background: "#FFFFFF",

                          // marginTop: "30px",
                          borderRadius: "16px",
                          outline: "none",
                        }}
                      />

                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          zIndex: "1",
                        }}
                      >
                        {showPassword ? (
                          <Tooltip title="Hide Password" placement="top-start">
                            <VisibilityIcon sx={{ color: "#6846C7" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Show Password" placement="top-start">
                            <VisibilityOffIcon sx={{ color: "#6846C7" }} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </Box>

                  <Box sx={{ mt: "30px" }}>
                    <Button
                      type="submit"
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        py: 1,
                        borderRadius: "15px",
                        fontSize: "16px",
                        fontWeight: 500,
                        background: "#6846C7",
                        color: "#fff",
                        "&:hover": {
                          background: "#6846C7",
                        },
                      }}
                    >
                      {loading ? "Sending ..." : "Submit"}
                    </Button>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#C40B0B",
                        fontWeight: 400,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      {errorFlagData ? (
                        <ul>
                          {" "}
                          {errorMessage?.map((el) => (
                            <li> {el}</li>
                          ))}{" "}
                        </ul>
                      ) : (
                        <> {passwordError} </>
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Link to="/">
                      <Typography
                        sx={{
                          textAlign: "left",
                          color: "#6846C7",
                          fontWeight: 600,
                          fontSize: { xs: "12px", md: "14px" },
                        }}
                      >
                        Go to login
                      </Typography>
                    </Link>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box sx={{ p: 7 }}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{ color: "#007848", fontWeight: 700 }}
                  >
                    Password updated
                  </Typography>
                  <Typography sx={{ my: 2, color: "#007848" }}>
                    Please go to login page.
                  </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Link to="/">
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#6846C7",
                        fontWeight: 600,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      Go to login
                    </Typography>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Setpassword;
