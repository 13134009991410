import axios from "axios";
import React, { useEffect, useState } from "react";
import { IP } from "../../../../baseUrlAndConfig";
import { Box, Button, Stack } from "@mui/material";
import { getCookie } from "../../../../Cookies";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";
import "./Print.css";
const InfrastructurePrint = () => {
  const [ArrInfura, setArrInfura] = useState([]);
  const [policiesState, setPolociesState] = useState({});
  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // // console.log(newobj.HRDI);
    // if (!newobj.HRDI?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);

  const fetchdata = () => {
    let configrecrut = {
      method: "get",

      url: `${IP}/infrastructure?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setArrInfura([]);
      setArrInfura(res.data);
    });
  };

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <Box className="pagebreak">
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Infrastructure"} index={6}></HrTopNavbar>
      </Box>

      <Stack
        spacing={4}
        className="tablebgColorForHr"
        style={{
          marginTop: "24px",
          backgroundColor: "#F3F1FF",
          marginLeft: "auto",
          marginRight: "auto",
          width: "97%",
          marginBottom: "100px",
        }}
      >
        {ArrInfura?.map((el, index) => (
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "6fr 1fr",

              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 18fr 2fr",
              }}
            >
              <Box
                sx={{
                  color: "#000",
                  backgroundColor: "#F3F1FF",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                {index + 1}.
              </Box>
              <Box
                sx={{
                  whiteSpace: "initial",
                }}
              >
                {" "}
                {el.heading}{" "}
                {/* <PoliciesInfoPopup data={el?.description} /> */}
              </Box>
              <Box>
                {" "}
                {/* <InfrastructureInfoPopup data={el?.description} /> */}
              </Box>
            </Box>

            <Box style={{ display: "flex", gap: "20px" }}>
              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree ? "#E1F566" : "#BCBCBC",
                }}
              >
                Yes
              </Button>

              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree === false ? "#E1F566" : "#BCBCBC",
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default InfrastructurePrint;
