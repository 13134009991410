import React from "react";

function Logout({ clr }) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 27C6.04395 27 0 20.9561 0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27ZM20.25 18.9L27 13.5L20.25 8.1V12.15H9.45V14.85H20.25V18.9Z"
        fill={clr}
      />
    </svg>
  );
}

export default Logout;
