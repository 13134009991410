import React, { useContext, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { ReactComponent as NextPage } from "./icons/pdfNextPage.svg";
import { ReactComponent as PrevPage } from "./icons/pdfPrevPage.svg";
import { ReactComponent as PageIcon } from "./icons/pdfPageIcon.svg";
import { ReactComponent as ZoomIn } from "./icons/zoomIn.svg";
import { ReactComponent as ZoomOut } from "./icons/zoomOut.svg";
import { ReactComponent as ListenAudioOn } from "./icons/listenAudioOn.svg";
import { ReactComponent as ListenAudioOff } from "./icons/listenAudioOff.svg";
import { CustomAudioPlayer } from "./CustomAudioPlayer";
import { Checkbox } from "@mui/material";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";

import { IP } from "../../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../../Cookies";
export const PdfContainer = ({ pdfURL, setCanNavigate, itemId }) => {
  const [audioEnable, setAudioEnable] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomFactor, setZoomFactor] = useState(1.0);

  const { courseId, isLinear, sample } = useContext(ItemContext);

  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroProgress/items`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
    return () => {};
  }, []);

  useEffect(() => {
    setCanNavigate(completed || !isLinear);
    if (completed) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userMicroCourseContent/pdf`,
        // params: { courseId: courseId, itemId: itemId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
        }),
      };
      axios(configrecrut).then((re) => {
        console.log(re);
      });
    }
  }, [completed]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const changePage = (goToPage) => {
    if (goToPage > numPages || goToPage <= 0) return;
    setPageNumber(goToPage);
  };
  const zoom = (action) => {
    if (zoomFactor <= 0.25 && action == "out") return;
    if (zoomFactor >= 1.5 && action == "in") return;
    if (action === "in") {
      setZoomFactor((prev) => prev + 0.25);
      //console.log(zoomFactor);
    } else if (action === "out") {
      setZoomFactor((prev) => prev - 0.25);
      //console.log(zoomFactor);
    }
  };

  return (
    <div className="courseItem-pdfContainer">
      <div className="courseItem-pdfTopbar">
        <div style={{ marginRight: "auto", display: "flex" }}>
          <PrevPage
            height={"1em"}
            style={{ marginRight: "20px" }}
            onClick={() => changePage(pageNumber - 1)}
          />
          <PageIcon height={"1em"} style={{ marginRight: "10px" }} />
          <p className="courseItem-pdfTopbarText">
            {pageNumber}/{numPages}
          </p>
          <NextPage height={"1em"} onClick={() => changePage(pageNumber + 1)} />
        </div>
        <div className="courseItem-pdfTopbar-zoomInfo">
          <ZoomOut onClick={() => zoom("out")} />
          <ZoomIn onClick={() => zoom("in")} style={{ marginRight: "10px" }} />
        </div>
        {/* {audioEnable ? (
          <ListenAudioOn
            onClick={() => setAudioEnable((prev) => !prev)}
            style={{ marginRight: "10px" }}
          />
        ) : (
          <ListenAudioOff
            onClick={() => setAudioEnable((prev) => !prev)}
            style={{ marginRight: "10px" }}
          />
        )} */}
      </div>
      <div className="courseItem-mainpdf">
        <Document
          className="courseItem-document"
          file={pdfURL}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            scale={zoomFactor}
            className={"courseitem-pdfPage"}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
      {/* don't show is sample */}
      {!sample && (
        <div className="markCompleted">
          <Checkbox
            aria-label="Mark Completed"
            onChange={(e) => setCompleted(e.target.checked)}
            checked={completed}
            sx={{
              color: "#007c84",
              "&.Mui-checked": {
                color: "#007C84",
              },
            }}
          />
          Mark Completed
        </div>
      )}
      {/* {audioEnable && (
        <CustomAudioPlayer url="https://firebasestorage.googleapis.com/v0/b/alghaithdev.appspot.com/o/download?alt=media&token=7ac7a6ad-17a7-4d0b-b85a-a7033c067203" />
      )} */}
    </div>
  );
};
