import { InputLabel, Modal, Stack, Typography } from "@mui/material";
import { Box, margin } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Chart from "./Chart";
import { psyclogicalSafetyData } from "./Option";
import { useContext, useEffect, useState } from "react";
import psy1 from "../../../utilities/pdfImages/psy11.jpg";

import psy2 from "../../../utilities/pdfImages/psy12.jpg";
import psy3 from "../../../utilities/pdfImages/psy13.jpg";
import psy4 from "../../../utilities/pdfImages/psy14.jpg";
import psy5 from "../../../utilities/pdfImages/psy15.jpg";
import psy6 from "../../../utilities/pdfImages/psy16.jpg";
import psy7 from "../../../utilities/pdfImages/psy17.jpg";
import HrloadingScreen from "../../LodingHrscreen";

import { exportMultipleChartsToPdf } from "./GraphUtils";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import ReactToPrint from "react-to-print";

import { ComponentToPrint } from "./ComponentToPrint";
import PrintPageForPsyclogical from "./PsyclogicalPrint";
import PrintPageForBystandard from "../Bystandard/BystandardPrint";
import DiversityMetricPrint from "../../home/component/PrintDiversityMeticAll/DiversityMetricPrint";
// import React, { useRef } from "react";

// import "../sendinviteforpsy.css";
export default function PrintPage() {
  const params = useParams();
  console.log(params);
  console.log(localStorage.getItem("companyName"));
  const companyName = localStorage.getItem("companyName");
  const allApiData = JSON.parse(localStorage.getItem("allApiData"));
  const componentRef = JSON.parse(localStorage.getItem("componentRef"));
  console.log("IN printPage", componentRef);
  //   const { companyName, allApiData, componentRef } = location.state;
  return (
    <>
      <div>
        <div>
          <ReactToPrint
            trigger={() => (
              <button
                style={{
                  width: "224px",
                  height: "48px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16px",
                  border: "1px solid #6846C7",
                  cursor: "pointer",
                  fontFamily: "Nunito",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#6846C7",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
            removeAfterPrint={true}
            onAfterPrint={() => {
              console.log("sdfs");
            }}
          />

          <ComponentToPrint
            ref={componentRef}
            companyName={companyName}
            allApiData={allApiData}
          >
            {params.id == "Psychologicalsafety" && (
              <PrintPageForPsyclogical
                companyName={companyName}
                allApiData={allApiData}
              />
            )}

            {params.id == "Bystanderintervention" && (
              <PrintPageForBystandard
                companyName={companyName}
                allApiData={allApiData}
              />
            )}

            {params.id == "DiversityMetric" && (
              <DiversityMetricPrint
                companyName={companyName}
                allApiData={allApiData}
              />
            )}
          </ComponentToPrint>
        </div>
      </div>
    </>
  );
}
