import React, { useEffect, useState } from "react";

import "./strategy.css"

import { Box, Container } from "@mui/system";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import ResponsiveDrawer from "../../Sidebar/HrSideBar";
import { getCookie } from "../../../Cookies";
import img from "./arrow.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import PDFloaderModel from "./stategimodal/PDFLoaderModel";
import MobileTopBar from "../../Sidebar/MobaileTopBar";

export default function Strategies() {
	const navigate = useNavigate();
	const [data, setdata] = useState([]);
	const [openStateForPdf, setOpenStateForPdf] = useState(false);
	const [pdfData, setPdfData] = useState("");


	useEffect(() => {
		let configrecrut = {
			method: "get",

			url: `${IP}/strategies/user`,
			// params: { gender: gender },
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getCookie("bearer")}`,
			},
			data: "dataregionf",
		};

		axios(configrecrut).then((res) => {
			console.log(res.data);
			setdata(res.data);
		});
	}, []);


	const handleContextMenu = (event) => {
		event.preventDefault();
	};


	return (
		<Box
			className="hrmainbox"
			sx={{ backgroundColor: "#F3F1FF", minHeight: "100vh" }}
			onContextMenu={handleContextMenu}
			onKeyDown={(e) => {
				e.preventDefault();
			}}
		>
			<PDFloaderModel
				open={openStateForPdf}
				pdfData={pdfData}
				handleClose={() => {
					setOpenStateForPdf(false);
				}}
			/>
			{/* <Box sx={{ width: "150px" }}> */}
			<ResponsiveDrawer sidebar={"Learning"} />
			{/* </Box> */}

			<Box
				sx={{
					width: "100%",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: "0",
						backgroundColor: "#F3F1FF",
						zIndex: "5",

						width: "100%",
					}}
				>
					<MobileTopBar sidebar={"Strategy"} />
					<HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
						<Box>
							<Button
								sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
								onClick={() => {
									navigate(-1);
								}}
							>
								Back
							</Button>
						</Box>
					</HrTopNavbar>

				</Box>
				<Box className="StrategiesGrid">
					{data.map((el) => (
						<Box
							sx={{
								display: "grid",
								gridTemplateColumns: "5fr 1fr",
								mt: "40px",
								gridGap: "10px",
								height: "183px",
								width: "100%",
								background: el?.bgcolor,
								borderRadius: "24px",
							}}
						>
							<Box
								sx={{
									display: "grid",
									gridTemplateRows: "20px 80px 80px",
									paddingLeft: "24px",
								}}
							>
								<Box
									sx={{
										marginTop: "-16px",
										width: "105px",
										height: "32px",
										backgroundColor: el.color,
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "400",
										zIndex: 2,
										borderRadius: "8px",
										display: "flex",
										paddingLeft: "10px",
										alignItems: "center",
									}}
								>
									{" "}
									{el.name}
								</Box>
								<Box
									sx={{
										fontFamily: "Poppins",
										fontSize: "16px",
										fontWeight: "600",
										mt: "14px",
									}}
								>
									{" "}
									{el.topic}
								</Box>
								<Box
									sx={{
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "400",
									}}
								>
									{" "}
									{el.description}
								</Box>
							</Box>

							<Box
								onClick={() => {
									setOpenStateForPdf(true);
									setPdfData(el.file);
									// window.open(el.file, "_blank");
								}}
								sx={{
									background: "#FFFFFF",
									mt: "120px",

									padding: "12px",
									display: "flex",
									width: "48px",
									height: "48px",
									borderRadius: "16px",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img src={img} alt="" />
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}
