import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useEffect, useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useNavigate } from "react-router";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
import AdminloadingScreen from "../loadingadmin";
export const CompanyDepartmentCreate = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const location = useLocation();
  // const [base64Image, setBase64Image] = useState("");
  const imageRef = useRef(null);

  const [subscriptionlist, setSubscriptionlist] = useState([]);
  const [details, setDetails] = useState({
    name: "",

    subscriptionName: "",
    subscriptionId: "",
    start: "",
    end: "",
    noticePeriod: "",
    companyId: "",
  });
  const [base64Img, setBase64Img] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const onImageClick = () => {
    imageRef.current.click();
  };
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    imageUrl: "",
  });
  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    } else {
      //console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);
      setImgFileName(e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };
  const getSubscriptionList = () => {
    //console.log("details", details);

    setSubloading(true);

    var config = {
      method: "get",

      url: `${IP}/subscription/all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        //console.log("res", res);
        setSubloading(false);
        setSubscriptionlist(res.data);
      })
      .catch((err) => {
        //console.log(err);
        setSubloading(false);
      });
  };
  const handleChange = (e) => {
    const name = e.target.name;
    setDetails({
      ...details,
    });
  };

  // logo change

  const handleget = () => {
    //console.log("details", details);

    if (location.state) {
      setSubloading(true);

      var config = {
        method: "get",
        params: { id: location.state.companyId },
        url: `${IP}/company/company`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "data",
      };

      axios(config)
        .then((res) => {
          setSubloading(false);
          setImageUrl(res.data.logo);
          setDetails({
            ...res.data,
            start: res.data.start.split("T")[0],
            end: res.data.end.split("T")[0],
            companyId: location.state.companyId,
          });
        })
        .catch((err) => {
          setSubloading(false);
        });
    }
  };

  const [subloading, setSubloading] = useState(false);
  const handleSubmit = () => {
    //console.log("details", details);
    if (
      details.name === "" ||
      details.subscriptionName == "" ||
      details.end == "" ||
      details.start == "" ||
      details.noticePeriod == "" ||
      imageUrl == ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    setSubloading(true);

    console.log(details);
    let data = JSON.stringify({
      ...details,
      logo: base64Img ? base64Img : imageUrl,
    });
    var config = {
      method: location.state ? "patch" : "post",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setSubloading(false);
        navigate("/admin/settings/company-department");
      })
      .catch((err) => {
        setSubloading(false);
      });
  };

  const moveToLocationsPage = () => {
    // /admin/settings/company/locations
    if (!location?.state) {
      alert("add company first");
      return;
    }
    console.log("company", location?.state);
    navigate("/admin/settings/company/locations", {
      state: location?.state?.companyId,
    });
  };

  useEffect(() => {
    getSubscriptionList();
    handleget();
  }, []);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company And Department Settings")} />
        {/* <Navbar /> */}

        <AdminloadingScreen open={subloading} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  // startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    moveToLocationsPage();
                  }}
                >
                  Add location
                </Button>
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "space-around",
                  gridTemplateColumns: "1fr 1fr",
                  marginBottom: "100px",
                }}
              >
                <div>
                  <h3>Company name</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.name}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        name: e.target.value,
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3 style={{ marginBottom: "15px" }}>Company logo</h3>

                  {imageUrl == "" ? (
                    <div
                      style={{
                        width: "20vw",
                        height: "8em",
                        background: "#d5d5d5",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                    >
                      Click to upload Image
                    </div>
                  ) : (
                    <img
                      style={{
                        width: "20vw",
                        height: "8em",
                        objectFit: "cover",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                      onClick={onImageClick}
                      src={imageUrl}
                    ></img>
                  )}
                  <input
                    ref={imageRef}
                    style={{ display: "none", padding: "5px" }}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="course-builder-inputImg"
                    id="courseImage"
                    onChange={(e) => {
                      handleCourseImage(e);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        imageUrl: "",
                      }));
                    }}
                  />
                  {checkErrorInForm.imageUrl == "error" && (
                    <span
                      style={{
                        marginLeft: "20px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      upload Image
                    </span>
                  )}
                </div>
                <div>
                  <h3>Subscription plans</h3>
                  <br />

                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <FormControl>
                      <InputLabel id="demo-simple-select-label68">
                        Plans
                      </InputLabel>
                      <Select
                        sx={{
                          width: "250px",
                        }}
                        value={details?.subscriptionId}
                        label="Plans"
                        labelId="demo-simple-select-label68"
                        id="demo-simple-select-label68"
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            subscriptionId: e.target.value,
                            subscriptionName: subscriptionlist.filter(
                              (el) => el.id == e.target.value
                            )[0]?.subscriptionName,
                          });
                        }}
                      >
                        {subscriptionlist?.map((el) => (
                          <MenuItem value={el?.id}>
                            {" "}
                            {el?.subscriptionName}{" "}
                          </MenuItem>
                        ))}
                      </Select>{" "}
                    </FormControl>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "100px",
                }}
              >
                <div>
                  <h3>Plan start date</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.start}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        start: e.target.value,
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="date"
                  />
                </div>

                <div>
                  <h3>Plan end date</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.end}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        end: e.target.value,
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="date"
                  />
                </div>
                <div>
                  <h3>Notice period</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.noticePeriod}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        noticePeriod: e.target.value,
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="number"
                  />
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <Button
                  disabled={subloading}
                  variant="contained"
                  component="label"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
