import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { getCookie } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";

export const EmailSettings = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const [showformBox, setshowformBox] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    id: "",
  });
  useEffect(() => {
    getTemplates();
  }, []);

  const [showLoading, setShowLoading] = useState(false);
  const [sortcodetrigger, setsortcodetrigger] = useState([]);
  // const [trigger,settrigger]=useState("");

  const [templateList, setTemplateList] = useState([]);
  const getTemplates = () => {
    var config = {
      method: "get",

      url: `${IP}/mail/senderName`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        //console.log("templates: ", res.data);
        setTemplateList(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const saveDepartment = () => {
    if (inputData.name == "") {
      alert("fill all the filed");
      return;
    }
    // setLoading(true);

    let data = JSON.stringify({
      senderName: inputData.name,

      id: inputData.id,
    });
    var config = {
      method: inputData.id !== "" ? "patch" : "post",

      url: `${IP}/mail/senderName`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setshowformBox(false);
        getTemplates();
      })
      .catch((err) => {
        setshowformBox(false);
      });
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Email Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          {/* <div className="admin-dashboard-main"> */}
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/admin/settings")}
              >
                Back
              </Button>

              {/* <div className="create-new-box">
                <Link to={{ pathname: "/admin/settings/email-create" }}>
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div> */}

              {showformBox && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                    marginBottom: "30px",
                  }}
                >
                  <div>
                    {" "}
                    <input
                      name="name"
                      value={inputData.name}
                      style={{ padding: "5px", fontSize: "18px" }}
                      type="text"
                      onChange={(e) => {
                        setInputData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }}
                    />{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <Button
                      onClick={saveDepartment}
                      variant="contained"
                      component="span"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Button
                      onClick={() => {
                        setshowformBox(false);
                      }}
                      variant="contained"
                      component="span"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}

              <div className="create-new-box">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    setshowformBox(true);
                  }}
                  startIcon={<AddIcon />}
                >
                  Create New
                </Button>
              </div>
              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                <div> Name </div>
                <div style={{ display: "flex" }}>
                  <div>Action</div>
                </div>
              </div>
              <div className="">
                {templateList?.map((ele, i) => {
                  return (
                    <div className="outer-inner-container" key={i}>
                      <div className="templatename">{ele.senderName}</div>
                      <div className="button-box-inner mx-3 my-1">
                        <Button
                          onClick={() => {
                            setshowformBox(true);

                            setInputData({
                              name: ele.senderName,
                              id: ele.id,
                            });
                          }}
                          variant="contained"
                          component="span"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  );
                })}
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
