import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import { toast } from "react-toastify";
export const DepartmentSettings = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const fetchCompany = httpsCallable(functions, "fetchCompany");

  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const [showformBox, setshowformBox] = useState(false);
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company

  const [departmentInputData, setDepartmentInputData] = useState({
    departmentName: "",
    departmentId: "",
    companyId: "",
  });
  const deleteDepartment = (id) => {
    //console.log(id);

    const data = JSON.stringify({
      departmentId: id,
    });

    var config = {
      method: "delete",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        alert("Company Deleted");
        setdeltemp(!deltemp);
      })
      .catch((er) => {
        toast.error("something went wrong");
      });
  };
  // get company and department data
  //   const fetchdata = () => {
  //     setLoading(true);
  //     var config = {
  //       method: "get",

  //       url: `${IP}/company`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${getCookie("bearer")}`,
  //       },
  //       data: "data",
  //     };

  //     axios(config)
  //       .then((res) => {
  //         setLoading(false);
  //         //console.log(res);
  //         if (res.data?.error) {
  //         } else {
  //           settemplate(res.data);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         //console.log(err);
  //       });
  //   };

  const fetchDepartmentdata = () => {
    setLoading1(true);
    var config = {
      method: "get",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        if (res.data.length > 0) {
          setDepartment(res.data);
        }
        setLoading1(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading1(false);
      });
  };
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
    fetchDepartmentdata();
  }, [deledep]);

  const navigate = useNavigate();

  const saveDepartment = () => {
    if (
      departmentInputData.departmentName == "" ||
      departmentInputData.companyId == ""
    ) {
      alert("fill all the filed");
      return;
    }

    setLoading(true);

    let data = JSON.stringify({
      departmentName: departmentInputData.departmentName,
      companyId: departmentInputData.companyId,
      departmentId: departmentInputData.departmentId,
    });
    var config = {
      method: departmentInputData.departmentId !== "" ? "patch" : "post",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setshowformBox(false);
        fetchDepartmentdata();
      })
      .catch((err) => {
        setshowformBox(false);
      });
  };
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              {showformBox && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                    marginBottom: "30px",
                  }}
                >
                  <div>
                    {" "}
                    <select
                      name="company"
                      id=""
                      style={{ width: "250px", height: "40px" }}
                      value={departmentInputData.companyId}
                      onChange={(e) => {
                        setDepartmentInputData((prev) => ({
                          ...prev,
                          companyId: e.target.value,
                        }));
                      }}
                    >
                      {template?.map((el) => (
                        <option value={el.companyId}> {el.name}</option>
                      ))}
                    </select>
                  </div>{" "}
                  <div>
                    {" "}
                    <input
                      name="DepartmentName"
                      // value={details.name}
                      // onChange={(e) =>
                      //   setDetails({
                      //     ...details,
                      //     name: e.target.value,
                      //   })
                      // }
                      value={departmentInputData.departmentName}
                      style={{ padding: "5px", fontSize: "18px" }}
                      type="text"
                      onChange={(e) => {
                        setDepartmentInputData((prev) => ({
                          ...prev,
                          departmentName: e.target.value,
                        }));
                      }}
                    />{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <Button
                      onClick={saveDepartment}
                      variant="contained"
                      component="span"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Button
                      onClick={() => {
                        setshowformBox(false);
                      }}
                      variant="contained"
                      component="span"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}

              <h1>Department Details</h1>
              <div className="create-new-box">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    setshowformBox(true);
                    setDepartmentInputData({
                      departmentId: "",
                      departmentName: "",
                      companyId: "",
                    });
                  }}
                  startIcon={<AddIcon />}
                >
                  Create New
                </Button>
              </div>

              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gridGap: "30px",
                  borderRadius: "5px 5px 0px 0px",
                  justifyItems: "center",
                }}
              >
                <div> Department Name</div>
                <div> Department Id</div>
                <div> company Id</div>
                <div style={{ display: "flex" }}>
                  <div>Action</div>
                </div>
              </div>
              <div className="setting_details_table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {department?.map((ele) => {
                  return (
                    <div
                      className="outer-inner-container"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        justifyItems: "center",
                        gridGap: "30px",
                      }}
                    >
                      <div className="templatename">{ele?.departmentName}</div>

                      <div className="templatename">{ele?.departmentId}</div>
                      <div className="templatename">{ele?.companyId}</div>

                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <Button
                            onClick={() => {
                              setDepartmentInputData(ele);
                              setshowformBox(true);
                            }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                        <div className="button-box-inner mx-3 my-1">
                          {" "}
                          <Button
                            variant="contained"
                            component="span"
                            onClick={() => {
                              deleteDepartment(ele.departmentId);
                            }}
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
