import React, { useContext, useEffect, useState } from "react";
import { ItemContext } from "../Context/ItemContext";

import { Button } from "@mui/material";
import axios from "axios";
import screenfull from "screenfull";
import { getCookie } from "../../../../../Cookies";
import { IP } from "../../../../../baseUrlAndConfig";
const HtmlItem = ({
  url,
  setCanNavigate,
  itemId,
  type,

  changeProgressIndex,
  typeOfScorm,
}) => {
  const [completed, setCompleted] = useState(false);
  const {
    courseId,
    isLinear,
    certificateInfo,
    setProgressIndex,
    progressIndex,
  } = useContext(ItemContext);
  const [count, setCount] = useState(0);
  const typeScorm = typeOfScorm ?? "genially";
  console.log("typeOfScorm from game-----------------", typeScorm);

  useEffect(() => {
    const interval = setInterval(() => {
      let userData = getCookie(courseId);
      // getCookie("user_data");
      console.log(userData);
      // settempdata(userData);

      if (userData) {
        let scoreData = Number(userData);

        console.log(scoreData);

        console.log(certificateInfo);
        console.log(progressIndex);
        if (scoreData === 100 && !certificateInfo) {
          setCanNavigate(true);
          setProgressIndex(progressIndex + 1);

          let configrecrut = {
            method: "patch",

            url: `${IP}/userGamesProgress/items`,

            data: JSON.stringify({
              courseId: courseId,
              itemId: itemId,
              lastVisited: new Date().toString(),
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
          };
          axios(configrecrut).then((res) => {
            console.log(res);
          });
        }
      }
      setCount(count + 1);
    }, 5000);

    return () => {
      clearInterval(interval);

      // setCookie("user_data", null);
    };
  }, [count]);

  useEffect(() => {
    //console.log(completed);
    if (completed) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userCourseContent/html`,

        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      // axios(configrecrut).then((res) => {
      //   console.log(res);
      // });

      // setHTMLProgress({
      //   courseId: courseId,
      //   itemId: itemId,
      //   lastVisited: new Date().toString(),
      // });
    }
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  const fullscreen = () => {
    // screenfull.request(findDOMNode("body"));

    // document.getElementById("testdomel").style.height = "1000px";
    // ds.style.width = "800px";
    // playerRef.current
    screenfull.request(
      // playerRef
      document.getElementById("testdomel")
    );
  };

  return (
    <div className="htmlItem-container">
      {/* {JSON.stringify(certificateInfo)} */}
      {/* {type == "scorm" ? (
        <embed
          src={`https://sereindevcontent.kdev.co.in/index.html?courseUrl=${url}&courseId=${courseId}&type=genially`}
          id="testdomel"
          className="htmlItem-iframe"
          allowfullscreen={true}
        >
          Something
        </embed>
      ) : (
      
      )} */}
      {/* https://sereindevcontent.kdev.co.in/index.html */}
      <iframe
        // src={`https://content.sereininc.live/index.html?courseUrl=${url}&courseId=${courseId}&type=${typeScorm}&isCourse=${false}`}
        src={`https://content.sereininc.com/index_prod_1.html?courseUrl=${url}&courseId=${courseId}&type=${typeScorm}&isCourse=${false}`}
        // src={`https://storage.googleapis.com/sereindevcontent.kdev.co.in/index.html?courseUrl=${url}&courseId=${courseId}&type=${typeScorm}&isCourse=${false}`}
        // src={`https://storage.googleapis.com/sereindevcontent.kdev.co.in/index.html`}
        // src={`https://content.sereininc.live/index.html?courseUrl=${url}&courseId=${courseId}&type=genially`}
        id="testdomel"
        className="htmlItem-iframe"
        allowfullscreen={true}
      ></iframe>
      <div className="markCompleted">
        <Button
          sx={{
            width: "120px",
            hight: "40px",
            // border: "1px solid #F3F1FF",
            backgroundColor: "#E1F566",
          }}
          onClick={() => {
            fullscreen();
          }}
        >
          {" "}
          Full Screen{" "}
        </Button>
        {/* <Checkbox
          aria-label="Mark Completed"
          onChange={(e) => setCompleted(e.target.checked)}
          checked={completed}
          sx={{
            color: "#007c84",
            "&.Mui-checked": {
              color: "#007C84",
            },
          }}
        />
        Mark Completed */}
      </div>
      {/* <CustomAudioPlayer
        url={
          "https://firebasestorage.googleapis.com/v0/b/alghaithdev.appspot.com/o/download?alt=media&token=7ac7a6ad-17a7-4d0b-b85a-a7033c067203"
        }
      /> */}
    </div>
  );
};
export default HtmlItem;
