import { InputLabel, Modal, Stack, Typography } from "@mui/material";
import { Box, margin } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Chart from "./Chart";
import { psyclogicalSafetyData } from "./Option";
import { useContext, useEffect, useState } from "react";
import psy1 from "../../../utilities/pdfImages/psy11.jpg";

import psy2 from "../../../utilities/pdfImages/psy12.jpg";
import psy3 from "../../../utilities/pdfImages/psy13.jpg";
import psy4 from "../../../utilities/pdfImages/psy14.jpg";
import psy5 from "../../../utilities/pdfImages/psy15.jpg";
import psy6 from "../../../utilities/pdfImages/psy16.jpg";
import psy7 from "../../../utilities/pdfImages/psy17.jpg";
import HrloadingScreen from "../../LodingHrscreen";

import { exportMultipleChartsToPdf } from "./GraphUtils";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import ReactToPrint from "react-to-print";

import { ComponentToPrint } from "./ComponentToPrint";

export default function PrintPageForPsyclogical({ companyName, allApiData }) {
  return (
    <div>
      <div style={{}}>
        <img src={psy1} style={{ width: "100%", height: "100%" }}></img>
      </div>
      <div style={{ position: "relative" }}>
        <img src={psy2} style={{ width: "100%", height: "100%" }}></img>
        <Typography
          sx={{
            position: "absolute",
            top: "69.5%",
            left: "7%",
            fontSize: "35px",
            fontFamily: "Manrope",
            color: "#6846C7",
            fontWeight: "700px",
          }}
        >{`Psychological Safety at ${companyName}`}</Typography>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={psy3} style={{ width: "100%", height: "100%" }}></img>
        <Stack
          direction="row"
          sx={{
            width: "45%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border: "1px solid green",
            position: "absolute",
            top: "48%",
            left: "4%",
            zIndex: "3",
          }}
        >
          <Chart chartOptions={allApiData[0]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "40%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "48%",
            left: "50%",
          }}
        >
          <Chart chartOptions={allApiData[1]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "50%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "72%",
            left: "30%",
          }}
        >
          <Chart chartOptions={allApiData[2]} />
        </Stack>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={psy4} style={{ width: "100%", height: "100%" }}></img>
        <Stack
          direction="row"
          sx={{
            width: "45%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "48%",
            left: "7%",
          }}
        >
          <Chart chartOptions={allApiData[3]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "40%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "48%",
            left: "53%",
          }}
        >
          <Chart chartOptions={allApiData[4]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "50%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "72%",
            left: "30%",
          }}
        >
          <Chart chartOptions={allApiData[5]} />
        </Stack>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={psy5} style={{ width: "100%", height: "100%" }}></img>
        <Stack
          direction="row"
          sx={{
            width: "45%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "48%",
            left: "7%",
          }}
        >
          <Chart chartOptions={allApiData[6]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "40%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "48%",
            left: "53%",
          }}
        >
          <Chart chartOptions={allApiData[7]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "50%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",
            // border:"1px solid green",
            position: "absolute",
            top: "72%",
            left: "30%",
          }}
        >
          <Chart chartOptions={allApiData[8]} />
        </Stack>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <img src={psy6} style={{ width: "100%", height: "100%" }}></img>
        <Stack
          direction="row"
          sx={{
            width: "45%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",

            position: "absolute",
            top: "48%",
            left: "7%",
          }}
        >
          <Chart chartOptions={allApiData[9]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "40%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",

            position: "absolute",
            top: "48%",
            left: "53%",
          }}
        >
          <Chart chartOptions={allApiData[10]} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "50%",
            height: "250px",
            background: "#FFFFFF",
            borderRadius: "0px 0px 24px 24px",

            position: "absolute",
            top: "72%",
            left: "30%",
          }}
        >
          <Chart chartOptions={allApiData[11]} />
        </Stack>
      </div>
      <div style={{}}>
        <img src={psy7} style={{ width: "100%", height: "100%" }}></img>
      </div>
    </div>
  );
}
