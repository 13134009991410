import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import Notify from "./Dashboard/Notify";
import "./Dashboard/OverdueEnrollments.css";
import GenerateReport from "./Dashboard/popUpForReports";
import "./StyleAdminComponents/GroupList.css";

import axios from "axios";
import { getCookie } from "../../Cookies";
import { IP } from "../../baseUrlAndConfig";
import { DrawerContext } from "../../utilities/context/DrawerProvider";

function GenerateReportPage() {
  const { clrs } = useSelector((state) => state.createClr);
  // const location = useLocation();
  // const overDueInfo = location.state;
  // //console.log(overDueInfo);
  const overdueData = httpsCallable(functions, "overdueData");
  const uploadReports = httpsCallable(functions, "uploadReports");
  const fetchReports = httpsCallable(functions, "fetchReports");
  const fetchCompany = httpsCallable(functions, "fetchCompany");
  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);
  const [showLoading, setLoading] = useState(false);
  const [overDueArray, setOverDueArray] = useState(null);
  const [details, setDetails] = useState(null);
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayNotify, setDisplayNotify] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [newEmployeeArray, setNewEmployeeArray] = useState(null);

  const [selectDate, setSelectDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [selectDefault, setselectDefault] = useState("default");

  const [employeeName, setEmployeeName] = useState("");
  const [employeeNo, setEmployeeNo] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [selectAllEmployee, setSelectAllEmployee] = useState(false);

  const [reportIdFromReports, setReportIdFromReports] = useState();
  ///////Company DAta///////////

  const [companyDataName, setCompanyDataName] = useState([]);
  const [departmentDataName, setDepartmentDataName] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedComapnyName, setSelectedCompanyName] = useState("");
  const [selectedTrainingType, setSelectedTrainingType] = useState("");
  const [courseDataName, setCourseDatadName] = useState([]);
  const [tempDataForCourseName, setTempDataForCourseName] = useState([]);

  const [statusDataSetForStatus, setStatusDataSetForStatus] = useState("");
  const [tempDataArrAfterCourseFilter, setTempDataArrAfterCourseFilter] =
    useState([]);
  const [filteredDataFromStatusFilter, setFilteredDataFromStatusFilter] =
    useState([]);
  const [selectFromDate, setSelectFromDate] = useState("");
  const [selectToDate, setSelectToDate] = useState("");

  /////////////////////Group ////////
  const [getApiDataForGroupName, setGetApiDataForGroupName] = useState([]);
  const [selectGroupName, setSelectGroupName] = useState("");
  const [getDataForASelectedGroup, setGetDataForASelectedGroup] = useState([]);
  const [groupCourseName, setGroupCourseName] = useState("");
  const [getDataForASelectedCourse, setGetDataForASelectedCourse] = useState(
    []
  );
  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  //console.log("overDueArray", overDueArray);

  // //console.log(details);
  const setDefault = () => {
    setQuery(initState);
    setDetails(overDueArray);
    setArrLength(overDueArray.length);
    return;
  };
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const generateReportsFunction = () => {
    let tempreportDataArr = details.filter((e) => e.checked == true);
    //console.log(tempreportDataArr)
    let reportsDataArr = [];
    tempreportDataArr.map((e) => {
      let milisecon = e.joiningDate._seconds * 1000;
      let year = new Date(milisecon).getFullYear();
      let months = new Date(milisecon).getMonth();
      let days = new Date(milisecon).getDate();
      let fulldate = "";
      if (months < 9) {
        months = Number(months) + 1;
        months = "0" + months;
      } else {
        months = Number(months) + 1;
      }
      if (days <= 9) {
        days = "0" + days;
      } else {
        days = Number(days);
      }
      fulldate = `${year}-${months}-${days}`;
      // //console.log(fulldate,selestedDate)

      //console.log(e)

      if (selectDefault == "Employee") {
        let tempObj = {
          joiningDate: e.joiningDate
            ? new Date(e.joiningDate?._seconds * 1000)
                .toISOString()
                .split("T")[0]
            : "No Date",
          email: e.email,
          role: e.role,
          name: e.name,
          company: e.company,
          department: e.department,
          startDate: e.startDate
            ? new Date(e.startDate?._seconds * 1000).toISOString().split("T")[0]
            : "No Date",
          link: e.link ? e.link : "",
          jobTitle: e.jobTitle ? e.jobTitle : "",
          id: e.id ? e.id : "",
          expiry: e.expiry
            ? new Date(e.expiry?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          employeeId: e.employeeId ? e.employeeId : "",
          dueDate: e.dueDate
            ? new Date(e.dueDate?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          courseType: e.courseType ? e.courseType : "",
          courseName: e.courseName ? e.courseName : "",
          completionDate: e.completionDate
            ? new Date(e.completionDate?._seconds * 1000)
                .toISOString()
                .split("T")[0]
            : "No Date",
          archived: e.archived ? e.archived : "",
        };
        reportsDataArr.push(tempObj);
      } else if (selectDefault == "Group") {
        //console.log(new Date(e.dueDate?._seconds*1000).toISOString().split("T")[0])
        let tempObj = {
          joiningDate: fulldate,
          email: e.email,
          role: e.role,
          name: e.name,
          company: e.company,
          department: e.department,
          courseName: e.courseName ? e.courseName : "",
          groupName: selectGroupName.split("_")[0],
          issuedOn: e.issuedOn
            ? new Date(e.issuedOn?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          expiry: e.expiry
            ? new Date(e.expiry?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",

          dueDate: e.dueDate
            ? new Date(e.dueDate?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          status: e.status,
        };
        reportsDataArr.push(tempObj);
      } else if (selectDefault == "Company") {
        let tempObj = {
          joiningDate: fulldate,
          email: e.email,
          role: e.role,
          name: e.name,
          jobTitle: e.jobTitle ? e.jobTitle : "",
          company: e.company,
          department: e.department,

          courseId: e.courseId ? e.courseId : "NaN",
          courseName: e.courseName ? e.courseName : "",

          issuedOn: e.issuedOn
            ? new Date(e.issuedOn?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          expiry: e.expiry
            ? new Date(e.expiry?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",

          dueDate: e.dueDate
            ? new Date(e.dueDate?._seconds * 1000).toISOString().split("T")[0]
            : "NaN",
          status: e.status,
        };
        reportsDataArr.push(tempObj);
      }
    });
    //console.log(reportsDataArr)
    // "data":{},
    // "reportType": "",
    // "id": ""
    let reportId = `${selectDefault}_${Timestamp.fromDate(new Date()).seconds}`;
    setReportIdFromReports(reportId);
    //console.log(reportId)
    if (reportsDataArr.length > 0) {
      setReportLoading(false);
      setOpenEnrollment(true);
      let dataex = JSON.stringify({
        data: reportsDataArr,
        reportType: selectDefault,
        id: reportId,
      });
      let configExInt = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/reportGeneration`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setOpenEnrollment(false);
          setReportLoading(true);
          //console.log(res.data.link)
        })
        .catch((err) => {
          //console.log(err)
        });
    } else {
      alert("Select any user");
    }
  };

  const handleStatusChange = (e) => {
    setDetails([]);
    setArrLength(0);
    setCurrentPage(0);
    setSelectAllEmployee(false);
    setselectDefault(e);
  };

  useEffect(() => {
    // return
  }, []);

  const ApiCallAndGetData = () => {
    setLoading(true);
    if (employeeName) {
      //console.log(employeeName)
      let dataex = JSON.stringify({
        // data: reportsDataArr,
        // reportType: selectDefault,
        // id: reportId,
      });
      let configExInt = {
        method: "get",
        params: { select: selectDefault.toLowerCase(), name: employeeName },
        url: `${IP}/reportGeneration`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          //console.log(res.data)
          let filterdata = res.data.map((e) => ({ ...e, checked: false }));
          //console.log(filterdata)
          setDetails(filterdata);
          //console.log(res.data)
          setCurrentPage(0);
          setArrLength(filterdata.length);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (employeeNo) {
      setLoading(true);

      let dataex = JSON.stringify({
        // data: reportsDataArr,
        // reportType: selectDefault,
        // id: reportId,
      });
      let configExInt = {
        method: "get",
        params: { select: selectDefault.toLowerCase(), empId: employeeNo },
        url: `${IP}/reportGeneration`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          //console.log(res.data)
          let filterdata = res.data.map((e) => ({ ...e, checked: false }));
          //console.log(filterdata)
          setDetails(filterdata);
          setCurrentPage(0);
          setArrLength(filterdata.length);
          //console.log(res.data)
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (employeeEmail) {
      setLoading(true);

      let dataex = JSON.stringify({
        // data: reportsDataArr,
        // reportType: selectDefault,
        // id: reportId,
      });
      let configExInt = {
        method: "get",
        params: { select: selectDefault.toLowerCase(), email: employeeEmail },
        url: `${IP}/reportGeneration`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          //console.log(res.data)
          let filterdata = res.data.map((e) => ({ ...e, checked: false }));
          //console.log(filterdata)
          setDetails(filterdata);
          //console.log(res.data)
          setCurrentPage(0);
          setArrLength(filterdata.length);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const fetchDataCompanyApi = (e) => {
    setLoading(true);

    setSelectedTrainingType(e);

    let dataex = JSON.stringify({
      // data: reportsDataArr,
      // reportType: selectDefault,
      // id: reportId,
    });
    let configExInt = {
      method: "get",
      params: {
        select: selectDefault.toLowerCase(),
        selectTraining: Number(e),
      },
      url: `${IP}/reportGeneration`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setLoading(false);
        //console.log(res.data.data)
        let filterdata = res.data.data?.map((e) => ({ ...e, checked: false }));
        //console.log(filterdata)
        let filterTempArr = filterdata.filter(
          (item) => item.company == selectedComapnyName
        );
        let sortedDataARR = filterTempArr;
        if (selectedDepartment != "All") {
          sortedDataARR = filterTempArr.filter(
            (item) => item.department == selectedDepartment
          );
        }
        //console.log(sortedDataARR)

        let finalFilterData = sortedDataARR.map((item) => {
          let status = "In Progress";
          //console.log("data")
          if (
            item.issuedOn !== undefined &&
            item.issuedOn != "" &&
            item.expiry !== undefined &&
            item.expiry !== "" &&
            item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
          ) {
            status = "complete";
          } else if (
            item.issuedOn === undefined ||
            (item.issuedOn == "" &&
              item.dueDate !== undefined &&
              item.dueDate != "" &&
              item.dueDate?._seconds < Timestamp.fromDate(new Date()).seconds)
          ) {
            status = "overdue";
          } else if (
            item.expiry !== undefined &&
            item.expiry != "" &&
            item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
          ) {
            status = "expired";
          } else if (item.renewed == true) {
            status = "renew";
          } else if (item.finalStatus == "fail") {
            status = "fail";
          }
          return { ...item, status: status };
        });
        //console.log(finalFilterData)
        setDetails(finalFilterData);
        setTempDataForCourseName(finalFilterData);
        setCourseDatadName(finalFilterData);
        setArrLength(finalFilterData.length);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const CompanyDataFilterFunction = (e) => {
    //console.log("this is fillter data")
    if (details.length > 0) {
      //console.log(e)
      let temparr = courseDataName.filter((item) => item.courseName == e);
      setDetails(courseDataName.filter((item) => item.courseName == e));
      setTempDataArrAfterCourseFilter(temparr);
      //console.log(temparr)
      setArrLength(temparr.length);
    }
    //console.log(details)
  };

  const statusFilterCompany = (e) => {
    setStatusDataSetForStatus(e);

    //console.log(e)

    if (e == "all") {
      setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter);
      setDetails(tempDataArrAfterCourseFilter);
    } else if (e == "complete") {
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "complete") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "overdue") {
      //console.log(Timestamp.fromDate(new Date()).seconds)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        //console.log(item)
        if (item.status == "overdue") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "expired") {
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "expired") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "renew") {
      // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

      //    return item
      //  }

      // }
      //  )
      //  //console.log(dataTemp)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "renew") {
          return item;
        }
      });
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "failed") {
      // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

      //    return item
      //  }

      // }
      //  )
      //  //console.log(dataTemp)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "fail") {
          return item;
        }
      });
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    }

    // if(details.length>0&&e!="all"){
    //   //console.log(e)
    //   // let temparr=courseDataName.filter((item)=>item.courseName==e)
    //   //     setDetails(courseDataName.filter((item)=>item.courseName==e))
    //   //     //console.log(temparr)
    //   //     setArrLength(temparr.length)
    //   setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter)

    //    }else{
    //     setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter)
    //    }
  };

  const DateFilterDataSelected = (e) => {
    var myDate = selectFromDate;
    myDate = myDate.split("-");

    var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

    let secondsData = Timestamp.fromDate(newDate).seconds;

    var myDateToselect = selectToDate;
    myDateToselect = myDateToselect.split("-");

    var newDate = new Date(
      myDateToselect[0],
      myDateToselect[1] - 1,
      myDateToselect[2]
    );

    let secondsDataTodate = Timestamp.fromDate(newDate).seconds;
    //console.log(filteredDataFromStatusFilter)

    let filteredData = filteredDataFromStatusFilter.filter(
      (item) =>
        item.joiningDate._seconds > secondsData &&
        item.joiningDate._seconds < secondsDataTodate
    );

    setDetails(filteredData);

    setArrLength(filteredData.length);
    //console.log(filteredData)
  };

  const fetchGroupDataFromApi = (e) => {
    setLoading(true);
    //console.log(e.target.value)

    setSelectGroupName(e.target.value);
    let tempArr = getApiDataForGroupName.filter(
      (item) => item.groupId == e.target.value
    );
    //console.log(tempArr)
    let filterdata = tempArr[0].courseList.map((e) => ({
      ...e,
      courseName: e.id.split("_")[0],
    }));
    //console.log(filterdata)

    setGetDataForASelectedGroup(filterdata);
    setLoading(false);
    //console.log(getDataForASelectedGroup)
  };

  const callApiForGetCourse = (e, type) => {
    //console.log(e.target.value)

    if (type == "course") {
      setLoading(true);
      setGroupCourseName(e.target.value);
      if (e.target.value == "All") {
        let dataex = JSON.stringify({
          // data: reportsDataArr,
          // reportType: selectDefault,
          // id: reportId,
        });
        let configExInt = {
          method: "get",
          params: {
            select: "group",
            group: selectGroupName,
          },
          url: `${IP}/reportGeneration`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(configExInt)
          .then((res) => {
            setLoading(false);
            //console.log(res.data)
            let filterdata = res.data.map((e) => ({ ...e, checked: false }));
            //console.log(filterdata)
            //  setGetDataForASelectedCourse(filterdata)
            //  setDetails(filterdata)
            setArrLength(filterdata.length);

            let finalFilterData = filterdata.map((item) => {
              let status = "In Progress";
              if (
                item.issuedOn !== undefined &&
                item.issuedOn != "" &&
                item.expiry != "" &&
                item.expiry !== undefined &&
                item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "complete";
              } else if (
                item.issuedOn == "" &&
                item.dueDate !== undefined &&
                item.dueDate != "" &&
                item.dueDate?._seconds < Timestamp.fromDate(new Date()).seconds
              ) {
                status = "overdue";
              } else if (
                item.expiry !== undefined &&
                item.expiry != "" &&
                item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "expired";
              } else if (item.renewed == true) {
                status = "renew";
              } else if (item.finalStatus == "fail") {
                status = "fail";
              }
              return { ...item, status: status };
            });

            setGetDataForASelectedCourse(finalFilterData);
            setDetails(finalFilterData);

            //console.log(finalFilterData)
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        setLoading(true);

        let dataex = JSON.stringify({
          // data: reportsDataArr,
          // reportType: selectDefault,
          // id: reportId,
        });
        let configExInt = {
          method: "get",
          params: {
            select: "group",
            group: selectGroupName,
            course: e.target.value,
          },
          url: `${IP}/reportGeneration`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(configExInt)
          .then((res) => {
            setLoading(false);
            //console.log(res.data)
            let filterdata = res.data.map((e) => ({ ...e, checked: false }));
            //console.log(filterdata)

            setArrLength(filterdata.length);

            let finalFilterData = filterdata.map((item) => {
              let status = "In Progress";
              if (
                item.issuedOn !== undefined &&
                item.issuedOn != "" &&
                item.expiry != "" &&
                item.expiry !== undefined &&
                item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "complete";
              } else if (
                item.issuedOn == undefined ||
                (item.issuedOn == "" &&
                  item.dueDate !== undefined &&
                  item.dueDate != "" &&
                  item.dueDate?._seconds <
                    Timestamp.fromDate(new Date()).seconds)
              ) {
                status = "overdue";
              } else if (
                item.expiry !== undefined &&
                item.expiry != "" &&
                item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "expired";
              } else if (item.renewed == true) {
                status = "renew";
              } else if (item.finalStatus == "fail") {
                status = "fail";
              }
              return { ...item, status: status };
            });

            setGetDataForASelectedCourse(finalFilterData);
            setDetails(finalFilterData);

            //console.log(finalFilterData)
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }

    // <option value="all">All</option>
    // <option value="overdue">Overdue</option>
    // <option value="complete">Complete</option>
    // <option value="Expired">Expired</option>
    // <option value="renew">Renew</option>
    else if (type == "status") {
      if (e.target.value == "all") {
        setDetails(getDataForASelectedCourse);
      } else if (e.target.value == "complete") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "complete") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "overdue") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "overdue") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "expired") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "expired") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "renew") {
        // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

        //    return item
        //  }

        // }
        //  )
        //  //console.log(dataTemp)
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "renew") {
            return item;
          }
        });
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "failed") {
        // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

        //    return item
        //  }

        // }
        //  )
        //  //console.log(dataTemp)
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "fail") {
            return item;
          }
        });
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom)
      .then((res) => {
        setCompanyDataName(res.data);
        setLoading(false);
        //console.log(res.data)
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
      });
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((res) => {
      setDepartmentDataName(res.data);
      //console.log(res.data)
    });

    let dataGo = JSON.stringify({});
    let configGo = {
      method: "get",

      url: `${IP}/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataGo,
    };
    axios(configGo).then((res) => {
      setGetApiDataForGroupName(res.data);
      //console.log(res.data)
    });
  }, [selectDefault]);

  const hendelChecked = (id, type) => {
    let newTempData = [];
    //console.log(type)
    if (type === "All") {
      setSelectAllEmployee(!selectAllEmployee);
      details.map((e) => {
        //console.log(e.checked)
        e.checked = !selectAllEmployee;
        //console.log(e.checked)
        newTempData.push(e);
      });
    } else if (type == "one") {
      details.map((e, index) => {
        //console.log(id)
        if (index === id) {
          //console.log(e.checked)
          e.checked = e.checked ? false : true;
          //console.log(e.checked)
          newTempData.push(e);
        } else {
          newTempData.push(e);
        }
      });
    }
    //console.log(newTempData)
    setDetails(newTempData);
  };

  const selectedDateTohumanDate = (type) => {
    // //console.log(result.data)
    //  setDetails(newEmployeeArray);

    if (type == "clear") {
      setCompanyDataName([]);
      setDepartmentDataName([]);
      setEmployeeName("");
      setSelectedDepartment("");
      setSelectedCompanyName("");
      setSelectedTrainingType("");
      setCourseDatadName([]);
      setTempDataForCourseName([]);
      setGroupCourseName("");
      setStatusDataSetForStatus("");
      setFilteredDataFromStatusFilter([]);
      setTempDataArrAfterCourseFilter([]);
      setSelectFromDate("");
      setSelectToDate("");
      setEmployeeNo("");
      setEmployeeEmail("");

      setSelectGroupName("");
      setDetails([]);
      setArrLength(0);
      setCurrentPage(0);
    }
    // //console.log(newarr)
  };

  // //console.log("courseName",courseName);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {displayNotify && <Notify NotifyClick={NotifyClick} />}
      <Navbar page={t("reports")} />
      <div className="createcourse-main">
        <Sidebar page={"reports"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("Generate Reports")}
            </div>
          </div>

          {/* <div
            style={{
              marginLeft: "71vw",
              marginTop: "-3vw",
              marginBottom: "2vw",
            }}
            className="admin-dashboard-main-top-filter"
          >
            <button
              style={{
                background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
              }}
            >
              <div>{t("Generate Reports")}</div>
            </button>
          </div> */}

          <div
            style={{
              marginLeft: "81.5vw",
              marginTop: "-4vw",
              marginBottom: "2vw",
              display: "flex",
            }}
            className="admin-dashboard-main-top-filter"
          >
            {reportLoading ? (
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  generateReportsFunction();
                }}
              >
                <div>{t("Generate Reports")}</div>
              </button>
            ) : (
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                // onClick={()=>{generateReportsFunction()}}
              >
                <div>{t("Generating reports ")}</div>
              </button>
            )}
          </div>
          <div className="admin-overdue-mid" style={{}}>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Reports Type")}
              </div>

              <div className="admin-overdue-mid-left-input">
                <select
                  name="courseName"
                  value={selectDefault}
                  onChange={(e) => {
                    handleStatusChange(e.target.value);
                  }}
                  id="company"
                >
                  <option value="">Select Report Type</option>
                  <option value="Employee">Employee</option>
                  <option value="Group">Group</option>
                  <option value="Company">Company</option>
                </select>
              </div>
            </div>
          </div>

          <div className="admin-overdue-mid" style={{}}>
            {selectDefault == "Group" && (
              <>
                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Group Name")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
                    <select
                      name="group"
                      id="group"
                      value={selectGroupName}
                      onChange={fetchGroupDataFromApi}
                    >
                      <option value="" selected disabled hidden>
                        Select a Group
                      </option>
                      {getApiDataForGroupName?.map((e) => (
                        <option value={e.groupId}>{e.groupName}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Group Enrollment")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
                    <select
                      name="groupenroll"
                      id="group"
                      value={groupCourseName}
                      onChange={(e) => {
                        callApiForGetCourse(e, "course");
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Select a Course
                      </option>
                      <option value="All">All</option>
                      {getDataForASelectedGroup?.map((item) => (
                        <option value={item.id}>{item.courseName}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Status")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <select
                      name="groupStatus"
                      id="group"
                      onChange={(e) => {
                        callApiForGetCourse(e, "status");
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Select a status
                      </option>
                      <option value="all">All</option>
                      <option value="overdue">Overdue</option>
                      <option value="complete">Complete</option>
                      <option value="failed">Failed</option>
                      <option value="expired">Expired</option>
                      <option value="renew">Renew</option>
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-right">
                  {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                    <div
                      className="admin-dashboard-main-mid-item-last-filter"
                      style={{ marginTop: "5vh" }}
                    >
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                            width: "8vw",
                          }}
                          onClick={() => {
                            selectedDateTohumanDate("clear");
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Clear")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectDefault == "Company" && (
              <>
                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Company Name")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
                    <select
                      name="group"
                      id="group"
                      value={selectedComapnyName}
                      onChange={(e) => {
                        setSelectedCompanyName(e.target.value);
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Select a company
                      </option>
                      {companyDataName?.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Department")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
                    <select
                      name="groupenroll"
                      id="group"
                      onChange={(e) => {
                        setSelectedDepartment(e.target.value);
                      }}
                    >
                      <option value="all">All</option>
                      {departmentDataName?.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Type of training")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <select
                      name="trainingType"
                      id="company"
                      onChange={(e) => fetchDataCompanyApi(e.target.value)}
                    >
                      <option value="none" selected disabled hidden>
                        Select Training Type
                      </option>
                      <option value="1">internalLMSTrainingInfo</option>
                      <option value="0">externalTrainingInfo</option>
                      <option value="2">internalOfflineTrainingInfo</option>
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Course Name")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <select
                      name="CourseName"
                      id="company"
                      onChange={(e) => {
                        CompanyDataFilterFunction(e.target.value);
                      }}
                    >
                      <option value="none" selected disabled hidden>
                        Select course
                      </option>
                      {tempDataForCourseName?.map((item) => (
                        <option value={item.courseName}>
                          {item.courseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
            {selectDefault == "Employee" && (
              <>
                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Employee Name")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <input
                      type="text"
                      value={employeeName}
                      onChange={(e) => {
                        setEmployeeName(e.target.value);
                      }}
                    />
                    {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
                  </div>
                </div>
                <div
                  style={{ marginTop: "3vw" }}
                  className="admin-overdue-mid-right"
                >
                  {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/*     <input type="date" placeholder="Select Date" /> */}
                    <div className="admin-dashboard-main-mid-item-last-filter">
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          onClick={() => {
                            ApiCallAndGetData("");
                          }}
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Filter")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Empoyee No")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <input
                      type="text"
                      value={employeeNo}
                      onChange={(e) => {
                        setEmployeeNo(e.target.value);
                      }}
                    />
                    {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
                  </div>
                </div>
                <div
                  style={{ marginTop: "3vw" }}
                  className="admin-overdue-mid-right"
                >
                  {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/*     <input type="date" placeholder="Select Date" /> */}
                    <div className="admin-dashboard-main-mid-item-last-filter">
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          onClick={() => {
                            ApiCallAndGetData("company");
                          }}
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Filter")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Empoyee Email")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <input
                      type="text"
                      value={employeeEmail}
                      onChange={(e) => {
                        setEmployeeEmail(e.target.value);
                      }}
                    />
                    {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
                  </div>
                </div>
                <div
                  style={{ marginTop: "3vw" }}
                  className="admin-overdue-mid-right"
                >
                  {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/*     <input type="date" placeholder="Select Date" /> */}
                    <div className="admin-dashboard-main-mid-item-last-filter">
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          onClick={() => {
                            ApiCallAndGetData("company");
                          }}
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Filter")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-overdue-mid-right">
                  {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                    <div
                      className="admin-dashboard-main-mid-item-last-filter"
                      style={{ marginTop: "5vh" }}
                    >
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                            width: "8vw",
                          }}
                          onClick={() => {
                            selectedDateTohumanDate("clear");
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Clear")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="admin-overdue-mid" style={{}}>
            {selectDefault == "Company" && (
              <>
                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("Status")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <select
                      name="Status"
                      id="company"
                      onChange={(e) => {
                        statusFilterCompany(e.target.value);
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Select a status
                      </option>
                      <option value="all">All</option>
                      {selectedTrainingType == "1" && (
                        <>
                          <option value="complete">Complete</option>
                          <option value="overdue">Overdue</option>
                          <option value="failed">Failed</option>
                          <option value="expired">Expired</option>
                          <option value="renew">Renew</option>
                        </>
                      )}

                      {selectedTrainingType == "0" && (
                        <>
                          <option value="complete">Complete</option>
                          <option value="overdue">Overdue</option>

                          <option value="expired">Expired</option>
                          <option value="renew">Renew</option>
                        </>
                      )}
                      {selectedTrainingType == "2" && (
                        <>
                          <option value="complete">Complete</option>
                          <option value="overdue">Overdue</option>

                          <option value="expired">Expired</option>
                          <option value="renew">Renew</option>
                        </>
                      )}
                      {/* <option value="complete">Complete</option>
                 <option value="overdue">Overdue</option>
                 <option value="failed">Failed</option>
                 <option value="Expired">Expired</option>
                 <option value="renew">Renew</option> */}
                    </select>
                  </div>
                </div>

                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("From Date")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <input
                      type="date"
                      disabled={statusDataSetForStatus == "all" ? true : false}
                      value={selectFromDate}
                      onChange={(e) => {
                        setSelectFromDate(e.target.value);
                      }}
                    />

                    {/* if status is all then need to disabled the date */}
                    {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
                  </div>
                </div>
                <div className="admin-overdue-mid-left">
                  <div className="admin-overdue-mid-left-txt">
                    {t("To Date")}
                  </div>
                  <div className="admin-overdue-mid-left-input">
                    <input
                      type="date"
                      value={selectToDate}
                      disabled={statusDataSetForStatus == "all" ? true : false}
                      onChange={(e) => {
                        setSelectToDate(e.target.value);
                      }}
                    />
                    {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
                  </div>
                </div>
                <div
                  style={{ marginTop: "3vw" }}
                  className="admin-overdue-mid-right"
                >
                  {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/*     <input type="date" placeholder="Select Date" /> */}
                    <div className="admin-dashboard-main-mid-item-last-filter">
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          onClick={() => {
                            DateFilterDataSelected("date");
                          }}
                          style={{
                            background: `${
                              statusDataSetForStatus == "all"
                                ? "rgb(215,215,215)"
                                : "rgb(36,24,135)"
                            }`,
                          }}
                          disabled={
                            statusDataSetForStatus == "all" ? true : false
                          }
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Filter")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-overdue-mid-right">
                  {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
                  <div className="admin-overdue-mid-left-input-right">
                    {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                    <div
                      className="admin-dashboard-main-mid-item-last-filter"
                      style={{ marginTop: "5vh" }}
                    >
                      {" "}
                      <div className="admin-dashboard-main-top-last-filter">
                        <button
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                            width: "8vw",
                          }}
                          onClick={() => {
                            selectedDateTohumanDate("clear");
                          }}
                        >
                          <div>
                            <svg
                              width="auto"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>{t("Clear")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <GenerateReport
            open={openEnrollment}
            onClose={() => setOpenEnrollment(false)}
            reportId={reportIdFromReports}
          />
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              {selectDefault == "Employee" && (
                <>
                  <div
                    className="admin-overdue-bottom-table-cnt"
                    style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                  >
                    {/*     <div style={{width:"1vw"}} className="admin-overdue-bottom-table-head"></div> */}
                    <div className="admin-overdue-bottom-table-head">
                      {" "}
                      <input
                        style={{
                          height: "1vw",
                          width: "2vw",
                          marginRight: "2vw",
                          marginLeft: "-3vw",
                        }}
                        type="checkbox"
                        onClick={() => {
                          hendelChecked("tets", "All");
                        }}
                        checked={selectAllEmployee}
                      />
                      {t("SrNo")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Name")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("employeeId")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Company")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("email")}
                    </div>

                    <div className="admin-overdue-bottom-table-head">
                      {t("role")}
                    </div>

                    {/* <div className="admin-overdue-bottom-table-head">{t("Action")}</div> */}
                  </div>
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}
                  {selectDefault == "Employee" &&
                    details?.map((item, id) => {
                      if (id < pageSize * currentPage) return;
                      if (id >= pageSize * (currentPage + 1)) return;

                      return (
                        <div
                          className="admin-overdue-bottom-table-cnt"
                          key={id}
                          style={{
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                          }}
                        >
                          <div className="admin-overdue-bottom-table-td">
                            <input
                              style={{
                                height: "1vw",
                                width: "2vw",
                                marginRight: "2vw",
                                marginLeft: "-3vw",
                              }}
                              type="checkbox"
                              onClick={() => {
                                hendelChecked(id, "one");
                              }}
                              checked={item.checked}
                            />
                            {id + 1}
                          </div>
                          <div className="admin-overdue-bottom-table-td">
                            {item.name}
                          </div>
                          <div className="admin-overdue-bottom-table-td">
                            {item.employeeId}
                          </div>
                          <div className="admin-overdue-bottom-table-td">
                            {item.company}
                          </div>
                          <div className="admin-overdue-bottom-table-td">
                            {item.email}
                          </div>

                          <div className="admin-overdue-bottom-table-td">
                            {item.role}
                          </div>

                          {/* <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                      onClick={NotifyClick}
                    >
                      {item.Action} */}
                          {/* </div> */}
                        </div>
                      );
                    })}
                </>
              )}
              {selectDefault != "Employee" && (
                <>
                  <div
                    className="admin-overdue-bottom-table-cnt"
                    style={{
                      gridTemplateColumns:
                        "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    {/*     <div style={{width:"1vw"}} className="admin-overdue-bottom-table-head"></div> */}
                    <div className="admin-overdue-bottom-table-head">
                      {" "}
                      <input
                        style={{
                          height: "1vw",
                          width: "2vw",
                          marginRight: "2vw",
                          marginLeft: "-3vw",
                        }}
                        type="checkbox"
                        onClick={() => {
                          hendelChecked("tets", "All");
                        }}
                        checked={selectAllEmployee}
                      />
                      {t("SrNo")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Name")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("employeeId")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Company")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("email")}
                    </div>

                    <div className="admin-overdue-bottom-table-head">
                      {t("role")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Course")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("expiry")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("issuedOn")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("dueDate")}
                    </div>
                    <div className="admin-overdue-bottom-table-head">
                      {t("Status")}
                    </div>
                    {/* <div className="admin-overdue-bottom-table-head">{t("Action")}</div> */}
                  </div>
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}
                  {details?.map((item, id) => {
                    if (id < pageSize * currentPage) return;
                    if (id >= pageSize * (currentPage + 1)) return;

                    let dueDate = item.dueDate
                      ? new Date(item.dueDate?._seconds * 1000)
                          .toISOString()
                          .split("T")[0]
                      : "No Date";
                    let issuedOn = item.issuedOn
                      ? new Date(item.issuedOn?._seconds * 1000)
                          .toISOString()
                          .split("T")[0]
                      : "No Date";
                    let expiry = item.expiry
                      ? new Date(item.expiry?._seconds * 1000)
                          .toISOString()
                          .split("T")[0]
                      : "No Date";
                    return (
                      <div
                        className="admin-overdue-bottom-table-cnt"
                        key={id}
                        style={{
                          gridTemplateColumns:
                            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                        }}
                      >
                        <div className="admin-overdue-bottom-table-td">
                          <input
                            style={{
                              height: "1vw",
                              width: "2vw",
                              marginRight: "2vw",
                              marginLeft: "-3vw",
                            }}
                            type="checkbox"
                            onClick={() => {
                              hendelChecked(id, "one");
                            }}
                            checked={item.checked}
                          />
                          {id + 1}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.name}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.employeeId}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.company}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.email}
                        </div>

                        <div className="admin-overdue-bottom-table-td">
                          {item.role}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.courseName}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {expiry}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {issuedOn}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {dueDate}
                        </div>
                        <div className="admin-overdue-bottom-table-td">
                          {item.status}
                        </div>

                        {/* <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                      onClick={NotifyClick}
                    >
                      {item.Action} */}
                        {/* </div> */}
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/course-reports"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateReportPage;
