import React, { useEffect } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";

export default function PsyclogicalList() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [template, settemplate] = useState([
    { name: "TCS" },
    { name: "Wipro" },
    { name: "Amazon" },
  ]);

  const getDataFromApi = () => {
    var config = {
      method: "get",

      url: `${IP}/inclusionStudy/generateUrl`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config).then((res) => {
      settemplate(res.data);
    });
  };

  useEffect(() => {
    getDataFromApi();
  }, []);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Psyclogical safety")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() =>
                      navigate("/admin/settings/CreatePsyclogicalForm")
                    }
                  >
                    Add
                  </Button>{" "}
                </div>
              </div>

              <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
                <div
                  className="eventTabel eventTabelTabelHeders"
                  style={{
                    gridGap: "30px",
                  }}
                >
                  <div> S.No</div>
                  <div>Company Name </div>
                  <div>Survey</div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div>Action</div>
                </div>

                {/* <div className="webinarsTabel webinarsTabelTabelBody"> */}
                {template?.map((ele, index) => {
                  return (
                    <div
                      className="eventTabel eventTabelTabelBody borderStyleFortabel"
                      style={{
                        lineBreak: "anywhere",
                        gridGap: "30px",
                      }}
                    >
                      <div>{index + 1}</div>

                      <div>{ele.name}</div>
                      <div>
                        {" "}
                        {ele.survey == "0"
                          ? "Psychological safety"
                          : "Bystander intervention"}
                      </div>
                      <div></div>
                      <div></div>
                      <div></div>

                      {/* <div>
                        {Timestamp.fromMillis(ele.date._seconds * 1000)
                          .toDate()
                          .toDateString()}
                      </div> */}

                      <div style={{ display: "flex" }}>
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/CreatePsyclogicalForm",
                              state: { ele },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              View
                            </Button>
                          </Link>
                        </div>

                        {/* <div className="button-box-inner mx-3 my-1">
                          <Button
                            // onClick={() => {
                            //   eventdelete(ele);
                            // }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            delete
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
