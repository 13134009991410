import { Box, grid } from "@mui/system";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";

import { getCookie } from "../../Cookies";
// import { RightArrowIcon } from "../icons/Icons";
import Download from "./Download.svg";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Timestamp } from "firebase/firestore";
import SearchIcon from "@mui/icons-material/Search";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { IP } from "../../baseUrlAndConfig";
import HrloadingScreen from "../LodingHrscreen";
import GenerateReportForLocal from "./reportPopUp";
import DontSendMailToMails from "./DSMTE";
import { saveAs } from "file-saver";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import SendRemainderPopUp from "./confirmationPopUp";
import MobileTopBar from "../Sidebar/MobaileTopBar";
import "./viewReportForGroup.css";
import { toast } from "react-toastify";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
const GroupReportView = () => {
  const [lodingscreen, setlodingscreen] = useState(false);
  const [reportClouse, setReportClouse] = useState(false);
  const [dontsendmailtoemail, setDontsendmailtoemail] = useState(false);
  const [confirmation, setConfirmation] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const [selectAllEmployee, setSelectAllEmployee] = useState(false);
  const [complitedarr, setComplitedarr] = useState([]);
  const [extraarr, setextrarr] = useState([]);
  const [combainarr, setcombainarr] = useState([]);
  const [reportArrData, setReportArrData] = useState([]);
  const [reportArrDataTwo, setReportArrDataTwo] = useState([]);
  const [generateReportPopUp, setgenerateReportPopUp] = useState(false);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [allSelectOption, setAllSelectOption] = useState(false);
  const [extraShortArr, setExtraShortArr] = useState([]);
  const [userName, setUserName] = useState("");
  const [emailid, setEmailid] = useState("");
  const [employeeNameOpt, setEmployeeNameOpt] = useState(true);
  const [showResetButton, setShowResetButton] = useState(false);

  const [failedarr, setFailedarr] = useState([]);
  const [countdata, setCountdata] = useState({
    total: "0",
    passed: "0",
    failed: "0",
  });
  const tempMap = {
    course: "employee",
    microcourse: "microemployee",
    games: "gamesemployee",
  };
  const [department, setDepartment] = useState([]);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    if (!rolesAndPermission) {
      setlodingscreen(false);
    } else {
      setlodingscreen(false);

      let newobj =
        rolesAndPermission?.Report?.componentPermission.includes("Report");
      // console.log(newobj.HRDI);
      if (!newobj) {
        navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);
  console.log(location.state.type);
  const generateReport = () => {
    // console.log(data);const
    const name = location.state.name;

    const pdfName = name;
    const pdf = new jsPDF("p", "pt", "a2");

    const columns = Object.keys(combainarr[0]);
    columns.pop();
    columns.pop();
    columns.unshift("Sr.No.");
    console.log(columns);
    var rows = [];
    //   console.log({ rows });
    combainarr.forEach((e, index) => {
      const createDate = new Date(e.createdAt).toLocaleDateString();
      const updatedDate = new Date(e.updatedAt).toLocaleDateString();
      const status = e.status ? "Active" : "InActive";
      var temp = Object.values(e);
      temp.pop();
      temp.pop();
      temp[temp.length - 1] = e?.completionDate
        ? new Date(e?.completionDate?._seconds * 1000).toLocaleDateString()
        : "";
      temp.unshift(index + 1);
      rows.push(temp);
    });
    pdf.text(100, 30, pdfName);
    // var img = new Image();
    // img.src = img;
    // console.log(img.src);
    // pdf.addImage(goss, "png", 50, 50, 300, 100);
    // pdf.addImage(moraikh, "png", 350, 50, 250, 100);
    // pdf.addImage(ocasco, "png", 600, 50, 250, 100);
    // pdf.addImage(alghat, "png", 850, 50, 250, 100);

    // pdf.addImage(

    //   400,
    //   40
    // );
    pdf.autoTable(columns, rows, {
      startY: 50,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 10,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    let rses = pdf.save(name + Date.now());

    console.log(pdf.output("bloburl"));
    // console.log(rows);
    // console.log(master);
  };

  const getcoursedata = (id) => {
    setlodingscreen(true);
    let configrecrutci = {
      method: "get",
      params: {
        // courseId: id,
        courseName: location.state.name,
        // groupId: location.state.groupId,
      },
      url: `${IP}/hrGroupReport/employee/${location.state.groupId}/${id}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        setlodingscreen(false);

        setCountdata({
          total: res.data?.length,
          passed: res.data
            .filter((el) => el.status == "Completed")
            .map((el) => ({ ...el, select: false }))?.length,
          failed: res.data
            .filter((el) => el.status == "Not Completed")
            .map((el) => ({ ...el, select: false }))?.length,
        });
        setFailedarr(
          res.data
            .filter((el) => el.status == "Not Completed")
            .map((el) => ({ ...el, select: false }))
        );
        setComplitedarr(
          res.data
            .filter((el) => el.status == "Completed")
            .map((el) => ({ ...el, select: false }))
        );

        setextrarr(res.data);
        setcombainarr(res.data);
        // setCoursedata(res.data);
      })
      .catch((err) => {
        setlodingscreen(false);

        //console.log(err);
      });
  };

  const getmicrocoursedata = (id, type) => {
    setlodingscreen(true);

    let configrecrutci = {
      method: "get",
      params: {
        courseId: id,
        name: location.state.name,
        groupId: location.state.groupId,
      },
      url:
        type == "microcourse"
          ? `${IP}/hrGroupReport/microemployee`
          : `${IP}/hrGroupReport/gamesemployee`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        setlodingscreen(false);

        setCountdata({
          total: res.data?.length,
          passed: res.data
            .filter((el) => el.status == "Completed")
            .map((el) => ({ ...el, select: false }))?.length,
          failed: res.data
            .filter((el) => el.status == "Not Completed")
            .map((el) => ({ ...el, select: false }))?.length,
        });
        setFailedarr(
          res.data
            .filter((el) => el.status == "Not Completed")
            .map((el) => ({ ...el, select: false }))
        );
        setComplitedarr(
          res.data
            .filter((el) => el.status == "Completed")
            .map((el) => ({ ...el, select: false }))
        );

        setextrarr(res.data);
        setcombainarr(res.data);
        // setCoursedata(res.data);
      })
      .catch((err) => {
        setlodingscreen(false);

        //console.log(err);
      });
  };

  const hendelChecked = (id, type) => {
    let newTempData = [];
    //console.log(type)
    if (type === "All") {
      console.log("jkvjkdsfkjs");
      setSelectAllEmployee(!selectAllEmployee);
      combainarr.map((e) => {
        //console.log(e.checked)
        e.select = !selectAllEmployee;
        //console.log(e.checked)
        newTempData.push(e);
      });
    } else if (type == "one") {
      combainarr.map((e, index) => {
        //console.log(id)
        if (index === id) {
          //console.log(e.checked)
          e.select = e.select ? false : true;
          //console.log(e.checked)
          newTempData.push(e);
        } else {
          newTempData.push(e);
        }
      });
    }
    //console.log(newTempData)
    setcombainarr(newTempData);
  };
  const handelSelect = (type, data) => {
    console.log(data);
    if (type == "complited") {
      if (data == "All") {
        // setcombainarr(extraarr);
        // setShortUserList(extraShortArr);
        return;
      }
      const config = {
        method: "get",
        params: {
          courseName: location.state.name,
        },

        url: `${IP}/hrGroupReport/employee/${location.state.groupId}/${location.state.courseId}?status=${data}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(config).then((res) => {
        setShortUserList(res.data.data);
        setExtraShortArr(res.data.data);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.prev);
        setCurrPage(res.data.c_page);
        setTotalPage(res.data.t_page);
        setTotalEmployee(res.data.t_count);
      });
      //   let tempdata = extraShortArr.filter((el) => el.status == data);
      //   const
      //   console.log(tempdata);
      //   setShortUserList(tempdata);
      //   setcombainarr([]);
      //   setcombainarr(tempdata);
    }

    if (type == "department") {
      if (data == "All") {
        setcombainarr(extraarr);
        return;
      }
      let tempdata = extraarr.filter((el) => el?.departmentName == data);
      console.log(tempdata);
      setcombainarr([]);
      setcombainarr(tempdata);
    }
  };

  const getDepartmentData = () => {
    let configrecrutci = {
      method: "get",

      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        setDepartment(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let locationdata = location.state;
    console.log(locationdata);
    // if (locationdata.type == "course") {
    //   getcoursedata(locationdata.courseId);
    // } else if (locationdata.type == "microcourse") {
    //   getmicrocoursedata(locationdata.courseId, "microcourse");
    // } else {
    //   getmicrocoursedata(locationdata.courseId, "games");
    // }

    getDepartmentData();
    getShortUsers();
  }, []);

  const sendremainder = () => {
    let temparr = checkedList;
    // let temparr = combainarr.filter((el) => el.select == true);
    console.log(checkedList);
    // alert("ok");S
    if (temparr.length == 0) {
      alert("select user");
      return;
    }
    setReportClouse(true);
  };

  const handelserch = (data) => {
    let arr = extraarr.filter((el) =>
      el.userName.toLowerCase().includes(data.toLowerCase())
    );
    setcombainarr(arr);
  };

  const getShortUsers = () => {
    setlodingscreen(true);
    const config = {
      method: "get",
      params: {
        courseName: location.state.name,
      },

      url: `${IP}/hrGroupReport/${tempMap[location.state.type]}/${
        location.state.groupId
      }/${location.state.courseId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      setShortUserList(res.data.data);
      setExtraShortArr(res.data.data);
      setNextUrl(res.data.next);
      setPrevUrl(res.data.prev);
      setCurrPage(res.data.c_page);
      setTotalPage(res.data.t_page);
      setTotalEmployee(res.data.t_count);
      setlodingscreen(false);
    });
  };
  const handleDownload = (url) => {
    // Prevent the default link behavior

    const fileUrl = url.split("//")[1];
    console.log(fileUrl);

    // let configrecrutci = {
    //   method: "get",

    //   url: `https://decapoda.nhm.org/pdfs/32295/32295-001.pdf`,

    //   headers: {
    //     "Content-Type": "application/json",
    //     // Authorization: `Bearer ${getCookie("bearer")}`,
    //   },
    //   data: "dataregionf",
    // };
    // axios(configrecrutci).then((res) => {
    //   console.log(res.data);
    // });

    // Use the 'saveAs' function from 'file-saver' to download the file
    saveAs(url, "downloaded-file.pdf");
  };

  const delfunction = (type, isAllSelected) => {
    console.log("line 436", isAllSelected);
    if (type == "Yes" && isAllSelected) {
      // let temparr = combainarr
      //   .filter((el) => el.select == true)
      //   .map((el) => {
      //     return {
      //       userName: el.userName,
      //       userId: el.userId,
      //       email: el.email,
      //     };
      //   });
      console.log("i am on line 419", shortUserList);
      let temparr = [];

      checkedList.map((_, i) => {
        // const fInd = shortUserList.indexOf(_);
        for (let i = 0; i < shortUserList.length; i++) {
          if (shortUserList[i].userId == _) {
            temparr.push({
              name: shortUserList[i].userName,
              userId: shortUserList[i].userId,
              email: shortUserList[i].email,
            });
          }
        }
        // console.log("SHRRY", fInd);
        // if (fInd) {

        // }
      });
      // let temparr = checkedList;

      // if (temparr.length == 0) {
      //   alert("select user");
      //   return;
      // }
      let tempobj = {};
      tempobj["courseName"] = location.state.name;
      tempobj["groupId"] = location.state.groupId;
      tempobj["courseId"] = location.state.courseId;
      tempobj["users"] = temparr;

      const dataregionf = JSON.stringify(tempobj);
      let configrecrutci = {
        method: "post",

        url: `${IP}/mail/userFailed`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataregionf,
      };
      axios(configrecrutci).then((res) => {
        console.log("I am res.data", res.data);
        toast.success("email sent successfully");
        // setConfirmation()
      });
    } else {
      let tempobj = {};
      tempobj["courseName"] = location.state.name;
      tempobj["groupId"] = location.state.groupId;
      tempobj["courseId"] = location.state.courseId;

      const dataregionf = JSON.stringify(tempobj);
      let configrecrutci = {
        method: "post",

        url: `${IP}/mail/userFailed`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataregionf,
      };
      axios(configrecrutci).then((res) => {
        console.log("I am res.data", res.data);
        toast.success("email sent successfully");

        // setConfirmation()
      });
    }
  };

  const downloadreport = () => {
    const config = {
      method: "get",
      params: {
        courseName: location.state.name,
        download: 1,
      },

      url: `${IP}/hrGroupReport/${tempMap[location.state.type]}/${
        location.state.groupId
      }/${location.state.courseId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((response) => {
      const text = response.data; // Assuming response.data is a string containing CSV data

      // Create a Blob with UTF-8 encoding and a BOM
      const utf8EncodedText = new TextEncoder().encode("\uFEFF" + text); // \uFEFF is the BOM for UTF-8

      const blob = new Blob([utf8EncodedText], {
        type: "text/csv; charset=UTF-8",
      });

      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const formattedDate = Date.now();

      const a = document.createElement("a");
      a.href = url;

      // a.download = `menu-template-${
      //   restaurantData?.name ? restaurantData?.name[language] : "name"
      // }-${formattedDate}.csv`; // Specify the file name with a .csv extension
      const date = new Date();
      a.download = `report_${date}.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
    });
    // let temparr = [];
    // let temparrtwo = [];

    // setgenerateReportPopUp(true);

    // combainarr.map((el, index) => {
    //   let obj = {};

    //   // <Box> Employee name</Box>
    //   // <Box> Total lessons</Box>
    //   // <Box> Lessons completed</Box>
    //   // <Box> Last step completed</Box>
    //   // <Box> Start date</Box>
    //   // <Box> Completion date</Box>

    //   // <Box> Status</Box>
    //   obj["User Id"] = el.userId;
    //   obj["Employee name"] = el.userName;
    //   obj["Email"] = el.email;
    //   obj["Course Id"] = el.courseId;
    //   obj["Course name"] = el.courseName;
    //   obj["Total lessons"] = el.totalLessonCount;
    //   obj["Lessons completed"] = el.lessonCompleted;
    //   obj["Last step completed"] = el.course_last_step;
    //   obj["Start date"] = el.course_started_on;
    //   obj["Completion date"] = el.course_completed_on;
    //   obj["Invitation accepted"] = el?.invitationAccept;

    //   obj["Status"] = el.status;

    //   if (index < 2500) {
    //     temparr.push(obj);
    //   } else {
    //     temparrtwo.push(obj);
    //   }
    // });
    // console.log(temparr);
    // setReportArrDataTwo(temparrtwo);
    // setReportArrData(temparr);
  };
  const [unChecked, setUnChecked] = useState([]);
  const handleChecked = (event) => {
    var updatedList = [...checkedList];
    if (event.target.checked) {
      updatedList = [...checkedList, event.target.value];
      if (unChecked.indexOf(event.target.value) != -1) {
        const tempArr = unChecked.splice(unChecked.indexOf(event.target.value));
        setUnChecked(tempArr);
      }
    } else {
      updatedList.splice(checkedList.indexOf(event.target.value), 1);
      setUnChecked([...unChecked, event.target.value]);
    }
    setCheckedList(updatedList);
  };

  const handleSelectAllNotComplete = () => {
    // allSelectOption == true
    //   ? setAllSelectOption(false)
    //   : setAllSelectOption(true);
    // setAllSelectOption((prev) => !prev);
    // setAllSelectOption(true);
    console.log("line 524", allSelectOption);
    if (allSelectOption == true) {
      setAllSelectOption(false);
      setCheckedList([]);
    } else {
      setAllSelectOption(true);

      const tempList = [];
      shortUserList.map((_, i) => {
        if (_.status != "Completed") {
          tempList.push(_.userId);
        }
      });
      setCheckedList(tempList);
    }
    // console.log("line 524", tempList);
    // setCheckedList([...checkedList, tempList]);
  };
  const [shortUserList, setShortUserList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleRadioSubmit = () => {
    //search by name
    if (userName == "" && emailid == "") setShowResetButton(false);
    else setShowResetButton(true);
    if (employeeNameOpt) {
      setlodingscreen(true);
      const config = {
        method: "get",
        params: {
          courseName: location.state.name,
        },
        url: `${IP}/hrGroupReport/${tempMap[location.state.type]}/${
          location.state.groupId
        }/${location.state.courseId}?name=${userName}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(config).then((res) => {
        setShortUserList(res.data.data);
        setExtraShortArr(res.data.data);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.prev);
        setCurrPage(res.data.c_page);
        setTotalPage(res.data.t_page);
        // setTotalEmployee(res.data.t_count);
        setlodingscreen(false);
      });
    }
    // search by email
    else {
      if (!emailid.match(validRegex)) return toast("Enter A Valid Email");
      setlodingscreen(true);
      const config = {
        method: "get",
        params: {
          courseName: location.state.name,
        },
        url: `${IP}/hrGroupReport/${tempMap[location.state.type]}/${
          location.state.groupId
        }/${location.state.courseId}?email=${emailid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(config).then((res) => {
        setShortUserList(res.data.data);
        setExtraShortArr(res.data.data);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.prev);
        setCurrPage(res.data.c_page);
        setTotalPage(res.data.t_page);
        setAllSelectOption(false);
        // setTotalEmployee(res.data.t_count);
        setlodingscreen(false);
      });
    }
  };
  return (
    <div
      className="hrmainbox"
      style={{
        background: "#F3F1FF",
        minHeight: "100vh",
      }}
    >
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"GroupReport"} />
      </Box>

      <HrloadingScreen open={lodingscreen} />

      <SendRemainderPopUp
        open={reportClouse}
        onClose={() => setReportClouse(false)}
        setConfirmation={setConfirmation}
        count={checkedList.length}
        delfun={(topic, isAllSelected) => {
          delfunction(topic, isAllSelected);
        }}
      />

      <GenerateReportForLocal
        open={generateReportPopUp}
        onClose={() => setgenerateReportPopUp(false)}
        data={reportArrData}
        datat={reportArrDataTwo}
      />
      <DontSendMailToMails
        open={dontsendmailtoemail}
        onClose={() => setDontsendmailtoemail(false)}
      />
      <Box sx={{ width: "100vw" }}>
        <MobileTopBar />
        <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
          <Box>
            <Button
              sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Box>
        </HrTopNavbar>
        <div className="VRTopHeading">
          <p>{location?.state?.name}</p>
        </div>
        <div className="VRShowOnMobile">
          <Button
            onClick={() => {
              downloadreport();
            }}
            sx={{
              background: "#6846C7",
              borderRadius: "6px",
              padding: "8px",
              color: "#FFFFFF",
              "&:hover": {
                background: "#6846C7",
              },
            }}
          >
            Download report
          </Button>
        </div>
        <div className="VRHideOnMobile">
          <Box
            id="view_report_count_number"
            sx={{
              padding: "24px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",

              gridGap: "10px",
            }}
          >
            <Box
              id="view_report_count_number_child"
              sx={{
                background: "#FFFFFF",
                height: "170px",
                padding: "24px",
                borderRadius: "24px",
              }}
            >
              <Box
                className="microlerningdiscription"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "40px",
                  color: "#6846C7",
                }}
              >
                {totalEmployee}
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Total no.of employees
              </Box>
            </Box>

            <Box
              id="view_report_count_number_child"
              sx={{
                background: "#FFFFFF",
                height: "170px",
                padding: "24px",
                borderRadius: "24px",
              }}
            >
              <Box
                className="microlerningdiscription"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "40px",
                  color: "#6846C7",
                }}
              >
                {/* {countdata.passed} */}
                {location.state.count ? location.state.count : "0"}
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Employees completed course
              </Box>
            </Box>
            {/* <Box
              id="view_report_count_number_child"
              sx={{
                background: "#FFFFFF",
                height: "170px",
                padding: "24px",
                borderRadius: "24px",
              }}
            >
              <Box
                className="microlerningdiscription"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "40px",
                  color: "#6846C7",
                }}
              >
                {countdata.passed}
                {}
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Employees completed course
              </Box>
            </Box> */}

            <Box
              id="view_report_count_number_child"
              sx={{
                background: "#000000",
                height: "170px",
                padding: "24px",
                borderRadius: "24px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className="microlerningdiscription"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "40px",
                    color: "#E1F566",
                  }}
                >
                  {/* {countdata.failed} */}
                  {/* {location.state.count
                    ? totalEmployee - location.state.count
                    : { "0totalEmployee" }} */}
                  {location.state.count
                    ? totalEmployee - location.state.count
                    : totalEmployee}
                  {/* {totalEmployee - location.state.count} */}
                </Box>
                <Box
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Employees not completed course
                </Box>
              </Box>

              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  // padding: "24px 0"
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#E1F566",
                    color: "#000",
                    borderRadius: "12px",
                    padding: "8px 16px",
                    "&:hover": {
                      background: "#E0FD2E",
                    },
                  }}
                  onClick={sendremainder}
                >
                  Send reminder
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              margin: "24px 24px 0px 24px",
              background: "#E8E3F6",
              // display: "grid",
              // gridGap: "20px",
              // gridTemplateColumns: "1fr 1fr 1fr",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "24px",
              borderRadius: "24px 24px 0px 0px",
            }}
          >
            {/* dasdasdas */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "40px",
                    border: "1px solid #6846C7",
                    width: "max-content",
                  }}
                >
                  <input
                    type="text"
                    placeholder={
                      employeeNameOpt
                        ? "Search by Employee Name"
                        : "Search by Employee Email"
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleRadioSubmit();
                      }
                    }}
                    style={{
                      width: "20.18rem",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      padding: "10px",
                    }}
                    value={employeeNameOpt ? userName : emailid}
                    onChange={(e) => {
                      if (employeeNameOpt) {
                        setUserName(e.target.value);
                      } else {
                        setEmailid(e.target.value);
                      }
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "#6846C7",
                      width: "3.3rem",
                      borderRadius: "0px 10px 10px 0px",
                      height: "100%",
                    }}
                  >
                    <SearchIcon
                      style={{
                        marginLeft: "1.05rem",
                        marginTop: "0.5rem",
                        color: "white",
                      }}
                      onClick={handleRadioSubmit}
                    />
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "15px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        width: "1.375rem",
                        height: "1.375rem",
                        accentColor: "#5C22FF",
                      }}
                      value="empName"
                      onChange={() => {
                        setEmailid("");
                        setUserName("");
                        setEmployeeNameOpt(true);
                      }}
                      checked={employeeNameOpt}
                    />
                    <label style={{ fontFamily: "Poppins" }}>
                      Employee Name
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",

                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        width: "1.375rem",
                        height: "1.375rem",
                        accentColor: "#5C22FF",
                      }}
                      value="empEmail"
                      onChange={() => {
                        setEmailid("");
                        setUserName("");
                        setEmployeeNameOpt(false);
                      }}
                      checked={!employeeNameOpt}
                    />
                    <label style={{ fontFamily: "Poppins" }}>
                      Employee Email
                    </label>
                  </div>
                </div>
              </Box>
              {showResetButton && (
                <Tooltip title="Reset Search">
                  <RestartAltIcon
                    sx={{ marginTop: "5px", color: "#6846C7" }}
                    onClick={() => {
                      setEmailid("");
                      setUserName("");
                      getShortUsers();
                      setShowResetButton(false);
                    }}
                  />
                </Tooltip>
              )}
            </Box>

            {/* dasdasdas */}
            <Box style={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => {
                  setDontsendmailtoemail(true);
                }}
                sx={{
                  border: "1px solid #6846C7",
                  color: "#6846C7",
                  borderRadius: "12px",
                  padding: "8px 16px",
                  height: "40px",
                  "&:hover": {
                    background: "#6846C7",
                    color: "#FFF",
                  },
                }}
              >
                DND List
              </Button>
              <Button
                onClick={() => {
                  downloadreport();
                }}
                sx={{
                  border: "1px solid #6846C7",
                  color: "#6846C7",
                  borderRadius: "12px",
                  padding: "8px 16px",
                  height: "40px",
                  "&:hover": {
                    background: "#6846C7",
                    color: "#FFF",
                  },
                }}
              >
                Download report
              </Button>
            </Box>
          </Box>
          {/* banner pop up */}
          {allSelectOption && (
            <Box
              style={{
                backgroundColor: "#E1F566",
                margin: "0px 24px 0px 24px",
                height: "3.5625rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "1.2rem",
                }}
              >
                <span>
                  All {checkedList.length} employee on this page are selected.
                </span>
                <span
                  style={{ color: "#5C22FF" }}
                  onClick={() => {
                    setReportClouse(true);
                  }}
                >
                  {" "}
                  Select all employees with incompleted status in this course.
                </span>
              </p>
            </Box>
          )}

          {/* Table starts */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px",
              margin: "0px 24px 0px 24px",
              background: "#FFFFFF",
              padding: "8px 24px",
              fontFamily: "Poppins",
              fontSize: "16px",
              gridGap: "10px",
              // height: "72px",
              fontWeight: "700",
              alignContent: "center",
              alignItems: "center",
              color: "#000000",
              borderBottom: "1px solid #BFBFBF",
            }}
          >
            <input
              type="checkbox"
              style={{
                width: "20px",
                height: "20px",
                border: "1px solid #6846C7",
              }}
              onClick={() => {
                // hendelChecked("tets", "All");
                handleSelectAllNotComplete();
              }}
              checked={allSelectOption}
            />

            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Employee name
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Total lessons
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Lessons completed
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Last step completed
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Start date
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Completion date
            </Typography>

            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Status
            </Typography>

            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Download certificate
            </Typography>
          </Box>

          {shortUserList.length == 0 && (
            <h1
              style={{
                marginLeft: "24px",
                fontWeight: "normal",
                fontSize: "20px",
                color: "#b2b2b2",
              }}
            >
              There is no data
            </h1>
          )}
          {shortUserList?.map((el, index) => (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px",
                gridRow: "40px",
                margin: "0px 24px 0px 24px",
                padding: "8px 24px",
                background: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "16px",
                minHeight: "60px",
                fontWeight: "700",
                gridGap: "10px",
                alignContent: "center",
                color: "#666666",
                borderBottom: "1px solid #BFBFBF",
                alignItems: "center",
              }}
            >
              <input
                className="GRViewCheckbox"
                type="checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid #6846C7",
                }}
                onChange={handleChecked}
                value={el.userId}
                checked={checkedList.includes(`${el.userId}`)}
              />

              <Typography> {el.userName}</Typography>
              <Typography> {el.totalLessonCount}</Typography>
              <Typography> {el.lessonCompleted}</Typography>
              <Typography> {el.course_last_step}</Typography>
              <Typography> {el?.course_started_on}</Typography>
              <Typography> {el?.course_completed_on}</Typography>
              <Box
                sx={{
                  color: el.status != "Completed" && "red",
                }}
              >
                {el.status}
              </Box>

              <Box
                // onClick={() => {
                //   if (el.certificateLink != "") {
                //     // window.location.href = el.certificateLink;
                //     // window.open(el.certificateLink, "_blank");
                //   }
                // }}

                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  style={{
                    cursor:
                      el.certificateLink == "" ? "not-allowed" : "pointer",
                  }}
                  // href={el.certificateLink}
                  onClick={() => {
                    if (el.certificateLink == "") {
                      return;
                    } else {
                      handleDownload(el?.certificateLink);
                    }
                  }}
                >
                  <img
                    src={Download}
                    style={{
                      opacity: el.certificateLink == "" && "0.5",
                    }}
                    alt=""
                  />
                </a>
              </Box>
            </Box>
          ))}
        </div>
        {/* pagination */}

        <div
          className="VRHideOnMobile withFlex"
          style={{
            // display: "flex",
            justifyContent: "flex-end",
            marginRight: "24px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Typography>Page&nbsp;</Typography>

          <p
            style={{
              border: "1px solid #6846C7",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderRadius: "4px",
            }}
          >
            {currPage}
          </p>
          <Typography>&nbsp;of&nbsp; {totalPage}&nbsp;&nbsp;</Typography>
          <button
            style={{
              border: `1px solid ${currPage == 1 ? "#b2b2b2" : "#6846C7"}`,
              paddingLeft: "15px",
              paddingRight: "15px",
              backgroundColor: `${currPage == 1 ? "#b2b2b2" : "#6846C7"}`,
              // backgroundColor: "#6846C7",
              color: "white",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "4px 0px 0px 4px",
              cursor: "pointer",
            }}
            disabled={currPage == 1 ? true : false}
            onClick={() => {
              setlodingscreen(true);
              const config = {
                method: "get",
                url: `${prevUrl}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getCookie("bearer")}`,
                },
              };
              axios(config).then((res) => {
                console.log("line 470", res.data.data);
                setShortUserList(res.data.data);
                setExtraShortArr(res.data.data);
                setAllSelectOption(false);
                setCheckedList([]);
                setlodingscreen(false);
                setNextUrl(res.data.next);
                setPrevUrl(res.data.prev);
                setCurrPage(res.data.c_page);
              });
            }}
          >
            {"<"}
          </button>
          <button
            style={{
              border: `1px solid ${
                currPage == totalPage ? "#b2b2b2" : "#6846C7"
              }`,
              paddingLeft: "15px",
              paddingRight: "15px",
              backgroundColor: `${
                currPage == totalPage ? "#b2b2b2" : "#6846C7"
              }`,
              color: "white",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "0px 4px 4px 0px",
              cursor: "pointer",
            }}
            disabled={currPage == totalPage ? true : false}
            onClick={() => {
              setlodingscreen(true);
              const config = {
                method: "get",
                url: `${nextUrl}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getCookie("bearer")}`,
                },
              };
              axios(config).then((res) => {
                //   console.log("line 470", res.data.data);
                setShortUserList(res.data.data);
                setExtraShortArr(res.data.data);
                setAllSelectOption(false);
                setCheckedList([]);
                setlodingscreen(false);
                setNextUrl(res.data.next);
                setPrevUrl(res.data.prev);
                setCurrPage(res.data.c_page);
              });
            }}
          >
            {">"}
          </button>
        </div>
      </Box>
    </div>
  );
};
export default GroupReportView;
