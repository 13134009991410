import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Input } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Sidebar from "../../../../Component/Sidebar";
import NavbarTop from "../../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../../baseUrlAndConfig";
import { getCookie } from "../../../../../Cookies";
import { toast } from "react-toastify";
import AdminloadingScreen from "../../loadingadmin";
// import { getCookie } from "../../../../Cookies";
// import HrloadingScreen from "../../../../Hr/LodingHrscreen";
// import { functions } from "../../../../Firebase";

const CreateStaticTemplate = (props) => {
  const { onClose, open, delfun, topicId, itemName } = props;
  const handleClose = () => {
    onClose();
  };

  const [lodingScreenPop, setlodingScreenPop] = useState(false);
  const [subloading, setSubloading] = useState(false);

  const [image, setImage] = useState([]);
  const { clrs } = useSelector((state) => state.createClr);

  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [webinarsData, setWebinarsData] = useState({
    templateName: "",
    templateType: "",
    templateDescription: "",
    filetype: "",
    file: "",
    link: "",
    eventId: "",
    templateId: "",
  });
  const [loading, setLoading] = useState(false);
  //   const [mathDotRendom, setMathDotRendom] = useState(topicId.id);
  const [inputId, setInputId] = useState("");

  let navigate = useNavigate();

  const updatetopic = () => {
    // get company and department data
    setlodingScreenPop(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "patch",

      url: `${IP}/event/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setlodingScreenPop(false);
        if (res.data?.error) {
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const saveTemplateData = () => {
    // get company and department data
    setlodingScreenPop(true);
    console.log(webinarsData);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "post",

      url: `${IP}/iccRoutes/template`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    console.log(webinarsData);

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        setlodingScreenPop(false);
        if (res.data?.error) {
        } else {
          handleClose();
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setlodingScreenPop(false);
        //console.log(err);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setWebinarsData({ ...webinarsData, file: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company ICC Settings")} />
        {/* <Navbar /> */}

        <AdminloadingScreen open={subloading} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </div>

              <Box
                sx={{
                  padding: "16px 24px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Template type
                    </div>
                    <div>
                      {" "}
                      <select
                        className="inputbox"
                        value={webinarsData.templateType}
                        onChange={(e) => {
                          setWebinarsData((prev) => ({
                            ...prev,
                            templateType: e.target.value,
                          }));
                        }}
                        placeholder="Item Topic"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      >
                        <option value=""> select template type</option>
                        <option value="static"> Static </option>

                        <option value="dynamic"> dynamic</option>
                      </select>
                    </div>
                  </div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi"> Item Topic</div>
                    <div>
                      {" "}
                      <input
                        className="inputbox"
                        type="text"
                        value={webinarsData.templateName}
                        onChange={(e) => {
                          setWebinarsData((prev) => ({
                            ...prev,
                            templateName: e.target.value,
                          }));
                        }}
                        placeholder=" Name"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Description
                    </div>
                    <div>
                      {" "}
                      <TextField
                        value={webinarsData.templateDescription}
                        onChange={(e) => {
                          setWebinarsData((prev) => ({
                            ...prev,
                            templateDescription: e.target.value,
                          }));
                        }}
                        InputProps={{
                          sx: {
                            resize: "both",
                            overflow: "auto",
                          },
                        }}
                        multiline
                        className="inputbox"
                        sx={{ width: "250px" }}
                        type="text"
                        placeholder="    Description"
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi"> File Type</div>
                    <div style={{ display: "flex", gap: "20px" }}>
                      {" "}
                      <div>
                        {" "}
                        <input
                          type="radio"
                          checked={webinarsData.filetype == "file"}
                          name="eventradio"
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              filetype: "file",
                            }));
                          }}
                          className="inputbox"
                          placeholder="  From"
                          style={{
                            padding: "5px",
                            height: "25px",
                            width: "30px",
                          }}
                        />
                        <label htmlFor=""> File</label>
                      </div>
                      <div>
                        {" "}
                        <input
                          value={"link"}
                          type="radio"
                          name="eventradio"
                          checked={webinarsData.filetype == "link"}
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              filetype: "link",
                            }));
                          }}
                          className="inputbox"
                          placeholder="  From"
                          style={{
                            padding: "5px",
                            height: "25px",
                            width: "50px",
                          }}
                        />
                        <label htmlFor=""> Link</label>
                      </div>
                    </div>
                  </div>
                  {webinarsData.filetype == "file" ? (
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Section File
                      </div>
                      <div>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "50px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            {" "}
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                              startIcon={<FileUploadIcon />}
                            >
                              Upload File
                              <input
                                name="logo"
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                              />
                            </Button>{" "}
                          </div>{" "}
                          <div>
                            {" "}
                            {image.url && (
                              <div style={{ width: "300px", height: "150px" }}>
                                <iframe
                                  src={image?.url}
                                  frameborder="0"
                                ></iframe>
                              </div>
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi"> Link</div>
                      <div>
                        {" "}
                        <input
                          className="inputbox"
                          type="text"
                          value={webinarsData.link}
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              link: e.target.value,
                            }));
                          }}
                          placeholder="Link"
                          style={{
                            padding: "5px",
                            height: "35px",
                            width: "250px",
                          }}
                        />
                        {webinarsData.link && (
                          <div style={{ width: "300px", height: "150px" }}>
                            <iframe
                              src={webinarsData.link}
                              frameborder="0"
                            ></iframe>
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  )}

                  <div style={{ display: "flex", gap: "50px" }}>
                    {" "}
                    <Button
                      //   onClick={createWebinar}
                      style={{
                        backgroundColor: "#007C84",
                        marginTop: "24px",
                        borderRadius: "5px",
                        color: "#FFFFFF",
                      }}
                      onClick={saveTemplateData}
                    >
                      {" "}
                      Save
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateStaticTemplate;
