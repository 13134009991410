import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Chart from "./Chart";
import { exportMultipleChartsToPdf } from "./GraphUtils";

import { useNavigate } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
// import psy1 from "../../../utilities/pdfImages/psy11.jpg";
import psy1 from "../../../utilities/bystander/1.png";
import psy2 from "../../../utilities/bystander/2.png";
import psy3 from "../../../utilities/bystander/3.png";
import psy4 from "../../../utilities/bystander/4.png";
import psy5 from "../../../utilities/bystander/5.png";
import psy6 from "../../../utilities/bystander/6.jpg";
import psy7 from "../../../utilities/bystander/7.jpg";
import psy8 from "../../../utilities/bystander/8.jpg";
import psy9 from "../../../utilities/bystander/9.jpg";
import psy10 from "../../../utilities/bystander/10.jpg";

import HrloadingScreen from "../../LodingHrscreen";
import { byStandardData } from "./Option";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";

export default function Bystandard() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const goToHome = () => navigate("/");
  const [tab, setTab] = useState(1);
  const [subtab, setSubtab] = useState(1);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [activeTab, setactiveTab] = useState("Employee Commitment");
  const [totalNumberOfUserFilledForm, setTotalNumberOfUserFilledForm] =
    useState(0);
  const [seriesData, setSeriesData] = useState({
    title: {
      text: "",
      style: {
        color: "#000000",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    chart: {
      type: "pie",
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "",
        colorByPoint: false,
        data: [
          {
            name: "Strongly Disagree",
            color: "#FE9CBF",
            y: 0,
          },
          {
            name: "Disagree",
            color: "#AFFB63",
            y: 0,
          },
          {
            name: "Unsure",
            color: "#7EC1FF",
            y: 0,
          },
          {
            name: "Agree",
            color: "#B2ACED",
            y: 0,
          },
          {
            name: "Strongly Agree",
            color: "#E2E1E1",
            y: 0,
          },
        ],
      },
    ],
  });
  const [lodingscreen, setlodingscreen] = useState(false);

  const [allApiData, setAllApiData] = useState([]);
  let text = [
    {
      title: "Employee commitment",
      desc1:
        "This graph outlines the employees’ commitment to taking action in the event of a potentially harmful situation in the workplace.",
    },
    {
      title: "Stakeholder",
      desc1:
        "This graph outlines the employees’ commitment to taking action in the event of a potentially harmful situation in the workplace.",
    },
    {
      title: "Type of harassment",
      desc1:
        "This graph outlines the employees’ commitment to taking action in the event of a potentially harmful situation in the workplace.",
    },
  ];

  const subOptionsDataObj = {
    "Employee Commitment": {
      title: "Employee Commitment",
      disc: [
        "This graph outlines the employees’ commitment to taking action in the event of a potentially harmful situation in the workplace.",
      ],
    },

    "Towards a senior leader": {
      title:
        "Bystander intervention when the harassment is from a senior leader",
      disc: [
        "When deciding to intervene, a bystander considers the threat to themselves. In some situations, intervening could mean physical harm to oneself. In some cases, it could lead to one’s career being impacted.",
        "When the harassment is from someone in a position of authority, direct intervention may not be the obvious choice. Here employees are likely to ignore the behavior or consider other options to assist the victim.",

        "As an organization, you are looking to create a culture where employees do not ignore the behavior but decide to engage in any form of intervention.       ",
      ],
    },

    "Towards a peer": {
      title: "Bystander intervention when the harassment is from a peer",
      disc: [
        "Discouraging peers from engaging in offensive behavior sounds doable. ",
        "However, certain workplace dynamics impact the employee’s choice of bystander intervention. For example, if the employee is a friend of the harasser or if the employee works closely with the harasser. In such situations, the bystander is considering the loss of a relationship if they choose to intervene.        ",

        "As an organization, it is important to coach employees on how to have these difficult conversations with team members.",
      ],
    },

    "Towards third parties": {
      title: "Bystander intervention when the harassment is from a third party",
      disc: [
        "Intervening when the harassment is from a third party is dependent on what is at stake. If the third party is a client or an important business partner an employee may second guess the option of direct confrontation. ",
        "Coaching employees on ‘Distract’ as a way to eliminate the immediate threat is a useful strategy. ",
        "In Serein’s experience, employees are often unaware of the organization’s stance against harassment from a third party. The assumption is that the organization will not stand up against the harasser if it is a valued relationship. It is important for leaders to communicate their stand and procedures to prevent harassment from third-party stakeholders.        ",
      ],
    },

    "Written/graphic harassment": {
      title: "Written/graphic harassment",
      disc: [
        "Written or graphic harassment in the workplace includes displaying sexual content or other inappropriate material on one’s work desk or screen. It also includes sharing messages that are demeaning to an employee’s identity. For example, racist, anti-religion, ableist, sexist, and other forms of content that are hurtful and humiliating to a colleague.",
        "Written or graphic harassment is content or comments shared physically or virtually (intentionally or unintentionally) to bully, demean or harass a co-worker.        ",
      ],
    },

    "Verbal harassment": {
      title: "Verbal harassment",
      disc: [
        "Verbal and non-verbal harassment includes gestures that are disrespectful, threatening, or inappropriate. For example, aggressively shouting at someone, making a joke about someone’s disability, innuendos, or gossiping about a colleague's personal life. ",
      ],
    },
    "Physical harassment": {
      title: "Physical harassment",
      disc: [
        "Physical harassment is presumed to be less probable. However, across industries, cases of physical harassment are reported at the workplace. ",
        "Physical harassment is a lack of respect for a colleague's personal space or boundaries. This can include unwanted physical contact like squeezing, grabbing someone, or brushing against another’s body. This also includes sitting or standing uncomfortably close to someone or blocking their path.",
      ],
    },
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let newobj =
      rolesAndPermission?.Psycological_Study?.componentPermission.includes(
        "Bystander Intervation"
      );
    // console.log(newobj.HRDI);
    // if (!newobj) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);

  const downloadSubmit = async () => {
    setlodingscreen(true);
    await exportMultipleChartsToPdf();
    setlodingscreen(false);
  };

  useEffect(() => {
    getCompanyName();
    getchatData("");
  }, []);

  const getCompanyName = (NumberToString) => {
    // { ...optiondata, series: seriesData }

    let configrecrut = {
      method: "get",

      url: `${IP}/inclusionStudy/name`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setCompanyName(res.data?.companyName);
        // setlodingscreen(false);
      })
      .catch((er) => {
        // setlodingscreen(false);
      });
  };
  useEffect(async () => {
    setAllApiData([]);
    let apiRaoutes = [
      "",
      "Towards a senior leader",
      "Towards a peer",
      "Towards third parties",
      "Written/graphic harassment",
      "Verbal harassment",
      "Physical harassment",
    ];

    // { ...optiondata, series: seriesData }
    let arr = [];
    (async () => {
      for (let i = 0; i < apiRaoutes.length; i++) {
        let configrecrut = {
          method: "get",

          url: `${IP}/inclusionStudy/bystandardGraph`,
          params: { type: apiRaoutes[i] },

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };

        let res = await axios(configrecrut);

        let obj = res.data;
        // arr[i - 1][j - 1] = { ...obj, series: res.data?.data };

        setAllApiData((prev) => prev.concat(obj));
        // console.log(arr[i - 1][j - 1]);
      }
    })();

    // setAllApiData(arr);
    // console.log(allApiData);

    let config_obj = {
      method: "get",

      url: `${IP}/forms`,
      params: { survey: "1" },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    let res = await axios(config_obj).then((res) => {
      setTotalNumberOfUserFilledForm(res.data.count);
    });
  }, []);
  const getchatData = (NumberToString) => {
    setlodingscreen(true);

    // console.log(apiRaoutes["11"]);

    let configrecrut = {
      method: "get",

      url: `${IP}/inclusionStudy/bystandardGraph`,
      params: { type: NumberToString },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        console.log(res.data);
        setlodingscreen(false);
        // setoptiondata(res.data?.graph);
        setSeriesData(res.data);
      })
      .catch((er) => {
        setlodingscreen(false);
      });
  };
  return (
    <>
      <Box className="PySafetyPage">
        {/* Button Box */}

        {/* Actual Content */}
        <HrloadingScreen open={lodingscreen} />

        <Stack className="PySafetyStack">
          {/* Text Box */}
          <Box>
            <p className="PySafetyPageTitle">Bystander intervention</p>
            <p className="PySafetyPageSubText">
              Assessing employees’ willingness to take a stand
            </p>
            <p className="PySafetyPageSubTitle">
              What is bystander intervention?
            </p>
            <p className="PySafetyPageSTitleText">
              We often read or witness acts of harassment, discrimination, and
              injustice in the workplace. In many situations, the person at the
              receiving end is unable to speak up against the harassment. This
              may be because the perpetrator is a senior employee, the victim is
              afraid that the behavior will escalate if they speak up or there
              is a project at stake.
            </p>
            <p className="PySafetyPageSTitleText">
              A bystander is a person who has knowledge of or witnesses a
              potentially harmful situation. Bystander intervention is when this
              person takes steps to intervene and assist the person on the
              receiving end of the harmful situation.
            </p>
            <p className="PySafetyPageSTitleText">
              When we intervene, we indicate to the perpetrator that their
              behavior is unacceptable. At the same time, it is a cue to the
              victim that they are not alone and you are on their side. This
              gives them the courage to speak up or report their harassment to
              someone in authority.
            </p>
          </Box>

          {/* Text Box */}
          <Box>
            <p className="PySafetyPageSubTitle">Why bystander intervention?</p>
            <p className="PySafetyPageSTitleText">
              Research has shown that training employees on bystander
              intervention is effective to build a safe work culture.
            </p>
            <p className="PySafetyPageSTitleText">
              Bystander intervention takes away the victim's burden of being the
              only one to address the harassment or discrimination. Instead, it
              makes all employees responsible to reinforce the company’s culture
              of respect, fairness, and inclusion.
            </p>
            <p className="PySafetyPageSTitleText">
              Often a perpetrator will attempt to see how far they can go with
              their inappropriate jokes, comments, language, or invasion of
              physical space. If the perpetrator is called out on their
              harassive behavior, they are likely to think twice before engaging
              in offensive acts.
            </p>
            <p className="PySafetyPageSTitleText">
              The key role of the bystander is to ensure their safety (and the
              victim’s) by preventing, discouraging and intervening when an act
              of harassment or discrimination takes place, or has the potential
              to occur.
            </p>
          </Box>
          {/* Text Box */}
          <Box>
            <p className="PySafetyPageSubTitle">
              What are the different ways in which a bystander can intervene?
            </p>
            <p className="PySafetyPageSTitleText">
              There are 5 useful Ds of bystander intervention:
            </p>
            <ol className="PySafetyUList toAlignInside">
              <li className="JustifyText">
                <b>Direct:</b> When a bystander directly intervenes in a
                possibly harmful situation by standing up or confronting the
                perpetrator.
              </li>
              <li className="JustifyText">
                <b>Document:</b>  When a bystander documents, records, or
                digitally captures the harassment and gives the same to the
                victim to use when reporting the harasser.
              </li>
              <li className="JustifyText">
                <b>Delay:</b> This involves checking in on the person who has
                faced the harassment. In this case, a bystander can also help by
                sharing resources for reporting the harassment.
              </li>
              <li className="JustifyText">
                <b>Distract or Disrupt:</b> Finding a way to de-escalate the
                situation by identifying means to distract the perpetrator.
              </li>
              <li className="JustifyText">
                <b>Delegate:</b> Reaching out to people on anti-harassment
                committees who are trained to address such situations.
              </li>
            </ol>
          </Box>

          {/* Graph Title */}
          <p className="PySafetyGraphTitle ">
            Bystander intervention at {companyName}
          </p>
          {/* Graph Box */}

          <Stack className="PySafetyStackBTNandGraph">
            <p className="PySafetyPageSTitleText">
              The type of bystander intervention is impacted by the power
              dynamics between the bystander and the perpetrator. It is also
              impacted by the severity or the form of harassment.
            </p>
            <p className="PySafetyPageSTitleText">
              In this study, employees have reported what they are most likely
              to do in situations of harassment or discrimination.
            </p>

            {/* Tab Box */}
            <div className="PySafetyBtns">
              <Stack
                className="PySafetyTabBtn"
                sx={{
                  background: tab == 1 ? "#E1F566" : null,
                  border: tab == 1 ? null : "2px solid #E1F566",
                  boxShadow:
                    tab == 1 ? "0px 2px 6px rgba(0, 0, 0, 0.25)" : null,
                }}
                onClick={() => {
                  setTab(1);
                  setSubtab(1);

                  setactiveTab("Employee Commitment");
                  getchatData("");

                  // setSubTitle("Gender");
                  // getchatData(`${11}`);
                }}
              >
                Employee Commitment
              </Stack>
              <Stack
                className="PySafetyTabBtn"
                sx={{
                  background: tab == 2 ? "#E1F566" : null,
                  border: tab == 2 ? null : "2px solid #E1F566",
                  boxShadow:
                    tab == 2 ? "0px 2px 6px rgba(0, 0, 0, 0.25)" : null,
                }}
                onClick={() => {
                  setTab(2);
                  setSubtab(1);
                  setactiveTab("Towards a senior leader");
                  getchatData("Towards a senior leader");

                  // setSubTitle("Gender");
                  // getchatData(`${11}`);
                }}
              >
                Stakeholder
              </Stack>
              <Stack
                className="PySafetyTabBtn"
                sx={{
                  background: tab == 3 ? "#E1F566" : null,
                  border: tab == 3 ? null : "2px solid #E1F566",
                  boxShadow:
                    tab == 3 ? "0px 2px 6px rgba(0, 0, 0, 0.25)" : null,
                }}
                onClick={() => {
                  setTab(3);
                  setSubtab(1);
                  setactiveTab("Written/graphic harassment");
                  getchatData("Written/graphic harassment");
                  // setSubTitle("Gender");
                  // getchatData(`${11}`);
                }}
              >
                Type of harassment
              </Stack>
            </div>

            {/* Description and graph Box */}
            <div className="PySGraphAndDescription">
              <Stack className="mt70m10">
                <p className="PySafetyPageSubTitle">
                  {/* {text[tab - 1].title} */}
                  {subOptionsDataObj[activeTab]?.title}
                </p>

                {subOptionsDataObj[activeTab]?.disc?.map((el) => (
                  <p className="PySafetyPageSTitleText mbM0">
                    {/* {text[tab - 1].desc1} */}
                    {el}
                  </p>
                ))}
              </Stack>

              <Stack alignItems="center">
                <Stack
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "24px",
                    padding: "10px",
                    marginBottom: "30px",
                  }}
                >
                  <FormControl>
                    <InputLabel id="demo-simple-select-label1">
                      Select type
                    </InputLabel>
                    {tab == 2 && (
                      <Select
                        label="Select type"
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select"
                        onChange={(e) => {
                          getchatData(e.target.value);
                          setactiveTab(e.target.value);
                        }}
                        MenuProps={{
                          sx: {
                            maxHeight: "250px",
                            maxWidth: "250px",
                          },
                        }}
                        sx={{
                          width: "308px",
                          height: "50px",
                          fontSize: "16px",
                          backgroundColor: "transparent",
                          borderRadius: "12px",
                        }}
                      >
                        <MenuItem value="Towards a senior leader">
                          Towards a senior leader
                        </MenuItem>
                        <MenuItem value="Towards a peer">
                          Towards a peer
                        </MenuItem>
                        <MenuItem value="Towards third parties">
                          Towards third parties
                        </MenuItem>
                      </Select>
                    )}

                    {tab == 3 && (
                      <Select
                        label="Select type"
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select"
                        onChange={(e) => {
                          getchatData(e.target.value);
                          setactiveTab(e.target.value);
                        }}
                        MenuProps={{
                          sx: {
                            maxHeight: "250px",
                            maxWidth: "250px",
                          },
                        }}
                        sx={{
                          width: "308px",
                          height: "50px",
                          fontSize: "16px",
                          backgroundColor: "transparent",
                          borderRadius: "12px",
                        }}
                      >
                        <MenuItem value="Written/graphic harassment">
                          Written/graphic harassment
                        </MenuItem>
                        <MenuItem value="Verbal harassment">
                          Verbal harassment
                        </MenuItem>
                        <MenuItem value="Physical harassment">
                          Physical harassment
                        </MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "72px",
                    background: "#e5e0fa",
                    borderRadius: "24px 24px 0px 0px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography>{activeTab}</Typography>
                  </Stack>
                </Stack>
                {/* Graph */}
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "475px",
                    background: "#FFFFFF",
                    borderRadius: "0px 0px 24px 24px",
                  }}
                >
                  <Chart chartOptions={seriesData} />
                </Stack>
              </Stack>
            </div>
          </Stack>

          {/* Text Box */}
          <Box sx={{ mt: "25px" }}>
            <p className="PySafetyPageSubTitle">
              How can leaders prepare employees to be active bystanders?
            </p>
            <p className="PySafetyPageSTitleText">
              Over the years actions like sexist jokes, innuendos, and anti-race
              or anti-queer comments have been normalized. The first step to
              bystander intervention is training employees to recognize
              harassment and discrimination.
            </p>
            <p className="PySafetyPageSTitleText">
              The second step is to empower them with tools, conversation, and
              information on internal reporting mechanisms.
            </p>
            <p className="PySafetyPageSTitleText">
              Serein’s DEI SaaS tool has all you need to drive this conversation
              within the organization. Here’s how you can get started.
            </p>
            <p className="PySafetyPageSTitleText">
              {/* Course - Taking a Stand */}
              <b>1. Course -</b> Taking a Stand
            </p>
            <p className="PySafetyPageSTitleText">
              A simulative course on bystander intervention and sexual
              harassment in the workplace
            </p>
            <p className="PySafetyPageSTitleText">
              <b>2. Inclusion game -</b> Find my Cape
            </p>
            <p className="PySafetyPageSTitleText">
              An interactive game on how to be an active bystander
            </p>

            <p className="PySafetyPageSTitleText">
              <b>3. Inclusion Calendar</b>
            </p>
            <p className="PySafetyPageSTitleText">
              Use the inclusion calendar to empower employees with the knowledge
              to recognize and call out discrimination or harassment and build a
              safe work culture.
            </p>
          </Box>
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "end", mt: "10px" }}>
          {" "}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "224px",
              height: "48px",
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              border: "1px solid #6846C7",
              cursor: "pointer",
            }}
            // onClick={handleOpen}

            onClick={() => {
              window.open(
                "/print/Bystanderintervention",
                "",
                "toolbars=no,minimizable=no,location=no,top=200,scrollbars=no,resizable=no,width=700,height=700,left=200"
              );
              localStorage.setItem("companyName", companyName);
              localStorage.setItem("allApiData", JSON.stringify(allApiData));
              localStorage.setItem(
                "componentRef",
                JSON.stringify(componentRef)
              );
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#6846C7",
              }}
            >
              Download report
            </Typography>
            <FileDownloadOutlinedIcon sx={{ color: "#6846C7" }} />
          </Stack>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            bgcolor: "white",
            border: "2px solid white",
            borderRadius: "10px",
            boxShadow: 24,
            pt: 3,
            px: 3,
            pb: 1.5,
            overflow: "scroll",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textAlign: "",
          }}
        >
          <div className="custom-chart" style={{}}>
            <img src={psy1} style={{ width: "100%", height: "100%" }}></img>
          </div>
          <div className="custom-chart" style={{ position: "relative" }}>
            <img src={psy2} style={{ width: "100%", height: "100%" }}></img>
            {/* <Typography
              sx={{
                position: "absolute",
                top: "69.5%",
                left: "7%",
                fontSize: "35px",
                fontFamily: "Manrope",
                color: "#6846C7",
                fontWeight: "700px",
              }}
            >{`Psychological Safety at ${companyName}`}</Typography> */}
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <img src={psy3} style={{ width: "100%", height: "100%" }}></img>
          </div>

          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <div>
              <div
                style={{
                  // width: "100vw",
                  // height: "100vh",
                  display: "block",
                }}
              >
                <img
                  src={psy4}
                  style={{
                    width: "100%",
                    // height: "100vh",
                    // display: "block",
                  }}
                ></img>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  // border: "1px solid red",
                  height: "400px",
                  padding: "7%",
                }}
              >
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "7%",
                    }}
                  >
                    <Chart chartOptions={allApiData[1]} />
                  </Stack>
                </div>
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "40%",
                    }}
                  >
                    <Chart chartOptions={allApiData[2]} />
                  </Stack>
                </div>
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "70%",
                    }}
                  >
                    <Chart chartOptions={allApiData[3]} />
                  </Stack>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  mt: "800px",
                  height: "100vh",
                  display: "block",
                }}
              >
                <img
                  src={psy5}
                  style={{
                    top: "80%",
                    width: "100%",
                    height: "100%",
                    // display: "block",
                  }}
                ></img>
              </div>
            </div>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <div>
              <div
                style={{
                  // width: "100vw",
                  // height: "100vh",
                  display: "block",
                }}
              >
                <img
                  src={psy6}
                  style={{
                    width: "100%",
                    // height: "100vh",
                    // display: "block",
                  }}
                ></img>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  // border: "1px solid red",
                  height: "400px",
                  padding: "7%",
                }}
              >
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "7%",
                    }}
                  >
                    <Chart chartOptions={allApiData[4]} />
                  </Stack>
                </div>
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "40%",
                    }}
                  >
                    <Chart chartOptions={allApiData[5]} />
                  </Stack>
                </div>
                <div>
                  <Stack
                    direction="row"
                    sx={{
                      width: "30%",
                      height: "300px",
                      background: "#FFFFFF",
                      borderRadius: "0px 0px 24px 24px",
                      // border:"1px solid green",
                      position: "absolute",

                      // left: "70%",
                    }}
                  >
                    <Chart chartOptions={allApiData[6]} />
                  </Stack>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  mt: "800px",
                  height: "100vh",
                  display: "block",
                }}
              >
                <img
                  src={psy7}
                  style={{
                    top: "80%",
                    width: "100%",
                    height: "100%",
                    // display: "block",
                  }}
                ></img>
              </div>
            </div>
          </div>
          <div
            className="custom-chart"
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: "6%",
              }}
            >
              <Typography
                sx={{
                  // position: "absolute",
                  top: "69.5%",

                  fontSize: "35px",
                  fontFamily: "Manrope",
                  color: "#6846C7",
                  fontWeight: "700px",
                }}
              >{`Psychological Safety at ${companyName}`}</Typography>
              <Typography
                sx={{
                  // position: "absolute",
                  // top: "30%",

                  fontSize: "17px",
                  fontFamily: "Manrope",
                  color: "black",
                  fontWeight: "700px",
                  widows: "80%",
                  mt: "20px",

                  whiteSpace: "normal",
                }}
              >
                in this study, employees have reported what they are most likely
                to do in situations of harassment or discrimination
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                gap: "20px",
                width: "100%",
                height: "500px",
                padding: "6%",
              }}
            >
              <div style={{ width: "50%" }}>
                <img
                  src={psy8}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <div style={{ width: "50%" }}>
                <Stack
                  direction="row"
                  sx={{
                    width: "40%",
                    height: "300px",
                    background: "#FFFFFF",
                    borderRadius: "0px 0px 24px 24px",
                    // border:"1px solid green",
                    position: "absolute",

                    // left: "7%",
                  }}
                >
                  <Chart chartOptions={allApiData[0]} />
                </Stack>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <img
                src={psy9}
                alt=""
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="custom-chart" style={{}}>
            <img src={psy10} style={{ width: "100%", height: "100%" }}></img>
          </div>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            sx={{ margin: "20px 60px" }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "224px",
                height: "48px",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                border: "1px solid #6846C7",
                cursor: "pointer",
              }}
              onClick={downloadSubmit}
            >
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#6846C7",
                }}
              >
                Generate PDF
              </Typography>
              <FileDownloadOutlinedIcon sx={{ color: "#6846C7" }} />
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
