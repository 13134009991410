import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Papa, { parse } from "papaparse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../Firebase";
import { IP } from "../../../../../baseUrlAndConfig";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";
// import { ValidateAuth } from "../../../../AuthToken";
import { useNavigate } from "react-router";
import { DrawerContext } from "../../../../../utilities/context/DrawerProvider";
import { Box } from "@mui/system";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
const ICCBulk = ({ showBulk, handleClose }) => {
  const bulkUserCreation = httpsCallable(functions, "bulkUserCreation");
  const [showHistory, setShowHistory] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [actionType, setActionType] = useState("");
  const [inviteType, setInviteType] = useState("");
  const [groupId, setGroupId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    companyId: "",
    courseId: "",
  });
  const [courseData, setCourseData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const getCourseData = (companyId) => {
    setCourseData([]);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "post",

      url: `${IP}/company/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setCourseData(res.data.course);
    });
  };

  const getGroupData = (companyId) => {
    setGroupData([]);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "get",
      params: { companyId: companyId },
      url: `${IP}/company/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setGroupData(res.data.groupData);
    });
  };

  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        setUserCompany(res.data);
      }
    });

    // getUserList();
  }, []);

  useEffect(() => {
    setJsonData([]);
  }, [showBulk]);
  const uploadBulkUser = () => {
    if (jsonData.length == 0) {
      toast.warning("Upload data");
      setLoading(false);
      return;
    }
    setLoading(true);

    let data = JSON.stringify({
      users: jsonData,
    });
    var config = {
      method: "patch",

      url: `${IP}/iccRoutes/createLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        setLoading(false);
        setJsonData(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        toast.error("something went wrong");
        setLoading(false);
      });
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];
    //console.log(file);
    const name = file.name;
    if (name.split(".")[1] != "csv") {
      alert("Not proper format");
      e.target.value = "";
      return;
    }
    let temp = [];
    let text = await file.text();
    const parsedData = Papa.parse(text);
    if (parsedData.data.length > 101) {
      toast.error("can not upload more then 100 user at a time");
      e.target.value = "";
      return;
    }
    //console.log(text, parsedData.data);
    for (let i = 1; i < parsedData.data.length; i++) {
      //console.log(i)
      let data = {};
      parsedData.data[0].forEach((item, id) => {
        data[item] = parsedData.data[i][id]?.trim();
      });
      // if(parsedData.data.length)
      temp.push({ ...data, status: "TBD", role: "USER" }); // default role by bulk add is user
    }
    //console.log(temp);
    setJsonData(temp);
  };

  return (
    <Dialog
      open={showBulk}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>{"Bulk task"}</DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <p>Upload CSV File</p> <br></br>
          <input type={"file"} accept={"text/csv"} onChange={handleFile} />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {jsonData?.length != 0 && (
            <div
              className="bulkUser-row"
              style={{
                gap: "20px",
                gridGap: "20px",
                display: "grid",
                background: "#e5e5e5",
                widows: "100vw",
                gridTemplateColumns:
                  "100px 120px 120px 150px 150px 150px 200px 200px 150px",
              }}
            >
              <div>{"Company Name"}</div>
              <div>{"Company Id"}</div>

              <div>{"Registered Company Name"}</div>
              <div>{"Address"}</div>

              <div>{"Location"}</div>
              <div>{"IC Members Names"}</div>

              <div>{"IC members Emails"}</div>
              <div>{"SPOC"}</div>
              <div>{"Status"}</div>
            </div>
          )}
          {jsonData.map((item) => {
            return (
              <div
                className="bulkUser-row"
                style={{
                  gap: "20px",
                  gridGap: "20px",
                  display: "grid",
                  marginTop: "20px",
                  gridTemplateColumns:
                    "100px 120px 120px 150px 150px 150px 200px 200px 150px",
                }}
              >
                {/* {JSON.stringify(item)} */}
                <div>{item["Company Name"]}</div>
                <div
                  style={{
                    lineBreak: "anywhere",
                  }}
                >
                  {item["companyId"]}
                </div>

                <div>{item["registeredName"]}</div>
                <div>{item.address}</div>

                <div>{item.location}</div>
                <div>{item["IC Members Names"]}</div>
                <div>{item["IC members Emails"]}</div>
                <div>{item["spocEmail"]}</div>
                <div>{item?.status}</div>
              </div>
            );
          })}
        </div>

        <History
          showHistory={showHistory}
          handleClose={() => setShowHistory(false)}
          data={{
            ...userData,
            groupId: groupId,
            actionType: actionType,
            inviteType: inviteType,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/serein-devqa-internal-gcp.appspot.com/o/companyBulkcsv%2FCopy%20of%20Krishworks-Sample%20for%20External%20portal%20-%20Auto-renewal%20clients(5).csv?alt=media&token=37233976-7715-4b26-8d30-9e7d24830078",
              "_blank"
            );
          }}
        >
          sample_csv
        </Button>

        {/* <Button
          disabled={loading}
          onClick={() => {
            if (userData.companyId == "") {
              toast.warning("select all fields ");
              return;
            }
            setShowHistory(true);
          }}
        >
          {"Show History"}
        </Button> */}
        <Button disabled={loading} onClick={uploadBulkUser}>
          {loading ? "Loading..." : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ICCBulk;

const History = ({ showHistory, handleClose, data }) => {
  const fetchBulkHistory = httpsCallable(functions, "fetchBulkHistory");
  const [histortData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);

  const styleObj = { display: "grid", gridTemplateColumns: "2fr 1fr 1fr" };
  useEffect(() => {
    if (!showHistory) return;
    setLoading(true);
    let configExInt = {
      method: "get",
      params: { companyId: data.companyId, actionType: data.actionType },
      url: `${IP}/adminUserProfile/bulkHistory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(data),
    };
    axios(configExInt)
      .then((res) => {
        console.log(res.data);
        setHistoryData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showHistory]);
  return (
    <Dialog
      open={showHistory}
      onClose={() => {
        // if (loading) return;
        handleClose();
      }}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Bulk User Creation History"}
      </DialogTitle>
      <DialogContent>
        {loading && "loading..."}
        {!loading && (
          <div style={styleObj}>
            <div>Created: {histortData?.created}</div>
            <div>failed: {histortData?.failed}</div>

            <div>Errors</div>
          </div>
        )}
        {!loading && (
          <div style={styleObj}>
            <div>Date</div>
            <div>Created</div>
            <div>Errors</div>
          </div>
        )}
        {!loading &&
          histortData?.data?.map((ele) => {
            let createdOn = new Date(ele.createdOn?._seconds * 1000)
              .toISOString()
              .split("T")[0];
            return (
              <div style={styleObj}>
                <div>{createdOn}</div>
                <div>{ele.created}</div>
                <div>{ele.failed}</div>
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};
