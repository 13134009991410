import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

function TrueAndFalse({ question, instruction, qno, qtot, setActiveSubmit }) {
  const [optclick, setOptClick] = useState();

  const optionclick = (val) => {
    setOptClick(val);
    const ans = {
      type: "mcq",
      qno: qno,
      correct: question.answer == Boolean(val == "true"),
    };
    localStorage.setItem("answer", JSON.stringify(ans));

    setActiveSubmit(true);
  };
  return (
    <Box sx={{ padding: { xs: "15px", md: "30px 30px 30px 15px" } }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box style={{}} sx={{ my: "30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            py: "20px",
            borderBottom: "1px solid #00495F80",
            "&:last-child": { borderBottom: "none" },
          }}
        >
          <Box
            onClick={() => {
              optionclick("true");
            }}
            style={{
              background: `${
                optclick == "true"
                  ? "#003A69"
                  : "white"
              }`,
              color: `${optclick == "true" ? "#fff" : "#007C84"}`,
            }}
            sx={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              border: "1px solid #00495F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>{"O"}</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontSize: { xs: "12px", md: "16px" },
              }}
            >
              {"True"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            py: "20px",
            borderBottom: "1px solid #00495F80",
            "&:last-child": { borderBottom: "none" },
          }}
        >
          <Box
            onClick={() => {
              optionclick("false");
            }}
            style={{
              background: `${
                optclick == "false"
                  ? "#003A69"
                  : "white"
              }`,
              color: `${optclick == "false" ? "#fff" : "#007C84"}`,
            }}
            sx={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              border: "1px solid #00495F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>{"X"}</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 600,
                fontSize: { xs: "12px", md: "16px" },
              }}
            >
              {"False"}
            </Typography>
          </Box>
        </Box>
        {/* <Box>
          <label>True</label>
          <input
            type="radio"
            name="radio-option"
            checked={optclick}
            onChange={() => {
              optionclick(true);
            }}
          />
        </Box>
        <Box>
          <label>False</label>
          <input
            type="radio"
            name="radio-option"
            checked={!optclick}
            onChange={() => {
              optionclick(false);
            }}
          />
        </Box> */}
      </Box>
    </Box>
  );
}

export default TrueAndFalse;
