const steps = [
  {
    name: "Initiation",
    img: (
      <svg
        width="44"
        height="52"
        viewBox="0 0 44 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44 30.0723C44 42.1826 34.1503 52 22 52C9.84974 52 0 42.1826 0 30.0723C0 17.962 9.84974 0 22 0C34.1503 0 44 17.962 44 30.0723Z"
          fill="#BCBCBC"
        />
      </svg>
    ),
    cimg: (
      <svg
        width="44"
        height="52"
        viewBox="0 0 44 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44 30.0723C44 42.1826 34.1503 52 22 52C9.84974 52 0 42.1826 0 30.0723C0 17.962 9.84974 0 22 0C34.1503 0 44 17.962 44 30.0723Z"
          fill="#E1F566"
        />
      </svg>
    ),
    dis: "You have initiated compliance policies and procedures that align with DEI goals. This is the first step for leadership and employee buy-in.",
    Strategies:
      "Access Serein's toolkit to strategically advance your DEI goals.",
    next: [
      "Assessment through - Serein’s DEI compliance checklist",
      "Draft a DEI charter that holds all stakeholders responsible",
      "Appoint the required members to address internal cases of harassment, bullying, and aggression",
    ],
    Recommended: [
      "Recognizing micro-aggressions in the workplaces",
      "Strategies to build an inclusive team",
      "Managing conflict",
      "Addressing subtle ‘isms’ in the workplace",
    ],
  },
  {
    name: "Developing",
    img: (
      <svg
        width="126"
        height="54"
        viewBox="0 0 126 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.9855 34.7769C33.3316 37.1417 29.9715 40.772 28.2126 43.2202C23.3789 49.9481 14.0064 51.4837 7.27847 46.65C0.550568 41.8163 -0.984998 32.4438 3.84868 25.7159C7.24207 20.9927 13.1825 14.4472 20.6855 9.59134C27.9595 4.88368 39.6476 -0.021529 52.4102 4.69614C60.8087 7.80068 68.0226 13.2011 72.4708 16.531C73.4588 17.2706 74.3103 17.9081 75.0081 18.3956C79.2986 21.393 80.1557 21.1447 80.8238 20.9512C80.8695 20.938 80.9142 20.9251 80.9591 20.9134C83.7233 20.196 86.9915 18.2985 90.2759 15.4552C93.5038 12.6608 95.8353 9.74439 96.7618 8.35447C101.357 1.46136 110.67 -0.401629 117.563 4.19338C124.456 8.78838 126.319 18.1013 121.724 24.9945C119.32 28.6006 115.204 33.5552 109.911 38.1368C104.675 42.6695 97.351 47.6529 88.4958 49.9513C74.674 53.5387 64.4411 47.6092 57.827 42.9884C55.7828 41.5603 54.0748 40.2978 52.5569 39.1758C48.6111 36.2591 45.9502 34.2922 42.0085 32.8352C42.0082 32.8351 42.0076 32.8348 42.0067 32.8344C41.9835 32.8238 41.765 32.7236 41.0225 32.8893C40.1214 33.0903 38.7503 33.6347 36.9855 34.7769Z"
          fill="#BCBCBC"
        />
        <circle cx="107.285" cy="15.5109" r="2.75359" fill="white" />
      </svg>
    ),
    cimg: (
      <svg
        width="125"
        height="50"
        viewBox="0 0 125 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.9454 33.4472C33.2915 35.812 29.9314 39.4423 28.1725 41.8905C23.3388 48.6184 13.9663 50.1539 7.23843 45.3203C0.510529 40.4866 -1.02504 31.1141 3.80864 24.3862C7.20203 19.663 13.1425 13.1175 20.6455 8.26162C27.9195 3.55397 39.6076 -1.35124 52.3701 3.36642C60.7687 6.47096 67.9826 11.8714 72.4307 15.2013C73.4188 15.9409 74.2703 16.5784 74.9681 17.0659C79.2585 20.0633 80.1157 19.815 80.7838 19.6215C80.8294 19.6083 80.8742 19.5953 80.9191 19.5837C83.6833 18.8662 86.9514 16.9688 90.2358 14.1255C93.4638 11.3311 95.7952 8.41468 96.7218 7.02476C101.317 0.131645 110.63 -1.73134 117.523 2.86367C124.416 7.45867 126.279 16.7716 121.684 23.6648C119.28 27.2709 115.164 32.2255 109.871 36.8071C104.635 41.3397 97.311 46.3232 88.4558 48.6216C74.6339 52.209 64.401 46.2795 57.7869 41.6587C55.7428 40.2306 54.0348 38.9681 52.5169 37.8461C48.571 34.9294 45.9101 32.9625 41.9685 31.5055C41.9682 31.5054 41.9676 31.5051 41.9667 31.5047C41.9435 31.494 41.725 31.3939 40.9824 31.5596C40.0814 31.7606 38.7103 32.305 36.9454 33.4472Z"
          fill="#E1F566"
        />
      </svg>
    ),
    dis: " You have secured processes, infrastructure, and policies to help your team adapt to the company’s DEI goals.",

    Strategies:
      "Access Serein's toolkit to strategically advance your DEI goals.",
    next: [
      "Revamp your recruitment process - structured interviews, bias-free and equitable recruitment processes",
      "Introduce managers to bias-free feedback skills",
      "Design equitable team-building and networking events",
    ],
    Recommended: [
      "Inclusive recruitment",
      "Inclusive promotions and team building strategies",
    ],
  },
  {
    name: "Acceleration",
    img: (
      <svg
        width="52"
        height="117"
        viewBox="0 0 52 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.582 36.3761C51.582 50.6484 40.035 116.06 25.791 116.06C11.547 116.06 0 50.6484 0 36.3761C0 22.1038 11.547 0.935181 25.791 0.935181C40.035 0.935181 51.582 22.1038 51.582 36.3761Z"
          fill="#BCBCBC"
        />
        <circle cx="17.9419" cy="21.1195" r="2.24269" fill="white" />
        <circle cx="33.6402" cy="21.1195" r="2.24269" fill="white" />
      </svg>
    ),
    cimg: (
      <svg
        width="52"
        height="116"
        viewBox="0 0 52 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.582 35.4409C51.582 49.7133 40.035 115.125 25.791 115.125C11.547 115.125 0 49.7133 0 35.4409C0 21.1686 11.547 0 25.791 0C40.035 0 51.582 21.1686 51.582 35.4409Z"
          fill="#E1F566"
        />
        <circle cx="17.9419" cy="20.1843" r="2.24269" fill="black" />
        <circle cx="33.6402" cy="20.1843" r="2.24269" fill="black" />
      </svg>
    ),
    dis: "Your diversity representation is on par with industry standards. Your inclusion and equity efforts are being experienced by employees.",

    Strategies: "",
    next: [
      "Revamp your promotions process - Structured appraisals",
      "Set up required ERGs",
      "Design a sponsorship program",
    ],
    Recommended: ["LGBTQIA inclusion", "PWD inclusion", "Emotional inclusion"],
  },
  {
    name: "Amplification",
    img: (
      <svg
        width="222"
        height="167"
        viewBox="0 0 222 167"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M126.628 72.7906C126.628 88.5942 119.772 161.024 111.315 161.024C102.857 161.024 96.001 88.5942 96.001 72.7906C96.001 56.9871 102.857 33.5474 111.315 33.5474C119.772 33.5474 126.628 56.9871 126.628 72.7906Z"
          fill="#BCBCBC"
        />
        <circle cx="105.244" cy="48.1196" r="2.24269" fill="white" />
        <circle cx="117.244" cy="48.1196" r="2.24269" fill="white" />
        <path
          d="M116.423 37.8775C116.122 22.8468 124.72 -1.44281 139.871 1.80383"
          stroke="#BCBCBC"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle cx="138.969" cy="3.60737" r="3.60737" fill="#BCBCBC" />
        <path
          d="M106.495 37.8775C106.796 22.8468 98.1981 -1.44281 83.0471 1.80383"
          stroke="#BCBCBC"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle
          cx="3.60737"
          cy="3.60737"
          r="3.60737"
          transform="matrix(-1 0 0 1 87.5566 0)"
          fill="#BCBCBC"
        />
        <ellipse
          cx="59.0753"
          cy="72.6849"
          rx="45.5273"
          ry="37.6635"
          transform="rotate(36.4657 59.0753 72.6849)"
          fill="#BCBCBC"
        />
        <ellipse
          cx="65.4089"
          cy="116.198"
          rx="42.9458"
          ry="35.5279"
          transform="rotate(-25.0455 65.4089 116.198)"
          fill="#BCBCBC"
        />
        <ellipse
          cx="45.5273"
          cy="37.6635"
          rx="45.5273"
          ry="37.6635"
          transform="matrix(-0.804213 0.594341 0.594341 0.804213 177.231 15.3365)"
          fill="#BCBCBC"
        />
        <ellipse
          cx="42.9458"
          cy="35.5279"
          rx="42.9458"
          ry="35.5279"
          transform="matrix(-0.905972 -0.423339 -0.423339 0.905972 210.617 102.192)"
          fill="#BCBCBC"
        />
      </svg>
    ),
    cimg: (
      <svg
        width="222"
        height="167"
        viewBox="0 0 222 167"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M126.55 72.7906C126.55 88.5942 119.694 161.024 111.237 161.024C102.779 161.024 95.9229 88.5942 95.9229 72.7906C95.9229 56.9871 102.779 33.5474 111.237 33.5474C119.694 33.5474 126.55 56.9871 126.55 72.7906Z"
          fill="#E1F566"
        />
        <circle cx="105.166" cy="48.1196" r="2.24269" fill="#1E1B1B" />
        <circle cx="117.166" cy="48.1196" r="2.24269" fill="#1E1B1B" />
        <path
          d="M116.345 37.8775C116.044 22.8468 124.642 -1.44281 139.793 1.80383"
          stroke="#BCBCBC"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle cx="138.891" cy="3.60737" r="3.60737" fill="#E1F566" />
        <path
          d="M106.417 37.8775C106.718 22.8468 98.1199 -1.44281 82.969 1.80383"
          stroke="#BCBCBC"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle
          cx="3.60737"
          cy="3.60737"
          r="3.60737"
          transform="matrix(-1 0 0 1 87.4785 0)"
          fill="#E1F566"
        />
        <ellipse
          cx="58.9972"
          cy="72.6846"
          rx="45.5273"
          ry="37.6635"
          transform="rotate(36.4657 58.9972 72.6846)"
          fill="#E1F566"
        />
        <ellipse
          cx="65.3308"
          cy="116.198"
          rx="42.9458"
          ry="35.5279"
          transform="rotate(-25.0455 65.3308 116.198)"
          fill="#E1F566"
        />
        <ellipse
          cx="45.5273"
          cy="37.6635"
          rx="45.5273"
          ry="37.6635"
          transform="matrix(-0.804213 0.594341 0.594341 0.804213 177.153 15.3364)"
          fill="#E1F566"
        />
        <ellipse
          cx="42.9458"
          cy="35.5279"
          rx="42.9458"
          ry="35.5279"
          transform="matrix(-0.905972 -0.423339 -0.423339 0.905972 210.539 102.191)"
          fill="#E1F566"
        />
      </svg>
    ),
    dis: "Your sustained DEI effort is pushing the inclusion envelope and impacting the larger industry/society. Your inclusion and equity efforts are reflected in your product/service and are being experienced by employees and external stakeholders. ",

    Strategies: "",
    next: [
      "Address pay-equity",
      "Review your social media handles through an inclusive lens",
      "Introducing returnship and apprentice programs for minority groups",
      "Inclusive CSR",
      "Inclusive review of product/service",
    ],
    Recommended: [
      "Sustainability and DEI amplification",
      "Inclusive communication",
      "Bias free products/services",
    ],
  },
];

export { steps };
