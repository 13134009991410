import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import { Button } from "@mui/material";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useLocation, useNavigate } from "react-router";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

export const EditCompany = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const [color, setColor] = useColor("hex", "#121212");
  const location = useLocation();
  const data = location.state;
  //console.log(data);

  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const showimg = image.url || data.logoURL;
  const [base64Image, setBase64Image] = useState("");

  const createCompany = httpsCallable(functions, "createCompany");

  const [details, setDetails] = useState({
    name: data.name,

    companyId: data.companyId,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setDetails({
      ...details,
    });
  };

  // logo change

  const handleSubmit = () => {
    //console.log("details", details);
    if (base64Image !== "") {
      setDetails({ ...details, logo: base64Image });
    }
    createCompany(details)
      .then((res) => {
        //console.log("res", res);
        navigate("/admin/settings/company-department");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company And Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "100px",
                }}
              >
                <div>
                  <h3>Company Name</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.name}
                    onChange={(e) =>
                      setDetails({ ...details, name: e.target.value })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Company Logo</h3>
                  <br />

                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<FileUploadIcon />}
                  >
                    Upload
                    <input
                      name="logo"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      // onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </div>

              <div style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
