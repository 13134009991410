import Chart from "react-apexcharts";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Container } from "@mui/system";

export default function Stacked({ data, Gbbvbar, overAll }) {
  const option = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        redrawOnParentResize: true,
        zoom: {
          autoScaleYaxis: true,
        },
        animations: {
          enabled: true,
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
      },
      xaxis: {
        type: "category",
        categories: data?.categories || [],
      },
      plotOptions: {
        bar: {
          borderRadius: 50,
          horizontal: true,
        },
      },
      legend: {
        labels: {
          colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
          useSeriesColors: false,
        },
      },
    }),
    [data?.categories]
  );

  const seriesData = useMemo(
    () => [
      { name: "Male", data: data?.male },
      { name: "Female", data: data?.female },
      { name: "Non-Binary", data: data?.nonBinary },
    ],
    [data]
  );

  // useEffect(() => {
  //   setShowimg(false);
  //   setSeriesData([
  //     { name: "Male", data: data?.male },
  //     { name: "Female", data: data?.female },
  //     { name: "Non-Binary", data: data?.nonBinary }
  //   ])
  // }, [data]);

  return (
    <div
      style={{
        height: "90%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!data?.company ? (
        <img
          src={Gbbvbar}
          alt=""
          style={{
            width: "80%",
            objectFit: "cover",
            overflow: "hidden",
            height: "400px",
          }}
        />
      ) : (
        <div className="responsiveGraph">
          <Chart
            options={{
              chart: {
                type: "bar",
                stackType: "100%",
                stacked: true,
                width: "100%",
                toolbar: {
                  tools: {
                    download: false,
                  },
                },
              },
              fill: {
                opacity: 1,
                colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
              },
              xaxis: {
                type: "category",
                categories: data?.categories || [],
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  // borderRadius: 10,
                  barHeight: "80%",
                },
              },
              legend: {
                labels: {
                  colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
                },
                horizontalAlign: "left",
                show: overAll ? false : true,
              },
              colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
              dataLabels: {
                formatter: (value) => {
                  let val = value.toString();
                  console.log("stk", val);
                  // return Number(val) + "%";
                  if (val.length >= 5) {
                    // console.log(val.slice(0, 5));
                    let newval = val.slice(0, 5);

                    let newvaldata = val.slice(5, 7);
                    console.log(newvaldata);
                    if (newvaldata == "99") {
                      let newfil = Number(newval) + 0.01;
                      return Number(newfil).toFixed(2) + "%";
                    } else {
                      return Number(newval) + "%";
                    }
                  } else {
                    // console.log(val);
                    return Number(val).toFixed(2) + "%";
                    console.log(val);
                  }
                },
              },
              tooltip: {
                y: {
                  formatter: function (value) {
                    let val = value.toString();
                    if (val.length > 5) {
                      // console.log(val.slice(0, 5));
                      let newval = val.slice(0, 5);
                      return Number(newval) + "%";
                    } else {
                      return Number(val) + "%";
                    }
                    return val.toFixed(2) + "%";
                  },
                },
              },
            }}
            series={seriesData}
            width="100%"
            height={
              data?.categories?.length <= 2
                ? 200
                : data?.categories?.length * 50
            }
            type="bar"
          />
        </div>
      )}
    </div>
  );
}
