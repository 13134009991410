import { Box, Button, Stack, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../../Sidebar/HrSideBar";

import { CertificateDownloadIcon, RightArrowIcon } from "../../icons/Icons";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useParams } from "react-router-dom";
import { getCookie, setCookie } from "../../../Cookies";
import { toast } from "react-toastify";

import { functions } from "../../../Firebase";
import "../learning.css";
import MobileTopBar from "../../Sidebar/MobaileTopBar";
import parse from "html-react-parser";

// import "../../styles/CourseLibrary/SingleCourse.css";
// import "../../Translater/Translater";
// import Navbar from "../Navbar/Navbar";
// import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
// import CustomAccordions from "../Timeline/CustomAccordions";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import gamesurl from "../Game_Microaggressions.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../../AuthToken";
import HrFooter from "../../Sidebar/HrFooter";
import Download from "../../report/Download.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function ProgressBars({ progress }) {
  let value = progress?.total
    ? Math.ceil((progress?.completed / progress?.total) * 100)
    : "0";
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
}

const GamesContent = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));

    // if (!newobj.LEARNING?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);
  const location = useLocation();
  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const fetchCourseCertificate = httpsCallable(
    functions,
    "fetchCourseCertificate"
  );
  // userEnrollment
  const [courseInfo, setCourseInfo] = useState(null);
  const [progress, setProgress] = useState(null);
  const [userDueDate, setUserDueDate] = useState("");
  const [lessonsInfo, setLessonsInfo] = useState([]);
  const [disclaimerRead, setDisclaimerRead] = useState(false);

  const [certificateInfo, setCertificateInfo] = useState(null);
  const courseId = location.state.courseId;
  console.log("location-------------->", location);
  //console.log(courseId);
  const { clrs } = useSelector((state) => state.createClr);
  const [courseQuiz, setCourseQuiz] = useState([]);
  const { t } = useTranslation();
  const [flagForComplitedCourse, setFlagForComplitedCourse] = useState("");
  const enrollUser = (data) => {
    //if user is already enrolled then redirect to course content page

    if (user_courses && data != "Start") {
      localStorage.removeItem(courseId);

      navigate(`/hr/Learning/games-content/games/${courseId}`, {
        state: {
          courseStatus: data == "Completed" ? true : false,
          certificateInfo: certificateInfo?.certificateDownloadURL,
          courseInfo: courseInfo,
        },
      });
      return;
    }
    //if user is not enrolled then enroll user
    let dueDate = new Date(
      Number(new Date()) +
        1000 * 60 * 60 * 24 * courseInfo.extraInfo.courseExpirationDays
    );

    if (data == "Start") {
      let configrecrut = {
        method: "post",
        data: JSON.stringify({
          startDate: new Date().toString(),
          dueDate: dueDate.toString(),

          courseId: courseId,
          courseName: courseInfo.courseName,
          trainingProvider: courseInfo.extraInfo.trainingProvider,
        }),
        url: `${IP}/gamesEnrollment`,
        params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        //data: "dataregionf",
      };
      axios(configrecrut).then((res) => {
        console.log("");
      });
    }

    // alert("Enrolled Rahul");
    localStorage.removeItem(courseId);

    navigate(`/hr/Learning/games-content/games/${courseId}`, {
      state: {
        courseStatus: data == "Completed" ? true : false,
        certificateInfo: certificateInfo?.certificateDownloadURL,
        courseInfo: courseInfo,
      },
    });
    // navigate(`/hr/Learning/games-content/games/${courseId}`);
  };

  // useEffect(() => {
  //   if (getCookie("UID") == null || getCookie("ROLE") != "user") {
  //     goToHome();
  //     return <></>;
  //   }
  // }, []);

  const params = useParams();
  //console.log(params);
  async function fetchData() {
    let configrecrut = {
      method: "get",

      url: `${IP}/games`,
      params: { courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataregionf",
    };

    const result = await axios(configrecrut);
    //console.log(result.data);
    setCourseInfo(result.data);
    // let dueDate = new Date(
    //   Number(new Date()) +
    //     1000 * 60 * 60 * 24 * result.data.extraInfo.courseExpirationDays
    // );
    //console.log("due date", dueDate);
    // setUserDueDate(dueDate.toDateString());

    if (result.data?.extraInfo?.lessons) {
      //console.log(quizId);
      let arr = result.data?.extraInfo?.lessons
        ? result.data?.extraInfo?.lessons
        : [];

      let configrecrut = {
        method: "get",

        url: `${IP}/games/lessons`,
        params: { id: arr },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        // data: JSON.stringify({ id: arr }),
      };
      axios(configrecrut).then((res) => {
        setLessonsInfo(
          res.data.map((ele, ind) => {
            if (ind == 0) {
              return { ...ele, select: true };
            }
            return { ...ele, select: false };
          })
        );
      });
    }
  }
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userGamesItemDetails/nodeProgress`,
      params: {
        courseId: courseId,
        type: location.state.type ? location.state.type : "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((result) => {
      setProgress(result.data);
      // setDisclaimerRead(result.data?.isDisclaimerAccept);
      if (
        result.data.completed === result.data.total &&
        !result.data.isDisclaimerAccept
      ) {
        setDisclaimerToTrue();
      } else {
        setDisclaimerRead(result.data?.isDisclaimerAccept);
      }

      //console.log(result.data)
    });

    let configrecrutci = {
      method: "get",

      url: `${IP}/userMicroCourse/courseCertificate`,
      params: { courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci).then((result) => {
      if (result.data.error) {
        return;
      }
      setCertificateInfo(result.data);
    });
    fetchData();
  }, [disclaimerRead]);

  //==========get data of course to know which course is allready enrolled==========//

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState();

  useEffect(() => {
    let configrecrutci = {
      method: "get",

      url: `${IP}/userGames/`,
      params: { select: 2 },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        console.log(res.data);
        // setUserCourses([...res.data]);
        const temp = res.data.find((course) => course.courseId == courseId);
        console.log(temp);
        setUserCourses(temp);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  // //console.log(user_courses);

  // imediate function
  const emiditaeFunc = () => {
    setCookie("user_data", 0);

    if (user_courses) {
      console.log(user_courses);

      let res;
      user_courses.progress.completed == 0
        ? (res = t("Start"))
        : user_courses?.progress.completed == user_courses?.progress.total
        ? (res = t("Completed"))
        : (res = t("Continue"));

      return res;
    } else {
      return t("Start");
    }
  };

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => {
    //console.log(newExpanded);
    setLessonsInfo((prev) =>
      prev.map((ele) => {
        if (ele.id == panel) {
          // console.log(ele.id);
          // console.log(ele);

          // ele.select = ele.select ? false : true;
          //console.log(ele);
          return { ...ele, select: ele.select ? false : true };
        }
        return ele;
      })
    );
    // setExpanded(panel);
  };

  const setDisclaimerToTrue = () => {
    console.log("clicked");
    const dataregionf = {
      courseId: courseId,
      courseType: "games",
    };
    let configrecrutci = {
      method: "post",
      url: `${IP}/disclaimer/setDisclaimer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };
    axios(configrecrutci)
      .then((res) => {
        console.log(res.data);
        setDisclaimerRead(true);
      })
      .catch((err) => {
        //console.log(err);
      });
    //  setDisclaimerRead(true);
  };
  const [disclaimerReadButton, setDisclaimerReadButton] = useState(false);

  return (
    <Box
      className="hrmainbox"
      sx={{
        backgroundColor: "#F3F1FF",
        "@media (max-width: 780px)": {
          display: "block",
        },
      }}
    >
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>
      <Box>
        <MobileTopBar />
        <HrTopNavbar title={"Games content"}>
          <Box>
            <Button
              sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Box>
        </HrTopNavbar>
        <Box
          sx={{
            padding: "20px 20px",
            display: "flex",
            gap: "20px",
            minHeight: "90vh",
            "@media (max-width: 780px)": {
              padding: "10px",
              minHeight: "400px",

              // padding: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "85vw",
              minWidth: "100%",
              borderRadius: "24px",
              overflow: "hidden",
              "@media (max-width: 780px)": {
                flexDirection: "column-reverse",
                backgroundColor: "#FFFFFF",
                gap: "1px",
                height: "400px",
                minHeight: "400px",

                overflow: "scroll",

                // padding: "10px",
              },
              // border: "2px solid red",
            }}
          >
            <Box
              sx={{
                minWidth: "60%",
                borderRadius: "24px",
                width: "60%",
                border: "none",
                "@media (max-width: 780px)": {
                  width: "100%",
                  minWidth: "100%",
                },
                backgroundColor: "#FFFFFF",
                padding: "30px 20px",
              }}
            >
              <Box
                className="sectionname"
                sx={{
                  mb: "40px",
                  color: "#666666",

                  border: "none",
                  paddingLeft: "16px",
                }}
              >
                Content
              </Box>

              {courseInfo?.disclaimer && (
                <Accordion
                  expanded={
                    progress?.isDisclaimerAccept
                      ? disclaimerReadButton
                        ? true
                        : false
                      : true
                  }
                  onClick={() => {
                    // setDisclaimerRead(!prev);
                    if (disclaimerReadButton) setDisclaimerReadButton(false);
                    else {
                      setDisclaimerReadButton(true);
                    }
                  }}
                  sx={{
                    border: "none",

                    borderBottom: "1px solid #6846C7",
                    borderTop: "1px solid #FFFFFF",
                    "&:before": {
                      backgroundColor: "#FFFFFF",
                    },
                    py: "12px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#6846C7",
                        // padding: "10px 10px 10px 0px !important",
                        width: "98%",
                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Disclaimer
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="corsediscriptioncontentpage"
                      sx={{
                        color: "#666666",

                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {parse(courseInfo?.disclaimer)}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {!progress?.isDisclaimerAccept && (
                        <button
                          style={{
                            backgroundColor: "#6846C7",
                            color: "white",
                            border: "none",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            setDisclaimerToTrue();
                          }}
                        >
                          Click here to proceed
                        </button>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}

              {lessonsInfo?.map((el) => (
                <Accordion
                  expanded={
                    progress?.isDisclaimerAccept
                      ? el?.select
                      : courseInfo.disclaimer
                      ? courseInfo.disclaimer === ""
                        ? el?.select
                        : ""
                      : el?.select
                  }
                  onClick={() => {
                    if (progress?.isDisclaimerAccept) {
                      handleChange(el.id);
                    } else {
                      courseInfo.disclaimer
                        ? console.log("not undefined")
                        : handleChange(el.id);
                    }
                  }}
                  sx={{
                    border: "none",
                    borderBottom: "1px solid #6846C7",
                    // mb: "20px",
                    pb: "20px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#6846C7",

                        width: "98%",
                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {el?.lessonName}
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="corsediscriptioncontentpage"
                      sx={{
                        color: "#666666",

                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {el?.lessonDescription}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: "24px",
                }}
              >
                <Box
                  className="corsenameincontentpage"
                  sx={{
                    color: "#6846C7",

                    paddingLeft: "16px",
                  }}
                >
                  Certificate
                </Box>
                <Box
                  onClick={() => {
                    if (certificateInfo?.certificateDownloadURL) {
                      window.open(
                        certificateInfo?.certificateDownloadURL,
                        "_blank"
                      );
                    }
                  }}
                  sx={{ fill: "#6846C7", cursor: "pointer" }}
                >
                  <img
                    src={Download}
                    style={{
                      opacity: certificateInfo?.certificateDownloadURL
                        ? ""
                        : "0.5",
                    }}
                    alt=""
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: "24px",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                width: "40%",
                borderRadius: "24px",
                "@media (max-width: 780px)": {
                  width: "100%",
                  minWidth: "100%",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  // objectFit: "cover",
                  borderRadius: "30px 30px 0px 0px",
                }}
              >
                {console.log(courseInfo)}
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "30px 30px 0px 0px",
                  }}
                  src={courseInfo?.courseImageURL}
                  // src={gamesurl}
                  alt="game image"
                />
              </Box>
              <Stack
                spacing={3}
                sx={{
                  padding: "30px 24px",
                  backgroundColor: "#E1F566",
                  position: "relative",
                  top: "-30px",
                  borderRadius: "30px",
                }}
              >
                <Box className="corsenameincontentpage">
                  {courseInfo?.courseName}
                </Box>
                <Box className="corsediscriptioncontentpage">
                  {courseInfo?.courseDescription != undefined
                    ? parse(courseInfo?.courseDescription)
                    : ""}
                </Box>
                <Box>
                  <ProgressBars
                    progress={progress}
                    sx={{ backgroundColor: "" }}
                  />
                  <Box
                    className="corsediscriptioncontentpage"
                    sx={{ mt: "10px" }}
                  >
                    {" "}
                    {progress?.total
                      ? Math.ceil((progress?.completed / progress?.total) * 100)
                      : "0"}{" "}
                    % Completed
                  </Box>
                </Box>
                <Box
                  sx={{
                    "@media (max-width: 780px)": {
                      display: "none",
                      // padding: "10px",
                    },
                  }}
                >
                  {/* <Button>Start</Button> */}

                  {user_courses?.finalStatus == "fail" ? (
                    <Button
                      sx={{
                        color: "#E1F566",
                        padding: { xs: "10px 20px", md: "10px 40px" },
                        background: "#000",
                        borderRadius: "5px",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        "&:hover": {
                          background: "#000",
                        },
                      }}
                      onClick={() => {
                        enrollUser(emiditaeFunc(user_courses));
                      }}
                    >
                      Retry
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        color: "#fff",
                        padding: { xs: "10px 20px", md: "10px 40px" },
                        background: "#000",
                        borderRadius: "5px",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        "&:hover": {
                          background: "#000",
                        },
                      }}
                      onClick={() => {
                        progress?.isDisclaimerAccept
                          ? enrollUser(emiditaeFunc(user_courses))
                          : courseInfo.disclaimer
                          ? courseInfo.disclaimer === ""
                            ? enrollUser(emiditaeFunc(user_courses))
                            : toast.warning("Please Accept Disclaimer First !")
                          : enrollUser(emiditaeFunc(user_courses));
                      }}
                    >
                      {/* {user_courses ? t("continue") : t("Enroll")} */}
                      {emiditaeFunc(user_courses)}
                    </Button>
                  )}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "none",
            "@media (max-width: 780px)": {
              position: "sticky",
              backgroundColor: "#F3F1FF",
              width: "100%",

              bottom: "0px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // padding: "10px",
            },
          }}
        >
          {/* <Button>Start</Button> */}

          {user_courses?.finalStatus == "fail" ? (
            <Button
              sx={{
                color: "#E1F566",
                padding: { xs: "10px 20px", md: "10px 40px" },
                background: "#000",
                borderRadius: "5px",
                fontSize: "16px",
                width: "90%",

                fontFamily: "Poppins",
                fontWeight: 600,
                "&:hover": {
                  background: "#000",
                },
              }}
              onClick={() => {
                enrollUser(emiditaeFunc(user_courses));
              }}
            >
              Retry
            </Button>
          ) : (
            <Button
              sx={{
                color: "#fff",
                padding: { xs: "10px 20px", md: "10px 40px" },
                background: "#000",
                borderRadius: "5px",
                fontSize: "16px",
                width: "90%",

                fontFamily: "Poppins",
                fontWeight: 600,
                "&:hover": {
                  background: "#000",
                },
              }}
              onClick={() => {
                enrollUser(emiditaeFunc(user_courses));
              }}
            >
              {/* {user_courses ? t("continue") : t("Enroll")} */}
              {emiditaeFunc(user_courses)}
            </Button>
          )}
        </Box>
        <HrFooter />
      </Box>
    </Box>
  );
};
export default GamesContent;
