import { Backdrop, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { NotificationAIcons } from "../../utilities/Icons/Icons";
import classes from "./notification.module.css";
import { auth, functions } from "../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Markup } from 'interweave';
import axios from "axios";
import { IP } from "../../baseUrlAndConfig";
import { getCookie } from "../../Cookies";
import { Link, useNavigate } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from "react-toastify";

const NotificationPopup = ({ onClose, notifications }) => {



  const markReadNotifications = httpsCallable(functions,"markReadNotifications")
  const [companySelect,setCompanySelect] = useState(true);
  const [allNotifications,setAllNotifications] = useState([]);
  const [groupDetails,setGroupDetails] = useState({});
  const navigate = useNavigate();



  const markAllAsRead =()=>{
    markReadNotifications({uid:auth._delegate.lastNotifiedUid}).then((res)=>{
      //console.log(res.data)
    })
  }


  const getAllNotifications = async() =>{
    var config = {
    method: "get",

    url: `${IP}/notification/${companySelect?"company":"group"}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("bearer")}`,
    },
  };
  axios(config).then((res) => {
    console.log(res.data);
    setAllNotifications(res.data.data);
  });
  }
  useEffect(()=>{
    getAllNotifications();
  },[companySelect]);

  const formatDate = (date) =>{

    const z = new Date(date*1000);
    let res=''
    res=`${z.getDate()}/${z.getMonth()}/${z.getFullYear()}`
    return res


  }
  const getGroupData = (groupId) =>{
    var config = {
    method: "get",
    
    url: `${IP}/group/${groupId}`,
    // url: `${IP}/group/${groupId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("bearer")}`,
    },
    // params:{groupId:groupId}
  };
  axios(config).then((res) => {
    console.log(res.data);
    const expDate = res.data.data.expDate;
    console.log(expDate);
    const state ={
      groupId:res.data.data.groupId,
      groupName:res.data.data.groupName,
      expDate:expDate,
      companyId:res.data.data.companyId,
      groupDescription:res.data.data.groupDescription
    }
    navigate('/admin/groupEdits', {state:{...state}})
    // return ;
    // setGroupDetails();
  });
  }
  const deleteNotification = (typee,id)=>{

    const type=id;
    const config = {
      method:'delete',
      url:`${IP}/notification/${type}`,
      headers:{
        'Content-Type':'application/json',
        Authorization:`Bearer ${getCookie('bearer')}`
      },
    };
    axios(config).then((res)=>{
      toast.success("Notification Deleted")
      getAllNotifications();
    }).catch(e=>{
      console.log(e);
      toast.error('Something went wrong')
    })


  }

  return (
    
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        // onClick={handleClose}
      >
        <Container>
          <Box sx={{ width: "100%", background: "#fff",border:'2px solid black',boxShadow: 20,}}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent:'space-between',
                borderBottom: "1px solid rgba(0, 124, 132, 0.5)",
                p: "10px",
              }}
            >
              <Box>
              <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#007C84" }}>
                Notifications
              </Typography>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
              onClick={() => {
                markAllAsRead()
              }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    color: "#007C84",
                    fontSize: "16px",

                    cursor: "pointer",
                  }}
                >
                  Mark all as read
                </div>

              </Box> */}
              <Box
                sx= {{justifyContent:'flex-end'}}
                onClick={() => {
                  onClose();
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    color: "#007C84",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  <CancelOutlinedIcon/>
                </div>
              </Box>
            </Box>
            <Box className={classes.example} sx={{ py: "30px", height: "70vh", overflowY: "scroll" }}>
              <Box sx={{ padding: "0px 20px", width: "85%" }}>
                <Box sx={{display:'flex',gap:'20px',marginBottom:'20px'}}>
                  {/* 007C84 */}
                  <Box onClick={()=>{setCompanySelect(true)}} sx={{cursor:'pointer',padding:'5px',borderBottom:`${companySelect?'2px solid #007C84 ':''}`}} >
                  <Typography sx={{color:`${companySelect?"#007C84":""}`}}>Company</Typography>  
                  </Box>
                  <Box onClick={()=>{setCompanySelect(false)}} sx={{cursor:'pointer',padding:'5px',borderColor:`${!companySelect?"#007C84":""}`,borderBottom:`${!companySelect?'2px solid #007C84':''}`}}>
                  <Typography sx={{color:`${!companySelect?"#007C84":""}`}}>Group</Typography>  
                    
                  </Box>
                </Box>
                <Box >
                  {/* <Box sx={{display:'flex',justifyContent:'space-between',mb:'10px'}}>
                    <Typography sx={{fontSize:'32px'}}>{companySelect?"Company":"Group"} Name</Typography>
                    <Typography sx={{fontSize:'32px'}}>Expiry Date</Typography>
                  </Box> */}
                  {allNotifications?.map((_,idx)=>{
                    return(
                      <Box sx={{display:'flex',justifyContent:'space-between',mb:'10px',alignItems:'center'}} key={idx}>
                        {/* <Box>{_.companyName}</Box> */}
                        <Box sx={{whiteSpace:'initial'}}> 
                         <Typography sx={{fontWeight:"600",display:'inline'}}> {companySelect?`${_.companyName}`:`${_.groupName}`}  {companySelect?`Subscription`:`Group`}</Typography> 
                         &nbsp;is going to expire on 
                         <Typography sx={{fontWeight:"600",display:'inline'}}> {formatDate(_.expiryDate._seconds)} </Typography>
                        </Box>
                        {/* <Box>{ {companySelect?`${_.companyName}`:`${_.groupName}`}  {companySelect?`Subscription`:``} is going to expire on   }</Box> */}
                        {companySelect && 
                        <Box sx={{display:"flex",alignItems:'center',gap:'20px'}}>
                          <Box>
                            <Link to={`/admin/settings/companycreate`} state={{companyId:`${_.companyId}`}}>
                              <LaunchIcon sx={{color:'#007C84'}} />
                            </Link>
                          </Box>
                          <Box sx={{cursor:'pointer'}}>
                            <DeleteIcon sx={{color:'red'}} onClick={()=>deleteNotification('company',_.id)}/>
                          </Box>
                        </Box>}
                        {!companySelect && 
                        <Box sx={{display:'flex',alignItems:'center',gap:'20px'}}>
                          <Box sx={{cursor:'pointer'}}>
                          {/* <Link to={`/admin/groupEdits`} state={{groupId:`${_.groupId}`,groupName:`${_.groupName}`,expDate:`${_.expiryDate} ,`}}> */}
                            <LaunchIcon sx={{color:'#007C84'}} onClick={()=>getGroupData(_.groupId)}/>
                          {/* </Link> */}
                          </Box>
                          <Box sx={{cursor:'pointer'}}>
                            <DeleteIcon sx={{color:'red'}} onClick={()=>deleteNotification('group',_.id)}/>
                          </Box>
                        </Box>
                        }
                        
                        {/* <Box>{formatDate(_.expiryDate._seconds)}</Box> */}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Backdrop>
  );
};

export default NotificationPopup;
