import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import pdt from "./utilities/images/pagenotfound.png"
const PageNotFound = () => {
  const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
          navigate(-1)
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
    return (
        <div id="wrapper" style={{width:"600px",margin:"auto"}}>
            <img src={pdt} style={{width:"400px",cursor:"pointer"}} alt="PageNotFound" onClick={()=>navigate(-1)} />
            
        </div >
    )
}

export default PageNotFound