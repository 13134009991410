import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function Mcqpic({ question, instruction, qno, qtot, setActiveSubmit }) {
  // const [optionChossed, setOptionChoosed] = useState();
  const [optclick, setoptClicks] = useState([]);
  useEffect(() => {
    let newarr = [];
    for (let i = 0; i < qtot; i++) newarr[i] = false;
    setoptClicks(newarr);
  }, [question]);
  //console.log(optclick);
  let cnt = 0;
  const optionclick = (num) => {
    // setOptionChoosed(num);
    let ans = {
      type: "mcqpic",
      qno: qno,
      correct: question.correctImage == num - 1, // ans was in 0th index
    };
    localStorage.setItem("answer", JSON.stringify(ans));
    let newarr = [];
    for (let i = 0; i < qtot + 1; i++) {
      if (i == parseInt(num)) {
        newarr[i] = true;
      } else {
        newarr[i] = false;
      }
    }
    setoptClicks(newarr);
    setActiveSubmit(true);
  };
  //console.log(question);
  return (
    <Box sx={{ padding: "30px 30px 30px 15px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ my: "30px" }}>
        <Grid container spacing={5}>
          {question &&
            question.imageOptions.map((items, id) => {
              cnt++;
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row-reverse", md: "column" },
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <img
                        style={{
                          width: "170px",
                          height: "100px",
                          borderRadius: "10px",
                        }}
                        src={items}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          border: "1px solid #717579",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) => optionclick(id)}
                        style={{
                          background: `${optclick[id] ? "#003A69" : "white"}`,
                          color: `${optclick[id] ? "white " : "black"}`,
                        }}
                      >
                        <Typography
                          sx={{ fontFamily: "'Readex Pro', sans-serif", fontWeight: 700 }}
                        >
                          {cnt}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
}

export default Mcqpic;

{
  /* 

<div className="mcq">
      <div className="mcq-main">
        <div className="mcq-main-question-no">
          Questin {qno} of {qtot}
        </div>
        <div className=" mcq-main-questin">Questin : {question.question}</div>
        <div className="mcq-main-inst">{instruction}</div>
        <div className="mcq-main-cnt-pic">
          <div className="mcq-main-options-pic">
            {question &&
              question.imageOptions.map((items, id) => {
                cnt++;
                return (
                  <div key={id}>
                    <div className="mcq-main-pic">
                      <img
                        src={items}
                        className="mcq-main-options-items-drag-image"
                        style={{
                          height: "inherit",
                          width: "15vh",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignitemss: "center",
                      }}
                    >
                      <div className="mcq-main-options-items-pic">
                        <div className="mcq-main-options-items-icon-pic">
                          <div
                            className="mcq-main-options-items-icon-cnt-pic"
                            onClick={(e) => optionclick(e)}
                            style={{
                              backgroundColor: `${
                                optclick[cnt] ? "#004577 " : "white"
                              }`,
                              color: `${optclick[cnt] ? "white " : "black"}`,
                            }}
                          >
                            {cnt}
                          </div>
                        </div>
                        <div className="mcq-main-options-items-txt">
                          {"hello"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>

*/
}
