import React from "react";

function Home({ clr }) {
  return (
    <svg
      width="auto"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6203 0H14.3858C15.2032 0.163771 15.7742 0.693602 16.3395 1.26173C19.8026 4.74174 23.2741 8.21339 26.754 11.6767C27.3314 12.2512 27.8565 12.8359 28 13.6711V14.3273C27.9822 14.3584 27.9677 14.3912 27.9569 14.4253C27.6821 15.7553 26.7003 16.5652 25.351 16.5734C25.2373 16.5742 25.1236 16.5734 24.9966 16.5734V16.9452C24.9966 19.5791 24.9964 22.213 24.996 24.847C24.9943 26.7021 23.7031 27.9953 21.8539 27.9979C20.4688 27.9999 19.0837 28 17.6986 27.9982C16.9914 27.9982 16.6912 27.6945 16.691 26.9791C16.6906 24.8829 16.6903 22.7867 16.6901 20.6905C16.689 19.777 16.121 19.2127 15.203 19.2088C14.4197 19.2054 13.6357 19.2071 12.8521 19.2083C11.861 19.2098 11.3136 19.7566 11.3131 20.7479C11.3121 22.8441 11.3118 24.9404 11.3122 27.0366C11.3117 27.6765 10.9909 27.998 10.3566 27.9984C8.94416 27.9994 7.53171 28.0028 6.11931 27.995C5.85554 27.9968 5.59241 27.9688 5.33493 27.9115C3.9325 27.5779 3.01293 26.3993 3.00953 24.9263C3.00339 22.256 3.00772 19.5856 3.00772 16.9152V16.6107C2.9805 16.5973 2.95192 16.5868 2.92248 16.5795C2.78596 16.5761 2.64917 16.5786 2.51282 16.572C1.56799 16.5263 0.840366 16.1022 0.357459 15.2903C-0.259939 14.2524 -0.0649135 13.0034 0.844584 12.0933C4.44543 8.48978 8.04586 4.88582 11.6459 1.2814C12.2177 0.706533 12.7925 0.166017 13.6203 0Z"
        fill={clr}
      />
    </svg>
  );
}

export default Home;
