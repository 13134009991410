import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import $ from "jquery";
import { auth, db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import "./Form.css";
import "../StyleAdminComponents/GroupList.css";
import { useNavigate } from "react-router-dom";
import { EditSvg, DeleteSVg } from "./icons";
import { getCookie } from "../../../Cookies";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "@mui/material/Select";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const Course = ({ editCourseInfo, setIsCourseIdSet, checkEditFlag }) => {
  //Course
  const navigate = useNavigate();
  const createCourse = httpsCallable(functions, "createCourse");
  const addCourseExtraInfo = httpsCallable(functions, "addCourseExtraInfo");
  const getCourses = httpsCallable(functions, "getCourses");
  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const getAdminAndInstructorList = httpsCallable(
    functions,
    "getAdminAndInstructorList"
  );
  const courseSharedNotification = httpsCallable(
    functions,
    "courseSharedNotification"
  );
  const [quillDescription, setQuillDescription] = useState("");

  const [courseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDisclaimer, setCourseDisclaimer] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);
  const [trainingProvider, setTrainingProvider] = useState("");
  const [courseExpirationDays, setCourseExpirationDays] = useState(0);
  const [templateArr, setTemplateArr] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [accessMode, setAccessMode] = useState("free");
  const [hasQuiz, setHasQuiz] = useState("yes");
  const [instructorArr, setInstructorArr] = useState(null);
  const [instructorId, setInstructorId] = useState("");
  const [sharedByArr, setSharedByArr] = useState(null);
  const [courseId, setCourseId] = useState("");
  // const [courseList, setCourseList] = useState(null);
  let newDate = new Date().toDateString();
  const [disclaimerCheckBox, setDisclaimerCheckBox] = useState(false);
  const [createdDate, setCreatedDate] = useState(newDate);
  const [lastVisited, setLastVisited] = useState(newDate);
  const [courseShare, setCourseShare] = useState(null);
  const [dependencyArr, setDependencyArr] = useState([]);
  const [dependencyMode, setDependencyMode] = useState("or");
  const [flow, setFlow] = useState("linear");
  // const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",
    courseDescription: "",
    trainingProvider: "",
    courseDuration: "",
    courseExpirationDays: "",
    imageUrl: "",
    instructorId: "",
    selectedTemplate: "",
    type: "",
  });
  const [editdataflagForLoading, setEditdataflagForLoading] = useState(true);
  const imageRef = useRef(null);
  var docURL = [];
  var files = [];
  const [role, setRole] = useState("");
  //console.log(sharedByArr);
  // if (courseName!== ""){
  //   setCheckErrorInForm((prev)=>({...prev,courseName:""}))
  //   };
  // if (courseDescription !== ""){
  //   setCheckErrorInForm((prev)=>({...prev,courseDescription:""}))
  //   };
  // if (trainingProvider !== "") {
  //   setCheckErrorInForm((prev)=>({...prev,trainingProvider:""}))
  //   ;}

  // if (courseDuration !== 0) {
  //   setCheckErrorInForm((prev)=>({...prev,courseDuration:""}))
  //    }
  // if (courseExpirationDays !== 0) {
  //   setCheckErrorInForm((prev)=>({...prev,courseExpirationDays:""}))

  // }
  // if (imageUrl !== "") {
  //   setCheckErrorInForm((prev)=>({...prev,imageUrl:""}))
  //   alert("Upload image");
  //     }
  // if (instructorId !== "") {
  //   setCheckErrorInForm((prev)=>({...prev,instructorId:""}))

  // }
  // if (selectedTemplate !== "") {
  //   setCheckErrorInForm((prev)=>({...prev,selectedTemplate:""}))

  // }

  const handleCourseShare = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setCourseShare(value);
  };

  const onImageClick = () => {
    imageRef.current.click();
  };
  const formCheck = () => {
    if (courseName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseName: "error" }));
      return "Course Name";
    }
    if (courseDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseDescription: "error" }));
      return "Description";
    }
    if (trainingProvider === "") {
      setCheckErrorInForm((prev) => ({ ...prev, trainingProvider: "error" }));
      return "Traing Provider";
    }

    if (courseDuration == 0) {
      setCheckErrorInForm((prev) => ({ ...prev, courseDuration: "error" }));
      return "Duration";
    }
    if (courseExpirationDays === 0) {
      setCheckErrorInForm((prev) => ({
        ...prev,
        courseExpirationDays: "error",
      }));
      return "Expiration Days";
    }
    if (imageUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }

    if (courseType === "") {
      setCheckErrorInForm((prev) => ({ ...prev, type: "error" }));

      return "courseType";
    }

    if (role == "admin") {
      if (instructorId == "") {
        setCheckErrorInForm((prev) => ({ ...prev, instructorId: "error" }));
        return "Instructor";
      }
    }

    if (selectedTemplate == "") {
      setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
      return "Certificate Template";
    }
    return "allIsWell";
  };

  if (editdataflagForLoading) {
    // //console.log(checkEditFlag)

    document.getElementsByClassName("course-builder-container").display =
      "none";
  }

  useEffect(() => {
    if (!editCourseInfo) return;
    setCourseId(editCourseInfo.courseId);
    if (editCourseInfo.disclaimer) {
      setDisclaimerCheckBox(true);
    } else {
      setDisclaimerCheckBox(false);
    }
    setCourseName(editCourseInfo.courseName);
    setCourseDescription(editCourseInfo.courseDescription);
    setCourseDisclaimer(editCourseInfo.disclaimer);
    setImageUrl(editCourseInfo.courseImageURL);
    setCourseDuration(editCourseInfo.extraInfo.courseDuration);
    setFlow(editCourseInfo.extraInfo.courseProgress);
    setInstructorId(editCourseInfo.courseInstructor);
    setSelectedTemplate(editCourseInfo.extraInfo.certifiateTemplate);
    setAccessMode(editCourseInfo.extraInfo.accessMode);
    setCourseType(editCourseInfo?.type);
    setCourseShare(editCourseInfo.extraInfo.courseShare);
    //courseProgress
    setTrainingProvider(editCourseInfo.extraInfo.trainingProvider);
    setCourseExpirationDays(editCourseInfo.extraInfo.courseExpirationDays);
    setDependencyArr(editCourseInfo.extraInfo.courseDependency);
    let created = new Date(
      editCourseInfo.created._seconds * 1000
    ).toDateString();
    setCreatedDate(created);
    setEditdataflagForLoading(false);
    //console.log("i am in useEffect", new Date());
    //console.log(editCourseInfo);
  }, [editCourseInfo]);

  useEffect(() => {
    let tRole = getCookie("ROLE");
    setRole(tRole);
    let uid = getCookie("UID");
    if (tRole == "instructor") {
      setInstructorId(uid);
    }
    var config = {
      method: "get",

      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config)
      .then((res) => {
        setTemplateArr(res.data);
      })
      .catch((er) => {});

    var configs = {
      method: "get",

      url: `${IP}/userProfile/adminAndInstructor`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configs)
      .then((res) => {
        setInstructorArr(res.data.instructor_list);
        setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
        //setTemplateArr(res.data);
      })
      .catch((er) => {});
    // fetchCertificateTemplate().then((res) => {
    //   setTemplateArr(res.data);
    // });
    // getCourses().then((result) => {
    //   setCourseList([...result.data].map((ele) => ({ ...ele, select: false })));
    // });
    // getAdminAndInstructorList().then((res) => {
    //   setInstructorArr(res.data.instructor_list);
    //   setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
    //   // let shared = res.data.admin_list;
    //   // let instructorList = [];
    //   // res.data.instructor_list;
    //   // instructorList.forEach(ele=>)
    // });
  }, []);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    } else {
      //console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);
      setImgFileName(e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };

  const addCourse = () => {
    const check = formCheck();

    let nsn = courseName.replaceAll(" ", "");

    for (let i = 0; i < nsn.length; i++) {
      if (nsn[i] == "/") {
        alert("you can not use any special characters");
        return;
      }
    }
    // let dat=(/[^A-Za-z0-9]/.test(nsn))

    //                if(!dat){

    //                }else{

    //                  alert("you can not use any special characters")
    //                  return
    //                }
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckErrorInForm({
      courseName: "",
      courseDescription: "",
      trainingProvider: "",
      courseDuration: "",
      courseExpirationDays: "",
      imageUrl: "",
      instructorId: "",
      selectedTemplate: "",
      type: "",
    });
    setCheckFailed(false); // removes error message after succesful creation
    let dependency = [];
    // for (var option of document.getElementById("courseDependency").options) {
    //   if (option.selected) {
    //     dependency.push(option.value);
    //   }
    // }
    let shared = [];
    for (var option of document.getElementById("courseShare").options) {
      if (option.selected) {
        //console.log(option.value);
        shared.push(option.value);
      }
    }
    if (shared.length > 0) {
      let data = JSON.stringify({
        courseId: courseId,
        course: courseName,
        users: shared,
      });

      var config = {
        method: "post",

        url: `${IP}/adminNotification/courseSharedNotification`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {})
        .catch((er) => {});
    }

    let data = JSON.stringify({
      courseId: courseId,
      courseDescription: courseDescription,
      disclaimer: disclaimerCheckBox ? courseDisclaimer : null,
      image: base64Img ? base64Img : imageUrl,
      type: courseType,
      courseInstructor:
        role == "ADMIN"
          ? document.getElementById("courseInstructor")?.value
          : instructorId,
      courseName: courseName,
      created: createdDate, // date
    });

    var config = {
      method: "post",

      url: `${IP}/courseBuilder/course`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        let dataex = JSON.stringify({
          courseDuration: courseDuration,
          trainingProvider: trainingProvider,
          courseExpirationDays: courseExpirationDays,
          accessMode: accessMode,
          hasQuiz: hasQuiz,
          courseDependency: dependency,
          courseComposeMode: dependencyMode,
          certifiateTemplate: selectedTemplate,
          courseId: courseId,
          courseProgress: flow,
          courseShare: shared,
        });

        let config = {
          method: "patch",

          url: `${IP}/courseBuilder/course`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(config)
          .then((result) => {})
          .catch((err) => {});
      })
      .catch((er) => {});

    sessionStorage.setItem("courseId", courseId);

    alert("Course " + courseName + " added");
    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("addC").style.display = "none";
    document.getElementById("editC").style.display = "";
    localStorage.setItem("courseId", courseId);

    setIsCourseIdSet(courseId);
  };
  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }
  //change this and do it the React Way
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }], // dropdown with defaults from theme
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const modulesForDisclaimer = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }], // dropdown with defaults from theme
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
      [{ header: [1, 2, false] }],

      // { size: ["small", false, "large", "huge"] },
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "link",
    "header",
  ];

  const formatsForDisclaimer = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "link",
    "header",
    "font",
    "size",
  ];

  return (
    <>
      {" "}
      {!checkEditFlag ? (
        <div className="course-builder-container">
          <h2 className="course-builder-heading">Internal Training LMS</h2>
          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>
                  <th>Training provider</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>
                  <td>{trainingProvider}</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg style={{ marginRight: "3px" }} />
                      </div>
                      <div>{/* <DeleteSVg /> */}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="course-builder-top">
            <div className="course-builder-info">
              <label htmlFor="courseName" className="course-builder-inputLable">
                Course Title *
              </label>
              <input
                type="text"
                className="course-builder-inputBar"
                id="courseName"
                value={courseName}
                style={{
                  border: `${
                    checkErrorInForm.courseName == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  setCourseName(e.target.value);
                  let value = e.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setCheckErrorInForm((prev) => ({ ...prev, courseName: "" }));
                  if (!editCourseInfo) {
                    setCourseId(value + "_" + time);
                  }
                }}
              />
              {checkErrorInForm.courseName == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Title
                </span>
              )}
            </div>

            <div className="course-builder-info">
              <label htmlFor="courseName" className="course-builder-inputLable">
                Course Type *
              </label>

              <select
                value={courseType}
                id="courseType"
                className="course-builder-inputSelect"
                onChange={(e) => {
                  setCourseType(e.target.value);

                  setCheckErrorInForm((prev) => ({ ...prev, type: "" }));
                }}
              >
                <option value="">Select course type</option>
                <option value="scorm">Scorm</option>
                <option value="nonscorm">Non scorm</option>
              </select>

              {checkErrorInForm.type == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Select Course Type
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Featured Image *</p>
              {imageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
                <img
                  style={{
                    width: "20vw",
                    height: "8em",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                  src={imageUrl}
                ></img>
              )}
              <input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e);
                  setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
                }}
              />
              {checkErrorInForm.imageUrl == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  upload Image
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Short description*</p>
              <div style={{ width: "500px" }}>
                <ReactQuill
                  theme="snow"
                  value={courseDescription}
                  modules={modules}
                  formats={formats}
                  onChange={setCourseDescription}
                />
              </div>

              {/* <textarea
                type="text"
                className="course-builder-textarea"
                value={courseDescription}
                style={{
                  border: `${
                    checkErrorInForm.courseDescription == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDescription"
                onChange={(event) => {
                  setCourseDescription(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDescription: "",
                  }));
                }}
              /> */}
              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description
                </span>
              )}
            </div>
            {/* Disclaimer */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disclaimerCheckBox}
                    onChange={(e) => {
                      setDisclaimerCheckBox(e.target.checked);
                      setCourseDisclaimer("");
                    }}
                  />
                }
                label="Disclaimer Required?"
              />
            </FormGroup>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Enter Disclaimer</p>
              <div style={{ width: "500px" }}>
                <ReactQuill
                  readOnly={!disclaimerCheckBox}
                  theme="snow"
                  value={courseDisclaimer}
                  modules={modulesForDisclaimer}
                  formats={formatsForDisclaimer}
                  onChange={setCourseDisclaimer}
                  height={500}
                />
              </div>

              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description
                </span>
              )}
            </div>
            {/* Disclaimer End */}
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Course Duration (hrs)*
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                value={courseDuration}
                style={{
                  border: `${
                    checkErrorInForm.courseDuration == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDuration"
                min={0}
                onChange={(event) => {
                  setCourseDuration(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDuration: "",
                  }));
                }}
              />

              {checkErrorInForm.courseDuration === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Duration
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Training Provider*</p>
              <input
                type="text"
                className="course-builder-inputBar"
                value={trainingProvider}
                style={{
                  border: `${
                    checkErrorInForm.trainingProvider == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="trainingProvider"
                onChange={(event) => {
                  setTrainingProvider(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    trainingProvider: "",
                  }));
                }}
              />
              {checkErrorInForm.trainingProvider == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Training Provider Name
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Access Mode
              </div>
              <select
                value={accessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                onChange={(e) => setAccessMode(e.target.value)}
              >
                <option value="free">Free</option>
                <option value="closed">Closed</option>
              </select>
            </div>
            {/* <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Quiz
            </div>
            <select
              value={hasQuiz}
              onChange={(e) => setHasQuiz(e.target.value)}
              id="hasQuiz"
              className="course-builder-inputSelect"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}
          </div>
          <div className="course-builder-top">
            {/* <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Course Dependency
            </div>
            <select
              onChange={(e) => {
                const { value } = e.target;
                let arr = Array.from(e.target.selectedOptions);
                setDependencyArr(arr.map((ele) => ele.value));
              }}
              id="courseDependency"
              className="course-builder-multipleSelect"
              multiple
            >
              {courseList?.map((ele, id) => (
                <option value={ele.courseId} key={id}>
                  {ele.courseName}
                </option>
              ))}
            </select>
          </div>
          <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Course Dependency Mode
            </div>
            <select
              value={dependencyMode}
              onChange={(e) => setDependencyMode(e.target.value)}
              id="inclusive"
              className="course-builder-inputSelect"
            >
              <option value="or">Or</option>
              <option value="and">And</option>
            </select>
          </div> */}
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Course Access Expiration (Days)*
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                id="courseExpirationDays"
                value={courseExpirationDays}
                style={{
                  border: `${
                    checkErrorInForm.courseExpirationDays == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    event.target.value = courseExpirationDays;
                  } else {
                    setCourseExpirationDays(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseExpirationDays: "",
                    }));
                  }
                }}
              />
              {checkErrorInForm.courseExpirationDays === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course ExpirationDays
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Progress
              </div>
              <select
                id="flow"
                value={flow}
                onChange={(e) => setFlow(e.target.value)}
                className="course-builder-inputSelect"
              >
                <option value="linear">Sequential</option>
                <option value="free">Free flow</option>
              </select>
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course ID</p>
              <input
                disabled
                type="text"
                className="course-builder-inputBar"
                id="courseId"
                value={courseId}
              />
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Certificate Template *
              </p>
              <select
                className="course-builder-inputSelect"
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    selectedTemplate: "",
                  }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.selectedTemplate == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {templateArr?.map((item, id) => (
                  <option key={id} value={item.id}>
                    {item.templateName}
                  </option>
                ))}
              </select>
              {checkErrorInForm.selectedTemplate === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  select Template
                </span>
              )}
            </div>
            {role == "admin" && (
              <div className="course-builder-info">
                <div
                  className="course-builder-inputLable"
                  style={{ marginTop: "2vw" }}
                >
                  Course Instructor *
                </div>
                <select
                  id="courseInstructor"
                  className="course-builder-inputSelect"
                  value={instructorId}
                  onChange={(e) => {
                    setInstructorId(e.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      instructorId: "",
                    }));
                  }}
                  style={{
                    border: `${
                      checkErrorInForm.instructorId == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select an Option
                  </option>
                  {instructorArr?.map((item, id) => (
                    <option value={item.userId} key={id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {checkErrorInForm.instructorId === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select instructor
                  </span>
                )}
              </div>
            )}
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Shared With
              </div>
              <Select
                id="courseShare"
                // className="course-builder-multipleSelect"
                value={courseShare}
                onChange={handleCourseShare}
                native
                multiple
              >
                {sharedByArr?.map((item, id) => (
                  <option key={id} value={item.userId}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            <br></br>
            {checkFailed && (
              <div>
                <p style={{ color: "red" }}>Fill all the field in the Form</p>
              </div>
            )}
          </div>
          <button
            id="addC"
            className="course-builder-button"
            onClick={addCourse}
          >
            Save Course
          </button>
          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}
        </div>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Course;
