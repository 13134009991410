import { Box, Typography, Button } from "@mui/material";
import ResponsiveDrawer from "../../Sidebar/HrSideBar";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
} from "../../../utilities/commonStyles/commonStyles";
import { LessonLeftArrowIcons } from "../../../utilities/Icons/Icons";
// import Navbar from "../../Navbar/Navbar";
// import ResponsiveNavbar from "../../Navbar/ResponsiveNavbar";
import { ItemContext } from "./Context/ItemContext";
import CourseContent from "./CoureContentMain/CourseContent";
import { CourseItem } from "./CoureContentMain/CourseItem";
import { usePrompt } from "./hooks/usePromptHook";
import "./SingleCourseContentMain.css";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../Cookies";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";

import "../learning.css";
import MobileTopBar from "../../Sidebar/MobaileTopBar";
function SingleMicroContentMainHr() {
  const { t } = useTranslation();
  const location = useLocation();

  //console.log(location.state)
  const navigate = useNavigate();
  const sampleLessonId = location.state?.sampleLessonId;
  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const getLastItemVisited = httpsCallable(functions, "getLastItemVisited");
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(false);

  const [showTab, setShowTab] = useState(1);
  const [itemsArray, setItemsArray] = useState([]); // quiz is also treated as item
  const [progressIndex, setProgressIndex] = useState(null);
  const [lastItemVisited, setLastItemVisited] = useState("");
  const [presentlessonName, setPresentlessonName] = useState("");
  // //console.log(showTab);

  const toggleTab = (index) => {
    setShowTab(index);
    //console.log(index);
  };

  const { courseId } = useParams();
  //console.log(courseId);
  // if courseId not defined send somewhere else

  // const [courseTree, setCourseTree] = useState();
  const [courseData, setCourseData] = useState(null);
  const [isLinear, setIsLinear] = useState(true);
  const [lessonsArray, setLessonsArray] = useState([]);
  async function fetchData() {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroCourseDetails/course`,
      params: { courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      //data: "dataregionf",
    };

    const response = await axios(configrecrut);

    console.log("cource data:", response.data);
    setCourseData(response.data);
    if (response.data.extraInfo.courseProgress == "linear") {
      if (sampleLessonId) return;
      setIsLinear(true);
    } else {
      setIsLinear(false);
    }
    setLessonsArray(response.data.extraInfo?.lessons);
  }

  //======================================================//

  const [loading, setLoading] = useState(true);

  usePrompt("Do you want to leave ?", true);
  useEffect(() => {
    setLoading(true);
    if (sampleLessonId) {
      setIsLinear(false);

      let configrecrut = {
        method: "get",

        url: `${IP}/userMicroItemDetails`,
        params: { lessonId: sampleLessonId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };
      axios(configrecrut)
        .then((result) => {
          setLoading(false);
          const itemsArray = result.data.filter((ele) => ele.type != "node");
          console.log(itemsArray);
          setItemsArray(itemsArray);
          if (progressIndex == null) setProgressIndex(0);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });

      return;
    }

    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroItemDetails`,
      params: { courseId: courseId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then(async (result) => {
      console.log(result.data);
      setLoading(false);
      let configrecrutf = {
        method: "get",

        url: `${IP}/userMicroProgress/lastVisited`,
        params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };

      axios(configrecrutf).then((lastItem) => {
        setLoading(false);
        console.log(lastItem);
        const itemsArray = result?.data;
        if (lastItem?.data?.itemId) {
          setItemsArray(itemsArray);
          if (lastItem.data.itemType === "video") setLastItemVisited("Video");
          if (lastItem.data.itemType === "pdf") setLastItemVisited("Pdf");
          if (lastItem.data.itemType === "quiz") setLastItemVisited("Quiz");
          if (lastItem.data.itemType === "html") setLastItemVisited("html");
          let index = itemsArray.findIndex(
            (ele) => ele.id == lastItem.data.itemId
          );

          if (location.state?.courseStatus) {
            setProgressIndex(itemsArray.length - 1);
            //console.log("lastItem:", lastItem.data);
          } else {
            setProgressIndex(index);
            //console.log("lastItem:", lastItem.data);
          }
        } else {
          //console.log(itemsArray);
          setItemsArray(itemsArray);
          if (progressIndex == null) setProgressIndex(0);
        }
      });

      console.log(itemsArray);
    });
  }, [lastItemVisited]);

  useEffect(() => {
    fetchData();
  }, []);

  const lessonstatusname = () => {
    if (progressIndex != null) {
      setPresentlessonName(
        itemsArray[progressIndex].name
          ? itemsArray[progressIndex]?.name
          : "course"
      );
    }

    // for (let i = progressIndex; i >= 0; i--) {
    //   if (itemsArray[i]?.headType == "lesson") {
    //     let str = "";
    //     if (itemsArray[i]?.name?.length > 40) {
    //       str = itemsArray[i]?.name?.slice(0, 35) + "....";
    //     } else {
    //       str = itemsArray[i]?.name;
    //     }
    //     setPresentlessonName(str);

    //     break;
    //   }
    // }
  };

  useEffect(() => {
    lessonstatusname();
  }, [progressIndex]);

  return (
    <Box
      className="hrmainbox"
      sx={{ backgroundColor: "#F3F1FF", width: "100%" }}
    >
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      {loading && (
        <Box
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 10, ml: 40, alignItems: "center" }}
        >
          <div className="admin-overdue-bottom-table-cnt-c ">
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td">
              <div className="spinner-container">
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "10px solid #f3f3f3" /* Light grey */,
                    borderTop: "10px solid #007C84" /* Black */,
                    borderRadius: "50%",
                    animation: "spinner 1.5s linear infinite",
                    marginBottom: "200px",
                    marginTop: "120px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Box>
      )}

      {!loading && (
        <ItemContext.Provider
          value={{
            courseId: courseId,
            itemsArray: itemsArray,
            progressIndex: progressIndex,
            setProgressIndex: setProgressIndex,
            sample: sampleLessonId,
            isLinear: isLinear,
            certificateInfo: location.state?.certificateInfo,
            courseStatus: location.state?.courseStatus,
            courseInfo: location?.state?.courseInfo,
          }}
        >
          <Box
            sx={{
              // ...responsiveFlexDirection,
              width: "100%",
              display: "flex",
              "@media (max-width: 780px)": {
                flexDirection: "column",
              },
              // gap: "15px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: showTab ? "75%" : "100%" },
                backgroundColor: "#f3f1ff",
                minWidth: "70%",
              }}
            >
              <MobileTopBar />
              <HrTopNavbar
                title={
                  <Box
                    sx={{
                      zIndex: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "50vw",
                      "&:hover": {
                        overflow: "visible",
                        whiteSpace: "normal",
                      },
                    }}
                  >
                    {presentlessonName}
                  </Box>
                }
              >
                <Button
                  onClick={() => {
                    navigate("/hr/Learning");
                  }}
                  sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
                >
                  Back
                </Button>
                <Box>
                  {!showTab && (
                    <Button
                      sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
                      onClick={() => toggleTab(1)}
                    >
                      Course Content
                    </Button>
                  )}
                </Box>
              </HrTopNavbar>{" "}
              <Box
                sx={{
                  margin: "24px",
                  // "@media (max-width: 780px)": {
                  //   display: "none",
                  // },
                }}
              >
                <CourseItem />
              </Box>
            </Box>

            {showTab != 0 && (
              <Box
                sx={{
                  minWidth: { xs: "100%", md: "25%", marginRight: "24px" },
                }}
              >
                {showTab === 1 && (
                  <CourseContent
                    setShowTab={setShowTab}
                    lessons={courseData?.extraInfo?.lessons}
                    quizzes={courseData?.extraInfo?.quizzes}
                    courseInfo={location?.state?.courseInfo}
                  />
                )}
              </Box>
            )}
            <Box
              sx={{
                margin: "24px",
                // "@media (min-width: 780px)": {
                //   display: "none",
                // },
              }}
            >
              {/* <CourseItem /> */}
            </Box>
          </Box>
        </ItemContext.Provider>
      )}
    </Box>
  );
}

export default SingleMicroContentMainHr;
